import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types'
import { Formik, withFormik } from 'formik';
import { withTranslation } from 'react-i18next';

import { editProtocol, removeAttachment, uploadAttachmentEndpoint } from '../../../api/protocol';
import { useBriefMasterData, useProtocol } from "../Hooks";
import { getProtocolBriefEditSchema } from "../../../components/Validations/FormValidationSchema"
import { BriefForm } from "../../../components/Protocols/Brief"
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { Success } from '../../../components/Notifications/Notifications';
import { CreateAuditModal } from "../../../components/AuditTrail/AuditModal";
import { Spinner } from "../../../components/Utils/Loaders";
import { useEffect } from "react";
import { useFiles } from "../../../components/CustomControls/Files/Hooks";
import { uploadStatusEnum } from "../../../components/CustomControls/Files/Utils";

export const ProtocolEditBriefContainer = ({ protocolId }) => {
    let history = useHistory();
    const [data, , postAPI] = useFiles(uploadAttachmentEndpoint(protocolId));

    const masterData = useBriefMasterData();
    const [isLoading, brief] = useProtocol(protocolId);
    const [modal, setModal] = useState(null);

    useEffect(() => {
        if (data.status === uploadStatusEnum.Uploaded)
            history.push(`/admin/protocols/${protocolId}`);
    }, [data.status])


    const handleSubmit = (values) => {

        setModal(<CreateAuditModal
            onClose={() => setModal(null)}
            onSave={(reason, comments) => confirmSubmit(values.brief, reason, comments)}
        />)
    }

    const confirmSubmit = async (brief, reason, comments) => {

        brief.audit = {
            reason: reason,
            comments: comments
        }

        try {
            const res = await editProtocol(protocolId, brief);

            // Upload attachments if any.
            if (brief.attachments) {
                try {
                    await postAPI(brief.attachments);
                }
                catch (error) {
                    console.log("handleSubmit", error);
                }
            }
            else {
                Success("protocolNotifications.protocol_Edited");
                history.push(`/admin/protocols/${protocolId}`);
            }
        }
        catch (error) {
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleCancel = () => {
        history.push(`/admin/protocols/${protocolId}`);
    }

    const handleRemoveAttach = (attachmentId) => {
        removeAttachment(protocolId, attachmentId)
            .then(() => {
                brief.attachments = brief.attachments.filter(x => { return x.id !== attachmentId });
            })
            .catch(error => {
                console.log(error);
            });
    }

    if (isLoading)
        return <Spinner />;

    return (
        <>
            {modal}
            <Formik
                onSubmit={handleSubmit}
                validationSchema={getProtocolBriefEditSchema}
                initialValues={{ brief }}
            >
                {props => (
                    <BriefForm
                        {...props}
                        protocolId={protocolId}
                        //brief={brief}
                        masterData={masterData}
                        onCancel={handleCancel}
                        onRemoveAttach={handleRemoveAttach}
                    />
                )}
            </Formik>
        </>
    );
}
ProtocolEditBriefContainer.propTypes = {
    protocolId: PropTypes.string.isRequired,
};

const ProtocolEditBriefContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => ({ brief: props.brief }),

    validationSchema: getProtocolBriefEditSchema(),

    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values.brief);
    },

    displayName: 'BriefForm',

})(BriefForm));

ProtocolEditBriefContainerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onRemoveAttach: PropTypes.func.isRequired,
    brief: PropTypes.object.isRequired,
    protocolId: PropTypes.string.isRequired,
    masterData: PropTypes.object.isRequired,
};