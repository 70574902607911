import { getAllNotifications, getUnreadNotifications, markAsReadedNotification } from "../api/notifications";

const receiveNotification = 'NOTIFICATION_RECEIVED';
const requestAllNotificationsType = 'NOTIFICATIONS_ALL_REQUEST';
const receiveAllNotificationsType = 'NOTIFICATIONS_ALL_RECEIVE';
const requestUnreadNotificationsType = 'NOTIFICATIONS_UNREAD_REQUEST';
const receiveUnreadNotificationsType = 'NOTIFICATIONS_UNREAD_RECEIVE';
const requestMarkAsReadedNotificationType = 'NOTIFICATIONS_MARKASREADED_REQUEST';
const receiveMarkAsReadedNotificationType = 'NOTIFICATIONS_MARKASREADED_RECEIVE';
const initialState = { unreadNotifications: [], notifications: [], isLoading: false };

export const actionCreators = {
    requestAllNotifications: () => async (dispatch) => {

        dispatch({ type: requestAllNotificationsType });

        try {
            const response = await getAllNotifications();
            const notifications = response.data;

            dispatch({ type: receiveAllNotificationsType, notifications });
        }
        catch (error) {
            console.log(error)
        }
    },
    requestUnreadNotifications: () => async (dispatch) => {

        dispatch({ type: requestUnreadNotificationsType });

        try {
            const response = await getUnreadNotifications();
            const unreadNotifications = response.data;

            dispatch({ type: receiveUnreadNotificationsType, unreadNotifications });
        }
        catch (error) {
            console.log(error)
        }
    },
    requestMarkAsReadedNotification: (messageId) => async (dispatch) => {

        dispatch({ type: requestMarkAsReadedNotificationType });

        try {
            await markAsReadedNotification(messageId);
            dispatch({ type: receiveMarkAsReadedNotificationType, messageId });
        }
        catch (error) {
            console.log(error)
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === receiveNotification) {
        console.log(action.payload)
        if (action.payload && action.payload.content)
            return { ...state, notifications: state.notifications.concat(action.payload) };
    }

    if (action.type === requestAllNotificationsType || action.type === requestUnreadNotificationsType) {
        return {
            ...state,
            isLoading: true
        };
    }
    if (action.type === receiveAllNotificationsType) {
        return {
            ...state,
            notifications: action.notifications,
            isLoading: false
        };
    }
    if (action.type === receiveUnreadNotificationsType) {
        return {
            ...state,
            notifications: action.unreadNotifications,
            isLoading: false
        };
    }

    if (action.type === requestMarkAsReadedNotificationType) {
        return {
            ...state,
            isLoading: true
        };
    }
    if (action.type === receiveMarkAsReadedNotificationType) {
        return {
            ...state,
            notifications: state.notifications.filter((item) => item.id !== action.messageId),
            isLoading: false
        };
    }

    return state;
};
