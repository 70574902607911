import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { withTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { HandleApiError } from '../../components/Notifications/APIErrorHandler';
import { Success } from '../../components/Notifications/Notifications';
import { TenantForm } from "../../components/Tenants/Tenant";
import { getTenantSchema } from "../../components/Validations/FormValidationSchema";
import { useTenants } from './Hooks';

export const EditTenantContainer = (props) => {
    const { match: { params: { tenantId } } } = props;
    let history = useHistory();

    const [data, setData] = useState({});
    const [, isLoading, , get, , update] = useTenants();

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = async () => {
            const resp = await get(tenantId, isSubscribed);
            if (isSubscribed)
                setData(resp);
        }

        fetchData();

        return () => isSubscribed = false;
    }, [tenantId]);

    if (isLoading)
        return null;

    const handleEdit = async (tenant) => {
        try {
            await update(tenant.tenantId, tenant);
            Success("tenantNotifications.tenant_Updated");
            history.goBack()
        }
        catch (ex) {
            console.log(ex);
            HandleApiError(ex);
        }
    }

    return (
        <EditTenantFormik
            onSubmit={handleEdit}
            onCancel={() => history.goBack()}
            tenant={data}
        />
    );
}

const EditTenantFormik = withTranslation()(withFormik({

    mapPropsToValues: (props) => (props.tenant),
    enableReinitialize: true,
    validationSchema: getTenantSchema(),

    handleSubmit: async (tenant, formikBag) => {
        formikBag.props.onSubmit(tenant);
    },

    displayName: 'TenantForm',

})(TenantForm));

EditTenantFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    tenant: PropTypes.object.isRequired,
};