import { useState } from 'react';
import IotMessagesServices from '../../../../api/Freezer/IotMessagesServices';
import FreezerService from '../../../../api/Freezer/FreezerService';

export default function useFreezerResume(from, to) {
    const [state, setState] = useState({
        resumes: [],
        isLoading: false
    })

    const findResume = async () => {
        const freezers = await FreezerService.Find({ pageSize: 100, withDevices: false });
        const freezerIdMap = freezers.Values.reduce((map, freezer) => map.set(freezer.Id, freezer), new Map())
        const resumeData = await IotMessagesServices.GetWeatherResume({ from, to, freezerIds: Array.from(freezerIdMap.keys()) });
        resumeData.forEach(r => r.Freezer = freezerIdMap.get(r.FreezerId))
        return resumeData;
    };

    const load = async () => {
        setState({ resumes: [], isLoading: true })
        const resumes = await findResume();
        setState({ resumes: resumes, isLoading: false })
    }
    return [state.resumes, state.isLoading, load];
}