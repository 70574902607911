import React from "react";
import { useHistory } from "react-router-dom";
import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';

import { getLaboratoriesSchema } from "../../../components/Validations/FormValidationSchema";
import { Success } from '../../../components/Notifications/Notifications';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { createLaboratory } from "../../../api/protocolAdmin";
import { LaboratoryForm } from "../../../components/Protocols/Admin/Laboratories";

export const CreateLaboratoryContainer = () => {
    let history = useHistory();

    const handleSubmit = () => {
        history.push(`/admin/settings/laboratories`);
    }
    const handleCancel = () => {
        history.push(`/admin/settings/laboratories`);
    }

    return (
        <CreateLaboratoryContainerFormik
            onSubmit={handleSubmit}
            onCancel={handleCancel}
        />
    );
}

const CreateLaboratoryContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => ({}),

    validationSchema: getLaboratoriesSchema(),

    handleSubmit: (values, formikBag) => {

        createLaboratory(values.laboratory)
            .then(res => {
                formikBag.setSubmitting(false);
                Success("protocolNotifications.protocol_Created");
                formikBag.props.onSubmit(res.data);
            }).catch(error => {
                console.log(error);
                formikBag.setSubmitting(false);
                HandleApiError(error);
            });
    },

    displayName: 'LaboratoryForm',

})(LaboratoryForm));
