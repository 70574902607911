import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AuditModal } from "../../../components/AuditTrail/AuditModal";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { AdverseEvents } from "../../../components/MedicalRecords/AdverseEvents";
import { conditions } from "../../../variables/Enums";
import { AuditTrail } from "../../Admin/AuditTrail";
import { useConditions } from "../Hooks/useConditions";
import { ModalAdverseEventHistory } from "../Modals/ModalAdverseEventHistory";

export const ObservationsListContainer = ({ mrn, medicalRecordNumber, title, expandedAll }) => {
    const { t } = useTranslation();
    const [data, query, totalSize, isLoading, handleTableChange,] = useConditions(medicalRecordNumber, null, conditions.observation);

    const [modal, setModal] = useState(null);
    const handleAuditTrail = (entityId) => {
        setModal(
            <AuditModal
                onClose={() => { setModal(null); }}
                title={t("auditTrail.audit")}
            >
                <AuditTrail entity="AdverseEvents" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }
    const handleHistory = (_itemId, _mrn, item) => {
        
        setModal(<ModalAdverseEventHistory
            onClose={() => { setModal(null); }}
            title={t("medicalRecords.history.history")}
            medicalRecordNumber={medicalRecordNumber || mrn}
            conceptId={item.name?.conceptId}
            condition={conditions.observation}
        />)
    }

    const _title = title ?? t("medicalRecords.condition.observation");
        
    return (
        <ExpandableContent title={_title} content="adverseEvents" expandedAll={expandedAll}>
            {modal}
            <AdverseEvents
                withCard={false}
                data={data}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onAuditTrail={handleAuditTrail}
                onHistory={handleHistory}
            />
        </ExpandableContent>
    );
}