// this is used to create scrollbars on windows devices like the ones from apple devices
//import PerfectScrollbar from "perfect-scrollbar";
//import "perfect-scrollbar/css/perfect-scrollbar.css";
import React, { Component } from "react";
// react component that creates notifications (like some alerts with messages)
import NotificationSystem from "react-notification-system";
import { Switch } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../components/Footer/Footer";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { CustomRoute } from "../components/api-authorization/CustomRoute";
// dinamically create routes
import routes from "../routes";
// style for notifications
import { style } from "../variables/Variables.jsx";

//var ps;

class AdminLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      color: "black",
      hasImage: false,
      navbar: false,
      mini: false,
      fixedClasses: "dropdown show-dropdown open"
    };
    this.handleMiniClick = this.handleMiniClick.bind(this);
  }
  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    //if (navigator.platform.indexOf("Win") > -1) {
    //  ps = new PerfectScrollbar(this.refs.mainPanel);
    //}
  }
  componentWillUnmount() {
    //if (navigator.platform.indexOf("Win") > -1) {
    //  ps.destroy();
    //}
  }
  componentDidUpdate(e) {
    //if (navigator.platform.indexOf("Win") > -1) {
    //  setTimeout(() => {
    //    ps.update();
    //  }, 350);
    //}
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
    if (
      window.innerWidth < 993 &&
      e.history.action === "PUSH" &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }

  handleMiniClick = () => {
    this.setState({ mini: !this.state.mini });
    document.body.classList.toggle("sidebar-mini");
  };

  getRoutes = routes => {
    return routes.map((route, i) => {

      if (route.collapse) {
        return this.getRoutes(route.routes);
      }
      if (route.layout === "/admin") {
        return (
          <CustomRoute
            exact
            path={route.layout + route.path}
            key={i}
            route={route}
            routes={routes}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
    return (
      <div className="wrapper">
        <ToastContainer
          position="top-right"
          autoClose={3500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          pauseOnHover
          theme="light"
          enableMultiContainer={true}
        />
        <NotificationSystem ref="notificationSystem" style={style} />
        <Sidebar
          {...this.props}
          image={this.state.image}
          color={this.state.color}
          hasImage={this.state.hasImage}
          mini={this.state.mini}
        />
        <div className="main-panel" ref="mainPanel">
          <AdminNavbar
            {...this.props}
            handleMiniClick={this.handleMiniClick}
            navbar={this.state.navbar}
          />
          <div className="main-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <Switch>
                    {this.getRoutes(routes)}
                  </Switch>
                </div>
              </div>
            </div>
          </div>
          <Footer fluid />
        </div>
      </div>
    );
  }
}

export default AdminLayout;