import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PatientPicker } from '../CustomControls/PatientPicker';

const SearchWidget = () => {
    const { t } = useTranslation();
    const navigation = useHistory();

    const handlePatientSearch = (_controlName, selectedPatient) => {

        if (selectedPatient)
            navigation.push(`/admin/medicalRecords/${selectedPatient.medicalRecordNumber}`)
    }

    return (
        <div className="search-widget">
            <div className="search-box">
                <div className="header">
                    <h4 className="title">{t("dashboard.searchTitle")}</h4>
                </div>
                <PatientPicker
                    id="dashboard-search-patient"
                    name="search-patient"
                    placeholder={t("database.patientSearch")}
                    onChange={handlePatientSearch}
                    showSearchIcon={true}
                />
            </div>
        </div>
    )
}

export default SearchWidget;
