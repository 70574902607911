import { IotDevice } from "./IotDevice";
import { EnumValue } from "./Utils";

export function Freezer({ id, description, device, createAt, deviceId, location, type, state } = {}) {
    this.Id = id ?? null;
    this.Description = description ?? null;
    this.Device = device != null ? new IotDevice(device) : null;
    this.DeviceId = deviceId ?? 0;
    this.CreateAt = createAt ?? null;
    this.Location = location ?? null;
    this.State = FreezerStates.TryParseState(state)
    this.Type = FreezerTypes.TryParseState(type)
    this.HasDevice = function () { return this.Device != null || this.DeviceId > 0; }
}



export const FreezerTypes = {
    Ambient: new EnumValue(1, "ambient"),
    Fridge: new EnumValue(2, "fridge"),
    Freezer: new EnumValue(3, "freezer"),
    UltraFreezer: new EnumValue(4, "ultra freezer"),

    TryParseState: (state) => {
        switch (state?.toLowerCase()) {
            default:
            case "ambient": return FreezerTypes.Ambient
            case "fridge": return FreezerTypes.Fridge
            case "freezer": return FreezerTypes.Freezer
            case "ultra freezer": return FreezerTypes.UltraFreezer
        }
    },

    ToArray: () => {
        const arr = new Array();
        arr.push(FreezerTypes.Ambient)
        arr.push(FreezerTypes.Fridge)
        arr.push(FreezerTypes.Freezer)
        arr.push(FreezerTypes.UltraFreezer)
        return arr;
    }
}

export const FreezerStates = {
    Active: new EnumValue(1, "active"),
    InRepair: new EnumValue(2, "in repair"),
    Inactive: new EnumValue(3, "inactive"),
    Deleted: new EnumValue(4, "deleted"),

    TryParseState: (state) => {
        switch (state?.toLowerCase()) {
            default:
            case "active": return FreezerStates.Active
            case "in repair": return FreezerStates.InRepair
            case "inactive": return FreezerStates.Inactive
            case "deleted": return FreezerStates.Deleted
        }
    },

    ToArray: () => {
        const arr = new Array();
        arr.push(FreezerStates.Active)
        arr.push(FreezerStates.InRepair)
        arr.push(FreezerStates.Inactive)
        return arr;
    }
}

