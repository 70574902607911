import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from "react";
import { useTranslation, withTranslation } from 'react-i18next';
import { changePasswordUser, disableUser, enableUser, resendCreatedUserEmail, updateUserInRole } from "../../api/identity";
import { ModalConfirm } from '../../components/Modals/ModalConfirm';
import { HandleApiError } from '../../components/Notifications/APIErrorHandler';
import { Success } from '../../components/Notifications/Notifications';
import { UserSettingsForm } from "../../components/Users/UserSettingsForm";
import { useRoleMasterData } from "../Security/Authorization/useRoles";
import { useUser } from "./Hooks";

export const UserSettingsContainer = (props) => {
    const { match: { params } } = props;
    const userId = params.userId;

    const [isUserLoading, user] = useUser(userId);
    const [isRoleLoading, masterData] = useRoleMasterData();
    const [modal, setModal] = useState(null);
    const { t } = useTranslation();

    const handleChangeRole = (roleId) => {
        if (!roleId)
            return;

        updateUserInRole(userId, roleId)
            .then(() => {
                Success("userNotifications.user_role_Updated");
            }).catch(error => {
                HandleApiError(error);
            });
    }
    const handleResendEmail = () => {
        resendCreatedUserEmail(userId)
            .then(() => {
                Success("userNotifications.email_sent");
            }).catch(error => {
                HandleApiError(error);
            });
    }
    const handleEnableUser = () => {
        enableUser(userId)
            .then(() => {
                Success("userNotifications.user_Enabled");
            }).catch(error => {
                HandleApiError(error);
            });
    }
    const handleDisableUser = () => {
        disableUser(userId)
            .then(() => {
                Success("userNotifications.user_Disabled");
            }).catch(error => {
                HandleApiError(error);
            });
    }
    const handleNewPassword = (password) => {
        if (!password)
            return;

        setModal(<ModalConfirm
            title={t("userProfile.changePassword_title")}
            onConfirm={() => handleConfirmNewPassword(password)}
            onCancel={() => { setModal(null) }}
        />);
    }
    const handleConfirmNewPassword = (password) => {
        if (!password)
            return;

        changePasswordUser(userId, password)
            .then(() => {
                Success("userNotifications.user_PasswordChanged");
                setModal(null);
            }).catch(error => {
                HandleApiError(error);
                console.log(error)
            });
    }

    if (isRoleLoading || isUserLoading) return null;

    return (
        <>
            {modal}
            <UserSettingsFormik
                onChangeRole={handleChangeRole}
                onResendEmail={handleResendEmail}
                onEnableUser={handleEnableUser}
                onDisableUser={handleDisableUser}
                onNewPassword={handleNewPassword}
                user={user}
                roles={masterData.roles}
            />
        </>
    );
}

const UserSettingsFormik = withTranslation()(withFormik({

    mapPropsToValues: (props) => (props.user),
    displayName: 'UserSettingsForm',

})(UserSettingsForm));

UserSettingsFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onResendEmail: PropTypes.func.isRequired,
    onEnableUser: PropTypes.func.isRequired,
    onDisableUser: PropTypes.func.isRequired,
    roles: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
};