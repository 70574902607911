import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import { WithAuth } from '../../components/Authorization/WithAuth';
import Card from '../../components/Cards/Card';
import { Add, ViewProtocol, VisitTracking } from '../../components/Icons/Icons';
import { ProtocolsFilters } from '../../components/Protocols/Protocols';
import { Tooltip } from "../../components/Utils/Tooltips";
import { useMasterData, useProtocols } from './Hooks';

export const Protocols = () => {
    const { t } = useTranslation();
    const [masterData, isLoadingMasterData] = useMasterData();
    const [data, query, totalSize, isLoading, handleTableChange, handleFilterChange] = useProtocols();

    const columns = [
        { dataField: 'laboratory.title', text: t("protocols.brief.laboratory"), headerStyle: { width: '200px' } },
        { dataField: 'title', text: t("protocols.brief.protocol"), sort: true, headerStyle: { width: '200px' } },
        { dataField: 'status.title', text: t("protocols.brief.status"), headerStyle: { width: '100px' } },
        { dataField: 'phase.title', text: t("protocols.brief.phase"), headerStyle: { width: '100px' } },
        {
            dataField: 'view',
            text: 'Opciones',
            isDummyField: true,
            headerClasses: 'header-protocol-actions',
            classes: 'row-protocol-actions',
            headerStyle: { width: '100px' }, headerAlign: 'center', align: 'center',
            formatter: function (_cell, row, rowIndex, _formatExtraData) {
                return (
                    <div key={rowIndex}>
                        <Tooltip tooltip="Visit Tracking" id={"tooltipvisit-" + row.id}>
                            <NavLink to={`/admin/protocols/${row.id}/section/visittracking`} activeClassName="active" className="btn-table-actions">
                                <VisitTracking />
                            </NavLink>
                        </Tooltip>
                        <Tooltip tooltip={t("protocols.visitTracking.viewProtocol")} id={"tooltipconfig-" + row.id}>
                            <NavLink to={`/admin/protocols/${row.id}`} activeClassName="active" className="btn-table-actions">
                                <ViewProtocol />
                            </NavLink>
                        </Tooltip>
                    </div>
                )
            }
        },
        { dataField: 'patientsObjective', text: t("protocols.visitTracking.patientsObjective"), headerClasses: 'header-protocol-number' },
        { dataField: 'patientsGoalPercent', text: t("protocols.visitTracking.patientsGoalPercent"), headerClasses: 'header-protocol-number' },
        { dataField: 'patientsScreening', text: t("protocols.visitTracking.patientsScreening"), headerClasses: 'header-protocol-number' },
        { dataField: 'patientsSelection', text: t("protocols.visitTracking.patientsSelection"), headerClasses: 'header-protocol-number' },
        { dataField: 'patientsFailed', text: t("protocols.visitTracking.patientsFailed"), headerClasses: 'header-protocol-number' },
        { dataField: 'patientsRandom', text: t("protocols.visitTracking.patientsRandom"), headerClasses: 'header-protocol-number' },
        { dataField: 'patientsCompleted', text: t("protocols.visitTracking.patientsCompleted"), headerClasses: 'header-protocol-number' },
    ];

    return (
        <Card
            header={
                <div className="row">
                    <div className="col-md-2">
                        <WithAuth requiredPermission={["Protocols.Create"]}>
                            <NavLink to="/admin/protocols/create" className="btn btn-primary" activeClassName="active">
                                <Add /> {t("protocols.brief.addProtocol")}
                            </NavLink>
                        </WithAuth>
                    </div>
                    <ProtocolsFilters isLoading={isLoadingMasterData} masterData={masterData} onChangeFilter={handleFilterChange} />
                </div>

            }
            content={
                <BootstrapTable
                    bordered={false}
                    hover
                    remote
                    keyField='id'
                    data={data}
                    columns={columns}
                    classes="table-spaced table-spaced-small"
                    wrapperClasses="table-responsive"
                    pagination={paginationFactory({
                        page: query.pagination.page,
                        sizePerPage: query.pagination.sizePerPage,
                        totalSize: totalSize,
                        hideSizePerPage: true,
                    })}

                    onTableChange={handleTableChange}
                    noDataIndication={() => <>{t("commons.noData")}</>}
                    loading={isLoading}
                    overlay={overlayFactory({ spinner: true })}
                />
            }
        />
    );
}