import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WithAuth } from '../../components/Authorization/WithAuth';
import { Card } from '../../components/Cards/Card';
import { Audit, Edit, IconSVG, Remove } from '../Icons/Icons';

export const Actions = (props) => {
    const { t } = useTranslation();
    const { onCreateEntry, onCreateAppointment, onRedirectTo, onEditPatient, onEditEntry, onDeleteEntry, onAuditTrail, isLoading, status, isProtocol } = props;

    var createEntry = null;
    if (onCreateEntry) {
        createEntry = (
            <button className={`btn btn-primary btn-fill btn-full`} onClick={onCreateEntry} disabled={isLoading}>
                <IconSVG name="Plus" /> {t('medicalRecords.actionNewEntry')}
            </button>
        );
    }

    var redirectTo = null;
    if (onRedirectTo) {
        redirectTo = (
            <button className={`btn btn-primary btn-fill btn-full`} onClick={onRedirectTo} disabled={isLoading}>
                <IconSVG name="back" /> {t('buttons.back')}
            </button>
        );
    }

    var createAppointment = null;
    if (onCreateAppointment) {
        createAppointment = (
            <button className={`btn btn-primary btn-fill btn-full`} onClick={onCreateAppointment} disabled={isLoading}>
                <IconSVG name="addappointment" fill="#fff" /> {t('medicalRecords.actionNewAppointment')}
            </button>
        );
    }
    var editPatient = null;
    if (onEditPatient) {
        editPatient = (
            <button className={`btn btn-primary btn-fill btn-full`} onClick={onEditPatient} disabled={isLoading}>
                <IconSVG name="patient" /> {t('medicalRecords.actionEditPatient')}
            </button>
        );
    }

    var saveEntry = null;
    if (props.onSaveEntry) {
        saveEntry = (
            <button className={`btn btn-primary btn-fill btn-full`} onClick={props.onSaveEntry} disabled={isLoading}>
                <IconSVG name="save" /> {t('buttons.save')}
            </button>
        );
    }

    var publishEntry = null;
    if (props.onPublishEntry && !isProtocol) {
        publishEntry = (
            <button className={`btn btn-success btn-fill btn-full`} onClick={props.onPublishEntry} disabled={isLoading}>
                <IconSVG name="publish" /> {t('buttons.endVisit')}
            </button>
        );
    }

    var exportToPDF = null;
    if (props.onExportToPDF) {
        exportToPDF = (
            <button className={`btn btn-primary btn-fill btn-full`} onClick={props.onExportToPDF} disabled={isLoading}>
                <IconSVG name="export" /> {t('buttons.export')}
            </button>
        );
    }

    var editEntry = null;
    if (onEditEntry && !isProtocol) {
        editEntry = (
            <button className={`btn btn-primary btn-fill btn-full`} onClick={onEditEntry} disabled={isLoading}>
                <Edit /> {t('buttons.edit')}
            </button>
        );
    }

    var deleteEntry = null;
    if (onDeleteEntry && !isProtocol && status !== 'Published') {
        deleteEntry = (
            <button className={`btn btn-danger btn-fill btn-full`} onClick={onDeleteEntry} disabled={isLoading}>
                <Remove /> {t('buttons.delete')}
            </button>
        );
    }

    return (
        <Card
            title={t('medicalRecords.actions')}
            className="card-actions"
            content={
                <>
                    <WithAuth requiredPermission={["MedicalRecords.Create"]}>
                        {createEntry}
                    </WithAuth>
                    <WithAuth requiredPermission={["Agenda.Create"]}>
                        {createAppointment}
                    </WithAuth>
                    <WithAuth requiredPermission={["MedicalRecords.Edit"]}>
                        {editPatient}
                    </WithAuth>
                    <WithAuth requiredPermission={["MedicalRecords.Edit"]}>
                        {editEntry}
                    </WithAuth>
                    <WithAuth requiredPermission={["MedicalRecords.Create"]}>
                        {saveEntry}
                    </WithAuth>
                    <WithAuth requiredPermission={["MedicalRecords.Create"]}>
                        {publishEntry}
                    </WithAuth>
                    <WithAuth requiredPermission={["MedicalRecords.Edit"]}>
                        {deleteEntry}
                    </WithAuth>
                    <WithAuth requiredPermission={["MedicalRecords.Create"]}>
                        {exportToPDF}
                    </WithAuth>
                    {
                        onAuditTrail &&
                        <button
                            type="button"
                            className="btn btn-audit btn-fill btn-full"
                            onClick={onAuditTrail}
                        >
                            <Audit style={{ marginRight: '5px' }} />{t('protocols.visitTracking.auditTrail')}
                        </button>
                    }
                    {redirectTo}
                </>
            }
        />
    );
}

Actions.defaultProps = {
    showNewEntry: true,
    isLoading: false,
};

Actions.propTypes = {
    onCreateEntry: PropTypes.func,
    onCreateAppointment: PropTypes.func,
    onRedirectTo: PropTypes.func,
    onEditPatient: PropTypes.func,
    onEditEntry: PropTypes.func
};
