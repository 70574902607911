import React from 'react';
import { FreezerTelemetryContainer } from '../containers/Freezers/TelemetryComponents/FreezerTelemetryContainer';
import { Col, Nav, NavItem, Row, Tab } from "react-bootstrap";
import { useProtocolPageAuth } from '../components/Authorization/useAuthorization';
import { useTranslation } from 'react-i18next';
import { ProtocolTelemetryContainer } from '../containers/Freezers/TelemetryComponents/ProtocolTelemetry';
import { TelemetryGeneralViewContainer } from '../containers/Freezers/TelemetryComponents/TelemetryGeneralView';

export function TelemetryPage() {
    const { t } = useTranslation();
    const [isAuthorized, ,] = useProtocolPageAuth();

    return (
        <Tab.Container id="telemetry-home" defaultActiveKey="telemetry-general-view">
            <Row class="clearfix">
                <Col sm={12}>
                    <Nav bsStyle="tabs">
                        <NavItem eventKey="telemetry-general-view">{t("telemetry.generalView")}</NavItem>
                        {
                            isAuthorized(["Freezers.View"]) && <NavItem eventKey="protocol-telemetry">{t("telemetry.protocolTelemetry")}</NavItem>
                        }
                        <NavItem eventKey="freezer-telemetry">{t("telemetry.freezerTelemetry")}</NavItem>
                    </Nav>
                </Col>
                <Col sm={12}>
                    <Tab.Content animation mountOnEnter={true}>
                        <Tab.Pane eventKey="telemetry-general-view">
                            <TelemetryGeneralViewContainer />
                        </Tab.Pane>
                        <Tab.Pane eventKey="protocol-telemetry">
                            <ProtocolTelemetryContainer />
                        </Tab.Pane>
                        <Tab.Pane eventKey="freezer-telemetry">
                            <FreezerTelemetryContainer />
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )
}


