import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CustomSelect } from '../../CustomControls/CustomSelect';
import { CustomSwitch } from '../../CustomControls/CustomSwitch';
import { Save } from '../../Icons/Icons';

export const FormsRestrictions = props => {
    const { t } = useTranslation();
    const { forms, practitioners, isLoading, onSave, onCancel } = props;

    const [selectedPractitioner, setSelectedPractitioner] = useState(null);
    const [userFormsRestrictions, setUserFormsRestrictions] = useState([]);

    const handlePractitionerChange = (selected) => {
        setSelectedPractitioner(selected);
        // Al cambiar de Profesional, cargo sus restricciones
        const userFormRestrictions = forms.map((form) => {
            return form.formRestrictions?.find(x => x.userId === selected.userId);
        }).filter(function (element) { return element !== undefined; });
        setUserFormsRestrictions(userFormRestrictions || []);
    }

    const handleRestrictionChange = (name, selected, formId) => {
        // Modifico State
        let userFormsRestrictionsCopy = JSON.parse(JSON.stringify(userFormsRestrictions));
        var restriction = userFormsRestrictionsCopy.find(x => x.formId === formId);

        if (!restriction) {
            restriction = { formId: formId, userId: selectedPractitioner?.userId, restrictView: false, restrictContribute: false }
            restriction[name] = selected;
            userFormsRestrictionsCopy.push(restriction);
        }
        else {
            let index = userFormsRestrictionsCopy.findIndex(x => x.formId === formId);
            restriction[name] = selected;
            userFormsRestrictionsCopy.splice(index, 1, restriction); // Update. 
        }

        setUserFormsRestrictions(userFormsRestrictionsCopy);
    }

    const handleSave = () => {
        if (onSave && selectedPractitioner)
            onSave(selectedPractitioner.userId, userFormsRestrictions);
    }

    const handleCancel = () => {
        if (onCancel)
            onCancel();
    }

    return (
        <>
            <Grid fluid>
                <Row style={{ marginBottom: "20px" }}>
                    <Col sm={4}>
                        <CustomSelect
                            name="practitioner"
                            isLoading={isLoading}
                            placeholder={t("commons.select")}
                            options={practitioners}
                            getOptionLabel={(option) => option.fullName}
                            getOptionValue={(option) => option.userId}
                            onChange={(_name, value) => handlePractitionerChange(value)}
                            isMulti={false} />
                    </Col>
                </Row>
            </Grid>

            {
                selectedPractitioner &&
                <>
                    <table className="table table-hover table-spaced-small">
                        <thead>
                            <tr>
                                <th>{t("protocols.visitTracking.formRestrictions.forms")}</th>
                                <th>{t("protocols.visitTracking.formRestrictions.restrictView")}</th>
                                <th>{t("protocols.visitTracking.formRestrictions.restrictContribute")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {forms.map((form, idx) => {
                                let userRestrictions = userFormsRestrictions?.find(x => x.formId === form.id);
                                if (!userRestrictions)
                                    userRestrictions = { formId: form.id, userId: selectedPractitioner?.userId, restrictView: false, restrictContribute: false }

                                return (
                                    <tr key={`userRestrictions-${idx}`}>
                                        <td>{form.title}</td>
                                        <td>{<CustomSwitch value={userRestrictions.restrictView} name="restrictView" onChange={(name, value) => handleRestrictionChange(name, value, form.id)} />}</td>
                                        <td>{<CustomSwitch value={userRestrictions.restrictContribute} name="restrictContribute" onChange={(name, value) => handleRestrictionChange(name, value, form.id)} />}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <Grid fluid>
                        <Row>
                            <Col sm={12}>
                                <button type="button" className="btn btn-success btn-right" disabled={!selectedPractitioner || isLoading} onClick={handleSave}><Save /> {t("buttons.save")}</button>
                                <button type="button" className="btn btn-default btn-right" onClick={handleCancel} style={{ marginRight: '10px' }}>{t("buttons.cancel")}</button>
                            </Col>
                        </Row>
                    </Grid>
                </>
            }
        </>
    );
}

FormsRestrictions.defaultProps = {
}

FormsRestrictions.propTypes = {
    forms: PropTypes.array.isRequired,
    practitioners: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};