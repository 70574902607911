import React from "react"
import { Col, Row } from 'react-bootstrap';
import { CustomDate } from "../../../../components/CustomControls/CustomDateTime";
import { ErrorMessage } from "../../../../components/Notifications/Notifications";
import { useTranslation } from "react-i18next";

export function DateRangeComponent({ startDate, endDate, handleChange, name, disabled }) {
    const { t } = useTranslation()

    const onHandleFromChange = (e, v) => {
        const date = v.toDate();
        if (date > endDate) {
            ErrorMessage(t('settings.freezer.validDate'))
            return
        }
        handleChange({ to: endDate, from: date })
    }

    const onHandleToChange = (e, v) => {
        const date = v.toDate();
        if (date < startDate) {
            ErrorMessage(t('settings.freezer.validDate'))
            return
        }
        handleChange({ from: startDate, to: date })
    }

    return (
        <Row>
            <Col sm={12} md={6}>
                <CustomDate
                    name={`${name}_from`}
                    onChange={onHandleFromChange}
                    onBlur={() => { }}
                    value={startDate}
                    dateFormat="DD/MM/YYYY"
                    disabled={disabled}
                    inputProps={{ placeholder: "DD/MM/YYYY" }}
                />
            </Col>
            <Col sm={12} md={6}>
                <CustomDate
                    name={`${name}_to`}
                    onChange={onHandleToChange}
                    onBlur={() => { }}
                    value={endDate}
                    dateFormat="DD/MM/YYYY"
                    disabled={disabled}
                    inputProps={{ placeholder: "DD/MM/YYYY" }} />
            </Col>
        </Row>)
}