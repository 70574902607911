import { EnumValue } from "./Utils";

export function IotDevice({ id, name, createAt, freezerId, state, certificatePath } = {}) {
    this.Id = id ?? 0;
    this.Name = name ?? null;
    this.CreateAt = createAt ?? null;
    this.FreezerId = freezerId
    this.State = DeviceStates.TryParseState(state)
    this.CertificatePath = certificatePath;
}

export const DeviceStates = {
    Active: new EnumValue(1, "active"),
    Asociated: new EnumValue(2, "asociated"),
    InRepair: new EnumValue(3, "in repair"),
    Deleted: new EnumValue(4, "deleted"),

    TryParseState: (state) => {
        switch (state?.toLowerCase()) {
            default:
            case "active": return DeviceStates.Active
            case "asociated": return DeviceStates.Asociated
            case "in repair": return DeviceStates.InRepair
            case "deleted": return DeviceStates.Deleted
        }
    },

    ToArray: () => {
        const arr = new Array();
        arr.push(DeviceStates.Active)
        arr.push(DeviceStates.InRepair)
        arr.push(DeviceStates.Asociated)
        return arr;
    }
}