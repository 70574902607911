import React, { useContext, useState, createContext, useReducer, useEffect } from "react"
import { Col, Row } from 'react-bootstrap';
import { CustomSelect } from "../../../../components/CustomControls/CustomSelect";
import { useTranslation } from "react-i18next";
import { DateRangeComponent } from "./DateRange";

const initialState = {
    from: new Date(Date.now()),
    to: new Date(Date.now()),
    selected: null
}

export const SearchContext = createContext(null);
export const SearchDispatchContext = createContext(null);

export function SearchHeaderContext({ children }) {
    const [state, dispatch] = useReducer(searchReducer, initialState)

    return (
        <SearchContext.Provider value={state}>
            <SearchDispatchContext.Provider value={dispatch}>
                {children}
            </SearchDispatchContext.Provider>
        </SearchContext.Provider>)
}
function searchReducer(state, action) {
    switch (action.type) {
        case 'search':
            return {
                from: action.from,
                to: action.to,
                selected: action.selected
            }
        case 'selectedChange':
            return {
                from: state.from,
                to: state.to,
                selected: action.selected
            }
        default:
            return state;
    }
}

export function SearchHeader({ name, options, handleSearch, Prepend, disabled = false }) {
    const dispatch = useContext(SearchDispatchContext)
    const { t } = useTranslation()
    const [selectedOption, setSelectedOption] = useState(null)
    const [dateRange, setDateRange] = useState({
        from: new Date(Date.now()),
        to: new Date(Date.now())
    })

    const handleSearchCallback = () => {
        if (handleSearch != null)
            handleSearch({
                selected: selectedOption,
                from: dateRange.from,
                to: dateRange.to
            })
        dispatch({
            type: 'search',
            selected: selectedOption,
            from: dateRange.from,
            to: dateRange.to
        })
    }

    useEffect(() => {
        setSelectedOption(null)
    }, [JSON.stringify(options)])

    return (
        <div name={`${name}_nav`}>
            <Row>
                {Prepend}
                <Col sm={2}>
                    <CustomSelect
                        name={`${name}_select`}
                        value={selectedOption}
                        onChange={(e, v) => { setSelectedOption(v) }}
                        options={options}
                        getOptionLabel={(option) => option?.Name}
                        getOptionValue={(option) => option?.Value}
                        isDisabled={disabled}
                    />
                </Col>
                <Col sm={4}>
                    <DateRangeComponent startDate={dateRange.from} endDate={dateRange.to} handleChange={setDateRange} disabled={disabled} />
                </Col>
                <Col sm={2}>
                    <button className='btn btn-primary'
                        onClick={handleSearchCallback}
                        disabled={selectedOption == null}
                    >{t('commons.search')}</button>
                </Col>
            </Row>
        </div>
    )
}