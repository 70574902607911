import PropTypes from 'prop-types'
import React, { useState } from "react"
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap'
import SweetAlert from "react-bootstrap-sweetalert"
import { useTranslation } from 'react-i18next'
import { CustomSelect } from '../CustomControls/CustomSelect'
import { FormErrorMessage } from '../Validations/FormValidationErrors'

export const ConfirmRemove = (props) => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const [error, setError] = useState(null);

    const onInputChange = (evt) => {
        setError(null);
        setInputValue(evt.currentTarget.value);
    }

    const onSelectChange = (_name, value) => {
        setInputValue(null);
        setError(null);
        setSelectedValue(value);
    }

    const handleOnConfirm = () => {
        if (!selectedValue) {
            setError(t("calendarErrors.event_Cancel_SelectReason"));
            return;
        }
        // Cancelación por error de carga.
        if (selectedValue && selectedValue.id === 1) {
            props.onConfirm(props.id, "error");
            return;
        }
        // Cancelación por paciente o médico. 
        if (selectedValue && selectedValue.id !== 1) {
            if (!inputValue) {
                setError(t("calendarErrors.event_Cancel_NotReason"));
                return;
            }
            else {
                props.onConfirm(props.id, inputValue);
            }
        }
    }

    return (
        <React.Fragment>
            <SweetAlert
                title={props.title}
                onConfirm={handleOnConfirm}
                onCancel={() => { props.onClose() }}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="info"
                confirmBtnText={t("buttons.confirm")}
                cancelBtnText={t("buttons.back")}
                showCancel
                allowOutsideClick={false}
            >
                <div style={{ height: '170px' }}>
                    <CustomSelect
                        name="cancelAppointment"
                        placeholder={t('commons.select')}
                        options={[
                            { id: 1, title: t('calendar.reasons.creationError') },
                            { id: 2, title: t('calendar.reasons.canceledByPatient') },
                            { id: 3, title: t('calendar.reasons.canceledByPractitioner') }
                        ]}
                        value={selectedValue}
                        onChange={onSelectChange}
                    />
                    {
                        selectedValue && selectedValue.id !== 1 &&
                        <FormGroup>
                            <br />
                            <ControlLabel>{t('calendar.reasons.reason')}</ControlLabel>
                            <FormControl
                                type="textarea"
                                placeholder={t('calendar.reasons.reason')}
                                name="inputMotivo"
                                value={inputValue}
                                onChange={onInputChange}
                                rows={3}
                                componentClass={"textarea"}
                            />
                        </FormGroup>
                    }
                    {
                        error && <FormErrorMessage><div key={error}></div></FormErrorMessage>
                    }
                </div>
            </SweetAlert>
        </React.Fragment>
    );
}

ConfirmRemove.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
};