import axios from './index';

export const getTenants = () => {
    return axios.get(`api/v1/tenant`);
};
export const getTenant = (tenantId) => {
    return axios.get(`api/v1/tenant/${tenantId}`);
};
export const createTenant = (tenant) => {
    return axios.post(`api/v1/tenant`, tenant);
};
export const editTenant = (tenantId, tenant) => {
    return axios.put(`api/v1/tenant/${tenantId}`, tenant);
};
export const removeTenant = (tenantId) => {
    return axios.delete(`api/v1/tenant/${tenantId}`);
};