import React from 'react';
import { useTranslation } from 'react-i18next';
import { Chart } from '../Charts/Chart';
import { dateFormatter } from '../Utils/Dates';
import { EntryItemsTable } from './EntryItemsTable';

export const VitalsHistory = (props) => {
    const { t } = useTranslation();

    const columns = [
        { dataField: 'created', text: t('commons.date'), formatter: function (cell) { return dateFormatter(cell, "DD/MM/YY") }, headerClasses: 'header-date' },
        { dataField: 'bloodPressure', text: t('medicalRecords.vitals.bloodPressure') },
        { dataField: 'heartRate', text: t('medicalRecords.vitals.heartRate') },
        { dataField: 'respiratoryRate', text: t('medicalRecords.vitals.respiratoryRate') },
        { dataField: 'temperatureC', text: t('medicalRecords.vitals.temperatureC') },
        { dataField: 'oxygenSaturation', text: t('medicalRecords.vitals.oxygenSaturation') }
    ];

    return (<EntryItemsTable
        {...props}
        withCard={false}
        columns={columns}
    />);
}

export const VitalsHistoryChart = ({ data, type }) => {
    const { t } = useTranslation();
    if (!data || data.length === 0)
        return null;

    const options = {
        responsive: true,
        plugins: {
            datalabels: {
                color: '#247dbd',
                align: 'top',
            },
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: "Histórico Signos Vitales",
            },
        },
    };

    const chartData = {
        labels: data.sort(function (a, b) {
            return new Date(a.created) - new Date(b.created);
        }).map(x => dateFormatter(x.created)),
        datasets: [
            {
                label: t("medicalRecords.vitals.bloodPressureHigh"),
                data: data.map(x => x.bloodPressureHigh),
                borderColor: 'rgb(218, 220, 222)',
                backgroundColor: 'rgba(218, 220, 222, 0.39)',
            },
            {
                label: t("medicalRecords.vitals.bloodPressureLow"),
                data: data.map(x => x.bloodPressureLow),
                borderColor: 'rgb(36, 125, 189)',
                backgroundColor: 'rgba(36, 125, 189, 0.29)',
            },
            {
                label: t("medicalRecords.vitals.heartRate"),
                data: data.map(x => x.heartRate),
                borderColor: 'rgb(28, 151, 136)',
                backgroundColor: 'rgba(28, 151, 136, 0.29)',
            },
            {
                label: t("medicalRecords.vitals.respiratoryRate"),
                data: data.map(x => x.respiratoryRate),
                borderColor: 'rgb(187, 44, 13)',
                backgroundColor: 'rgba(187, 44, 13, 0.29)',
            },
            {
                label: t("medicalRecords.vitals.temperatureC"),
                data: data.map(x => x.temperatureC),
                borderColor: 'rgb(156, 187, 13)',
                backgroundColor: 'rgba(156, 187, 13, 0.29)',
            },
            {
                label: t("medicalRecords.vitals.oxygenSaturation"),
                data: data.map(x => x.oxygenSaturation),
                borderColor: 'rgb(160, 13, 187)',
                backgroundColor: 'rgba(160, 13, 187, 0.29)',
            },
        ],
    };

    return (<Chart options={options} data={chartData} type={type} />);
}