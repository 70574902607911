import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { FormGroup, InputGroup } from 'react-bootstrap';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { getPractitioners } from '../../api/identity';
import '../../assets/sass/lbd/_asyncTypeahead.scss';
import { Search } from '../Icons/Icons';
import { HandleApiError } from "../Notifications/APIErrorHandler";
import { useTranslation } from 'react-i18next';

export const PractitionerPicker = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const { t } = useTranslation();

    const handleChange = value => {
        if (value.length > 0)
            props.onChange(props.name, value[0]);
        else
            props.onChange(props.name, null);
    };

    const handleBlur = () => {
        if (props.onBlur)
            props.onBlur(props.name, true);
    };

    const onSearch = (query) => {
        setIsLoading(true);
        getPractitioners({
            pagination: {
                sizePerPage: 100,
            },
            onlyDoctor: props.onlyDoctor,
            query: query
        }).then(response => {
            console.log(response.data.data)
            setOptions(response.data.data);
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
            setOptions([]);
            HandleApiError(error);
        });
    }

    const renderMenuItemChildren = (option, { text }, index) => {
        return (
            <Fragment key={index}>
                <Highlighter search={text}>
                    {option.fullName}
                </Highlighter>
                <div>
                    <small>
                        {option.speciality && <div><b>{t("person.speciality")}:</b> <Highlighter search={text}>{option.speciality}</Highlighter></div>}
                        {option.registrationNumber && <div><b>{t("person.registrationNumber")}</b>: <Highlighter search={text}>{option.registrationNumber}</Highlighter></div>}
                    </small>
                </div>
            </Fragment>
        )
    };

    const typeahead = (<AsyncTypeahead
        {...props}
        renderMenuItemChildren={renderMenuItemChildren}
        labelKey="fullNameUnique"
        filterBy={() => true}
        options={options}
        isLoading={isLoading}
        clearButton={true}
        highlightOnlyResult={true}
        allowNew={false}
        delay={1000}
        onSearch={onSearch}
        onChange={handleChange}
        onBlur={handleBlur}
    />);

    if (props.showSearchIcon)
        return (
            <FormGroup>
                <InputGroup>
                    <InputGroup.Addon><Search /></InputGroup.Addon>
                    {typeahead}
                </InputGroup>
            </FormGroup>
        );

    return typeahead;
}

PractitionerPicker.propTypes = {
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    minLength: PropTypes.number,
    showSearchIcon: PropTypes.bool,
    onlyDoctor: PropTypes.bool,
};

PractitionerPicker.defaultProps = {
    multiple: false,
    onChange: () => { },
    minLength: 2,
    showSearchIcon: false,
    onlyDoctor: false
};