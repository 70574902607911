import { useEffect, useState } from "react";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import { createAdminNotificationAPI, getAdminNotificationAPI, getAdminNotificationsAPI, getLastActiveAdminNotificationAPI, removeAdminNotificationAPI, updateAdminNotificationAPI } from "../../../api/settings";

export const useAdminNotifications = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalSize, setTotalSize] = useState(0);
    const [reload, setReload] = useState(false);
    const [query, setQuery] = useState({
        pagination: {
            page: 1,
            sizePerPage: 10,
            totalSize: 0
        },
        sort: {
            sortField: "Created",
            sortOrder: "desc"
        }
    });

    useEffect(() => {
        let isSubscribed = true

        const fetchData = async () => {

            setIsLoading(true);
            setReload(false);
            try {
                const paginatedItems = await getAdminNotificationsAPI(query);
                if (isSubscribed) {
                    setTotalSize(paginatedItems.data.count);
                    setData(paginatedItems.data.data);
                    //setData([{ created: "2024-06-26 10:20", message: "Mensaje1", from: "2024-06-25 10:20", to: "2024-06-27 10:20", active: true },
                    //{ created: "2024-06-26 10:20", message: "Mensaje2", from: "2024-06-25 10:20", to: "2024-06-27 10:20", active: true },
                    //{ created: "2024-06-26 10:20", message: "Mensaje3", from: "2024-06-25 10:20", to: "2024-06-27 10:20", active: true }]);
                    setIsLoading(false);
                }

            } catch (error) {
                if (isSubscribed) {
                    console.log(error);
                    HandleApiError(error);
                    setIsLoading(false);
                    setTotalSize(0);
                    setData([]);
                }
            }
        }

        fetchData();

        return () => isSubscribed = false;
    }, [query, reload]);


    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        var newQuery = JSON.parse(JSON.stringify(query));
        switch (type) {
            case 'pagination': {
                newQuery.pagination.page = page;
                newQuery.pagination.sizePerPage = sizePerPage;
                setQuery(newQuery);
                break;
            }
            case 'sort': {
                newQuery.sort.sortField = sortField;
                newQuery.sort.sortOrder = sortOrder;
                setQuery(newQuery);
                break;
            }
            default: break;
        }
    }

    return [data, query, totalSize, isLoading, handleTableChange, setReload];
}

export const useAdminNotification = () => {
    const [isLoading, setIsLoading] = useState(true);

    const getLastActiveNotification = async () => {
        try {
            setIsLoading(true);
            const note = await getLastActiveAdminNotificationAPI();
            setIsLoading(false);

            return note;

        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);

            return null;
        }
    }

    const getNotification = async (notificationId) => {
        try {
            setIsLoading(true);
            const note = await getAdminNotificationAPI(notificationId);
            setIsLoading(false);

            return note;

        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);

            return null;
        }
    }

    const createNotification = async (notification) => {
        try {
            setIsLoading(true);
            const documentNote = await createAdminNotificationAPI(notification);
            setIsLoading(false);

            return documentNote;

        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);

            return null;
        }
    }

    const updateNotification = async (notification) => {
        try {
            setIsLoading(true);
            const documentNote = await updateAdminNotificationAPI(notification);
            setIsLoading(false);

            return documentNote;

        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);

            return null;
        }
    }

    const deleteNotification = async (notificationId) => {
        try {
            setIsLoading(true);
            var res = await removeAdminNotificationAPI(notificationId);
            setIsLoading(false);

            return res;

        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);

            return null;
        }
    }

    return [getLastActiveNotification, getNotification, createNotification, updateNotification, deleteNotification, isLoading];
}