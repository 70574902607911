import moment from "moment"

export function MapAsDataSet(data, label) {
    return {
        label,
        fill: false,
        data: data,
        pointRadius: 0,
        tension: 0,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        borderWidth: 3
    }
}

export const commonOptions = {
    animation: false,
    parsing: true,
    responsive: true,
    interaction: {
        mode: "nearest",
        axis: "x",
        intersect: false
    },
    plugins: {
        decimation: {
            enabled: true,
            algorithm: "min-max",
        },
        datalabels: {
            display: false,
        }
    },
}

export const commonPlugins = {
    decimation: {
        enabled: true,
        algorithm: "min-max",
    },
    datalabels: {
        display: false,
    }
}

export const commonScale = {
    x:
    {
        type: 'time',
        autoSkip: false,
        stacked: false,
        time: {
            unit: 'hour',
            displayFormats: {
                hour: 'HH:mm'
            },
            tooltipFormat: 'DD/MM/yyyy HH:mm'
        },
        ticks: {
            callback: function (value, index, values) {
                if (index % 24 === 0) {
                    const format = moment(values[index].value).format("DD/MM")
                    return format
                }

                return value
            }
        }
    }
}