import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { WithAuth } from './components/Authorization/WithAuth';
import SessionTimeout from './components/SessionControls/SessionTimeout';
import { Spinner } from './components/Utils/Loaders';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { AuthorizeRoute } from "./components/api-authorization/AuthorizeRoute";
import { SystemNotification } from './containers/Settings/AdminNotifications/SystemNotification';
import AdminLayout from './layouts/Admin';
import SignalRProvider from './services/signalRService';
import { settingsActions } from './store/Configuration';

export default () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
  const currentUser = useSelector(state => state.authentication.user);
  const settingsState = useSelector(state => state.settings);
  let navigation = useHistory();
  const { i18n } = useTranslation()

  useEffect(() => {
    if (isAuthenticated) {
      i18n.changeLanguage(currentUser.language);

      dispatch(settingsActions.requestDigitalSignEnabled());
    }
  }, [isAuthenticated]);

  const handleLogOut = () => {
    navigation.push({ pathname: `${ApplicationPaths.LogOut}`, state: { local: true } });
  }

  if (settingsState.isLoading)
    return <Spinner />;

  return (
    <>
      <WithAuth>
        <SignalRProvider groupName="SystemNotifications" settings={settingsState.settings}>
          <SystemNotification />
        </SignalRProvider>
      </WithAuth>
      <WithAuth requiredPermission={["MedicalRecords.Create"]}>
        <SessionTimeout isAuthenticated={isAuthenticated} logOut={handleLogOut} />
      </WithAuth>
      <Switch>
        <AuthorizeRoute path="/admin" component={props => <AdminLayout {...props} />} />
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </>
  );
}