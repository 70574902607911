import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { Button, Col, Grid, Modal, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { ChartBar, ChartLine, Table } from '../../../components/Icons/Icons';
import { VitalsHistory, VitalsHistoryChart } from '../../../components/MedicalRecords/VitalsHistory';
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import { Tooltip } from '../../../components/Utils/Tooltips';
import { useVitalsHistory } from "../Hooks/useVitals";

export const ModalVitalsHistory = (props) => {
    const { t } = useTranslation();
    var { medicalRecordNumber } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [fetchHistory] = useVitalsHistory();
    const [type, setType] = useState("bar"); // table, bar, line

    useEffect(() => {
        let isSubscribed = true;
        const fetchData = async () => {
            try {
                const res = await fetchHistory(medicalRecordNumber);
                if (isSubscribed) {
                    setData(res.data);
                    setIsLoading(false);
                }
            } catch (error) {
                HandleApiError(error);
                setIsLoading(false);
                setData(null);
            }
        }

        fetchData();

        return () => isSubscribed = false;
    }, [medicalRecordNumber]);

    if (isLoading)
        return null;

    let component = null;
    switch (type) {
        case "table":
            component = (<VitalsHistory
                query={{ medicalRecordNumber }}
                data={data}
                isLoading={isLoading}
            />);
            break;
        case "line":
        case "bar":
            component = (<VitalsHistoryChart
                data={data}
                type={type}
            />);
            break;
        default:
            break;
    }

    return (
        <Modal show={true} onHide={props.onClose} bsSize="large">
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <div style={{ float: 'right' }}>
                                <Tooltip tooltip={t("medicalRecords.history.viewTable")} id={"tooltipTable"}>
                                    <button className="btn-table-actions" onClick={() => setType("table")}>
                                        <Table />
                                    </button>
                                </Tooltip>
                                <Tooltip tooltip={t("medicalRecords.history.viewChartBar")} id={"tooltipchartBar"}>
                                    <button className="btn-table-actions" onClick={() => setType("bar")}>
                                        <ChartBar />
                                    </button>
                                </Tooltip>
                                <Tooltip tooltip={t("medicalRecords.history.viewChartLines")} id={"tooltipchartLine"}>
                                    <button className="btn-table-actions" onClick={() => setType("line")}>
                                        <ChartLine />
                                    </button>
                                </Tooltip>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            {component}
                        </Col>
                    </Row>
                </Grid>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onClose}>{t('buttons.close')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalVitalsHistory.propTypes = {
    onClose: PropTypes.func.isRequired,
    medicalRecordNumber: PropTypes.string,
    title: PropTypes.string,
};