import React from "react";
import { Navbar } from "react-bootstrap";
import AdminNavbarLinks from "./AdminNavbarLinks.jsx";

const AdminNavbar = props => {

  const getActiveRoute = (routes) => {
    let activeRoute = "EMR";

    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].routes);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        var path = routes[i].layout + routes[i].path.replace(/:id/gi, "");;
        if (window.location.href.indexOf(path) !== -1) {
          return routes[i].name// route.layout + path;
          //return routes[i].layout + path;
        }
      }
    }
    return activeRoute;
  };

  //const getCrumbs = () => {
  //  routes
  //    // Get all routes that contain the current one.
  //    .filter(({ path }) => props.match.path.includes(path))
  //    // Swap out any dynamic routes with their param values.
  //    .map(({ path, ...rest }) => ({
  //      path: Object.keys(props.match.params).length
  //        ? Object.keys(props.match.params).reduce(
  //          (item, param) =>
  //            item.replace(`:${param}`, props.match.params[param]),
  //          getActiveRoute(routes, path)//route.layout + path
  //        )
  //        : getActiveRoute(routes, path),//route.layout + path,
  //      ...rest
  //    }));
  //}

  //// function for responsive that hides/shows the sidebar
  //const mobileSidebarToggle = e => {
  //  document.documentElement.classList.toggle("nav-open");
  ////if (
  ////  document.documentElement.className === "nav-open" &&
  ////  props.fixedClasses !== "dropdown"
  ////) {
  ////  //props.handleFixedClick();
  ////}
  //};

  return (
    <Navbar
      fluid
      collapseOnSelect
      className={props.navbar ? "navbar-fixed" : ""}
    >
      {/*
      <div className="navbar-minimize">
        <button
          id="minimizeSidebar"
          className="btn btn-default btn-fill btn-round btn-icon"
          onClick={props.handleMiniClick}
        >
          <i className="fa fa-ellipsis-v visible-on-sidebar-regular" />
          <i className="fa fa-navicon visible-on-sidebar-mini" />
        </button>
      </div>
      <Navbar.Header>
        <Navbar.Brand>
          {getActiveRoute(routes)}
        </Navbar.Brand>
        <Navbar.Toggle onClick={mobileSidebarToggle} />
      </Navbar.Header>
      */}
      {/* Here we import the links that appear in navbar */}
      {window.innerWidth > 992 ? (
        <Navbar.Collapse>
          <AdminNavbarLinks />
        </Navbar.Collapse>
      ) : null}
    </Navbar>
  );
}

export default AdminNavbar;
