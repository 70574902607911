import { useState } from "react";
import { HandleApiError } from "../../components/Notifications/APIErrorHandler";
import { getTenant, getTenants, editTenant, createTenant, removeTenant } from "../../api/tenants";

export const useTenants = () => {
    const [tenants, setTenants] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getAll = () => {
        setIsLoading(true);
        getTenants()
            .then(response => {
                setTenants(response.data);
                setIsLoading(false);
            }).catch(error => {
                HandleApiError(error);
                setIsLoading(false);
            });
    }

    const get = async (tenantId, isSubscribed) => {

        try {
            setIsLoading(true);
            const resp = await getTenant(tenantId);

            if (isSubscribed) {
                setIsLoading(false);
                return resp.data;
            }

        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);
            return {};
        }

        //return getTenant(tenantId);
    }

    const create = (tenantId, data) => {
        return createTenant(tenantId, data);
    }

    const update = (tenantId, data) => {
        return editTenant(tenantId, data);
    }

    const remove = (tenantId) => {
        return removeTenant(tenantId);
    }

    return [tenants, isLoading, getAll, get, create, update, remove];
}