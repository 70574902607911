import { ErrorMessage, FieldArray } from 'formik';
import React from 'react';
import { Col, ControlLabel, FormControl, FormGroup, Grid, Row } from 'react-bootstrap';
import Card from '../Cards/Card';
import { Remove } from '../Icons/Icons';
import { FormErrorMessage } from '../Validations/FormValidationErrors';


export const RelatedContactDataForm = props => {

    const {
        values,
        handleChange,
        handleBlur,
        t
    } = props;

    const emptyRelatedContact = {
        relationship: "",
        given: "",
        familyName: "",
        phone: "",
        email: "", streetName: '',
        streetNumber: ''
    }
    return (
        <fieldset>
            <legend>{t("person.relatedContactData")}</legend>
            <FieldArray
                name="relatedContacts"
                render={arrayHelpers => (
                    <div>
                        {values.relatedContacts && values.relatedContacts.length > 0 ? (
                            values.relatedContacts.map((relatedContact, index) => (
                                <Card
                                    key={`relatedContact-${index}`}
                                    className="card-form"
                                    content={
                                        <div>
                                            <FormGroup>
                                                <Grid fluid>
                                                    <Row>
                                                        <Col sm={2}>
                                                            <ControlLabel>{t("person.relationship")}</ControlLabel>
                                                            <FormControl type="text" name={`relatedContacts.${index}.relationship`} value={relatedContact.relationship ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                        </Col>
                                                        <Col sm={5}>
                                                            <ControlLabel>{t("person.given")}</ControlLabel>
                                                            <FormControl type="text" name={`relatedContacts.${index}.given`} value={relatedContact.given ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                            <ErrorMessage name={`relatedContacts.${index}.given`} component={FormErrorMessage} />
                                                        </Col>
                                                        <Col sm={5}>
                                                            <ControlLabel>{t("person.familyName")}</ControlLabel>
                                                            <FormControl type="text" name={`relatedContacts.${index}.familyName`} value={relatedContact.familyName ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                            <ErrorMessage name={`relatedContacts.${index}.familyName`} component={FormErrorMessage} />
                                                        </Col>
                                                    </Row>
                                                </Grid>
                                            </FormGroup>
                                            <FormGroup>
                                                <Grid fluid>
                                                    <Row>
                                                        <Col sm={3}>
                                                            <ControlLabel>{t("person.address.streetName")}</ControlLabel>
                                                            <FormControl type="text" name={`relatedContacts.${index}.streetName`} value={relatedContact.streetName ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                            <ErrorMessage name={`relatedContacts.${index}.streetName`} component={FormErrorMessage} />
                                                        </Col>
                                                        <Col sm={1}>
                                                            <ControlLabel>{t("person.address.streetNumber")}</ControlLabel>
                                                            <FormControl type="text" name={`relatedContacts.${index}.streetNumber`} value={relatedContact.streetNumber ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                            <ErrorMessage name={`relatedContacts.${index}.streetNumber`} component={FormErrorMessage} />
                                                        </Col>
                                                        <Col sm={3}>
                                                            <ControlLabel>{t("person.contact.phone")}</ControlLabel>
                                                            <FormControl type="text" name={`relatedContacts.${index}.phone`} value={relatedContact.phone ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                            <ErrorMessage name={`relatedContacts.${index}.phone`} component={FormErrorMessage} />
                                                        </Col>
                                                        <Col sm={4}>
                                                            <ControlLabel>{t("person.contact.email")}</ControlLabel>
                                                            <FormControl type="text" name={`relatedContacts.${index}.email`} value={relatedContact.email ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                            <ErrorMessage name={`relatedContacts.${index}.email`} component={FormErrorMessage} />
                                                        </Col>
                                                        <Col sm={1}>
                                                            <ControlLabel></ControlLabel>
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                onClick={() => arrayHelpers.remove(index)} // remove from the list
                                                            >
                                                                <Remove /> {t("commons.remove")}
                                                        </button>
                                                        </Col>
                                                    </Row>
                                                </Grid>
                                            </FormGroup>
                                        </div>
                                    }
                                />
                            ))
                        ) : ""}

                        <button type="button" className="btn btn-primary" onClick={() => arrayHelpers.push(emptyRelatedContact)}>
                            {t("person.relatedContact_add")}
                        </button>
                    </div>
                )}
            />
        </fieldset>
    );
}