import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from 'react-i18next';
import { putProcedure, removeProcedureAPI } from "../../../api/medicalRecord";
import { AuditModal, CreateAudit, CreateAuditModal } from "../../../components/AuditTrail/AuditModal";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { ProcedureForm } from "../../../components/MedicalRecords/Forms/ProceduresForm";
import { Procedures } from "../../../components/MedicalRecords/Procedures";
import { ModalData } from '../../../components/Modals/ModalData';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { getMedicalRecordsSchema } from "../../../components/Validations/FormValidationSchema";
import { AuditTrail } from "../../Admin/AuditTrail";
import { useProcedure, useProcedures } from "../Hooks/useProcedures";
import { Success } from '../../../components/Notifications/Notifications';
import { ModalConfirm } from '../../../components/Modals/ModalConfirm';
import { ModalSpinner } from '../../../components/Utils/Loaders';

export const ProceduresContainer = (props) => {
    const [content, setContent] = useState(null);
    const { medicalRecordNumber, entryStatus, entryId, expandedAll } = props;
    const [procedures, query, totalSize, isLoading, handleTableChange, setReload] = useProcedures(medicalRecordNumber, entryId, true);
    const [, , , create] = useProcedure();
    const { t } = useTranslation();

    useEffect(() => {
        if (props.contentToReload?.contentName === "procedures")
            setReload(true);
    }, [props.contentToReload]);

    const handleEdit = (id) => {
        setContent(<ProcedureEditContainer
            medicalRecordNumber={medicalRecordNumber}
            entryId={entryId}
            id={id}
            onSubmit={handleSubmit}
            onCancel={handleClose}
        />);
    }

    const handleSubmit = async (data) => {

        if (!data || !data.procedures || data.procedures.length === 0)
            return;

        if ((entryStatus !== "Published" && entryStatus !== "AutoPublished") && entryId == data.procedures[0].entryId && (!data.procedures[0].originalId || data.procedures[0].id == data.procedures[0].originalId)) {
            onConfirmSubmit(data);
        } else {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (data && data.procedures && data.procedures.length > 0) {
                data.procedures.forEach(x => (x.reason && (x.reasonId = x.reason.id))); // Mapeo Reason
                let procedure = data.procedures[0];
                if (reason) {
                    procedure.auditReason = reason;
                    procedure.auditComments = comments;
                }
                await putProcedure(entryId, procedure.id, procedure);
                setContent(null);
                setReload(true);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleClose = () => {
        setContent(null);
    }

    const handleAuditTrail = (entityId) => {
        setContent(
            <AuditModal
                onClose={() => { setContent(null); }}
                title={t("auditTrail.audit")}
            >
                <AuditTrail entity="Procedures" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }

    const handleAddProcedure = (item) => {
        setContent(
            <ModalData
                title={t("medicalRecords.procedures.addProcedure")}
                isShowing={true}
                size="lg"
                className=""
                hide={handleClose}
            >
                <ProcedureEditContainer
                    medicalRecordNumber={medicalRecordNumber}
                    entryId={entryId}
                    id={item.id}
                    onSubmit={handleSubmitProcedure}
                    onCancel={handleClose}
                />
            </ModalData>
        );
    }

    const handleSubmitProcedure = (data) => {
        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmitProcedure(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmitProcedure(data);
        }
    }

    const onConfirmSubmitProcedure = async (data, reason, comments) => {
        handleClose();

        if (data && data.procedures && data.procedures.length > 0) {
            data.procedures.forEach(x => (x.reason && (x.reasonId = x.reason.id))); // Mapeo Reason

            if (reason) {
                data.auditReason = reason;
                data.auditComments = comments;
            }

            try {
                await create(entryId, data);
                if (props.onSubmit)
                    props.onSubmit("procedures") // Llamo a refresh para que se actualice el componente Diagnoses. 
            }
            catch (error) {
                HandleApiError(error);
            }
        }
    }

    //#region Delete item with audit
    const handleRemove = (id) => {
        setContent(
            <ModalConfirm
                onConfirm={() => confirmRemoveItem(id)}
                onCancel={() => setContent(null)}
                title={t("medicalRecordsNotifications.item_delete_title")}
                description={t("medicalRecordsNotifications.item_delete_description")}
                confirmStyle="danger"
            />);
    }
    const confirmRemoveItem = (id) => {
        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => _removeItemAudited(id, reason, comments)}
            />)
        }
        else {
            _removeItemAudited(id, null, null);
        }
    }
    const _removeItemAudited = async (id, reason, comments) => {
        try {
            setContent(<ModalSpinner isShowing={true} hide={null} />);
            await removeProcedureAPI(entryId, id, reason?.code, comments);
            setReload(true);
            setContent(null);
            Success("medicalRecordsNotifications.item_DeletedSuccessfully");
        }
        catch (error) {
            setContent(null);
            console.log(error);
            HandleApiError(error);
        }
    }
    //#endregion

    if (totalSize === 0)
        return null;

    return (
        <ExpandableContent title={t("medicalRecords.procedures.title")} expandedAll={expandedAll} content="procedures">
            {content}
            <Procedures
                withCard={false}
                entryId={entryId}
                data={procedures}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onEdit={handleEdit}
                onAuditTrail={handleAuditTrail}
                onAddProcedure={handleAddProcedure}
                onRemove={handleRemove}
            />
        </ExpandableContent>
    );
}

export const ProcedureCreateContainer = (props) => {
    const { entryStatus, entryId, medicalRecordNumber, onSubmit } = props;
    const [, masterData, isLoading, create] = useProcedure(medicalRecordNumber, entryId);
    const [content, setContent] = useState(null);

    const handleSubmit = async (data) => {

        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAudit
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (reason) {
                data.auditReason = reason;
                data.auditComments = comments;
            }
            if (data && data.procedures && data.procedures.length > 0) {
                data.procedures.forEach(x => (x.reason && (x.reasonId = x.reason.id))); // Mapeo Reason

                await create(entryId, data);
                setContent(null);
                onSubmit("procedures"); // Indico al padre sobre q contenido hacer Reload. 
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleCancel = () => { props.hide() }

    if (!masterData || isLoading)
        return null;

    return (
        <>
            {
                content ??
                <ProcedureContainerFormik
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    events={masterData}
                />
            }
        </>

    );
}

const ProcedureEditContainer = (props) => {
    const { medicalRecordNumber, entryId, id, onSubmit } = props;
    const [data, masterData, isLoading] = useProcedure(medicalRecordNumber, entryId, id);

    if (!data || !masterData || isLoading)
        return null;

    return (
        <ProcedureContainerFormik
            onSubmit={onSubmit}
            onCancel={props.onCancel}
            procedure={data}
            events={masterData}
        />
    );
}

const ProcedureContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => (props.procedure && { procedures: [props.procedure] }),

    validationSchema: getMedicalRecordsSchema().proceduresEdit,

    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values);
    },

    displayName: 'ProcedureForm',

})(ProcedureForm));

ProcedureContainerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    procedure: PropTypes.object.isRequired,
};