import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Card from '../../components/Cards/Card';
import { Add } from '../../components/Icons/Icons';
import { ResultsTemplates } from '../../components/Settings/ResultsTemplates';
import { useResultsTemplates } from './Hooks/useResultsTemplates';
import { ModalTemplate } from './ModalTemplate';
import { ResultsTemplateCreateContainer, ResultsTemplateEditContainer, ResultsTemplateRemoveContainer } from './ResultsTemplate';

export const ResultsTemplatesContainer = (props) => {
    const { t } = useTranslation();
    const [templates, query, totalSize, isLoading, handleTableChange, setReload] = useResultsTemplates();
    const [modal, setModal] = useState(null);

    const onModalSubmit = () => {
        setModal(null);
        setReload(true);
    }
    const handleClose = () => { setModal(null) }

    const handleCreate = () => {
        setModal(
            <ModalTemplate isShowing={true} hide={handleClose}>
                <ResultsTemplateCreateContainer {...props} onSubmit={onModalSubmit} onCancel={handleClose} />
            </ModalTemplate>
        );
    }
    const handleEdit = (id) => {
        setModal(
            <ModalTemplate isShowing={true} hide={handleClose}>
                <ResultsTemplateEditContainer {...props} id={id} onSubmit={onModalSubmit} onCancel={handleClose} />
            </ModalTemplate>
        )
    }
    const handleRemove = (id) => {
        setModal(
            <ResultsTemplateRemoveContainer id={id} onSubmit={onModalSubmit} onCancel={handleClose} />
        )
    }

    return (
        <>
            {modal}
            <Card
                tabs
                header={
                    <Button bsStyle="primary" onClick={handleCreate} disabled={isLoading}>
                        <Add /> {t("settings.resultsTemplates.add")}
                    </Button>
                }
                content={
                    <ResultsTemplates
                        data={templates}
                        query={query}
                        totalSize={totalSize}
                        isLoading={isLoading}
                        onTableChange={handleTableChange}
                        onEdit={handleEdit}
                        onRemove={handleRemove}
                    />
                }
            />
        </>
    );
}