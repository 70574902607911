import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { ScheduleLockForm } from '../../components/Agenda/ScheduleLock';

export const ScheduleLockContainer = (props) => {
    return (
        <ScheduleLockFormik
            onSubmit={props.onSubmit}
            onCancel={props.onClose}
        />
    );
}
ScheduleLockContainer.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

const ScheduleLockFormik = withFormik({
    //validationSchema: getAgendaAppointmentSchema(),
    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values);
    },
    displayName: 'ScheduleLock',
})(ScheduleLockForm);

ScheduleLockFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};