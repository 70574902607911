import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Audit, Copy, Print, View } from '../Icons/Icons';
import { dateFormatter } from '../Utils/Formatter';
import { Tooltip } from '../Utils/Tooltips';
import { EntryItemsTable } from './EntryItemsTable';

export const Orders = (props) => {
    const { t } = useTranslation();
    var { onEdit, onAuditTrail, ...rest } = props;

    const columns = [
        { dataField: 'orderDate', text: t('commons.date'), formatter: function (cell) { return dateFormatter(cell, "DD/MM/YY") }, headerClasses: 'header-date' },
        {
            isDummyField: true,
            dataField: 'resultsCount',
            text: "Cantidad Procedimientos",
            headerClasses: 'header-date',
            formatter: function (_cell, row) {
                return <div>{row.orders?.length}</div>
            }
        },
        {
            dataField: 'actionsParent',
            text: "",
            isDummyField: true,
            headerClasses: 'header-actions',
            classes: 'row-actions',
            formatter: function (_cell, row, rowIndex) {
                return (
                    <div key={`actions2-${rowIndex}`} style={{ marginRight: "-80px" }}>
                        {
                            props.onPrint &&
                            <Tooltip tooltip="Imprimir" id={"tooltipprint-" + row.id}>
                                <button className="btn-table-actions" onClick={() => props.onPrint(row.entryId, row.originalId ?? row.id)}>
                                    <Print />
                                </button>
                            </Tooltip>
                        }
                        {
                            props.onCopy &&
                            <Tooltip tooltip="Copiar" id={"tooltipcopy-" + row.id}>
                                <button className="btn-table-actions" onClick={() => props.onCopy(row.entryId, row.originalId ?? row.id)}>
                                    <Copy />
                                </button>
                            </Tooltip>
                        }
                    </div>
                )
            }
        }
    ];

    const columnsOrders = [
        { dataField: 'procedure', text: t('medicalRecords.orders.procedure') },
        {
            dataField: 'actions',
            text: t("commons.actions"),
            isDummyField: true,
            headerClasses: 'header-actions',
            classes: 'row-actions',
            formatter: function (_cell, row, rowIndex) {
                return (
                    <div key={`${Math.random()}-actions-${rowIndex}`}>
                        {
                            row.entryId != props.entryId && props.query.medicalRecordNumber !== 0 &&
                            <Tooltip tooltip={t("medicalRecords.viewVisit")} id={"tooltipview-" + row.id}>
                                <NavLink to={`/admin/medicalRecords/${props.query.medicalRecordNumber}/entry/${row.entryId}`} className="nav-link" activeClassName="active">
                                    <View />
                                </NavLink>
                            </Tooltip>
                        }
                        {
                            row.hasAudit && onAuditTrail &&
                            <Tooltip tooltip={t("auditTrail.view")} id={"tooltipaudit-" + row.id}>
                                <button className="btn-table-actions" onClick={() => onAuditTrail(row.id)}>
                                    <Audit />
                                </button>
                            </Tooltip>
                        }
                    </div>
                )
            }
        }
    ];

    const expandedContent = (row, _rowIndex) => {
        return (
            <BootstrapTable
                bordered={false}
                classes="table-spaced table-spaced-small"
                wrapperClasses="table-responsive"
                striped
                hover
                keyField='id'
                data={row.orders}
                columns={columnsOrders}
                noDataIndication={() => <>{t("commons.noData")}</>}
                pagination={paginationFactory({
                    page: 1,
                    sizePerPage: 6,
                    totalSize: row.orders.length,
                    hideSizePerPage: true,
                    hidePageListOnlyOnePage: true
                })}
            />
        );
    };

    return (<EntryItemsTable
        {...rest}
        columns={columns}
        onEdit={onEdit}
        expandContent={expandedContent}
        onPrint={props.onPrint}
        onCopy={props.onCopy}
    />);
}
