import React from "react";
import { NavLink, useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next'

import { Success } from "../../../components/Notifications/Notifications";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import Card from "../../../components/Cards/Card";
import { WithAuth } from "../../../components/Authorization/WithAuth";
import { useLaboratories } from "./useLaboratories";
import { removeLaboratory } from "../../../api/protocolAdmin";
import { Laboratories } from "../../../components/Protocols/Admin/Laboratories";
import { Add } from "../../../components/Icons/Icons";

export const LaboratoriesContainer = (props) => {
    let history = useHistory();
    const { t } = useTranslation();
    const [laboratories, isLoading, reload] = useLaboratories();

    const handleEditLaboratory = (studyTaskId) => {
        history.push(`/admin/settings/laboratories/${studyTaskId}`);
    }

    const handleRemoveLaboratory = (studyTaskId) => {
        if (studyTaskId) {
            removeLaboratory(studyTaskId)
                .then(res => {
                    reload(true);
                    Success("protocols.admin.laboratory.removed");
                })
                .catch(error => { HandleApiError(error); })
        }
    }

    return (
        <Card
            tabs
            header={
                <WithAuth requiredPermission={["Protocols.Create"]}>
                    <NavLink to={`/admin/settings/laboratories/create`} className="btn btn-primary" activeClassName="active">
                        <Add /> {t("protocols.admin.laboratory.laboratoryAdd")}
                    </NavLink>
                </WithAuth>
            }
            content={
                <Laboratories
                    laboratories={laboratories}
                    onEdit={handleEditLaboratory}
                    onRemove={handleRemoveLaboratory}
                    isLoading={isLoading}
                />
            }
        />
    );
}