import { getIsDigitalSignEnabled } from '../api/digitalSign';
import { getConfiguration } from '../api/settings';

const requestConfiguration = 'CONFIGURATION_REQUEST';
const receiveConfiguration = 'CONFIGURATION_RECEIVE';
const requestDigitalSign_Enabled = 'DIGITALSIGN_ENABLED_REQUEST';
const receivetDigitalSign_Enabled = 'DIGITALSIGN_ENABLED_RECEIVE';

const initialState = { isLoading: false, settings: {} };

export const settingsActions = {

    requestConfiguration: () => async (dispatch) => {
        dispatch({ type: requestConfiguration });

        try {
            const response = await getConfiguration();
            const config = response.data;

            dispatch({ type: receiveConfiguration, config });
        }
        catch (error) {
            console.log(error)
        }
    },

    requestDigitalSignEnabled: () => async (dispatch) => {
        dispatch({ type: requestDigitalSign_Enabled });

        try {
            const response = await getIsDigitalSignEnabled();
            const isEnabled = response.data;

            dispatch({ type: receivetDigitalSign_Enabled, isEnabled });
        }
        catch (error) {
            console.log(error);
            dispatch({ type: receivetDigitalSign_Enabled, isEnabled: false });
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestConfiguration) {
        return {
            ...state,
            isLoading: true
        };
    }
    if (action.type === receiveConfiguration) {
        return {
            ...state,
            settings: action.config,
            isLoading: false
        };
    }
    if (action.type === requestDigitalSign_Enabled) {
        return {
            ...state,
            isLoading: true
        };
    }
    if (action.type === receivetDigitalSign_Enabled) {

        return {
            ...state,
            settings: { ...state.settings, digitalSignEnabled: action.isEnabled },
            isLoading: false
        };
    }

    return state;
};
