import PropTypes from 'prop-types';
import React from "react";
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CustomSelect } from "../CustomControls/CustomSelect";

export const ProtocolsFilters = (props) => {
    const { t } = useTranslation();
    const {
        selectedLaboratory,
        selectedPhase,
        selectedStatus,
        masterData,
        isLoading,
        onChangeFilter,
    } = props;

    if (masterData && masterData.Laboratories && masterData.Phases && masterData.Status) {
        return (
            <Row>
                <Col md={4}>
                    <CustomSelect
                        value={selectedLaboratory}
                        name="laboratoryId"
                        placeholder={t("protocols.brief.laboratory")}
                        onChange={onChangeFilter}
                        isMulti={false}
                        isClearable={true}
                        loading={isLoading}
                        options={masterData.Laboratories} />
                </Col>
                <Col md={2}>
                    <CustomSelect
                        value={selectedPhase}
                        name="phaseId"
                        placeholder={t("protocols.brief.phase")}
                        onChange={onChangeFilter}
                        isMulti={false}
                        isClearable={true}
                        loading={isLoading}
                        options={masterData.Phases} />
                </Col>
                <Col md={2}>
                    <CustomSelect
                        value={selectedStatus}
                        name="statusId"
                        placeholder={t("protocols.brief.status")}
                        onChange={onChangeFilter}
                        isMulti={false}
                        isClearable={true}
                        loading={isLoading}
                        options={masterData.Status} />
                </Col>
            </Row>
        );
    }

    return null;
}

ProtocolsFilters.propTypes = {
    selectedLaboratory: PropTypes.object,
    selectedPhase: PropTypes.object,
    selectedStatus: PropTypes.object,
    masterData: PropTypes.object.isRequired,
    onChangeFilter: PropTypes.func.isRequired,
};