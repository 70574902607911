import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types'
import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';

import { getLaboratoriesSchema } from "../../../components/Validations/FormValidationSchema";
import { Success } from '../../../components/Notifications/Notifications';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { LaboratoryForm } from "../../../components/Protocols/Admin/Laboratories";
import { useLaboratory } from "./useLaboratories";
import {  editLaboratory } from "../../../api/protocolAdmin";

export const EditLaboratoryContainer = (props) => {
    let history = useHistory();
    const { match: { params } } = props;
    const [laboratory, isLoading] = useLaboratory(params.laboratoryId);

    const handleSubmit = () => {
        history.push(`/admin/settings/laboratories`);
    }
    const handleCancel = () => {
        history.push(`/admin/settings/laboratories`);
    }

    if (!laboratory.id)
        return null;

    return (
        <EditLaboratoryContainerFormik
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            laboratory={laboratory}
            loading={isLoading}
        />
    );
}

const EditLaboratoryContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => ({ laboratory: props.laboratory }),

    validationSchema: getLaboratoriesSchema(),

    handleSubmit: (values, formikBag) => {
        editLaboratory(values.laboratory.id, values.laboratory)
            .then(res => {
                formikBag.setSubmitting(false);
                Success("protocolNotifications.protocol_Updated");
                formikBag.props.onSubmit(res.data);
            }).catch(error => {
                console.log(error);
                formikBag.setSubmitting(false);
                HandleApiError(error);
            });
    },

    displayName: 'LaboratoryForm',

})(LaboratoryForm));

EditLaboratoryContainerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};