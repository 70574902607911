import PropTypes from "prop-types";
import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Trans } from "react-i18next";
import { Card } from "../../../components/Cards/Card";
import { AdverseEventsContainer } from "./AdverseEventsContainer";
import { AnthropometricsContainer } from "./AnthropometricsContainer";
import { AttachmentsContainer } from "./AttachmentsContainer";
import { ConditionsContainer } from "./ConditionsContainer";
import { DrugsContainer } from "./DrugsContainer";
import { FamilyHistoryContainer } from "./FamilyHistoryContainer";
import { LaboratoriesContainer } from "./LaboratoriesContainer";
import { PersonalHabitsContainer } from "./PersonalHabitsContainer";
import { PersonalHistoryContainer } from "./PersonalHistoryContainer";
import { ProceduresContainer } from "./ProceduresContainer";
import { VitalsContainer } from "./VitalsContainer";

export const EntryItems = (props) => {
    const [expandedAll, setExpandedAll] = useState(false);
    
    return (
        <Card
            header={
                <div>
                    <button className="btn btn-right btn-primary" onClick={() => setExpandedAll(!expandedAll)}>
                        {
                            expandedAll ? <Trans>commons.colapseAll</Trans> : <Trans>commons.expandAll</Trans>
                        }
                    </button>
                    <div className="clearfix"></div>
                </div>
            }
            content={
                <>
                    {/*<AdverseEventsContainer {...props} expandedAll={expandedAll} />
                    <PersonalHistoryContainer {...props} expandedAll={expandedAll} />*/}
                    <ConditionsContainer {...props} expandedAll={expandedAll} />
                    <DrugsContainer {...props} expandedAll={expandedAll} />
                    <ProceduresContainer {...props} expandedAll={expandedAll} />
                    {/*<PrescriptionsContainer {...props} expandedAll={expandedAll} />
                    <OrdersContainer {...props} expandedAll={expandedAll} />*/}
                    <LaboratoriesContainer {...props} expandedAll={expandedAll} />
                    <VitalsContainer {...props} expandedAll={expandedAll} />
                    <AnthropometricsContainer {...props} expandedAll={expandedAll} />
                    <PersonalHabitsContainer {...props} expandedAll={expandedAll} />
                    <FamilyHistoryContainer {...props} expandedAll={expandedAll} />
                    <AttachmentsContainer {...props} expandedAll={expandedAll} />
                </>
            }
        />
    );
}

EntryItems.propTypes = {
    medicalRecordNumber: PropTypes.string.isRequired,
    entryStatus: PropTypes.string.isRequired,
    entryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    contentToReload: PropTypes.object,
}

export const EntryItemsTabs = (props) => {
    return (
        <Tabs defaultActiveKey="Eventos" id="medical-record-home" mountOnEnter={true}>
            <Tab eventKey={"Eventos"} title="Eventos">
                <AdverseEventsContainer {...props} expandedAll={true} />
            </Tab>
            <Tab eventKey={"Antecedentes"} title="Antecedentes">
                <PersonalHistoryContainer {...props} expandedAll={true} />
            </Tab>

            <Tab eventKey={"Medicametnos"} title="Medicamentos">
                <DrugsContainer {...props} expandedAll={true} />
            </Tab>
            <Tab eventKey={"Procedimientos"} title="Procedimientos">
                <ProceduresContainer {...props} expandedAll={true} />
            </Tab>
            <Tab eventKey={"LaboratoriesContainer"} title="Laboratorios">
                <LaboratoriesContainer {...props} expandedAll={true} />
            </Tab>
            <Tab eventKey={"VitalsContainer"} title="Signos Vitales">
                <VitalsContainer {...props} expandedAll={true} />
            </Tab>
            <Tab eventKey={"AnthropometricsContainer"} title="Antropometría">
                <AnthropometricsContainer {...props} expandedAll={true} />
            </Tab>
            <Tab eventKey={"PersonalHabitsContainer"} title="Habitos Personales">
                <PersonalHabitsContainer {...props} expandedAll={true} />
            </Tab>
            <Tab eventKey={"FamilyHistoryContainer"} title="Ant. Familiares">
                <FamilyHistoryContainer {...props} expandedAll={true} />
            </Tab>
            <Tab eventKey={"AttachmentsContainer"} title="Adjuntos">
                <AttachmentsContainer {...props} expandedAll={true} />
            </Tab>
        </Tabs>
    );
}

EntryItemsTabs.propTypes = {
    medicalRecordNumber: PropTypes.string.isRequired,
    entryStatus: PropTypes.string.isRequired,
    entryId: PropTypes.string.isRequired,
    contentToReload: PropTypes.object,
}
