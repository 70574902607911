import React from 'react';
import PropTypes from 'prop-types';
import { Entries, EntriesScrollable, EntriesScrollableSmall } from '../../../components/MedicalRecords/Entries';
import { useEntries } from '../Hooks/useEntries';

const EntriesContainer = (props) => {

    const { medicalRecordNumber, view } = props;
    const [entries, query, entriesTotalSize, isEntriesLoading, handleTableChange, , getMore, handleFilterChange] = useEntries(medicalRecordNumber);

    switch (view) {
        case "scroll":
            return (
                <EntriesScrollable
                    data={entries}
                    currentPage={query.pagination.page}
                    totalSize={entriesTotalSize}
                    isLoading={isEntriesLoading}
                    getMore={getMore}
                    onFilterChange={handleFilterChange} />
            );
        case "table": return (
            <Entries
                data={entries}
                query={query}
                totalSize={entriesTotalSize}
                isLoading={isEntriesLoading}
                onTableChange={handleTableChange} />
        );
        case "scroll-small": return (
            <EntriesScrollableSmall
                data={entries}
                currentPage={query.pagination.page}
                totalSize={entriesTotalSize}
                isLoading={isEntriesLoading}
                getMore={getMore}
                onFilterChange={handleFilterChange} />
        );
        default:
            return (
                <Entries
                    data={entries}
                    query={query}
                    totalSize={entriesTotalSize}
                    isLoading={isEntriesLoading}
                    onTableChange={handleTableChange} />
            );
    }
}

export default EntriesContainer;

EntriesContainer.propTypes = {
    medicalRecordNumber: PropTypes.string.isRequired,
    view: PropTypes.oneOf(['scroll', 'scroll-small', 'table']),
};