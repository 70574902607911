import { ErrorMessage, FieldArray } from 'formik';
import React from 'react';
import { Col, ControlLabel, FormControl, FormGroup, Grid, Row, Panel, PanelGroup } from 'react-bootstrap';
import { conceptType, snomedExpressions } from '../../../variables/Enums';
import { CustomSelect } from "../../CustomControls/CustomSelect";
import { FromToNullableDatePicker } from "../../CustomControls/DatePicker";
import { SnomedPicker } from '../../CustomControls/SnomedPicker';
import { Remove } from '../../Icons/Icons';
import { FormErrorMessage } from '../../Validations/FormValidationErrors';
import { CustomRadio } from '../../CustomControls/CustomRadio';

export const DrugsForm = props => {

    const {
        values,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        isSubmitting,
        handleSubmit,
        t,
        data,
        events
    } = props;

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };
    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };
    const formatGroupLabel = data => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    const findReasonInEventList = (reasonId, events) => {
        if (!reasonId)
            return events[0].options[0]; // Devuelvo default

        var reason = events[1].options.find(x => x.id === reasonId); // Busco en patologias
        if (reason)
            return reason;
    }

    const emptyResult = {
        name: '',
        reason: '',
        dose: '',
        fromYear: '',
        routeOfAdministration: data && data.routesOfAdministration && data.routesOfAdministration.find(x => x.code.toLowerCase() == "oral"),
        unit: data && data.units && data.units.find(x => x.code == "comp"),
        type: 0
    };

    if (!values.drugs) {
        values.drugs = [emptyResult];
    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <PanelGroup accordion id="multi-items" defaultActiveKey="multi-item-0">
                <FieldArray
                    name="drugs"
                    render={arrayHelper => (
                        <div>
                            {values.drugs && values.drugs.length > 0 && (
                                values.drugs.map((drug, index) => (
                                    <Panel key={`multi-item-${index}`} eventKey={`multi-item-${index}`}>
                                        <Panel.Heading>
                                            <Panel.Title toggle>{drug.name ? drug.name.description : t("medicalRecords.drugs.drug")}</Panel.Title>
                                        </Panel.Heading>
                                        <Panel.Body collapsible>
                                            <FormGroup key={index}>
                                                <Grid fluid>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Row>
                                                                <Col md={4}>
                                                                    <ControlLabel>{t("medicalRecords.drugs.type")}</ControlLabel>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <CustomRadio
                                                                        number={`drugs.${index}.substance`}
                                                                        option={0}
                                                                        name={`drugs.${index}.type`}
                                                                        onChange={setFieldValue}
                                                                        checked={drug.type == 0}
                                                                        label={t("medicalRecords.drugs.type_substance")}
                                                                    />
                                                                </Col>
                                                                <Col md={4}>
                                                                    <CustomRadio
                                                                        number={`drugs.${index}.medicine`}
                                                                        option={1}
                                                                        name={`drugs.${index}.type`}
                                                                        onChange={setFieldValue}
                                                                        checked={drug.type == 1}
                                                                        label={t("medicalRecords.drugs.type_medicine")}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={11}>
                                                            <ControlLabel>{t("medicalRecords.drugs.drug")}</ControlLabel>
                                                            <SnomedPicker
                                                                conceptType={drug.type == 0 ? conceptType.drugs : conceptType.prescriptions}
                                                                autoFocus
                                                                id={`drugs.${index}.name`}
                                                                defaultInputValue={drug.name ? drug.name.description : ''}
                                                                //selected={[drug.name ?? null]} 
                                                                value={drug.name}
                                                                name={`drugs.${index}.name`}
                                                                placeholder={t("commons.search")}
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                expression={snomedExpressions.Producto_O_Sustancia}
                                                            />
                                                            <ErrorMessage name={`drugs.${index}.name`} component={FormErrorMessage} />
                                                        </Col>
                                                        <Col md={1}>
                                                            <button
                                                                className="btn-table-actions btn-remove"
                                                                style={{ verticalAlign: 'middle' }}
                                                                type="button"
                                                                onClick={() => arrayHelper.remove(index)}>
                                                                <Remove />
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={4}>
                                                            <ControlLabel>{t("medicalRecords.drugs.routeOfAdministration")}</ControlLabel>
                                                            <CustomSelect
                                                                //value={data.routesOfAdministration[2]}
                                                                value={drug.routeOfAdministration}
                                                                name={`drugs.${index}.routeOfAdministration`}
                                                                placeholder={t("commons.select")}
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                options={data.routesOfAdministration} />
                                                            <ErrorMessage name={`drugs.${index}.routeOfAdministration`} component={FormErrorMessage} />
                                                        </Col>
                                                        <Col sm={8}>
                                                            <ControlLabel>{t("medicalRecords.drugs.reason")}</ControlLabel>
                                                            <CustomSelect
                                                                defaultValue={findReasonInEventList(drug.reasonId, events)}
                                                                name={`drugs.${index}.reason`}
                                                                placeholder={t("commons.select")}
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                options={events}
                                                                getOptionLabel={(option) => option.adverseEffect}
                                                                getOptionValue={(option) => option.id}
                                                                formatGroupLabel={formatGroupLabel} />
                                                            <ErrorMessage name={`drugs.${index}.reason`} component={FormErrorMessage} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={4}>
                                                            <ControlLabel>{t("medicalRecords.drugs.dose")}</ControlLabel>
                                                            <FormControl type="text" name={`drugs.${index}.dose`} value={drug.dose} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                            <ErrorMessage name={`drugs.${index}.dose`} component={FormErrorMessage} />
                                                        </Col>
                                                        <Col sm={4}>
                                                            <ControlLabel>{t("medicalRecords.drugs.unit")}</ControlLabel>
                                                            <CustomSelect
                                                                value={drug.unit}
                                                                //defaultValue={drug.unit ?? data.units[1]}
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                name={`drugs.${index}.unit`}
                                                                placeholder={t("commons.select")}
                                                                options={data.units} />
                                                            <ErrorMessage name={`drugs.${index}.unit`} component={FormErrorMessage} />
                                                        </Col>
                                                        {
                                                            drug.unit && drug.unit.code.toLowerCase() === "other" ?
                                                                <Col sm={3}>
                                                                    <ControlLabel>{t("medicalRecords.drugs.other")}</ControlLabel>
                                                                    <FormControl type="text" name={`drugs.${index}.otherUnit`} value={drug.otherUnit || ''} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                                    <ErrorMessage name={`drugs.${index}.otherUnit`} component={FormErrorMessage} />
                                                                </Col> :
                                                                ""
                                                        }
                                                        <Col sm={4}>
                                                            <ControlLabel>{t("medicalRecords.drugs.frequency")}</ControlLabel>
                                                            <CustomSelect
                                                                value={drug.frequency}
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                name={`drugs.${index}.frequency`}
                                                                placeholder={t("commons.select")}
                                                                options={data.frequencies} />
                                                            <ErrorMessage name={`drugs.${index}.frequency`} component={FormErrorMessage} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <FromToNullableDatePicker
                                                                valuesFrom={{ fromDay: drug.fromDay, fromMonth: drug.fromMonth, fromYear: drug.fromYear }}
                                                                nameFromDay={`drugs.${index}.fromDay`}
                                                                nameFromMonth={`drugs.${index}.fromMonth`}
                                                                nameFromYear={`drugs.${index}.fromYear`}
                                                                valuesTo={{ toDay: drug.toDay, toMonth: drug.toMonth, toYear: drug.toYear }}
                                                                nameToDay={`drugs.${index}.toDay`}
                                                                nameToMonth={`drugs.${index}.toMonth`}
                                                                nameToYear={`drugs.${index}.toYear`}
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                            />
                                                            <ErrorMessage name={`drugs.${index}.fromYear`} component={FormErrorMessage} />
                                                            <ErrorMessage name={`drugs.${index}.toYear`} component={FormErrorMessage} />
                                                            <ErrorMessage name={`drugs.${index}.toMonth`} component={FormErrorMessage} />
                                                            <ErrorMessage name={`drugs.${index}.toDay`} component={FormErrorMessage} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <ControlLabel>{t("medicalRecords.drugs.notes")}</ControlLabel>
                                                            <FormControl componentClass="textarea" value={drug.notes || ''} name={`drugs.${index}.notes`} onChange={handleChange} />
                                                        </Col>
                                                    </Row>
                                                </Grid>
                                            </FormGroup>
                                        </Panel.Body>
                                    </Panel>
                                ))
                            )}
                            {
                                (values.drugs.length === 0 || (values.drugs.length > 0 && !values.drugs[0].id)) &&
                                <Row style={{ marginTop: "20px" }}>
                                    <Col md={4}><button type="button" className="btn btn-default" onClick={() => arrayHelper.push(emptyResult)}>{t("buttons.add")}</button></Col>
                                    <Col md={8}></Col>
                                </Row>
                            }
                        </div>
                    )}
                />
            </PanelGroup>
            <button type="button" className="btn btn-default" onClick={props.onCancel} disabled={isSubmitting}>{t("buttons.cancel")}</button>
            {
                values.drugs && values.drugs.length > 0 &&
                <button type="submit" className="btn btn-success btn-right" disabled={isSubmitting}>{t("buttons.save")}</button>
            }

            <div className="clearfix"></div>
        </form>
    );
}