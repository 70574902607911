import axios from './index';

export const getQueryString = (newActiveFilters, search) => {
    const searchParams = new URLSearchParams(search || '');
    
    searchParams.delete('sizePerPage'); // se resetea el paginado
    if(newActiveFilters.pagination?.sizePerPage && newActiveFilters.pagination.sizePerPage !== 1){
        searchParams.append('pageSize', newActiveFilters.pagination.sizePerPage);
    }
    searchParams.delete('pageIndex'); // se resetea el paginado
    if(newActiveFilters.pagination?.page && newActiveFilters.pagination.page !== 1){
        searchParams.append('pageIndex', newActiveFilters.pagination.page);
    }
    searchParams.delete('sortField');
    if(newActiveFilters.sort?.sortField){
      searchParams.append('sortField', newActiveFilters.sort.sortField);
    }
    searchParams.delete('sortOrder');
    if(newActiveFilters.sort?.sortOrder){
      searchParams.append('sortOrder', newActiveFilters.sort.sortOrder);
    }
    
    searchParams.delete('q');
    if(newActiveFilters.q){
      searchParams.append('q', newActiveFilters.q);
    }

    searchParams.delete('projects');
    (newActiveFilters.projects || []).forEach(project => searchParams.append('projects', project.id));
    searchParams.delete('protocols');
    (newActiveFilters.protocols || []).forEach(protocol => searchParams.append('protocols', protocol.id));
    searchParams.delete('professionals');
    (newActiveFilters.professionals || []).forEach(professional => searchParams.append('professionals', professional.id));
    searchParams.delete('type');
    if (newActiveFilters.type != null && newActiveFilters.type.id) {
        searchParams.append('type', newActiveFilters.type.id);
    }

    return searchParams.toString();
}

export const getPractitioners = async filters => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(queryString ? `api/v1/admin/practitioners?${queryString}` : 'api/v1/admin/practitioners');
};

export const getPractitioner = (id) => {
    return axios.get(`api/v1/admin/practitioner/${id}`);
};

export const createPractitioner = (patient) => {
    return axios.post('api/v1/admin/practitioner', patient);
};

export const updatePractitioner = (patient) => {
    return axios.put('api/v1/admin/practitioner', patient);
};

export const getMasterData = () => {
    return axios.get('api/v1/admin/masterdata');
};

export const getMedicalInsurancePlanByMedicalInsurance = (medicalInsurance) => {
    return axios.get(`api/v1/admin/masterdata/${medicalInsurance}`);
};

export const getMasterDataSelect = (item) => {
    return axios.get(`api/v1/admin/selectmasterdata?items=${item}`);
};

export const getStatesByCountry = (countrycodeISO2) => {
    return axios.get(`api/v1/admin/states/${countrycodeISO2}`);
};

export const getCitiesByState = (statecodeISO2) => {
    return axios.get(`api/v1/admin/cities/${statecodeISO2}`);
};

export const exportToExcel = (filters) => {
    const queryString = getQueryString(filters, window.location.search);
    
    return axios.get(queryString ? `api/v1/admin/practitioners/exportToExcel?${queryString}` : 'api/v1/admin/practitioners/exportToExcel', {
        responseType: 'arraybuffer'
      });
};

export const getAdverseEventsData = () => {
    return axios.get('api/v1/admin/adverseeventsdata');
}
export const getDrugsData = () => {
    return axios.get('api/v1/admin/drugsdata');
}
export const getEntryData = () => {
    return axios.get('api/v1/admin/entrydata');
}
export const getFamilyHistoryData = () => {
    return axios.get('api/v1/admin/familyhistorydata');
}
export const getLaboratoryData = () => {
    return axios.get('api/v1/admin/laboratorydata');
}

export const getAuditTrail = (entity, entityId) => {
    return axios.get(`api/v1/admin/audits/${entity}/${entityId}`);
}

export const getLastAuditTrail = (entity, entityId) => {
    return axios.get(`api/v1/admin/audits/last/${entity}/${entityId}`);
}