import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Modal as BootstrapModal, Button, Grid, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormEarlyCompleted, IconSVG } from '../Icons/Icons';
import { useVisitsTracking } from '../../containers/Protocols/Hooks';
import { CustomSelect } from '../CustomControls/CustomSelect';
import { getVisitsTrackingOptions, getVisitTrackingOptionFormat } from '../../containers/Protocols/Tracking/Services';
import { Spinner } from '../Utils/Loaders';
import { ModalConfirm } from '../Modals/ModalConfirm';

const ModalNewEntry = ({ patient, isShowing, hide, isLoading, onCreateExternal, onCreateProtocol, onCreateExtraProtocol }) => {
    const { t } = useTranslation();
    const [selectedVisit, setSelectedVisit] = useState(null);
    const [selectedProtocol, setSelectedProtocol] = useState(null);
    const [data, isVisitsLoading, fetchData] = useVisitsTracking();
    const [modal, setModal] = useState(null);

    useEffect(() => {
        if (patient && patient.protocols && patient.protocols.length === 1)
            setSelectedProtocol(patient.protocols[0]);

    }, [patient, selectedProtocol]);

    const handleClickProtocol = () => {
        if (selectedProtocol)
            fetchData(selectedProtocol.id, patient.medicalRecordNumber);
    }

    const formatOptionLabel = (option) => (getVisitTrackingOptionFormat(option));

    const handleCreateExternal = () => {
        setModal(
            <ModalConfirm
                onCancel={() => setModal(null)}
                onConfirm={() => { setModal(null); onCreateExternal(); }}
                title={t('medicalRecords.actionNewEntry_confirm_ce')}
                description={t('medicalRecords.actionNewEntry_confirm_description')}
            />);
    }

    const handleCreateVisit = () => {
        setModal(
            <ModalConfirm
                onCancel={() => setModal(null)}
                onConfirm={() => {
                    setModal(null);
                    if (selectedVisit)
                        onCreateProtocol(data, selectedVisit);
                }}
                title={t('medicalRecords.actionNewEntry_confirm_vp')}
                description={t('medicalRecords.actionNewEntry_confirm_description')}
            />);
    }

    const handleCreateExtraProtocol = () => {
        setModal(
            <ModalConfirm
                onCancel={() => setModal(null)}
                onConfirm={() => onConfirmCreate("ExtraProtocolVisit")}
                title={t('medicalRecords.actionNewEntry_confirm_vep')}
                description={t('medicalRecords.actionNewEntry_confirm_description')}
            />);
    }

    const handleCreateProtocolNote = () => {
        setModal(
            <ModalConfirm
                onCancel={() => setModal(null)}
                onConfirm={() => onConfirmCreate("Note")}
                title={t('medicalRecords.actionNewEntry_confirm_n')}
                description={t('medicalRecords.actionNewEntry_confirm_description')}
            />);
    }

    const handleCreateEarlyCompletion = () => {
        setModal(
            <ModalConfirm
                onCancel={() => setModal(null)}
                onConfirm={() => onConfirmCreate("EarlyCompletion")}
                title={t('medicalRecords.actionNewEntry_confirm_ec')}
                description={t('medicalRecords.actionNewEntry_confirm_description')}
            />);
    }

    const onConfirmCreate = (type) => {
        setModal(null);
        if (selectedProtocol && onCreateExtraProtocol)
            onCreateExtraProtocol(selectedProtocol.id, type);
    }

    const handleSelectVisit = (_name, selectedValue) => {
        setSelectedVisit(selectedValue)
    }

    const renderVisits = () => {

        if (data.length == 0 || isVisitsLoading)
            return null;

        const options = getVisitsTrackingOptions(data.patients.data, data.visits);
        return <>
            <CustomSelect
                name="visitTracking"
                placeholder={t("commons.select")}
                onChange={handleSelectVisit}
                formatOptionLabel={formatOptionLabel}
                options={options}
            />
            <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleCreateVisit()}
                disabled={!selectedVisit}
                style={{ marginTop: 10 }}
            >
                {t("medicalRecords.actionNewEntry_protocolVisit")}
            </button>
        </>
    }

    if (modal)
        return ReactDOM.createPortal(
            <React.Fragment>
                {modal}
            </React.Fragment>, document.body
        );

    return isShowing && patient ? ReactDOM.createPortal(
        <React.Fragment>
            <BootstrapModal show={true} onHide={hide}>
                <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title>{t("medicalRecords.actionNewEntry")}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    {
                        isLoading ?
                            <Spinner /> :
                            <div className="modal-create-entry">
                                <Grid fluid>
                                    <Row className='display-flex'>
                                        <Col md={4}>
                                            <div className="create-entry-container">
                                                <Button onClick={handleCreateExternal} className="btn-create-entry">
                                                    <IconSVG name="consultorio" />
                                                </Button>
                                                <div className="title-create-entry">{t("medicalRecords.actionNewEntry_confirm_ce")}</div>
                                            </div>
                                        </Col>
                                        {
                                            patient.protocols.length > 1 &&
                                            <Col md={8}>
                                                <div className="select-protocol">
                                                    <div className="title-create-entry">{t("medicalRecords.actionNewEntry_multipleProtocols")}</div>
                                                    <CustomSelect
                                                        name="protocols"
                                                        placeholder={`${t("commons.select")} ${t("protocols.brief.protocol")}`}
                                                        onChange={(_name, selected) => { setSelectedProtocol(selected) }}
                                                        options={patient.protocols}
                                                    />
                                                </div>
                                            </Col>
                                        }
                                        {
                                            selectedProtocol &&
                                            <>
                                                <Col md={4}>
                                                    <div className="create-entry-container">
                                                        <Button onClick={handleClickProtocol} className="btn-create-entry">
                                                            <IconSVG name="protocol" />
                                                        </Button>
                                                        <div className="title-create-entry">{t("medicalRecords.actionNewEntry_confirm_vp")}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="create-entry-container">
                                                        <Button onClick={handleCreateExtraProtocol} className="btn-create-entry">
                                                            <IconSVG name="protocol" />
                                                        </Button>
                                                        <div className="title-create-entry">{t("medicalRecords.actionNewEntry_confirm_vep")}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="create-entry-container">
                                                        <Button onClick={handleCreateProtocolNote} className="btn-create-entry">
                                                            <IconSVG name="protocol" />
                                                        </Button>
                                                        <div className="title-create-entry">{t("medicalRecords.actionNewEntry_confirm_n")}</div>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="create-entry-container">
                                                        <Button onClick={handleCreateEarlyCompletion} className="btn-create-entry">
                                                            <FormEarlyCompleted />
                                                        </Button>
                                                        <div className="title-create-entry">{t("medicalRecords.actionNewEntry_confirm_ec")}</div>
                                                    </div>
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            {renderVisits()}
                                        </Col>
                                    </Row>
                                </Grid>
                            </div>
                    }
                </BootstrapModal.Body>
            </BootstrapModal>
        </React.Fragment>, document.body
    ) : null;
}

ModalNewEntry.propTypes = {
    onCreateExternal: PropTypes.func.isRequired,
    onCreateProtocol: PropTypes.func.isRequired,
    onCreateExtraProtocol: PropTypes.func.isRequired,
    isShowing: PropTypes.bool.isRequired,
    hide: PropTypes.func.isRequired
};

export default ModalNewEntry;