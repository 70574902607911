import React from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { withFormik } from 'formik';
import { FreezerForm } from "./FreezerForm";
import FreezerService from "../../../api/Freezer/FreezerService";
import { getFreezerSchema } from "../../../components/Validations/FormValidationSchema";
import { ErrorMessage, Success } from "../../../components/Notifications/Notifications";

export default function CreateFreezerContainer() {
    let history = useHistory();

    const handleSubmit = () => {
        history.push(`/admin/settings/freezers`);
    }
    const handleCancel = () => {
        history.push(`/admin/settings/freezers`);
    }

    return (
        <CreateFreezerContainerFormik
            onSubmit={handleSubmit}
            onCancel={handleCancel} />
    )
}

const CreateFreezerContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => ({}),
    validationSchema: getFreezerSchema(),
    handleSubmit: async (values, formikBag) => {
        try {
            const result = await FreezerService.Create(values.freezer)
            if (result.Succeeded) {
                Success("commons.successSave")
                formikBag.props.onSubmit();
            } else {
                formikBag.props.onSubmit(false);
                ErrorMessage("errors.serverError")
            }
        } catch (err) {
            ErrorMessage("errors.serverError")
        } finally {
            formikBag.setSubmitting(false);
        }
    },
    displayName: 'FreezerForm',
})(FreezerForm))