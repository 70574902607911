import React, { useState } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory, useLocation } from "react-router-dom";
import Card from '../Cards/Card';
import { Back, Save } from '../Icons/Icons';
import { AddressData } from '../Person/AddressData';
import { AddressDataForm } from '../Person/AddressDataForm';
import { ContactData } from '../Person/ContactData';
import { ContactDataForm } from '../Person/ContactDataForm';
import { MedicalData } from '../Person/MedicalData';
import { MedicalDataForm } from '../Person/MedicalDataForm';
import { PersonalData } from '../Person/PersonalData';
import { PersonalDataForm } from '../Person/PersonalDataForm';
import { RelatedContactData } from '../Person/RelatedContactData';
import { RelatedContactDataForm } from '../Person/RelatedContactDataForm';
import { Actions } from './Actions';

export const Patient = props => {
    let history = useHistory();
    const location = useLocation();
    const patient = props.data;

    return (
        <Grid fluid>
            <Row className="display-flex">
                <Col md={6}>
                    <PersonalData data={patient} />
                </Col>
                <Col md={6}>
                    <MedicalData data={patient} />
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={2}>
                    <Actions
                        onCreateAppointment={() => history.push(`/admin/agenda?medicalRecordNumber=${patient?.medicalRecordNumber}&source=${location.pathname}`)}
                        onEditPatient={() => history.push(`/admin/database/${patient?.medicalRecordNumber}/edit`)}
                        onRedirectTo={() => history.goBack()}
                        onViewMR={() => history.push(`/admin/medicalRecords/${patient?.medicalRecordNumber}`)}
                    />
                </Col>
                <Col md={12} lg={10}>
                    <AddressData data={patient} />
                    <ContactData data={patient} />
                    <RelatedContactData data={patient} />
                </Col>
            </Row>
        </Grid>
    );
}

export const PatientForm = props => {
    const {
        handleSubmit,
        isSubmitting,
        isValid,
        validateForm,
        form,
        t
    } = props;

    let navigation = useHistory();

    const onSubmit = (e) => {
        e.preventDefault();

        if (form === "new") {
            validateForm().then(
                () => {
                    if (isValid)
                        warningWithConfirmAndCancelMessage(props.values);
                    else
                        handleSubmit()
                }
            );
        }
        else {
            handleSubmit()
        }
    }

    const [modal, setModal] = useState(null);
    const submitForm = () => { setModal(null); handleSubmit(); }
    const cancelForm = () => { setModal(null); }

    const warningWithConfirmAndCancelMessage = (values) => {
        setModal(
            <SweetAlert
                warning
                title={t("databaseNotifications.Database_PatientConfirmationTitle")}
                onConfirm={() => submitForm()}
                onCancel={() => cancelForm()}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="info"
                confirmBtnText={t("buttons.save")}
                cancelBtnText={t("buttons.back")}
                showCancel
            >
                <div>
                    <span>{values.identificationType.title} - {values.identificationNumber}</span>
                </div>
            </SweetAlert>);
    }

    return (
        <Card
            className="card-form"
            content={
                <>
                    {modal}
                    <form onSubmit={onSubmit} autoComplete="off">
                        <Grid fluid>
                            <Row>
                                <Col md={12}>
                                    <PersonalDataForm {...props}></PersonalDataForm>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} lg={12}>
                                    <AddressDataForm {...props}></AddressDataForm>
                                    <ContactDataForm {...props}></ContactDataForm>
                                    <MedicalDataForm {...props}></MedicalDataForm>
                                    <RelatedContactDataForm {...props}></RelatedContactDataForm>
                                </Col>
                                <Col md={12} lg={12}>
                                    <button className="btn btn-success btn-fill" type="submit" disabled={isSubmitting} style={{ float: "right", marginRight: '15px' }}>
                                        <Save /> {t("buttons.save")}
                                    </button>
                                    <button className="btn btn-default btn-fill" type="button" disabled={isSubmitting} onClick={() => navigation.goBack()} style={{ float: "right", marginRight: '15px' }}>
                                        <Back /> {t('buttons.back')}
                                    </button>
                                </Col>
                            </Row>
                        </Grid>
                    </form>
                </>
            }
        />

    );
};