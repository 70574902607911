import PropTypes from 'prop-types';
import React from "react";
import { Modal as BootstrapModal } from 'react-bootstrap';
import ReactDOM from 'react-dom';

export const ModalData = (props) => {
    const { isShowing, hide, title, children } = props;

    return isShowing ? ReactDOM.createPortal(
        <React.Fragment>
            <BootstrapModal show={true} onHide={hide} bsSize="large" backdrop="static" >
                <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title>{title}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    {children}
                </BootstrapModal.Body>
            </BootstrapModal>
        </React.Fragment>, document.body
    ) : null;
};

ModalData.propTypes = {
    title: PropTypes.string,
    isShowing: PropTypes.bool,
    hide: PropTypes.func,
};