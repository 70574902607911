import { useSelector, useDispatch } from 'react-redux';
import { roleActionCreator } from '../../store/Protocol';

export const useAuthorization = () => {
    const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
    const user = useSelector(state => state.authentication.user);
    const isAuthorized = (requiredPermissions) => {

        if (!user || !user.permission)
            return false;

        if (requiredPermissions) {
            const permissions = requiredPermissions.filter(permission => user["permission"].includes(permission));
            if (!permissions || permissions.length === 0)
                return false;
        }

        return true;
    }

    return [isAuthenticated, user, isAuthorized];
}

export const useAuthProtocol = (protocolId) => {
    const currentUserRoleData = useSelector(state => state.protocol);
    const user = useSelector(state => state.authentication.user);
    const dispatch = useDispatch();

    const isAuthorized = (includePermissions, excludePermissions) => {
        if (currentUserRoleData.isLoading)
            return false;

        if (currentUserRoleData.protocolId != protocolId) {
            dispatch(roleActionCreator.requestCurrentUserRole(protocolId));
        }

        const currentUserRoles = currentUserRoleData.role;
        if (includePermissions && currentUserRoles.permissions) {
            const hasIncludePermissions = includePermissions.some(permission => currentUserRoles.permissions.includes(permission));

            if (excludePermissions) {
                const hasExcludePermission = excludePermissions.some(permission => currentUserRoles.permissions.includes(permission));

                return hasIncludePermissions && !hasExcludePermission;
            }

            return hasIncludePermissions;
        }

        return false;
    }

    return [isAuthorized, currentUserRoleData.isLoading, user];
}

export function useProtocolAuthFilter() {
    const currentUserRoleData = useSelector(state => state.protocol);
    const canViewProtocol = (protocolId) => {
        if (currentUserRoleData.isLoading)
            return false;

        return currentUserRoleData.protocolId != protocolId
    }
    return [canViewProtocol, currentUserRoleData.isLoading]
}

export function useProtocolPageAuth() {
    const currentUserRoleData = useSelector(state => state.protocol);
    const user = useSelector(state => state.authentication.user);

    const isAuthorized = (includePermissions, excludePermissions) => {
        if (currentUserRoleData.isLoading)
            return false;

        const currentUserRoles = currentUserRoleData.role;
        if (includePermissions && currentUserRoles.permissions) {
            const hasIncludePermissions = includePermissions.some(permission => currentUserRoles.permissions.includes(permission));

            if (excludePermissions) {
                const hasExcludePermission = excludePermissions.some(permission => currentUserRoles.permissions.includes(permission));

                return hasIncludePermissions && !hasExcludePermission;
            }

            return hasIncludePermissions;
        }

        return false;
    }
    return [isAuthorized, currentUserRoleData.isLoading, user];
}
