import React from 'react';
import { useTranslation } from 'react-i18next';

export const RequiredFieldError = () =>
    <div className='has-error'>
        <p><i className='pe-7s-attention'></i> El campo es requerido</p>
    </div>

export const FormErrorMessage = props => {

    const { t } = useTranslation();

    if (props.children.key) {

        return (
            <small className="text-danger">{t(props.children.key)}</small>
        );
    }

    return (
        <small className="text-danger">{t(props.children)}</small>
    );    
}