import { useEffect, useState } from 'react';
import { getAttachment, getAttachments } from '../../../api/medicalRecord';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';

export const useAttachments = (medicalRecordNumber, entryId) => {

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [blobData, setBlobData] = useState(null);
    const [totalSize, setTotalSize] = useState(0);
    const [query, setQuery] = useState({
        medicalRecordNumber: medicalRecordNumber ? medicalRecordNumber : null,
        entryId: entryId ? entryId : 0,
        pagination: {
            page: 1,
            sizePerPage: 10,
            totalSize: 0
        }
    });
    const [reload, setReload] = useState(false);

    useEffect(() => {
        let isSubscribed = true
        const fetchData = async () => {
            try {
                setReload(false);
                setIsLoading(true);
                const paginatedItems = await getAttachments(query);

                if (isSubscribed) {
                    setTotalSize(paginatedItems.data.count);
                    setData(paginatedItems.data.data);
                    setIsLoading(false);
                }

            } catch (error) {
                HandleApiError(error);
                setIsLoading(false);
                setTotalSize(0);
                setData([]);
            }
        }

        fetchData();

        return () => isSubscribed = false;
    }, [query, reload]);

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        var newQuery = JSON.parse(JSON.stringify(query));

        switch (type) {
            case 'pagination': {
                newQuery.pagination.page = page;
                newQuery.pagination.sizePerPage = sizePerPage;
                setQuery(newQuery);
                break;
            }
            case 'sort': {
                newQuery.sort.sortField = sortField;
                newQuery.sort.sortOrder = sortOrder;
                setQuery(newQuery);
                break;
            }
            default: break;
        }
    }

    const downloadAttachment = (id) => {
        getAttachment(id, medicalRecordNumber ? medicalRecordNumber : "", entryId ? entryId : 0)
            .then((response) => {
                const blob = new Blob([response.data], { type: response.data.type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                const contentDisposition = response.headers['content-disposition'];
                let fileName = 'unknown';
                if (contentDisposition) {
                    const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                    if (fileNameMatch.length > 1)
                        fileName = fileNameMatch[1].replace(/['"]+/g, '');
                }
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);

            })
            .catch(error => {
                //setData(null);
                console.error(error);
            });
    }

    const viewAttachment = async (id) => {
        try {
            const response = await getAttachment(id, medicalRecordNumber ? medicalRecordNumber : "", entryId ? entryId : 0);

            const blob = new Blob([response.data], { type: response.data.type });

            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'unknown';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                if (fileNameMatch.length > 1)
                    fileName = fileNameMatch[1].replace(/['"]+/g, '');
            }

            setBlobData({ blob, fileName });
        }
        catch (error) {
            console.error(error);
            setBlobData(null);
        }

    }

    return [data, query, totalSize, isLoading, handleTableChange, setReload, downloadAttachment, blobData, viewAttachment];
}