import { ErrorMessage } from 'formik';
import React, { useEffect } from 'react';
import { Col, ControlLabel, FormControl, FormGroup, Grid, Row } from 'react-bootstrap';
import { FromToNullableDatePicker } from "../../CustomControls/DatePicker";
import { FormErrorMessage } from '../../Validations/FormValidationErrors';
import { CustomSelect } from '../../CustomControls/CustomSelect';
import { CustomCheckbox } from '../../CustomControls/CustomCheckbox';

export const DiagnosisForm = props => {

    const {
        values,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
        handleChange,
        isSubmitting,
        t
    } = props;

    const options = [
        { id: 0, code: "mild", title: t("medicalRecords.condition.intensity.mild") },
        { id: 1, code: "moderate", title: t("medicalRecords.condition.intensity.moderate") },
        { id: 2, code: "severe", title: t("medicalRecords.condition.intensity.severe") }
    ];

    useEffect(() => {
        values.condition.intensity = values.condition.intensityCode ? options.find(x => x.code == values.condition.intensityCode) : values.condition.intensity;
        values.condition.serious = null;
        values.condition.reported = null;
        values.condition.relationwithdrug = null;
        values.condition.action = null;
    }, []);

    if (!values.condition) {
        values.condition = {
            intensity: '',
            fromYear: ''
        };
    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <FormGroup>
                <Grid fluid>
                    <Row>
                        <Col sm={12}>
                            <ControlLabel>{t("medicalRecords.adverseEvents.intensity")}</ControlLabel>
                            <CustomSelect
                                value={values.condition.intensity}
                                //defaultValue={values.condition.intensityCode ? options.find(x => x.code == values.condition.intensityCode) : values.condition.intensity}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                name={`condition.intensity`}
                                placeholder={t("commons.select")}
                                options={options}
                                optionValue={(option) => option.code}
                            />
                            <ErrorMessage name={`condition.intensity`} component={FormErrorMessage} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4} md={4}>
                            <ControlLabel>{t("medicalRecords.condition.chronic")}</ControlLabel>
                            <CustomCheckbox
                                name={`condition.isChronic`}
                                isChecked={values.condition.isChronic || false}
                                onChange={handleChange} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <FromToNullableDatePicker
                                valuesFrom={{ fromDay: values.condition.fromDay, fromMonth: values.condition.fromMonth, fromYear: values.condition.fromYear }}
                                nameFromDay={`condition.fromDay`}
                                nameFromMonth={`condition.fromMonth`}
                                nameFromYear={`condition.fromYear`}
                                valuesTo={{ toDay: values.condition.toDay, toMonth: values.condition.toMonth, toYear: values.condition.toYear }}
                                nameToDay={`condition.toDay`}
                                nameToMonth={`condition.toMonth`}
                                nameToYear={`condition.toYear`}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                disabledTo={values.condition.isChronic}
                            />
                            <ErrorMessage name={`condition.fromYear`} component={FormErrorMessage} />
                            <ErrorMessage name={`condition.toYear`} component={FormErrorMessage} />
                            <ErrorMessage name={`condition.toMonth`} component={FormErrorMessage} />
                            <ErrorMessage name={`condition.toDay`} component={FormErrorMessage} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <ControlLabel>{t("medicalRecords.adverseEvents.notes")}</ControlLabel>
                            <FormControl componentClass="textarea" value={values.condition.notes} name={`condition.notes`} onChange={handleChange} />
                        </Col>
                    </Row>
                </Grid>
                <hr />
            </FormGroup>

            <button type="button" className="btn btn-default" onClick={props.onCancel} disabled={isSubmitting}>{t("buttons.cancel")}</button>
            <button type="submit" className="btn btn-success btn-right" disabled={isSubmitting}>{t("buttons.save")}</button>

            <div className="clearfix"></div>
        </form>
    );
}