import React from "react";
import Card from "../components/Cards/Card";
import { EditUserContainer } from "../containers/Users/EditUser";
import { useTranslation } from "react-i18next";

const UserProfile = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Card
        title={t("userProfile.userProfile_incomplete_title")}
        subtitle={t("userProfile.userProfile_incomplete_subtitle")}
      />
      <EditUserContainer {...props} hideCancel={true} />
    </>
  );
}

export default UserProfile;