import React from "react";
import PropTypes from "prop-types";
import { Modal as BootstrapModal } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

export const ModalTakePic = (props) => {
    const { onHide, onPhotoTaken } = props;
    const { t } = useTranslation();

    function handleTakePhotoAnimationDone(dataUri) {
        onPhotoTaken(dataUri);
    }

    return ReactDOM.createPortal(
        <React.Fragment>
            <BootstrapModal show={true} onHide={onHide} bsSize="large" backdrop="static" >
                <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title>{t("person.camera")}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <Camera
                        onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                        isFullscreen={false}
                        idealResolution={{ width: 400, height: 400 }}
                    />
                </BootstrapModal.Body>
            </BootstrapModal>
        </React.Fragment>, document.body
    )
};

ModalTakePic.propTypes = {
    onHide: PropTypes.func.isRequired,
    onPhotoTaken: PropTypes.func.isRequired,
};