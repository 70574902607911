import { HubConnectionState } from '@microsoft/signalr';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DailyAgendaWidget from '../../components/Dashboard/DailyAgendaWidget';
import { ModalConfirm } from '../../components/Modals/ModalConfirm';
import { HandleApiError } from '../../components/Notifications/APIErrorHandler';
import { useSignalR } from '../../services/signalRService';
import { agendaActionCreators } from '../../store/Agenda';

const WaitingListWidgetContainer = () => {
    const [modal, setModal] = useState(null);
    const { t } = useTranslation();
    const agendaStore = useSelector(state => state.agenda);
    const dispatch = useDispatch();
    const { connection } = useSignalR();

    useEffect(() => {
        if (connection) {
            connection.on('Agenda', res => {
                dispatch({
                    type: 'NOTIFICATION_RECEIVED',
                    payload: {
                        ...res
                    }
                });
                dispatch(agendaActionCreators.requestWaitingList());
            });

            return () => {
                // Leave group when component unmounts
                if (connection.connection._connectionState === HubConnectionState.Connected)
                    connection.invoke('LeaveGroup', "SystemNotifications");
            };
        }
    }, [connection]);

    useEffect(() => {
        let isSubscribed = true;

        const initComponent = async () => {
            if (isSubscribed)
                await dispatch(agendaActionCreators.requestWaitingList());
        }

        initComponent();

        return () => isSubscribed = false;
    }, []); //Uso el cambio de notifications, para hacer reload de eventos. TODO: ¿Pasar sala de espera a redux?

    const handleAttendance = async (eventId) => {
        if (!eventId)
            return;

        setModal(<ModalConfirm
            title={t('calendar.attended')}
            onConfirm={() => { dispatch(agendaActionCreators.requestMarkAsAttended(eventId)); setModal(null) }}
            onCancel={() => { setModal(null) }}
        />);
    }

    if (!agendaStore.isLoading && agendaStore.error) {
        HandleApiError(agendaStore.error);
        dispatch(agendaActionCreators.clearErrors());
    }

    return (
        <>
            {modal}
            <DailyAgendaWidget events={agendaStore.events} isLoading={agendaStore.isLoading} onAttendance={handleAttendance} />
        </>
    );

}

export default WaitingListWidgetContainer;
