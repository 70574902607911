import React from 'react'
import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react';

export function Tooltip({ id, children, tooltip, placement, style }) {

    const Children = React.forwardRef((props, ref) => <div {...props} ref={ref} style={style} />)

    return <Tippy
        content={tooltip}
        delay={[300, 150]}
        hideOnClick={false}
    >
        <Children>{children}</Children>
    </Tippy>;
}

Tooltip.defaultProps = {
    placement: "top",
    style: { display: 'inline-block' }
};

Tooltip.propTypes = {
    id: PropTypes.string.isRequired,
    tooltip: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
    ]).isRequired,
    style: PropTypes.object
};