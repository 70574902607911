import React from "react";
import { Popover } from "../Utils/Popover";
import { Audit } from '../Icons/Icons';
import { Diff } from '../Utils/Diff';
import { extractContentFromHTML } from "../Utils/Formatter";
import "../../assets/sass/lbd/_audit.scss";
import { useTranslation } from "react-i18next";

export const AuditTooltip = (props) => {
    const { t } = useTranslation();

    const diffComponent = <Diff inputA={extractContentFromHTML(props.text.old.toString())} inputB={extractContentFromHTML(props.text.current.toString())} type="words" />;
    return (
        <Popover
            id={`audit-tooltip-${props.id}`}
            title={t("auditTrail.lastChanges")}
            popover={diffComponent}
        >
            <Audit style={{ fontSize: '25px', color: '#50ABB5', cursor: 'hand' }} />
        </Popover>
    );
}