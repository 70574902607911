import React, { useState } from "react";
import PropTypes from 'prop-types';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import LinesEllipsis from 'react-lines-ellipsis'
import { useTranslation } from "react-i18next";

export const ReadMore = (props) => {
    const [readMore, setReadMore] = useState(false);
    const [isClamped, setIsClamped] = useState(false);
    const { t } = useTranslation();

    if (!props.content)
        return null;

    const linkName = readMore ? t("commons.viewLess") : t("commons.viewMore");
    return (
        <>
            {
                readMore ?
                    <span dangerouslySetInnerHTML={{ __html: props.content }} /> :
                    <HTMLEllipsis
                        unsafeHTML={props.content}
                        maxLine={props.maxLine}
                        ellipsis='...'
                        basedOn='letters'
                        onReflow={({ clamped }) => { setIsClamped(clamped) }}
                    />
            }
            {
                isClamped && <a className="read-more-link" onClick={() => { setReadMore(!readMore) }}>{linkName}</a>
            }
        </>
    );
}
ReadMore.propTypes = {
    maxLine: PropTypes.number,
    component: PropTypes.string,
};
ReadMore.defaultProps = {
    maxLine: 3,
    component: "div"
};


export const ReadMoreLines = (props) => {
    const [readMore, setReadMore] = useState(false);
    //const [isClamped, setIsClamped] = useState(false);

    if (!props.content)
        return null;

    const linkName = readMore ? '<< ' : '>> ';
    return (
        <>
            {
                readMore ?
                    <div onClick={() => setReadMore(!readMore)} tabIndex='0'>
                        <article className='ellipsis-text'>{props.content}</article>
                    </div>
                    :
                    <div onClick={() => setReadMore(!readMore)} tabIndex='0'>
                        <LinesEllipsis
                            component='article'
                            text={props.content}
                            maxLine={props.maxLine}
                            ellipsis='...'
                            basedOn='words'
                            className='ellipsis-text'
                        />
                    </div>
            }
            {/*
                readMore ?
                    <span>{props.content}</span> :
                    <LinesEllipsis
                        text={props.content}
                        component='article'
                        //className='read-more-text'
                        maxLine={props.maxLine}
                        ellipsis='...'
                        basedOn='words'
                        onReflow={({ clamped }) => { setIsClamped(clamped) }}
                    />
            */}
            {
                // isClamped && <a className="read-more-link" onClick={() => { setReadMore(!readMore) }}>{linkName}</a>
            }
        </>
    );
}
ReadMoreLines.propTypes = {
    maxLine: PropTypes.number,
    component: PropTypes.string,
};
ReadMoreLines.defaultProps = {
    maxLine: 3,
    component: "span"
};
