import { getQueryString } from './commons';
import axios from './index';

// Forms
export const getExtraForms = async (protocolId, query) => {
    const queryString = getQueryString(query, window.location.search);
    console.log(queryString)
    return axios.get(`api/v1/protocolExtraForms/${protocolId}/forms?${queryString}`);
};
export const getExtraForm = (protocolId, formId) => {
    return axios.get(`api/v1/protocolExtraForms/${protocolId}/forms/${formId}`);
};
export const createExtraForm = (protocolId, extraformtype, form) => {
    return axios.post(`api/v1/protocolExtraForms/${protocolId}/forms/type/${extraformtype}`, form);
};
export const editExtraForm = (protocolId, formId, form) => {
    return axios.put(`api/v1/protocolExtraForms/${protocolId}/forms/${formId}`, form);
};
export const removeExtraForm = (protocolId, formId) => {
    return axios.delete(`api/v1/protocolExtraForms/${protocolId}/forms/${formId}`);
};