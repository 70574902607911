import React from "react";
import { useHistory } from "react-router-dom";
import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';

import { createStudyRole } from "../../../api/security";
import { getStudyRoleSchema } from "../../../components/Validations/FormValidationSchema";
import { StudyRoleForm } from "../../../components/Authorization/StudyRoles";
import { Success } from '../../../components/Notifications/Notifications';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';

export const CreateStudyRoleContainer = () => {
    let history = useHistory();

    const handleSubmit = () => {
        history.push(`/admin/settings/studyRoles`);
    }
    const handleCancel = () => {
        history.push(`/admin/settings/studyRoles`);
    }

    return (
        <CreateStudyRoleContainerFormik
            onSubmit={handleSubmit}
            onCancel={handleCancel}
        />
    );
}

const CreateStudyRoleContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: () => ({}),

    validationSchema: getStudyRoleSchema(),

    handleSubmit: (values, formikBag) => {
        const role = {
            name: values.role.name,
            permissions: values.role.permissions//.flat().filter(x => x)
        }
        //console.log("Posting StudyRole:", values);
        createStudyRole(role)
            .then(res => {
                var role = res.data;
                formikBag.setSubmitting(false);
                Success("protocolNotifications.protocol_Created");
                formikBag.props.onSubmit(role);
            }).catch(error => {
                console.log(error);
                formikBag.setSubmitting(false);
                HandleApiError(error);
            });
    },

    displayName: 'StudyRoleForm',

})(StudyRoleForm));
