import React, { useState } from "react";

import { useDrugs } from "../Hooks/useDrugs";
import { Drugs } from "../../../components/MedicalRecords/Drugs";
import { AuditModal } from "../../../components/AuditTrail/AuditModal";
import { AuditTrail } from "../../Admin/AuditTrail";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { useTranslation } from "react-i18next";
import { ModalDrugsHistory } from "../Modals/ModalDrugsHistory";

export const DrugsListContainer = ({ mrn,medicalRecordNumber, entryId, expandedAll }) => {
    const { t } = useTranslation();
    const [drugs, query, totalSize, isLoading, handleTableChange,] = useDrugs(medicalRecordNumber, entryId);

    const [modal, setModal] = useState(null);
    const handleAuditTrail = (entityId) => {
        setModal(
            <AuditModal
                onClose={() => { setModal(null); }}
                title={t("auditTrail.audit")}
            >
                <AuditTrail entity="Drugs" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }
    const handleHistory = (_drugId, _mrn, drug) => {
        setModal(<ModalDrugsHistory
            onClose={() => { setModal(null); }}
            title={t("medicalRecords.history.history")}
            medicalRecordNumber={medicalRecordNumber || mrn}
            conceptId={drug.name?.conceptId}
        />)
    }

    if (entryId && totalSize === 0)
        return null;

    return (
        <ExpandableContent title={t("medicalRecords.drugs.title")} content="drugs" expandedAll={expandedAll}>
            {modal}
            <Drugs
                withCard={false}
                data={drugs}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onAuditTrail={handleAuditTrail}
                onHistory={handleHistory}
            />
        </ExpandableContent>
    );
}