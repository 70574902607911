import PropTypes from 'prop-types';
import React from "react";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useProtocolAdverseEvents } from "../../containers/Protocols/Hooks";
import { useMasterData } from '../CustomHooks/Hooks';

export const CustomSelect = (props) => {

    const handleChange = value => {
        if (props.onChange)
            props.onChange(props.name, value);
    };

    const handleBlur = () => {
        if (props.onBlur)
            props.onBlur(props.name, true);
    };

    return (
        <Select
            {...props}
            className="react-select"
            classNamePrefix="custom-select"
            placeholder={props.placeholder}
            options={props.options}
            onChange={handleChange}
            onBlur={handleBlur}
        />
    );
}
CustomSelect.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    value: PropTypes.object,
    isMulti: PropTypes.bool,
    optionValue: PropTypes.func,
    optionLabel: PropTypes.func,
    isClearable: PropTypes.bool,
    isDisabled: PropTypes.bool
};
CustomSelect.defaultProps = {
    getOptionLabel: (option) => option.title,
    getOptionValue: (option) => option.id,
    isClearable: false
};


export const CustomCreatableSelect = (props) => {

    const handleChange = value => {
        if (props.onChange)
            props.onChange(props.name, value);
    };

    const handleBlur = () => {
        if (props.onBlur)
            props.onBlur(props.name, true);
    };

    return (
        <CreatableSelect
            {...props}
            className="react-select"
            classNamePrefix="custom-select"
            placeholder={props.placeholder}
            options={props.options}
            onChange={handleChange}
            onBlur={handleBlur}
            value={props.value}
        />
    );
}
CustomCreatableSelect.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    isMulti: PropTypes.bool,
    optionValue: PropTypes.func,
    optionLabel: PropTypes.func,
    isClearable: PropTypes.bool,
    isDisabled: PropTypes.bool
};
CustomCreatableSelect.defaultProps = {
    getOptionLabel: (option) => option,
    getOptionValue: (option) => option,
    isClearable: false
};

export const CustomSelectAsync = (props) => {

    const options = useMasterData(props.masterData);

    const handleChange = value => {
        props.onChange(props.name, value);
    };

    const handleBlur = () => {
        if (props.onBlur)
            props.onBlur(props.name, true);
    };

    return (
        <>
            <Select
                {...props}
                placeholder={props.placeholder}
                options={options}
                onChange={handleChange}
                onBlur={handleBlur}
                value={props.value}
            />
        </>
    );
}
CustomSelectAsync.propTypes = {
    name: PropTypes.string.isRequired,
    masterData: PropTypes.string,
    isMulti: PropTypes.bool,
    optionValue: PropTypes.func,
    optionLabel: PropTypes.func,
    isClearable: PropTypes.bool,
    isDisabled: PropTypes.bool
};
CustomSelectAsync.defaultProps = {
    getOptionLabel: (option) => option.title,
    getOptionValue: (option) => option.id,
    isClearable: false
};

export const AdverseEventsAsync = (props) => {

    const [data, isLoading] = useProtocolAdverseEvents(props.protocolId, props.visitId, props.medicalRecordNumber, props.patientNumber);

    const handleChange = value => {
        props.onChange(props.name, value);
    };

    const handleBlur = () => {
        if (props.onBlur)
            props.onBlur(props.name, true);
    };

    return (
        <Select
            {...props}
            isLoading={isLoading}
            placeholder={props.placeholder}
            options={data}
            onChange={handleChange}
            onBlur={handleBlur}
            value={props.value}
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        />
    );
}
AdverseEventsAsync.propTypes = {
    name: PropTypes.string.isRequired,
    protocolId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    visitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isMulti: PropTypes.bool,
    optionValue: PropTypes.func,
    optionLabel: PropTypes.func,
    isClearable: PropTypes.bool,
    isDisabled: PropTypes.bool
};
AdverseEventsAsync.defaultProps = {
    getOptionLabel: (option) => option.description,
    getOptionValue: (option) => option.conceptId,
    isClearable: false
};