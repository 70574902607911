import { FieldArray } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { Col, Grid, Row } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import '../../../assets/sass/lbd/_attachments.scss'
import { DocumentViewer } from '../DocViewer/DocumentViewer'

import { IconSVG, Remove } from '../../Icons/Icons'
import { dateTimeFormatter } from '../../Utils/Formatter'
import { Tooltip } from '../../Utils/Tooltips'
import { useFiles } from './Hooks'
import { activeStyle, baseStyle, getIconClassName } from './Utils'

export const Files = (props) => {
    const {
        endpoint,
        label,
        attachments,
        showRemove,
        extensionProp,
        nameProp,
        idProp,
    } = props;

    const [response, downloadBlob] = useFiles(endpoint);

    const viewFile = async (fileId) => {
        try {
            await downloadBlob(fileId);
        }
        catch (error) {
            console.error(error);
        }
    }

    const handleRemove = (event, attachmentId) => { event.preventDefault(); props.onRemove(attachmentId) }

    return (<>
        {label}
        <DocumentViewer
            file={response?.blob}
            fileName={response?.fileName}
            inModal={true}
        />
        {attachments && attachments.length > 0 ? (
            attachments.map((file, index) => (
                <Col md={4} sm={4} key={`attachIndex-${index}`}>
                    <div className="uploaded">
                        <i className={"file-extension " + getIconClassName(file[extensionProp])}></i>
                        <div className="file">
                            <div className="file-name">
                                <p>
                                    <a onClick={() => viewFile(file[idProp])} style={{ cursor: 'pointer' }}>
                                        {file[nameProp]}
                                    </a>
                                </p>

                                {
                                    showRemove ??
                                    <Tooltip tooltip="Eliminar Archivo" id="tooltip-remove-attach">
                                        <button className="btn btn-fill btn-danger btn-sm btn-right" onClick={(event) => handleRemove(event, file[idProp])}>
                                            <Remove />
                                        </button>
                                    </Tooltip>
                                }
                            </div>
                            <div>
                                <small>{dateTimeFormatter(file.created)}</small>
                            </div>
                        </div>
                    </div>
                </Col>
            ))
        ) : ""}
    </>
    );
}

Files.propTypes = {
    endpoint: PropTypes.string.isRequired,
    onRemove: PropTypes.func,
    attachments: PropTypes.array.isRequired,
    label: PropTypes.string,
    showRemove: PropTypes.bool,
    extensionProp: PropTypes.string,
    nameProp: PropTypes.string,
    idProp: PropTypes.string,
}
Files.defaultProps = {
    showRemove: false,
    extensionProp: "fileExtension",
    nameProp: "fileName",
    idProp: "id",
}

export const FilesForm = (props) => {
    const {
        name,
        values,
        placeholder,
        onChange,
        disabled,
        onDownload,
        onRemove,
        accept,
        multiple,
        showRemove
    } = props;

    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: acceptedFiles => {
            var attach = acceptedFiles.map(file => Object.assign({}, {
                file: file,
                fileName: file.path,
                size: file.size
            }));
            setFiles(attach);
            if (multiple)
                onChange(name, attach);
            else
                onChange(name, attach[0]);
        },
        multiple: multiple,
        accept: accept,
        maxSize: 5000000
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {})
    }), [isDragActive]);

    useEffect(() => {
        if (values)
            setFiles(Array.isArray(values) ? values : [values]);
        else
            setFiles([])
    }, [values]);

    const handleRemove = (fileName) => {
        onChange(name, null);
        setFiles(files.filter(x => x.fileName !== fileName));
        if (onRemove)
            onRemove(name, fileName);
    }

    const handleView = (fileName) => {
        if (onDownload)
            onDownload(fileName)
    }

    return (
        <div className="attachments-container">
            {
                files.length === 0 &&
                <section>
                    <div {...getRootProps({ style })} className="dropzone">
                        <input {...getInputProps()} disabled={disabled} />
                        <div className="dropzone-content">
                            <IconSVG name="upload" />
                            <p>{placeholder}</p>
                        </div>
                    </div>
                </section>
            }
            <FieldArray
                name={name}
                render={() => (
                    <>
                        <Grid fluid>
                            {files && files.length > 0 && (
                                files.map((file, index) => (
                                    <Row key={index}>
                                        <Col md={12}>
                                            <a onClick={() => handleView(file.fileName)} style={{ cursor: 'pointer' }}>
                                                {file.fileName}
                                            </a>
                                            {
                                                showRemove && <button type="button" className="btn btn-fill btn-danger btn-sm btn-right" onClick={() => handleRemove(file.fileName)}>
                                                    <Remove />
                                                </button>
                                            }
                                        </Col>
                                    </Row>
                                ))
                            )}
                            {files && files.length > 0 && <hr style={{ marginTop: '50px' }}></hr>}
                        </Grid>
                    </>
                )}
            />
        </div>
    )
}

FilesForm.defaultProps = {
    accept: 'image/*,.pdf',
    showRemove: true,
    multiple: true
}

FilesForm.propTypes = {
    accept: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onDownload: PropTypes.func,
    onRemove: PropTypes.func,
    name: PropTypes.string,
    values: PropTypes.object,
    showRemove: PropTypes.bool,
    multiple: PropTypes.bool,
}