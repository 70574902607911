import React from "react";
import { useTranslation } from 'react-i18next'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Edit, Remove, Attach } from "../../../components/Icons/Icons";
import { Tooltip } from "../../../components/Utils/Tooltips";
import { WithAuth } from "../../../components/Authorization/WithAuth";

export function Freezers({ pagedFreezers, isLoading, onEdit, onRemove, onTableChange, onOpenCertificate }) {
    const { t } = useTranslation();

    const columns = [
        { dataField: 'Description', text: t("commons.description"), headerStyle: { width: '15.625rem' } },
        { dataField: 'Location', text: t("commons.location"), headerStyle: { width: '12.5rem' } },
        { dataField: 'State', text: t("commons.status"), headerStyle: { width: '8rem' }, formatter: function (_cell, freezer, rowIndex) { return freezer.State !== null ? t(`settings.freezer.states.${freezer.State.Name}`) : "-" } },
        { dataField: 'Type', text: t("commons.type"), headerStyle: { width: '8rem' }, formatter: function (_cell, freezer, rowIndex) { return freezer.Type !== null ? t(`settings.freezer.types.${freezer.Type.Name}`) : "-" } },
        {
            text: t("settings.freezer.device"), headerStyle: { width: '4rem' }
            , formatter: function (_cell, freezer, rowIndex) {
                return freezer.HasDevice() ? freezer.Device.Name : " - "
            }
        },
        {
            dataField: 'view',
            text: "",
            isDummyField: true,
            headerClasses: 'header-actions',
            classes: 'row-actions',
            formatter: function (_cell, row, rowIndex) {
                return (
                    <div key={rowIndex}>
                        <WithAuth requiredPermission={["Freezers.Edit"]}>
                            <button className="btn-table-actions" onClick={() => onEdit(row.Id)}>
                                <Tooltip tooltip={t("settings.freezer.editTooltip")} id={"tooltipedit-" + row.id}>
                                    <span aria-hidden="true"><Edit /></span>
                                </Tooltip>
                            </button>
                        </WithAuth>


                        <WithAuth requiredPermission={["Freezers.Delete"]}>
                            <button className="btn-table-actions" onClick={() => onRemove(row.Id)}>
                                <Tooltip tooltip={t("settings.freezer.removeTooltip")} id={"tooltipremove-" + row.id}>
                                    <span aria-hidden="true"><Remove /></span>
                                </Tooltip>
                            </button>
                        </WithAuth>

                        <WithAuth requiredPermission={["Freezers.View", "VisitTracking.View"]}>
                            {row.Device?.CertificatePath != null && <button className="btn-table-actions" onClick={() => onOpenCertificate(row.Device?.CertificatePath)}>
                                <Tooltip tooltip={t("commons.certificate")} id={"tooltipcertificate-" + row.id}>
                                    <span aria-hidden="true"><Attach /></span>
                                </Tooltip>
                            </button>}
                        </WithAuth>
                    </div>
                )
            }
        }
    ]

    return (
        <>
            <BootstrapTable
                bordered={false}
                hover
                remote
                keyField='Id'
                classes="table-spaced table-spaced-small"
                wrapperClasses="table-responsive"

                pagination={paginationFactory({
                    page: pagedFreezers.PageNumber,
                    sizePerPage: pagedFreezers.PageSize,
                    totalSize: pagedFreezers.TotalCount,
                    hideSizePerPage: true
                })}
                onTableChange={onTableChange}

                data={pagedFreezers.Values}
                columns={columns}
                loading={isLoading}
                noDataIndication={() => <>{t("commons.noData")}</>}
            />
        </>
    )
}