import { useState, useEffect } from "react";
import { HandleApiError } from "../../components/Notifications/APIErrorHandler";
import { getAvailabilityAPI, getEvents, getMasterData, getMedicalServiceAPI, getMedicalServicesAPI, 
    getPractitionersAPI, getMedicalServicePriceAPI, getGlobalAvailabilityAPI, getScheduleLocksAPI, getEventAPI, deleteScheduleLockAPI, getEventsByPatient } from '../../api/agenda'
import moment from "moment";

export const useEvents = (query) => {
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let isSubscribed = true
        const fetchData = async () => {
            try {
                const response = await getEvents(query);

                if (isSubscribed) {
                    var events = response.data.map(event => {
                        event.start = moment.utc(moment.utc(event.start)).local().toDate();
                        event.end = moment.utc(moment.utc(event.end)).local().toDate();
                        return event;
                    });
                    setEvents(events);
                    setIsLoading(false);
                }
            } catch (error) {
                HandleApiError(error);
                setIsLoading(false);
            }
        }

        if (query)
            fetchData();

        return () => isSubscribed = false

    }, [query]);

    const getEvent = async (id) => {
        try {
            return getEventAPI(id);
        } catch (error) {
            HandleApiError(error);
            return [];
        }
    }

    return [events, isLoading, setEvents, getEvent];
}

export const useMasterData = () => {
    const [masterData, setMasterData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let isSubscribed = true
        const fetchData = async () => {
            try {
                const response = await getMasterData();

                if (isSubscribed) {
                    setMasterData(response.data);
                    setIsLoading(false);
                }
            } catch (error) {
                HandleApiError(error);
                setIsLoading(false);
            }
        }

        fetchData();

        return () => isSubscribed = false;
    }, []);

    return [masterData, isLoading];
}

export const useAppointment = (medicalRecordNumber) => {
    const [isLoading, setIsLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [totalSize, setTotalSize] = useState(0);
    const [query, setQuery] = useState({
        medicalRecordNumber: medicalRecordNumber,
        pagination: {
            page: 1,
            sizePerPage: 5,
            totalSize: 0
        },
        sort: {
            sortField: "Start",
            sortOrder: "desc"
        },
        filters: {}
    });

    useEffect(() => {
        let isSubscribed = true
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const paginatedItems = await getEventsByPatient(medicalRecordNumber, query);

                if (isSubscribed) {
                    setTotalSize(paginatedItems.data.count);
                    setEvents(paginatedItems.data.data);
                    setIsLoading(false);
                }

            } catch (error) {
                HandleApiError(error);
                setIsLoading(false);
                setTotalSize(0);
                setEvents([]);
            }
        }

        if (medicalRecordNumber)
            fetchData();

        return () => isSubscribed = false;
    }, [medicalRecordNumber, query]);


    const handleFilterChange = (filterName, selectedValue) => {
        if (filterName) {
            var newQuery = JSON.parse(JSON.stringify(query));
            newQuery.filters[filterName] = selectedValue;
            newQuery.pagination.page = 1;
            setQuery(newQuery);
        }
    }

    const getMore = () => {
        var newQuery = JSON.parse(JSON.stringify(query));
        newQuery.pagination.page = newQuery.pagination.page + 1;
        setQuery(newQuery);
    }

    return [events, query, totalSize, isLoading, getMore, handleFilterChange];
}

export const useAgenda = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [practitioners, setPractitioners] = useState([]);
    const [availability, setAvailability] = useState([]);

    const getPractitioners = async (medicalServiceId, medicalInsuranceId) => {
        try {
            return getPractitionersAPI(medicalServiceId, medicalInsuranceId);
        } catch (error) {
            HandleApiError(error);
            return [];
        }
    }

    const getAvailability = async (userId, medicalServiceId, medicalInsuranceId, medicalPlanId, isFirstTime, isOverShift) => {
        try {
            return getAvailabilityAPI(userId, medicalServiceId ?? 0, medicalInsuranceId ?? 0, medicalPlanId ?? 0, isFirstTime, isOverShift);
        } catch (error) {
            HandleApiError(error);
            return [];
        }
    }

    const getGlobalAvailability = async (userId) => {
        try {
            return getGlobalAvailabilityAPI(userId);
        } catch (error) {
            HandleApiError(error);
            return [];
        }
    }

    return [
        isLoading, setIsLoading,
        practitioners, getPractitioners, setPractitioners,
        availability, getAvailability, setAvailability, getGlobalAvailability];
}

export const useScheduleLocks = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalSize, setTotalSize] = useState(0);
    const [query, setQuery] = useState({
        pagination: {
            page: 1,
            sizePerPage: 5,
            totalSize: 0
        },
        sort: {
            sortField: "Start",
            sortOrder: "desc"
        },
        filters: {}
    });

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = () => {
            setIsLoading(true);
            getScheduleLocksAPI(query)
                .then(response => {
                    if (isSubscribed) {
                        const paginatedResult = response.data;

                        setTotalSize(paginatedResult.count);
                        setData(paginatedResult.data);
                        setIsLoading(false);
                    }
                }).catch(error => {
                    if (isSubscribed) {
                        HandleApiError(error);
                        setIsLoading(false);
                    }
                });
        }

        fetchData();

        return () => isSubscribed = false;
    }, [query]);

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {

        var newQuery = null;
        if (type === 'pagination') {
            newQuery = JSON.parse(JSON.stringify(query));

            newQuery.pagination.page = page;
            newQuery.pagination.sizePerPage = sizePerPage;

            setQuery(newQuery);
        }

        if (type === 'sort') {
            newQuery = JSON.parse(JSON.stringify(query));

            newQuery.sort.sortField = sortField;
            newQuery.sort.sortOrder = sortOrder;

            setQuery(newQuery);
        }

        if (type === 'reload') {
            newQuery = JSON.parse(JSON.stringify(query));
            setQuery(newQuery);
        }
    }

    const handleFilterChange = (filterName, selectedValue) => {
        if (filterName) {
            var newQuery = JSON.parse(JSON.stringify(query));

            newQuery.filters[filterName] = selectedValue != null ? selectedValue.id : selectedValue;

            setQuery(newQuery);
        }
    }

    const handleRemove = async (id) => {
        try {
            return deleteScheduleLockAPI(id);
        } catch (error) {
            HandleApiError(error);
            return [];
        }
    }

    return [data, query, totalSize, isLoading, handleTableChange, handleFilterChange, handleRemove];
}

export const useMedicalServices = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [medicalService, setMedicalService] = useState([]);
    const [medicalServices, setMedicalServices] = useState([]);
    const [medicalServicePrice, setMedicalServicePrice] = useState(null);

    const getMedicalService = async (id) => {
        try {
            return getMedicalServiceAPI(id);
        } catch (error) {
            HandleApiError(error);
            return [];
        }
    }

    const getMedicalServices = async () => {
        try {
            return getMedicalServicesAPI();
        } catch (error) {
            HandleApiError(error);
            return [];
        }
    }

    const getMedicalServicePrice = async (medicalServiceId, medicalInsuranceId, medicalPlanId) => {
        try {
            return getMedicalServicePriceAPI(medicalServiceId, medicalInsuranceId, medicalPlanId);
        } catch (error) {
            HandleApiError(error);
            return {};
        }
    }

    return [
        isLoading, setIsLoading,
        medicalService, getMedicalService, setMedicalService,
        medicalServices, getMedicalServices, setMedicalServices,
        medicalServicePrice, getMedicalServicePrice, setMedicalServicePrice];
}