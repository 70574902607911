import { ErrorMessage, FieldArray } from 'formik';
import React, { useState } from 'react';
import { Col, ControlLabel, FormControl, Row } from 'react-bootstrap';
import { conceptType, snomedExpressions } from '../../../variables/Enums';
import { CustomDate } from '../../CustomControls/CustomDateTime';
import { CustomRadio } from '../../CustomControls/CustomRadio';
import { CustomSelect } from "../../CustomControls/CustomSelect";
import { SnomedPicker } from '../../CustomControls/SnomedPicker';
import { Note, Remove } from '../../Icons/Icons';
import { FormErrorMessage } from '../../Validations/FormValidationErrors';
import { CustomSwitch } from '../../CustomControls/CustomSwitch';

export const PrescriptionsForm = props => {

    const {
        values,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        isSubmitting,
        handleSubmit,
        t,
        data,
        events
    } = props;

    const [showNote, setShowNote] = useState(null);
    const [diagnosis, setDiagnosis] = useState(null);
    
    const emptyResult = {
        name: '',
        dose: '',
        packageCount: null,
        posology: null,
        unit: data && data.units && data.units.find(x => x.code == "comp"),
        longTerm: false,
        reason: diagnosis
    };

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };
    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };
    const formatGroupLabel = data => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    const findReasonInEventList = (reasonId, events) => {
        if (!reasonId)
            return events[0].options[0]; // Devuelvo default

        var reason = events[1].options.find(x => x.id === reasonId); // Busco en patologias
        if (reason)
            return reason;
    }

    const getReasonTitle = (reason) => {

        if (!reason)
            return null;

        if (typeof reason === 'string')
            return reason;

        return reason.title;
    }

    const handleSelectGeneralReason = (_name, selectedReason) => {
        setDiagnosis(selectedReason);
        let prescriptionsCopy = JSON.parse(JSON.stringify(values.prescriptions));
        prescriptionsCopy.forEach(prescription => {
            prescription.reason = selectedReason;
        });
        setFieldValue("prescriptions", prescriptionsCopy);
    }

    if (!values.prescriptions) {
        values.prescriptions = [emptyResult];
    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <Row>
                <Col md={3}>
                    <ControlLabel>{t("medicalRecords.prescriptions.prescriptionDate")}</ControlLabel>
                    <CustomDate
                        value={values.prescriptionDate ?? new Date()}
                        name="prescriptionDate"
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        closeOnSelect={true}
                        dateFormat="DD/MM/YYYY"
                        inputProps={{ placeholder: "DD/MM/YYYY" }}
                    />
                    <ErrorMessage name="prescriptionDate" component={FormErrorMessage} />
                </Col>
                <Col md={3}>
                    <ControlLabel>{t("medicalRecords.prescriptions.medicalInsurance")}</ControlLabel>
                    <FormControl type="text" name="medicalInsurance" value={values.medicalInsurance} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                    <ErrorMessage name="medicalInsurance" component={FormErrorMessage} />
                </Col>
                <Col md={3}>
                    <ControlLabel>{t("medicalRecords.prescriptions.medicalInsurancePlan")}</ControlLabel>
                    <FormControl type="text" name="medicalInsurancePlan" value={values.medicalInsurancePlan} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                    <ErrorMessage name="medicalInsurancePlan" component={FormErrorMessage} />
                </Col>
                <Col md={3}>
                    <ControlLabel>{t("medicalRecords.prescriptions.medicalInsuranceNumber")}</ControlLabel>
                    <FormControl type="text" name="medicalInsuranceNumber" value={values.medicalInsuranceNumber} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                    <ErrorMessage name="medicalInsuranceNumber" component={FormErrorMessage} />
                </Col>
                {/*<Col md={12}>
                    <Row>
                        <Col md={3}>
                            <ControlLabel>{t("medicalRecords.prescriptions.prescriptionType")}</ControlLabel>
                        </Col>
                        <Col md={2}>
                            <CustomRadio
                                number="SIMPLE"
                                option={0}
                                name="type"
                                onChange={setFieldValue}
                                checked={values.type == 0}
                                label={t("medicalRecords.prescriptions.prescriptionType_simple")}
                            />
                        </Col>
                        <Col md={2}>
                            <CustomRadio
                                number="DOUBLE"
                                option={1}
                                name="type"
                                onChange={setFieldValue}
                                checked={values.type == 1}
                                label={t("medicalRecords.prescriptions.prescriptionType_double")}
                            />
                        </Col>
                        <Col md={2}>
                            <CustomRadio
                                number="TRIPLE"
                                option={2}
                                name="type"
                                onChange={setFieldValue}
                                checked={values.type == 2}
                                label={t("medicalRecords.prescriptions.prescriptionType_triple")}
                            />
                        </Col>
                    </Row>
                </Col>*/}
            </Row>
            <br />
            <FieldArray
                name="prescriptions"
                render={arrayHelper => (
                    <>
                        <Row className="table-row-header">
                            <Col md={1}>
                                {t('medicalRecords.prescriptionsItems.packageCount')}
                            </Col>
                            <Col md={3}>
                                {t("medicalRecords.prescriptionsItems.drug")}
                            </Col>
                            <Col md={1}>
                                {t('medicalRecords.prescriptionsItems.posology')}
                            </Col>
                            <Col md={2}>
                                {t('medicalRecords.prescriptionsItems.unit')}
                            </Col>
                            <Col md={2}>
                                {t('medicalRecords.prescriptionsItems.frequency')}
                            </Col>
                            <Col md={1}>
                                {t('medicalRecords.prescriptionsItems.longTerm')}
                            </Col>
                            <Col md={1}>
                                {t('medicalRecords.prescriptionsItems.observeBrand')}
                            </Col>
                        </Row>
                        {
                            values.prescriptions && values.prescriptions.length > 0 ? (
                                values.prescriptions.map((prescription, index) => (
                                    <Row className="table-row-body display-flex" key={index}>
                                        <Col md={1}>
                                            <FormControl type="text" name={`prescriptions.${index}.packageCount`} value={prescription.packageCount} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                            <ErrorMessage name={`prescriptions.${index}.packageCount`} component={FormErrorMessage} />
                                        </Col>
                                        <Col md={3}>
                                            {
                                                prescription.drug && prescription.drug.description
                                            }
                                            {
                                                !prescription.drug &&
                                                <>
                                                    <SnomedPicker
                                                        conceptType={conceptType.prescriptions}
                                                        autoFocus
                                                        id={`prescriptions.${index}.drug`}
                                                        defaultInputValue={prescription.drug ? prescription.drug.description : ''}
                                                        value={prescription.drug}
                                                        name={`prescriptions.${index}.drug`}
                                                        placeholder={t("commons.search")}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        expression={snomedExpressions.Producto_O_Sustancia}
                                                    />
                                                    <ErrorMessage name={`prescriptions.${index}.drug`} component={FormErrorMessage} />
                                                </>
                                            }
                                        </Col>
                                        <Col md={1}>
                                            <FormControl type="text" name={`prescriptions.${index}.posology`} value={prescription.posology} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                            <ErrorMessage name={`prescriptions.${index}.posology`} component={FormErrorMessage} />
                                        </Col>
                                        <Col md={2}>
                                            <CustomSelect
                                                value={prescription.unit}
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                name={`prescriptions.${index}.unit`}
                                                placeholder={t("commons.select")}
                                                options={data.units} />
                                            <ErrorMessage name={`prescriptions.${index}.unit`} component={FormErrorMessage} />
                                        </Col>
                                        <Col md={2}>
                                            <CustomSelect
                                                value={prescription.frequency}
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                name={`prescriptions.${index}.frequency`}
                                                placeholder={t("commons.select")}
                                                options={data.frequencies} />
                                            <ErrorMessage name={`prescriptions.${index}.frequency`} component={FormErrorMessage} />
                                        </Col>
                                        <Col md={1}>
                                            <CustomSwitch value={prescription.longTerm} name={`prescriptions.${index}.longTerm`} onChange={setFieldValue} />
                                            <ErrorMessage name={`prescriptions.${index}.longTerm`} component={FormErrorMessage} />
                                        </Col>
                                        <Col md={1}>
                                            <CustomSwitch value={prescription.observeBrand} name={`prescriptions.${index}.observeBrand`} onChange={setFieldValue} />
                                            <ErrorMessage name={`prescriptions.${index}.observeBrand`} component={FormErrorMessage} />
                                        </Col>
                                        <Col md={1}>
                                            <div>
                                                <button
                                                    className="btn-table-actions"
                                                    style={{ verticalAlign: 'middle' }}
                                                    type="button"
                                                    onClick={() => { showNote === index ? setShowNote(null) : setShowNote(index) }}
                                                >
                                                    <Note />
                                                </button>
                                                {
                                                    !props.edit &&
                                                    <button
                                                        className="btn-table-actions btn-remove"
                                                        style={{ verticalAlign: 'middle' }}
                                                        type="button"
                                                        onClick={() => arrayHelper.remove(index)}>
                                                        <Remove />
                                                    </button>
                                                }
                                            </div>
                                        </Col>
                                        {
                                            prescription.drug && prescription.drug.description && !prescription.drug.description.includes('[') &&
                                            <Col md={12} style={{ paddingTop: '0' }}>
                                                <Row>
                                                    <Col md={2}>
                                                        {t('medicalRecords.prescriptionsItems.dose')}
                                                    </Col>
                                                    <Col md={3}>
                                                        {t("medicalRecords.prescriptionsItems.unit")}
                                                    </Col>
                                                    <Col md={2}>
                                                        {t('medicalRecords.prescriptionsItems.count')}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={2}>
                                                        <FormControl type="text" name={`prescriptions.${index}.dose`} value={prescription.dose} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                        <ErrorMessage name={`prescriptions.${index}.dose`} component={FormErrorMessage} />
                                                    </Col>
                                                    <Col md={3}>
                                                        <CustomSelect
                                                            value={prescription.genericUnit}
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            name={`prescriptions.${index}.genericUnit`}
                                                            placeholder={t("commons.select")}
                                                            options={data.units} />
                                                        <ErrorMessage name={`prescriptions.${index}.genericUnit`} component={FormErrorMessage} />
                                                    </Col>
                                                    <Col md={2}>
                                                        <FormControl type="text" name={`prescriptions.${index}.count`} value={prescription.count} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                        <ErrorMessage name={`prescriptions.${index}.count`} component={FormErrorMessage} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        }
                                        {
                                            showNote === index &&
                                            <Col md={12}>
                                                <ControlLabel>{t('medicalRecords.prescriptions.indications')}</ControlLabel>
                                                <FormControl type="text" name={`prescriptions.${index}.notes`} value={prescription.notes} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                            </Col>
                                        }
                                        <hr />
                                    </Row>
                                ))
                            ) : ""}
                        <Row style={{ marginTop: '20px' }}>
                            <Col md={4}><button type="button" className="btn btn-default" onClick={() => {
                                emptyResult.order = values.prescriptions.length + 1;
                                arrayHelper.push(emptyResult);
                            }}>{t('medicalRecords.prescriptions.drugs.addDrug')}</button></Col>
                            <Col md={8}></Col>
                        </Row>
                    </>
                )
                }
            />
            <br />
            {
                values.prescriptions && values.prescriptions.length > 0 &&
                <Row>
                    <Col md={6}>
                        <ControlLabel>{t("medicalRecords.drugs.reason")}</ControlLabel>
                        {
                            getReasonTitle(values.reason) ??
                            <CustomSelect
                                defaultValue={findReasonInEventList(values.reasonId, events)}
                                name="reason"
                                placeholder={t("commons.select")}
                                onChange={handleSelectGeneralReason}
                                onBlur={setFieldTouched}
                                options={events}
                                getOptionLabel={(option) => option.adverseEffect}
                                getOptionValue={(option) => option.id}
                                formatGroupLabel={formatGroupLabel} />
                        }
                    </Col>
                </Row>
            }
            <br />
            <Row>
                <Col md={12}>
                    <ControlLabel>{t('medicalRecords.prescriptions.justification')}</ControlLabel>
                    <FormControl type="text" name="justification" value={values.justification || ''} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                </Col>
            </Row>
            <br />

            <button type="button" className="btn btn-default" onClick={props.onCancel} disabled={isSubmitting} > {t("buttons.cancel")}</button >
            {
                values.prescriptions && values.prescriptions.length > 0 &&
                <button type="submit" className="btn btn-success btn-right" disabled={isSubmitting}>{t("buttons.save")}</button>
            }

            < div className="clearfix" ></div >
        </form >
    );
}