import React, { useContext } from "react";
import { Col, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ProtocolContext from '../../containers/Protocols/ProtocolContext/ProtocolContext';
import Card from "../Cards/Card";
import { Laboratory, Phase, PrincipalInvestigator, Status } from "../Icons/Icons";
import { dateFormatter } from '../Utils/Formatter';

export const ProtocolInfo = (props) => {
    const { t } = useTranslation();
    const context = useContext(ProtocolContext);

    let brief;
    if (props.protocol)
        brief = props.protocol;
    else
        if (!context || context.isLoading)
            brief = null;
        else
            brief = context.protocol;

    return (
        <Card
            className="card-protocol-info"
            content={
                <Grid fluid >
                    <Row>
                        <Col md={3} sm={12}>
                            <Col md={12}>
                                <div className="">
                                    <div>
                                        <div className="title">{brief?.title}</div>
                                        <div className="group">{dateFormatter(brief?.created, "DD/MMM/YYYY")}</div>
                                    </div>
                                </div>
                            </Col>
                        </Col>
                        <Col md={9} sm={12}>
                            <Col md={3} sm={6}>
                                <div className="">
                                    <div className="icon">
                                        <div className="svg-container"><Laboratory /></div>
                                    </div>
                                    <div>
                                        <div className="group">{t("protocols.brief.laboratory")}</div>
                                        <div className="title">{brief?.laboratory?.title}</div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3} sm={6}>
                                <div className="">
                                    <div className="icon">
                                        <div className="svg-container"><PrincipalInvestigator /></div>
                                    </div>
                                    <div>
                                        <div className="group">{t("protocols.brief.principalInvestigator")}</div>
                                        <div className="title">{brief?.principalInvestigator?.fullName}</div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3} sm={6}>
                                <div className="">
                                    <div className="icon">
                                        <div className="svg-container"><Status /></div>
                                    </div>
                                    <div>
                                        <div className="group">{t("protocols.brief.status")}</div>
                                        <div className="title">{brief?.status?.title}</div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3} sm={6}>
                                <div className="">
                                    <div className="icon">
                                        <div className="svg-container"><Phase /></div>
                                    </div>
                                    <div>
                                        <div className="group">{t("protocols.brief.phase")}</div>
                                        <div className="title">{brief?.phase?.title}</div>
                                    </div>
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </Grid >
            }
        />
    );
}

//ProtocolInfo.propTypes = {
//    protocol: PropTypes.object.isRequired,
//};