import axios from './index';

export const getQueryString = (newActiveFilters, search) => {
    const searchParams = new URLSearchParams(search || '');

    searchParams.delete('pageIndex'); // se resetea el paginado
    if (newActiveFilters.pagination && newActiveFilters.pagination.page && newActiveFilters.pagination.page !== 1) {
        searchParams.append('pageIndex', newActiveFilters.pagination.page);
    }
    searchParams.delete('pageSize');
    if (newActiveFilters.pagination && newActiveFilters.pagination.sizePerPage && newActiveFilters.pagination.sizePerPage !== 1) {
        searchParams.append('pageSize', newActiveFilters.pagination.sizePerPage);
    }
    searchParams.delete('sortField');
    if (newActiveFilters.sort && newActiveFilters.sort.sortField) {
        searchParams.append('sortField', newActiveFilters.sort.sortField);
    }
    searchParams.delete('sortOrder');
    if (newActiveFilters.sort && newActiveFilters.sort.sortOrder) {
        searchParams.append('sortOrder', newActiveFilters.sort.sortOrder);
    }

    searchParams.delete('patientId');
    if (newActiveFilters.patientId) {
        searchParams.append('patientId', newActiveFilters.patientId);
    }

    searchParams.delete('medicalRecordNumber');
    if (newActiveFilters.medicalRecordNumber) {
        searchParams.append('medicalRecordNumber', newActiveFilters.medicalRecordNumber);
    }

    searchParams.delete('actual');
    if (newActiveFilters.actual) {
        searchParams.append('actual', newActiveFilters.actual);
    }

    searchParams.delete('includePersonalHistory');
    if (newActiveFilters.includePersonalHistory) {
        searchParams.append('includePersonalHistory', newActiveFilters.includePersonalHistory);
    }

    searchParams.delete('entryId');
    if (newActiveFilters.entryId) {
        searchParams.append('entryId', newActiveFilters.entryId);
    }

    searchParams.delete('category');
    if (newActiveFilters.category) {
        searchParams.append('category', newActiveFilters.category);
    }

    if (newActiveFilters.filters) {
        for (const property in newActiveFilters.filters) {

            searchParams.delete(property);
            if (newActiveFilters.filters[property] !== null && newActiveFilters.filters[property] !== undefined) {
                searchParams.append(property, newActiveFilters.filters[property]);
            }
        }
    }

    return searchParams.toString();
}

//#region Entries
export const getEntries = async (medicalRecordNumber, filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(`api/v1/medicalRecords/${medicalRecordNumber}?${queryString}`);
};
export const getEntry = (medicalRecordNumber, id) => {
    return axios.get(`api/v1/medicalRecords/${medicalRecordNumber}/entry/${id}`);
};
export const createEntry = (medicalRecordNumber, entry) => {
    return axios.post(`api/v1/medicalRecords/${medicalRecordNumber}/entry`, entry);
};
export const assignVisitId = (medicalRecordNumber, entryId, visitId) => {
    return axios.put(`api/v1/medicalRecords/${medicalRecordNumber}/entry/${entryId}/assignVisitId/${visitId}`, {});
};
export const saveEntry = (medicalRecordNumber, entryId, entry) => {
    return axios.put(`api/v1/medicalRecords/${medicalRecordNumber}/entry/${entryId}`, entry);
};
export const publishEntry = (medicalRecordNumber, entryId, entry) => {
    return axios.put(`api/v1/medicalRecords/${medicalRecordNumber}/entry/${entryId}/publish`, entry);
};
export const deleteEntry = (medicalRecordNumber, entryId) => {
    return axios.delete(`api/v1/medicalRecords/${medicalRecordNumber}/entry/${entryId}`);
};
export const exportEntry = (medicalRecordNumber, entryId, pin) => {
    return axios.get(`api/v1/medicalRecords/${medicalRecordNumber}/entry/${entryId}/exportToPDF/${pin}`, {
        responseType: 'blob',
        timeout: 30000,
    });
};
export const exportEntries = (medicalRecordNumber, pin) => {
    return axios.get(`api/v1/medicalRecords/${medicalRecordNumber}/exportToPDF/${pin}`, {
        responseType: 'blob',
        timeout: 300000,
    });
};
//#endregion

export const getAdherence = (filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(`api/v1/medicalRecords/adherence?${queryString}`);
};
export const postAdherence = (entryId, adherence) => {
    return axios.post(`api/v1/medicalRecords/entry/${entryId}/adherence`, adherence);
};

//#region AdverseEvents
export const getEvents = (filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(`api/v1/medicalRecords/events?${queryString}`);
};
export const getAdverseEvents = (filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(`api/v1/medicalRecords/adverseEvents?${queryString}`);
};
export const getAdverseEvent = (entryId, id) => {
    return axios.get(`api/v1/medicalRecords/entry/${entryId}/adverseEvents/${id}`);
};
export const getAdverseEventHistoryAPI = (medicalRecordNumber, conceptId, category) => {
    return axios.get(`api/v1/medicalRecords/${medicalRecordNumber}/adverseEvents/${conceptId}/condition/${category}/history`);
};
export const postAdverseEvents = (entryId, data) => {
    return axios.post(`api/v1/medicalRecords/entry/${entryId}/adverseEvents`, data);
};
export const putAdverseEvent = (entryId, id, data) => {
    return axios.put(`api/v1/medicalRecords/entry/${entryId}/adverseEvents/${id}`, data);
};
export const removeAdverseEventAPI = (entryId, id, reason, comments) => {
    return axios.delete(`api/v1/medicalRecords/entry/${entryId}/adverseEvents/${id}/audit/${reason}/${comments}`);
};
//#endregion

//#region Conditions
export const getAllConditions = (filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(`api/v1/medicalRecords/conditions?${queryString}`);
};
export const deleteCondition = (entryId, id, reason, comments) => {
    return axios.delete(`api/v1/medicalRecords/entry/${entryId}/adverseEvents/${id}/audit/${reason}/${comments}`);
}
export const postCondition = (entryId, data, category) => {
    return axios.post(`api/v1/medicalRecords/entry/${entryId}/condition/${category}`, data);
}
export const postCopyCondition = (entryId, conditionId, data) => {
    return axios.post(`api/v1/medicalRecords/entry/${entryId}/condition/${conditionId}/copy`, data);
}
export const getConditions = (entryId, category, filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(`api/v1/medicalRecords/entry/${entryId}/conditions/${category}?${queryString}`);
};

//#region attachments
export const getAttachments = (filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(`api/v1/medicalRecords/attachments?${queryString}`);
};
export const getAttachment = (id, medicalRecordNumber, entryId) => {
    return axios.get(`api/v1/medicalRecords/attachment?id=${id}&medicalRecordNumber=${medicalRecordNumber}&entryId=${entryId}`, { responseType: 'blob' });
};
export const uploadAttachment = (entryId, formData) => {
    return axios.post(`api/v1/medicalRecords/uploadAttachment/entry/${entryId}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
export const uploadAttachmentEndpoint = (entryId) => {
    return `api/v1/medicalRecords/uploadAttachment/entry/${entryId}`;
}
//#endregion

//#region drugs
export const getDrugs = (filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(`api/v1/medicalRecords/drugs?${queryString}`);
};
export const getDrugsHistoryAPI = (medicalRecordNumber, conceptId) => {
    return axios.get(`api/v1/medicalRecords/${medicalRecordNumber}/drugs/${conceptId}/history`);
};
export const getDrug = (entryId, id) => {
    return axios.get(`api/v1/medicalRecords/entry/${entryId}/drugs/${id}`);
};
export const postDrug = (entryId, data) => {
    return axios.post(`api/v1/medicalRecords/entry/${entryId}/drugs`, data);
};
export const putDrug = (entryId, id, data) => {
    return axios.put(`api/v1/medicalRecords/entry/${entryId}/drugs/${id}`, data);
};
export const removeDrugAPI = (entryId, id, reason, comments) => {
    return axios.delete(`api/v1/medicalRecords/entry/${entryId}/drugs/${id}/audit/${reason}/${comments}`);
};
//#endregion

//#region prescriptions
export const getPrescriptions = (filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(`api/v1/medicalRecords/prescriptions?${queryString}`);
};
export const getPrescription = (entryId, id) => {
    return axios.get(`api/v1/medicalRecords/entry/${entryId}/prescriptions/${id}`);
};
export const postPrescription = (medicalRecordNumber, entryId, data) => {
    return axios.post(`api/v1/medicalRecords/${medicalRecordNumber}/entry/${entryId}/prescriptions`, data);
};
export const putPrescription = (entryId, id, data) => {
    return axios.put(`api/v1/medicalRecords/entry/${entryId}/prescriptions/${id}`, data);
};
export const removePrescriptionAPI = (entryId, id, reason, comments) => {
    return axios.delete(`api/v1/medicalRecords/entry/${entryId}/prescriptions/${id}/audit/${reason}/${comments}`);
};
export const exportPrescription = (medicalRecordNumber, entryId, id, pin) => {
    return axios.get(`api/v1/medicalRecords/${medicalRecordNumber}/entry/${entryId}/prescriptions/${id}/exportToPDF/${pin}`, {
        responseType: 'blob',
        timeout: 30000,
    });
};
export const recreatePrescriptionAPI = (medicalRecordNumber, entryId, prescriptionId) => {
    return axios.post(`api/v1/medicalRecords/${medicalRecordNumber}/entry/${entryId}/prescriptions/${prescriptionId}`, null);
};
//#endregion

//#region orders
export const getOrders = (filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(`api/v1/medicalRecords/orders?${queryString}`);
};
export const getOrder = (entryId, id) => {
    return axios.get(`api/v1/medicalRecords/entry/${entryId}/orders/${id}`);
};
export const postOrder = (entryId, data) => {
    return axios.post(`api/v1/medicalRecords/entry/${entryId}/orders`, data);
};
export const putOrder = (entryId, id, data) => {
    return axios.put(`api/v1/medicalRecords/entry/${entryId}/orders/${id}`, data);
};
export const removeOrderAPI = (entryId, id, reason, comments) => {
    return axios.delete(`api/v1/medicalRecords/entry/${entryId}/orders/${id}/audit/${reason}/${comments}`);
};
export const exportOrder = (medicalRecordNumber, entryId, id, pin) => {
    return axios.get(`api/v1/medicalRecords/${medicalRecordNumber}/entry/${entryId}/orders/${id}/exportToPDF/${pin}`, {
        responseType: 'blob',
        timeout: 30000,
    });
};
//#endregion


//#region procedures
export const getProcedures = (filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(`api/v1/medicalRecords/procedures?${queryString}`);
};
export const getProcedure = (entryId, id) => {
    return axios.get(`api/v1/medicalRecords/entry/${entryId}/procedures/${id}`);
};
export const getProcedureHistoryAPI = (medicalRecordNumber, conceptId) => {
    return axios.get(`api/v1/medicalRecords/${medicalRecordNumber}/procedures/${conceptId}/history`);
};
export const postProcedure = (entryId, data) => {
    return axios.post(`api/v1/medicalRecords/entry/${entryId}/procedures`, data);
};
export const putProcedure = (entryId, id, data) => {
    return axios.put(`api/v1/medicalRecords/entry/${entryId}/procedures/${id}`, data);
};
export const removeProcedureAPI = (entryId, id, reason, comments) => {
    return axios.delete(`api/v1/medicalRecords/entry/${entryId}/procedures/${id}/audit/${reason}/${comments}`);
};
//#endregion


//#region anthropometrics
export const getAnthropometrics = (filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(`api/v1/medicalRecords/anthropometrics?${queryString}`);
};
export const getAnthropometric = (entryId, id) => {
    return axios.get(`api/v1/medicalRecords/entry/${entryId}/anthropometrics/${id}`);
};
export const getAnthropometricsHistory = (medicalRecordNumber) => {
    return axios.get(`api/v1/medicalRecords/${medicalRecordNumber}/anthropometrics/history`);
};
export const postAnthropometric = (entryId, data) => {
    return axios.post(`api/v1/medicalRecords/entry/${entryId}/anthropometrics`, data);
};
export const putAnthropometric = (entryId, id, data) => {
    return axios.put(`api/v1/medicalRecords/entry/${entryId}/anthropometrics/${id}`, data);
};
export const removeAnthropometricAPI = (entryId, id, reason, comments) => {
    return axios.delete(`api/v1/medicalRecords/entry/${entryId}/anthropometrics/${id}/audit/${reason}/${comments}`);
};
//#endregion


//#region familyhistories
export const getFamilyHistories = (filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(`api/v1/medicalRecords/familyhistories?${queryString}`);
};
export const getFamilyHistory = (entryId, id) => {
    return axios.get(`api/v1/medicalRecords/entry/${entryId}/familyhistories/${id}`);
};
export const postFamilyHistory = (entryId, data) => {
    return axios.post(`api/v1/medicalRecords/entry/${entryId}/familyhistories`, data);
};
export const putFamilyHistory = (entryId, id, data) => {
    return axios.put(`api/v1/medicalRecords/entry/${entryId}/familyhistories/${id}`, data);
};
export const removeFamilyHistoryAPI = (entryId, id, reason, comments) => {
    return axios.delete(`api/v1/medicalRecords/entry/${entryId}/familyhistories/${id}/audit/${reason}/${comments}`);
};
//#endregion


//#region personalHistory
export const getPersonalHistories = (filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(`api/v1/medicalRecords/personalHistory?${queryString}`);
};
export const getPersonalHistory = (entryId, id) => {
    return axios.get(`api/v1/medicalRecords/entry/${entryId}/personalHistory/${id}`);
};
export const postPersonalHistory = (entryId, data) => {
    return axios.post(`api/v1/medicalRecords/entry/${entryId}/personalHistory`, data);
};
export const putPersonalHistory = (entryId, id, data) => {
    return axios.put(`api/v1/medicalRecords/entry/${entryId}/personalHistory/${id}`, data);
};
export const removePersonalHistoryAPI = (entryId, id, reason, comments) => {
    return axios.delete(`api/v1/medicalRecords/entry/${entryId}/personalHistory/${id}/audit/${reason}/${comments}`);
};
//#endregion


//#region personalhabits
export const getPersonalHabits = (medicalRecordNumber, entryId) => {
    const queryString = getQueryString({ medicalRecordNumber, entryId }, window.location.search);
    return axios.get(`api/v1/medicalRecords/personalhabits?${queryString}`);
};
export const getPersonalHabit = (entryId, id) => {
    return axios.get(`api/v1/medicalRecords/entry/${entryId}/personalHabits/${id}`);
};
export const postPersonalHabit = (entryId, data) => {
    return axios.post(`api/v1/medicalRecords/entry/${entryId}/personalHabits`, data);
};
export const putPersonalHabit = (entryId, id, data) => {
    return axios.put(`api/v1/medicalRecords/entry/${entryId}/personalHabits/${id}`, data);
};
export const removePersonalHabitAPI = (entryId, id, reason, comments) => {
    return axios.delete(`api/v1/medicalRecords/entry/${entryId}/personalHabits/${id}/audit/${reason}/${comments}`);
};
//#endregion


//#region laboratories
export const getLaboratories = (filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(`api/v1/medicalRecords/laboratories?${queryString}`);
};
export const postLaboratories = (entryId, data) => {
    return axios.post(`api/v1/medicalRecords/entry/${entryId}/laboratories`, data);
};
export const putLaboratoryResult = (entryId, id, data) => {
    return axios.put(`api/v1/medicalRecords/entry/${entryId}/laboratories/${id}`, data);
};
export const getLaboratoryResult = (entryId, id) => {
    return axios.get(`api/v1/medicalRecords/entry/${entryId}/laboratories/${id}`);
};
export const getLaboratoryResults = (laboratoryId) => {
    return axios.get(`api/v1/medicalRecords/laboratories/${laboratoryId}/results`);
};
export const getLaboratoryResultHistory = (laboratoryId) => {
    return axios.get(`api/v1/medicalRecords/laboratories/${laboratoryId}/history`);
};
export const removeLaboratoryAPI = (entryId, id, reason, comments) => {
    return axios.delete(`api/v1/medicalRecords/entry/${entryId}/laboratories/${id}/audit/${reason}/${comments}`);
};
export const removeLaboratoryResultAPI = (entryId, id, reason, comments) => {
    return axios.delete(`api/v1/medicalRecords/entry/${entryId}/laboratoryResult/${id}/audit/${reason}/${comments}`);
};
//#endregion


//#region vitals
export const getLastVitals = (medicalRecordNumber) => {
    return axios.get(`api/v1/medicalRecords/vitals/last/${medicalRecordNumber}`);
};
export const getVitals = (filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(`api/v1/medicalRecords/vitals?${queryString}`);
};
export const getVital = (entryId, id) => {
    return axios.get(`api/v1/medicalRecords/entry/${entryId}/vitals/${id}`);
};
export const getVitalsHistory = (medicalRecordNumber) => {
    return axios.get(`api/v1/medicalRecords/${medicalRecordNumber}/vitals/history`);
};
export const postVital = (entryId, data) => {
    return axios.post(`api/v1/medicalRecords/entry/${entryId}/vitals`, data);
};
export const putVital = (entryId, id, data) => {
    return axios.put(`api/v1/medicalRecords/entry/${entryId}/vitals/${id}`, data);
};
export const removeVitalAPI = (entryId, id, reason, comments) => {
    return axios.delete(`api/v1/medicalRecords/entry/${entryId}/vitals/${id}/audit/${reason}/${comments}`);
};
//#endregion

export const isPatientInProtocol = (medicalRecordNumber) => {
    return axios.get(`api/v1/medicalRecords/${medicalRecordNumber}/protocol`);
};
export const isPatientInProjects = (medicalRecordNumber) => {
    return axios.get(`api/v1/medicalRecords/${medicalRecordNumber}/projects`);
};