import React from 'react'
import { Patient } from '../../components/Database/Patient';
import { usePatient } from './Hooks';

export const PatientContainer = (props) => {
    const { match: { params } } = props;
    const medicalRecordNumber = params.medicalRecordNumber;

    const [patient, isLoading] = usePatient(medicalRecordNumber);

    if (isLoading)
        return null;

    return (
        <Patient data={patient} />
    );
}