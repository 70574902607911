import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react';

export function Popover({ title, children, popover, trigger, cssClass }) {

    const Children = React.forwardRef((props, ref) => <div {...props} ref={ref} style={{ display: 'inline-block' }} />)
    const Content = (
        <div>
            {
                title &&
                <>
                    <h6 style={{ minWidth: '500px' }}>{title}</h6>
                    <hr />
                </>
            }
            <p>{popover}</p>
        </div>
    );

    return <LazyTippy
        theme="light"
        content={Content}
        trigger={trigger}
        delay={[300, 150]}
        animateFill={true}
        interactive={true}
        className={cssClass}
        popperOptions={{ strategy: 'fixed' }}
    >
        <Children>
            {children}
        </Children>
    </LazyTippy>;
}

Popover.defaultProps = {
    trigger: "click"
}

Popover.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    popover: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
        PropTypes.any
    ]).isRequired,
    cssClass: PropTypes.string
};

const LazyTippy = forwardRef((props, ref) => {
    const [mounted, setMounted] = useState(false);

    const lazyPlugin = {
        fn: () => ({
            onMount: () => setMounted(true),
            onHidden: () => setMounted(false),
        }),
    };

    const computedProps = { ...props };

    computedProps.plugins = [lazyPlugin, ...(props.plugins || [])];

    if (props.render) {
        computedProps.render = (...args) => (mounted ? props.render(...args) : '');
    } else {
        computedProps.content = mounted ? props.content : '';
    }

    return <Tippy {...computedProps} ref={ref} />;
});