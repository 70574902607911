import { useState } from "react"
import useLoading from "../../components/Common/useLoading"

import IotMessagesServices from "../../api/Freezer/IotMessagesServices"
import { useTranslation } from "react-i18next"
import { MapAsDataSet } from "./TelemetryComponents/Utils"

export default function useIotMessages() {
    const [iotMessages, setIotMessages] = useState({
        weather: [],
        door: []
    })
    const [isLoading, load, reload] = useLoading()

    const search = ({ from, to, freezerId } = {}) => load(async () => {
        const weatherResult = await IotMessagesServices.GetWeatherTelemetry({ from, to, freezerId })
        const doorResult = await IotMessagesServices.GetDoorTelemetry({ from, to, freezerId })
        setIotMessages({
            weather: weatherResult,
            door: doorResult
        })
    })

    return [iotMessages.weather, iotMessages.door, isLoading, search]
}

export function useIotTelemetry() {
    const { t } = useTranslation()
    const [telemetry, setTelemetry] = useState({
        temperature: { datasets: [] },
        humidity: { datasets: [] },
        door: { datasets: [] },
    })
    const [isLoading, load, reload] = useLoading()

    const search = ({ from, to, freezerId } = {}) => load(async () => {
        const weatherResult = await IotMessagesServices.GetWeatherTelemetry({ from, to, freezerId })
        const doorResult = await IotMessagesServices.GetDoorTelemetry({ from, to, freezerId })
        setTelemetry({
            temperature: {
                datasets: [MapAsDataSet(weatherResult.map(w => { return { y: w.Temperature, x: w.MeasureDate.getTime() } }), t('settings.freezer.temperature'))]
            },
            humidity: {
                datasets: [MapAsDataSet(weatherResult.map(w => { return { y: w.Humidity, x: w.MeasureDate.getTime() } }), t('settings.freezer.humidity'))]
            },
            door: {
                datasets: [{
                    ...MapAsDataSet(doorResult.map(d => { return { y: d.State ? t('settings.freezer.open') : t('settings.freezer.close'), x: d.MeasureDate.getTime() } }), t('settings.freezer.door'))
                    , stepped: true
                }]
            }
        })
    })

    return [telemetry, isLoading, search]
}