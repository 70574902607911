import PropTypes from 'prop-types';
import React, { Fragment, useContext, useState } from "react";
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";

import ScrollMenu from 'react-horizontal-scrolling-menu';
import ProtocolContext from "../../../containers/Protocols/ProtocolContext/ProtocolContext";
import { formStatus, patientInProtocolStatus, visitStage, visitStatus } from "../../../variables/Enums";
import { WithAuth } from "../../Authorization/WithAuthProtocol";
import Card from "../../Cards/Card";
import { Italic } from "../../CustomControls/CustomTextStyle";
import { Audit, Back, Cancel, Change, Contract, Drug, Expand, Export, Next, Publish, Remove, Save, Swap, Sync, Warning } from "../../Icons/Icons";
import { toLocaleDateTimeFormatter } from "../../Utils/Formatter";

export const FormMenu = (props) => {
    const [showAdditionals, setShowAdditionals] = useState(false);
    const { t } = useTranslation();
    const handleOthersClick = () => { setShowAdditionals(!showAdditionals); }

    const additionals = props.forms.filter(x => x.extraFormType === 0).map(function (form, index) {
        return (<button
            key={`button_additionals_${index}`}
            type="button"
            className={`btn ${form.id === props.selectedFormId ? "btn-primary" : "btn-default"} btn-full btn-fill`}
            onClick={() => props.onClick(form.id)}
            style={{ marginBottom: '5px', textAlign: 'left' }}>

            {form.status === 1 ? <Save style={{ marginRight: '5px' }} /> : ''}
            {props.formsWithErrors?.find(x => x.id === form.id) ? <Warning style={{ marginRight: '5px' }} /> : ''}
            {form.title}
        </button>);
    });

    const content = props.forms.filter(x => x.extraFormType !== 0).map(function (form, index) {
        return (<button
            key={`button_content_${index}`}
            type="button"
            className={`btn ${form.id === props.selectedFormId ? "btn-primary" : "btn-default"} btn-full btn-fill`}
            onClick={() => props.onClick(form.id)}
            style={{ marginBottom: '5px', textAlign: 'left' }}>

            {form.status === 1 ? <Save style={{ marginRight: '5px' }} /> : ''}
            {props.formsWithErrors?.find(x => x.id === form.id) ? <Warning style={{ marginRight: '5px' }} /> : ''}
            {form.title}
        </button>);
    });

    if (additionals.length > 0)
        content.push(
            <button
                key="button_additional"
                type="button"
                className="btn btn-default btn-fill btn-full"
                onClick={(handleOthersClick)}
                style={{ marginBottom: '5px', textAlign: 'left' }}>
                {showAdditionals ? <Contract /> : <Expand />} {t("protocols.extraForms.additionals")}
            </button>
        );

    return (
        <Card
            content={
                <>
                    {!props.isDisabled && content}
                    {showAdditionals && additionals}
                </>
            }
        />
    );
}

FormMenu.propTypes = {
    onClick: PropTypes.func.isRequired,
    forms: PropTypes.array.isRequired,
    formsWithErrors: PropTypes.array.isRequired,
    selectedFormId: PropTypes.number,
};

export const FormActions = (props) => {
    const {
        onBack,
        onSave,
        onSaveUpload,
        onSkip,
        onScreenFailure,
        onLostToFollowUp,
        onAuditTrail,
        onWithoutDrugs,
        onReturnDrugs,
        onDiscontinuation,
        onReSync,
        onChangeType,
        onChangeVisitTrackingType,
        onRollbackVisitStatus,
        onRemove,
        onExport,
        onEndPatientInProtocol,
        visit,
        hasVisitTracking,
        forms,
        isSubmitting
    } = props;

    const { t } = useTranslation();
    const context = useContext(ProtocolContext);
    const protocolId = context.protocolId;

    if (!visit)
        return null;

    return (
        <Card
            title={t('medicalRecords.actions')}
            className="card-actions"
            content={
                <Fragment>
                    <WithAuth protocolId={protocolId} requiredPermission={["VisitTracking.UploadFile"]}>
                        {   // Subir Adjunto. 
                            hasVisitTracking && //(visit.status !== visitStatus.Failed && visit.status !== visitStatus.Skipped) &&
                            <button
                                type="button"
                                className="btn btn-primary btn-full "
                                onClick={onSaveUpload}
                                disabled={isSubmitting || context.isDisabled}
                            >
                                <Save style={{ marginRight: '5px' }} />{t('protocols.visitTracking.saveUploadChanges')}
                            </button>
                        }
                    </WithAuth>
                    <WithAuth protocolId={protocolId} requiredPermission={["VisitTracking.Add"]}>
                        {   // Guardar Visita. 
                            hasVisitTracking && //(visit.status !== visitStatus.Failed && visit.status !== visitStatus.Skipped) &&
                            <button
                                type="button"
                                className="btn btn-primary btn-full "
                                onClick={() => onSave()}
                                disabled={isSubmitting || context.isDisabled}
                            >
                                <Save style={{ marginRight: '5px' }} />{t('protocols.visitTracking.saveChanges')}
                            </button>
                        }
                    </WithAuth>
                    {
                        hasVisitTracking &&
                        <WithAuth protocolId={protocolId} requiredPermission={["VisitTracking.Publish"]}>
                            { // Publicar visita. 
                                (visit.status !== visitStatus.Failed && visit.status !== visitStatus.Skipped && visit.status !== visitStatus.Completed) &&
                                <button
                                    type="submit"
                                    name="publish"
                                    className="btn btn-success btn-full"
                                    disabled={isSubmitting || context.isDisabled || visit.status == visitStatus.Skipped || visit.status == visitStatus.Failed ? true : false}
                                >
                                    <Publish style={{ marginRight: '5px' }} />
                                    {visit.status === visitStatus.Changed && t('protocols.visitTracking.publishChanges')}
                                    {visit.status !== visitStatus.Changed && t('protocols.visitTracking.publish')}
                                </button>
                            }
                            { // Eliminar visita. 
                                visit.status === visitStatus.Started && forms.filter(x => x.status > formStatus.Initial).length === 0 &&
                                <button
                                    type="button"
                                    name="remove"
                                    className="btn btn-danger btn-full"
                                    onClick={() => onRemove()}
                                    disabled={isSubmitting || context.isDisabled ? true : false}
                                >
                                    <Remove style={{ marginRight: '5px' }} />{t('protocols.visitTracking.remove')}
                                </button>
                            }
                            { // Sincronizar. 
                                visit.status === visitStatus.Completed &&
                                <button
                                    type="button"
                                    name="publish"
                                    className="btn btn-warning btn-full"
                                    disabled={isSubmitting || context.isDisabled || visit.status !== visitStatus.Completed}
                                    onClick={onReSync}
                                >
                                    <Sync style={{ marginRight: '5px' }} />
                                    {t('protocols.visitTracking.resync')}
                                </button>
                            }
                        </WithAuth>
                    }
                    {
                        hasVisitTracking &&
                        <WithAuth protocolId={protocolId} requiredPermission={["VisitTracking.Add", "VisitTracking.Publish"]}>
                            <>
                                {
                                    // Terminar participación del paciente en el protocolo
                                    visit.stage === visitStage.FollowUp &&
                                    <button
                                        type="button"
                                        className="btn btn-warning btn-full "
                                        onClick={onEndPatientInProtocol}
                                        disabled={isSubmitting || context.isDisabled || visit.status >= visitStatus.Completed ? true : false}
                                    >
                                        <Warning style={{ marginRight: '5px' }} />{t('protocols.visitTracking.endPatientInProtocol')}
                                    </button>

                                }
                                { // Cambiar tipo
                                    visit.status === undefined || visit.status === 0 &&
                                    <button
                                        type="button"
                                        className="btn btn-warning btn-full "
                                        onClick={onChangeVisitTrackingType}
                                        disabled={isSubmitting || context.isDisabled || visit.status >= visitStatus.Completed ? true : false}
                                    >
                                        <Change style={{ marginRight: '5px' }} />{t('protocols.visitTracking.changeVisitTrackingType')}
                                    </button>
                                }
                                { // Saltear
                                    visit.stage !== visitStage.Random && (visit.status === undefined || visit.status === visitStatus.Started) &&
                                    <button
                                        type="button"
                                        className="btn btn-warning btn-full "
                                        onClick={onSkip}
                                        disabled={isSubmitting || context.isDisabled || visit.status >= visitStatus.Completed ? true : false}
                                    >
                                        <Cancel style={{ marginRight: '5px' }} />{t('protocols.visitTracking.skipVisitTracking')}
                                    </button>
                                }
                                { // Falla Screening. 
                                    (visit.stage === visitStage.Screening || visit.stage === visitStage.Random) &&
                                    (visit.status === undefined || visit.status === 0 || visit.status === visitStatus.AutoCompleted) &&
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-full "
                                        onClick={onScreenFailure}
                                        disabled={isSubmitting || context.isDisabled || (visit.status >= 1 && visit.status < visitStatus.AutoCompleted) ? true : false}
                                    >
                                        <Warning style={{ marginRight: '5px' }} />{t('protocols.visitTracking.screenFailure')}
                                    </button>
                                }
                                { // Falla Seguimiento
                                    (visit.stage === visitStage.Treatment || visit.stage === visitStage.FollowUp) && (visit.status === undefined || visit.status === 0) &&
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-full "
                                        onClick={onLostToFollowUp}
                                        disabled={isSubmitting || context.isDisabled || visit.status >= 1 ? true : false}
                                    >
                                        <Warning style={{ marginRight: '5px' }} />{t('protocols.visitTracking.lostToFollowUp')}
                                    </button>
                                }
                                { // Discontinuacion
                                    (visit.stage > visitStage.Random) &&
                                    (visit.status === undefined || visit.status === 0) &&
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-full"
                                        onClick={onDiscontinuation}
                                        disabled={isSubmitting || visit.status >= 1 ? true : false}
                                    >
                                        <Warning style={{ marginRight: '5px' }} />{t('protocols.visitTracking.discontinuation')}
                                    </button>
                                }
                                { // Sin Medicacion
                                    (visit.stage > visitStage.Random) && (visit.status === undefined || visit.status === 0) && (props.patientInProtocolStatus !== patientInProtocolStatus.WithoutDrugs) &&
                                    <button
                                        type="button"
                                        className="btn btn-info btn-full "
                                        onClick={onWithoutDrugs}
                                        disabled={isSubmitting || context.isDisabled || visit.status >= 1 ? true : false}
                                    >
                                        <Drug style={{ marginRight: '5px' }} />{t('protocols.visitTracking.withoutDrugs')}
                                    </button>
                                }
                                { // Rollback Visit Status
                                    (visit.status === visitStatus.Failed || (visit.status === visitStatus.Completed && props.patientInProtocolStatus === patientInProtocolStatus.EndParticipationInProtocol)) &&
                                    (
                                        props.patientInProtocolStatus === patientInProtocolStatus.Failed || 
                                        props.patientInProtocolStatus === patientInProtocolStatus.LostToFollowUp || 
                                        props.patientInProtocolStatus === patientInProtocolStatus.Discontinuation ||
                                        props.patientInProtocolStatus === patientInProtocolStatus.EndParticipationInProtocol
                                    ) &&
                                    <button
                                        type="button"
                                        className="btn btn-info btn-full "
                                        onClick={onRollbackVisitStatus}
                                        disabled={isSubmitting || context.isDisabled}
                                    >
                                        <Warning style={{ marginRight: '5px' }} />{t('protocols.visitTracking.rollbackStatus')}
                                    </button>
                                }
                                { // Retornar Medicacion
                                    (visit.stage > visitStage.Random) && (visit.status === undefined || visit.status === 0) && (props.patientInProtocolStatus === patientInProtocolStatus.WithoutDrugs) &&
                                    <button
                                        type="button"
                                        className="btn btn-info btn-full "
                                        onClick={onReturnDrugs}
                                        disabled={isSubmitting || context.isDisabled || visit.status >= 1 ? true : false}
                                    >
                                        <Drug style={{ marginRight: '5px' }} />{t('protocols.visitTracking.returnDrugs')}
                                    </button>
                                }
                                <button
                                    type="button"
                                    className={`btn btn-primary btn-fill btn-full`}
                                    onClick={onChangeType}
                                    disabled={isSubmitting || context.isDisabled ? true : false}>
                                    <Swap /> {t('buttons.changeType')}
                                </button>
                            </>
                        </WithAuth>
                    }

                    <WithAuth protocolId={protocolId} requiredPermission={["VisitTracking.ExportPatientVisit"]}>
                        {
                            hasVisitTracking &&
                            <button
                                type="button"
                                className="btn btn-success btn-full "
                                onClick={onExport}
                                disabled={isSubmitting}
                            >
                                <Export style={{ marginRight: '5px' }} />{t('protocols.visitTracking.export')}
                            </button>
                        }
                    </WithAuth>
                    <button className={`btn btn-primary btn-fill btn-full`} onClick={onBack} disabled={isSubmitting}>
                        <Back /> {t('buttons.back')}
                    </button>
                    <button className={`btn btn-primary btn-fill btn-full`} onClick={props.onBackToMR} disabled={isSubmitting}>
                        <Back /> {t('protocols.visitTracking.viewMR')}
                    </button>
                    {
                        hasVisitTracking && <button
                            type="button"
                            disabled={isSubmitting}
                            className="btn btn-audit btn-fill btn-full"
                            onClick={() => onAuditTrail()}
                        >
                            <Audit style={{ marginRight: '5px' }} />{t('protocols.visitTracking.auditTrail')}
                        </button>
                    }
                </Fragment>
            }
        />
    );
}

FormActions.propTypes = {
    onSave: PropTypes.func.isRequired,
    onSkip: PropTypes.func.isRequired,
    onScreenFailure: PropTypes.func.isRequired,
    onLostToFollowUp: PropTypes.func.isRequired,
    onWithoutDrugs: PropTypes.func.isRequired,
    onAuditTrail: PropTypes.func.isRequired,
    visit: PropTypes.object.isRequired,
    hasVisitTracking: PropTypes.bool.isRequired,
    forms: PropTypes.array.isRequired
};

export const FormHeader = (props) => {
    const {
        protocolId,
        patientId,
        visits
    } = props;


    if (!visits)
        return <Card
            className="card-visitTracking-header"
            noHeader
            content={<></>}
        />;

    const Menu = () =>
        visits.map((visit, index) => {
            return (
                <div key={`header-visits-${index}`} className={"header-visit-container " + getNavLinkClass(`/admin/protocols/${protocolId}/visitTracking/${visit.crossVersionId}/patient/${patientId}`)}>
                    <NavLink to={`/admin/protocols/${protocolId}/visitTracking/${visit.crossVersionId}/patient/${patientId}`} className="nav-link" activeClassName="active">
                        {visit.title}
                    </NavLink>
                </div>
            );
        });

    const ArrowLeft = <Back />;
    const ArrowRight = <Next />;

    const getNavLinkClass = (path) => {
        return window.location.pathname === path ? 'active' : '';
    }

    return (
        <Card
            className="card-visitTracking-header"
            noHeader
            content={
                <ScrollMenu
                    data={Menu()}
                    arrowLeft={ArrowLeft}
                    arrowRight={ArrowRight}
                    selected={null}
                    onSelect={null}
                    hideSingleArrow={true}
                />
            }
        />
    );
}

FormHeader.propTypes = {
    protocolId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    visits: PropTypes.array,
};

export const VisitStatus = ({ status, date }) => {
    const { t } = useTranslation();
    switch (status) {
        case visitStatus.Started:
            return (<Alert bsStyle="info">[<Italic>{toLocaleDateTimeFormatter(date)}</Italic>] {t("protocols.visit.status.started")}</Alert>);
        case visitStatus.Completed:
            return (<Alert bsStyle="success">{t("protocols.visit.status.completed")}</Alert>);
        case visitStatus.Skipped:
            return (<Alert bsStyle="warning">{t("protocols.visit.status.skipped")}</Alert>);
        case visitStatus.Failed:
            return (<Alert bsStyle="danger">{t("protocols.visit.status.failed")}</Alert>);
        case visitStatus.Changed:
            return (<Alert bsStyle="warning">{t("protocols.visit.status.changed")}</Alert>);
        case visitStatus.AutoCompleted:
            return (<Alert className="montoto">{t("protocols.visit.status.autoCompleted")}</Alert>);
        default:
            return null;
    }
}

VisitStatus.propTypes = {
    status: PropTypes.string,
};