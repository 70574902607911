import React, { useState, useRef } from 'react'
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop, } from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'
import RangeSlider from 'react-bootstrap-range-slider';

import 'react-image-crop/dist/ReactCrop.css'

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

interface Props {
    name: string,
    toggleAspect: boolean,
    placeholder: string,
    onChange: Function,
    maxWidth?: number,
    maxHeight?: number,
    minWidth?: number,
    minHeight?: number,
}

export default function CustomImageUpload(props: Props) {
    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const blobUrlRef = useRef('')
    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState<number | undefined>(16 / 9)

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
        else {
            setCrop(undefined);
            setImgSrc('');

            if (props.onChange)
                props.onChange(null);
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    function handleOnCompleteCrop() {
        if (!previewCanvasRef.current) {
            throw new Error('Crop canvas does not exist')
        }

        previewCanvasRef.current.toBlob((blob) => {
            if (!blob) {
                throw new Error('Failed to create blob')
            }
            if (blobUrlRef.current) {
                URL.revokeObjectURL(blobUrlRef.current)
            }

            if (props.onChange)
                props.onChange(props.name, {
                    file: new File([blob], 'image.jpeg', {
                        type: blob.type,
                    }),
                    fileName: "croppedImage",
                    size: blob?.size
                });
        }, "image/jpeg")
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    function handleToggleAspectClick() {
        if (aspect) {
            setAspect(undefined)
        } else if (imgRef.current) {
            const { width, height } = imgRef.current
            setAspect(16 / 9)
            setCrop(centerAspectCrop(width, height, 16 / 9))
        }
    }

    return (
        <div className="App">
            <div className='row'>
                <div className='col-md-12'>
                    <div className="Crop-Controls">
                        <input type="file" accept="image/*" onChange={onSelectFile} />
                        {/*<div>
                            <label htmlFor="rotate-input">Rotate: </label>
                            <input
                                id="rotate-input"
                                type="number"
                                value={rotate}
                                disabled={!imgSrc}
                                onChange={(e) =>
                                    setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
                                }
                            />
                        </div>
                        <div>
                            <button type="button" onClick={handleToggleAspectClick}>
                                Toggle aspect {aspect ? 'off' : 'on'}
                            </button>
                        </div>*/}
                    </div>
                </div>
                {
                    imgSrc && <div className='col-md-3'>
                        <label htmlFor="scale-input">Zoom: </label>
                        <RangeSlider
                            tooltip='on'
                            step={0.1}
                            value={scale}
                            disabled={!imgSrc}
                            min={0}
                            max={2}
                            onChange={e => setScale(Number(e.target.value))}
                        />
                    </div>
                }
                <div className='col-md-12'>
                    {!!imgSrc && (
                        <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={aspect}
                            maxWidth={props.maxWidth}
                            maxHeight={props.maxHeight}
                            minWidth={props.minWidth}
                            minHeight={props.minHeight}
                        >
                            <img
                                ref={imgRef}
                                alt="Cortar imagen"
                                src={imgSrc}
                                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    )}
                    {!!completedCrop && (
                        <>
                            <div>
                                <canvas
                                    ref={previewCanvasRef}
                                    style={{
                                        border: '1px solid black',
                                        objectFit: 'contain',
                                        width: completedCrop.width,
                                        height: completedCrop.height,
                                    }}
                                />
                            </div>
                            <div>
                                <button className="btn btn-success" type="button" disabled={imgSrc === ''} onClick={handleOnCompleteCrop}>
                                    Cortar
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

/*
export default function CustomImageUpload(props: Props) {

    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const [crop, setCrop] = useState<Crop | undefined>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [scale, setScale] = useState(1)
    const [aspect, setAspect] = useState<number | undefined>(undefined)
    const [image, setImage] = useState<HTMLImageElement>();
    const [originY, setOriginY] = useState(0)
    const [originX, setOriginX] = useState(0)
    //
    //const prevSquareCordsX = React.useRef(0)
    //const prevSquareCordsY = React.useRef(0)

    React.useEffect(() => {
        if (props.minHeight && props.minWidth)
            setCrop({
                height: props.minHeight,
                width: props.minWidth,
                unit: 'px',
                x: 0,
                y: 0,
            })
    }, [imgSrc])

    function onSelectFile(controlName: string, uploadedFile: { file: File }) {
        if (uploadedFile) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(uploadedFile.file)
        }
        else {
            setCrop(undefined);
            setImgSrc('');

            if (props.onChange)
                props.onChange(controlName, null);
        }
        //setFile(uploadedFile);
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {

        setImage(e.currentTarget)
        if (aspect) {
            const { width, height } = e.currentTarget
            //setCrop(centerAspectCrop(width, height, aspect, props.maxWidth, props.maxHeight))
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    originX,
                    originY
                )
            }
        },
        100,
        [completedCrop, scale, originX, originY],
    )

    function handleToggleAspectClick() {
        if (aspect) {
            setAspect(undefined)
        } else if (imgRef.current) {
            const { width, height } = imgRef.current
            setAspect(16 / 9)
            setCrop(centerAspectCrop(width, height, 16 / 9))
            //setCrop(centerAspectCrop(width, height, 16 / 9, props.maxWidth, props.maxHeight))
        }
    }

    function handleZoom(e: React.ChangeEvent<HTMLInputElement>) {
        setScale(Number(e.target.value));

        if (crop) {
            setOriginX(crop.x)
            setOriginY(crop.y)
        }
    }

    const makeClientCrop = async (crop: Crop) => {
        if (image && crop.width && crop.height) {
            const croppedImg = await getCroppedImg();
            if (croppedImg) {
                if (props.onChange)
                    props.onChange(props.name, {
                        file: new File([croppedImg], 'image.jpeg', {
                            type: croppedImg.type,
                        }),
                        fileName: "croppedImage",
                        size: croppedImg?.size
                    });
            }
        }
    };

    function getCroppedImg(): Promise<Blob> | null {
        try {
            return new Promise((resolve) => {
                previewCanvasRef.current?.toBlob((blob) => {
                    if (blob)
                        resolve(blob);
                }, "image/jpeg");
            });
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    function handleOnCompleteCrop() {
        image && crop ? makeClientCrop(crop) : console.log("wait");
    }

    return (
        <div className="App">
            <div className='row'>
                <div className='col-md-12'>
                    <CustomFileUpload
                        accept="image/*"
                        name={props.name}
                        placeholder={props.placeholder}
                        onChange={onSelectFile}
                    />
                </div>
                <div className='col-md-12'>
                    {!!imgSrc && (
                        <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => { setCrop(percentCrop) }}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={aspect}
                            locked={false}
                            maxWidth={props.maxWidth}
                            maxHeight={props.maxHeight}
                            minWidth={props.minWidth}
                            minHeight={props.minHeight}
                        >
                            <img
                                ref={imgRef}
                                alt="Crop me"
                                src={imgSrc}
                                //style={{ transform: `scale(${scale})` }} , transformOrigin: `${originX}% ${originY}%`
                                //style={{ transform: `scale(${scale}) translateX(${originX}px) translateY(${originY}px` }}
                                style={{ transform: `scale(${scale})`, transformOrigin: `${originX}% ${originY}%` }}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    )}
                </div>
            </div>
            {!!completedCrop && imgSrc !== '' && (
                <>
                    <div className='row'>
                        <div className='col-md-2'>
                            <label htmlFor="scale-input">Zoom:  </label>
                            <input
                                id="scale-input"
                                type="number"
                                step="0.1"
                                value={scale}
                                disabled={!imgSrc}
                                //onChange={(e) => setScale(Number(e.target.value))}
                                style={{ marginLeft: '10px', width: '50px' }}
                                onChange={handleZoom}
                            />
                        </div>
                        <div className='col-md-2'>
                            <button className="btn btn-success" type="button" disabled={imgSrc === ''} onClick={handleOnCompleteCrop}>
                                Cortar
                            </button>
                        </div>
                        {
                            props.toggleAspect &&
                            <div>
                                <button onClick={handleToggleAspectClick}>
                                    Toggle aspect {aspect ? 'off' : 'on'}
                                </button>
                            </div>
                        }
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <canvas
                                ref={previewCanvasRef}
                                style={{
                                    border: '1px solid black',
                                    objectFit: 'contain',
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                }}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
*/