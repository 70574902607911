import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, ControlLabel, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAuthorization } from '../Authorization/useAuthorization';
import { TextBoxUsersMentions } from '../CustomControls/TextBoxUsersMentions';
import { Add, Edit, Save } from '../Icons/Icons';
import { getFullNameFromUserMention } from '../Utils/Commons';
import { dateTimeFormatter } from '../Utils/Formatter';

export const DocumentNotes = (props) => {
    const [, user] = useAuthorization();

    if (!props.notes)
        return null;

    const handleEdit = (noteId) => {
        if (props.onEdit)
            props.onEdit(noteId);
    }

    return (
        <Grid fluid>
            {
                props.notes.sort(function (a, b) {
                    return new Date(b.created) - new Date(a.created);
                }).map((note, index) => (
                    <Row key={`note-idx-${index}`} style={{ padding: '10px 0', borderBottom: '1px solid #eee' }}>
                        <Col sm={3}>
                            <div>{dateTimeFormatter(note.created)}</div>
                            <div>{note.createdBy}</div>
                        </Col>
                        <Col sm={7}>
                            <div>{getFullNameFromUserMention(note.note)}</div>
                        </Col>
                        {
                            user?.sub === note.createdById &&
                            <Col sm={2}>
                                <button className="btn-table-actions" type="button" onClick={() => handleEdit(note.id)}><Edit /></button>
                            </Col>
                        }
                    </Row>
                ))
            }
        </Grid>
    );
}

DocumentNotes.propTypes = {
    notes: PropTypes.array.isRequired,
    documentId: PropTypes.number.isRequired,
    onEdit: PropTypes.func.isRequired
};

export const DocumentNoteForm = ({ onCreate, onEdit, note, protocolId }) => {
    const { t } = useTranslation();
    const [text, setText] = useState("");

    useEffect(() => {
        if (note)
            setText(note.note);
    }, [note]);

    const handleChange = (_name, value) => {
        setText(value);
    }

    const handleCreate = () => {
        if (onCreate)
            onCreate(text);
        setText("");
    }

    const handleEdit = () => {
        if (onEdit) {
            onEdit(note.id, text);
        }
        setText("");
    }

    return (
        <Grid fluid>
            <Row>
                <Col sm={12}>
                    <ControlLabel>{t("protocols.visitTracking.documentNotes")}</ControlLabel>
                </Col>
                <Col sm={10}>
                    <TextBoxUsersMentions name="notes" value={text} onChange={handleChange} isProtocol={true} protocolId={protocolId} />
                </Col>
                <Col sm={2}>
                    {
                        note
                            ?
                            <button className="btn-table-actions" style={{ fontSize: '24px', fontWeight: '700', lineHeight: '1' }} type="button" onClick={handleEdit}><Save /></button>
                            :
                            <button className="btn-table-actions" style={{ fontSize: '24px', fontWeight: '700', lineHeight: '1' }} type="button" onClick={handleCreate}><Add /></button>
                    }
                </Col>
            </Row>
        </Grid>
    );
}

DocumentNoteForm.propTypes = {
    note: PropTypes.object,
    onCreate: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    protocolId: PropTypes.number.isRequired
};