import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useTranslation } from 'react-i18next';
import Card from '../Cards/Card';
import { Edit, Remove } from '../Icons/Icons';
import { ModalData } from '../Modals/ModalData';
import { dateTimeFormatter, dateTimeUTCFormatter } from '../Utils/Formatter';
import { Tooltip } from '../Utils/Tooltips';
import { AdminNotification } from './AdminNotification';
import { Button } from '../CustomControls/Button';

export const AdminNotifications = (props) => {
    const { data, query, totalSize, isLoading, handleTableChange, handleEdit, handleRemove, handleCreate, handleSetReadOnly } = props;
    const [modal, setModal] = useState(null);
    const { t } = useTranslation();

    const columns = [
        { dataField: 'created', sort: true, text: t("settings.adminNotifications.created"), formatter: function (cell) { return dateTimeFormatter(cell) }, headerClasses: 'header-date' },
        { dataField: 'message', sort: true, text: t("settings.adminNotifications.message") },
        { dataField: 'from', sort: true, text: t("settings.adminNotifications.from"), formatter: function (cell) { return dateTimeFormatter(cell) }, headerClasses: 'header-date' },
        { dataField: 'to', sort: true, text: t("settings.adminNotifications.to"), formatter: function (cell) { return dateTimeFormatter(cell) }, headerClasses: 'header-date' },
        { dataField: 'active', sort: true, text: t("settings.adminNotifications.active") },
        {
            dataField: 'view',
            text: t("settings.adminNotifications.actions"),
            isDummyField: true,
            headerClasses: 'header-actions',
            classes: 'row-actions',
            formatter: function (_cell, row, rowIndex) {
                return (
                    <div key={rowIndex}>
                        <button className="btn-table-actions" onClick={() => onEdit(row)}>
                            <Tooltip tooltip={t("security.edit")} id={"tooltipedit-" + row.id}>
                                <span aria-hidden="true"><Edit /></span>
                            </Tooltip>
                        </button>
                        <button className="btn-table-actions" onClick={() => handleRemove(row.id)}>
                            <Tooltip tooltip={t("security.remove")} id={"tooltipremove-" + row.id}>
                                <span aria-hidden="true"><Remove /></span>
                            </Tooltip>
                        </button>
                    </div>
                )
            }
        }
    ];

    function handlePreCreate(notification) {
        setModal(null);
        handleCreate(notification);
    }
    function onCreate() {
        setModal(<ModalData
            title={t("settings.adminNotifications.createTitle")}
            isShowing={true}
            size="medium"
            className=""
            hide={() => setModal(null)}
        >
            <AdminNotification
                onSubmit={handlePreCreate}
                onCancel={() => setModal(null)}
            />
        </ModalData>);
    }
    function handlePreEdit(notification) {
        setModal(null);
        handleEdit(notification);
    }
    function onEdit(item) {
        setModal(<ModalData
            title={t("settings.adminNotifications.createTitle")}
            isShowing={true}
            size="medium"
            className=""
            hide={() => setModal(null)}
        >
            <AdminNotification
                values={item}
                onSubmit={handlePreEdit}
                onCancel={() => setModal(null)}
            />
        </ModalData>);
    }

    return (
        <>
            {modal}
            <Card
                header={<div className="row">
                    <div className="col-md-2">
                        <Button bsStyle="primary" onClick={onCreate}>
                            Agregar
                        </Button>
                    </div>
                    <div className="col-md-6"></div>
                    <div className="col-md-2">
                        <Button bsStyle="danger" onClick={() => handleSetReadOnly(true)}>
                            Modo Solo Lectura
                        </Button>
                    </div>
                    <div className="col-md-2">
                        <Button bsStyle="danger" onClick={() => handleSetReadOnly(false)}>
                            Modo Normal
                        </Button>
                    </div>
                </div>}
                content={
                    <BootstrapTable
                        classes="table-spaced table-spaced-small"
                        wrapperClasses="table-responsive"
                        bordered={false}
                        hover
                        remote
                        keyField='id'
                        data={data}
                        columns={columns}
                        noDataIndication={() => <>{t("commons.noData")}</>}
                        loading={isLoading}
                        overlay={overlayFactory({ spinner: true })}
                        pagination={paginationFactory({
                            page: query.pagination.page,
                            sizePerPage: query.pagination.sizePerPage,
                            totalSize: totalSize,
                            hideSizePerPage: true,
                        })}
                        onTableChange={handleTableChange}
                    />
                }
            />
        </>
    );
}  