import PropTypes from "prop-types";
import React from "react";
import { Button, Modal as BootstrapModal, Table } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import { Cancel, Edit, View } from "../Icons/Icons";
import { Tooltip } from "../Utils/Tooltips";

export const ModalDuplicatedPatient = (props) => {
    const { patients, onHide } = props;
    const { t } = useTranslation();

    return ReactDOM.createPortal(
        <React.Fragment>
            <BootstrapModal show={true} onHide={onHide} bsSize="large" backdrop="static" bsStyle="warning">
                <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title>{t("commons.attention")}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    {t("databaseNotifications.patient_duplicated_description")}:
                    {
                        <Table striped bordered responsive style={{ marginTop: '40px' }}>
                            <thead>
                                <tr>
                                    <th>{t("person.medicalRecord")}</th>
                                    <th>{t("person.identificationNumber")}</th>
                                    <th>{t("person.familyName")}</th>
                                    <th>{t("person.given")}</th>
                                    <th>{t("commons.actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    patients.map((patient, idx) => {
                                        return (
                                            <tr key={`patient-${idx}`}>
                                                <td>{patient.medicalRecordNumber}</td>
                                                <td>{patient.identificationType} {patient.identificationNumber}</td>
                                                <td>{patient.familyName}</td>
                                                <td>{patient.given}</td>
                                                <td>
                                                    <Tooltip tooltip={t("database.view")} id={"tooltipview-" + idx}>
                                                        <NavLink to={`/admin/database/${patient.medicalRecordNumber}`} className="nav-link" activeClassName="active">
                                                            <View style={{ height: '25px', width: '25px', marginRight: '5px', verticalAlign: 'middle' }} />
                                                        </NavLink>
                                                    </Tooltip>
                                                    <Tooltip tooltip={t("database.edit")} id={"tooltipedit-" + idx}>
                                                        <NavLink to={`/admin/database/${patient.medicalRecordNumber}/edit`} className="nav-link" activeClassName="active">
                                                            <Edit style={{ height: '25px', width: '25px', marginRight: '5px', verticalAlign: 'middle' }} />
                                                        </NavLink>
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </Table>
                    }
                </BootstrapModal.Body>
                <BootstrapModal.Footer>
                    <Button bsStyle="default" onClick={onHide}><Cancel /> {t('buttons.cancel')}</Button>
                </BootstrapModal.Footer>
            </BootstrapModal>
        </React.Fragment>, document.body
    )
};

ModalDuplicatedPatient.propTypes = {
    onHide: PropTypes.func.isRequired,
    patients: PropTypes.array.isRequired
};