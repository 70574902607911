import React from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export const Spinner = () => {
  return (<div className="loader center" style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
    <Loader type="TailSpin" color="#247dbd" height={80} width={80} />
  </div>);
}

export const ModalSpinner = ({ isShowing, hide }) => {

  return isShowing && ReactDOM.createPortal(
    <React.Fragment>
      <BootstrapModal show={true} onHide={hide}>
        <BootstrapModal.Header closeButton>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <Spinner />
        </BootstrapModal.Body>
      </BootstrapModal>
    </React.Fragment>, document.body
  );
}