import React from 'react'

import { useUser } from './Hooks';
import { UserProfile } from '../../components/Users/UserProfile';

export const UserContainer = (props) => {
    const { match: { params } } = props;
    const userId = params.userId;

    const [isLoading, user] = useUser(userId);

    if (isLoading)
        return null;

    return (
        <UserProfile user={user} />
    );
}