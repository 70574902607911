import React from 'react';
import { Col, ControlLabel, FormControl, FormGroup, Grid, Row } from 'react-bootstrap';
import { CustomCheckbox } from "../../CustomControls/CustomCheckbox";

export const PersonalHabitsForm = props => {

    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        t
    } = props;

    if (!values.habits) {
        values.habits = [
            { habit: t("medicalRecords.personalHabits.alcohol"), hasHabit: false, count: '', fromTo: '' },
            { habit: t("medicalRecords.personalHabits.tobacco"), hasHabit: false, count: '', fromTo: '' },
            { habit: t("medicalRecords.personalHabits.physicalActivity"), hasHabit: false, count: '', fromTo: '' },
            { habit: t("medicalRecords.personalHabits.drugs"), hasHabit: false, count: '', fromTo: '' }
        ]
    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <FormGroup>
                <Grid fluid>
                    {
                        values.habits && values.habits.length > 0 ? (
                            values.habits.map((habit, index) => (
                                <Row key={index}>
                                    <Col sm={4} >
                                        <ControlLabel>{habit.habit}</ControlLabel>
                                        <CustomCheckbox name={`habits.${index}.hasHabit`} isChecked={habit.hasHabit || false} onChange={handleChange} autoComplete="off" />
                                    </Col>
                                    <Col sm={4}>
                                        <ControlLabel>{t("medicalRecords.personalHabits.typeCount")}</ControlLabel>
                                        <FormControl type="text" name={`habits.${index}.count`} value={habit.count || ''} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                    </Col>
                                    <Col sm={4}>
                                        <ControlLabel>{t("medicalRecords.personalHabits.fromTo")}</ControlLabel>
                                        <FormControl type="text" name={`habits.${index}.fromTo`} value={habit.fromTo || ''} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                    </Col>
                                </Row>
                            ))
                        ) : ""
                    }
                    <hr></hr>
                    <Row>
                        <Col sm={2}>
                            <ControlLabel>{t("medicalRecords.personalHabits.education")}</ControlLabel>
                            <CustomCheckbox name="readsWrites" label={t("medicalRecords.personalHabits.readWrite")} isChecked={values.readsWrites || false} onChange={handleChange} />
                        </Col>
                        <Col sm={4}>
                            <ControlLabel>{t("medicalRecords.personalHabits.scholarship")}</ControlLabel>
                            <FormControl type="text" name="scholarship" value={values.scholarship || ''} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                        </Col>
                        <Col sm={2}>
                            <ControlLabel>{t("medicalRecords.personalHabits.completed")}</ControlLabel>
                            <CustomCheckbox name="scholarshipCompleted" label={t("medicalRecords.personalHabits.completed")} isChecked={values.scholarshipCompleted || false} onChange={handleChange} />
                        </Col>
                        <Col sm={4}>
                            <ControlLabel>{t("medicalRecords.personalHabits.scholarshipYears")}</ControlLabel>
                            <FormControl type="text" name="scholarshipYears" value={values.scholarshipYears || ''} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={8}>
                            <ControlLabel>{t("medicalRecords.personalHabits.occupation")}</ControlLabel>
                            <FormControl type="text" name="occupation" value={values.occupation || ''} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                        </Col>
                        <Col sm={4}>
                            <ControlLabel>{t("medicalRecords.personalHabits.workHours")}</ControlLabel>
                            <FormControl type="text" name="workHours" value={values.workHours || ''} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <ControlLabel>{t("medicalRecords.personalHabits.diet")}</ControlLabel>
                            <FormControl type="text" name="diet" value={values.diet || ''} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                        </Col>
                    </Row>
                </Grid>
            </FormGroup>
            <button type="button" className="btn btn-default" onClick={props.onCancel} disabled={isSubmitting}>{t("buttons.cancel")}</button>
            <button type="submit" className="btn btn-success btn-right" disabled={isSubmitting}>{t("buttons.save")}</button>
            <div className="clearfix"></div>
        </form>
    );
}