import axios from "axios";
import { ResponseResult, Url } from "./Util";
import { DoorMessage, WeatherMessage } from "./Entities/IotMessages";
import moment from "moment";
import { FreezerTelemetryResume } from "./Entities/FreezerTelemetryResume";

const GetUrl = Url("api/v1/IotMessage/");

export default {
    GetWeatherTelemetry,
    GetWeatherResume,
    GetDoorTelemetry,
}

const format = (date) => moment(date).format('MM-DD-YYYY')

async function GetWeatherTelemetry({ freezerId, from, to }) {
    if (from == null) throw new Error("From: Type 'Date' is required")
    if (to == null) throw new Error("To: Type 'Date' is required")
    if (freezerId == null) throw new Error("FreezerId: can not be empty")
    const response = await axios.get(GetUrl('Weather'), {
        params: {
            freezerId: freezerId,
            from: format(from),
            to: format(to)
        }
    });

    const responseResult = new ResponseResult(response.data);
    return responseResult.Value?.map(m => new WeatherMessage(m)) ?? [];
}

async function GetWeatherResume({ freezerIds, from, to, withFreezers }) {
    if (from == null) throw new Error("From: Type 'Date' is required")
    if (to == null) throw new Error("To: Type 'Date' is required")
    if (freezerIds == null) throw new Error("FreezerIds: can not be empty")

    const response = await axios.post(GetUrl('Weather/Resume'), { freezerIds, from, to })
    const responseResult = new ResponseResult(response.data);
    return responseResult.Value?.map(m => new FreezerTelemetryResume(m)) ?? [];
}

async function GetDoorTelemetry({ freezerId, from, to }) {
    if (from == null) throw new Error("From: Type 'Date' is required")
    if (to == null) throw new Error("To: Type 'Date' is required")
    if (freezerId == null) throw new Error("FreezerId: can not be empty")

    const response = await axios.get(GetUrl('Door'), {
        params: {
            freezerId: freezerId,
            from: from,
            to: to
        }
    });

    const responseResult = new ResponseResult(response.data);
    return responseResult.Value?.map(m => new DoorMessage(m)) ?? [];
}