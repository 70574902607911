import React, { useState, useEffect } from 'react';
import { ErrorMessage, FieldArray } from 'formik';
import { Row, Col, ControlLabel, FormControl } from 'react-bootstrap'

import { FormErrorMessage } from '../../Validations/FormValidationErrors'
import { conceptType, snomedExpressions } from '../../../variables/Enums'
import { SnomedPicker } from '../../CustomControls/SnomedPicker'
import { getLaboratoryData } from '../../../api/admin'
import { CustomSelect } from "../../CustomControls/CustomSelect"
import { Remove } from '../../Icons/Icons';
import { CustomDate } from '../../CustomControls/CustomDateTime';

export const LaboratoriesForm = props => {

    const {
        values,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        t
    } = props;

    const [data, setData] = useState({
        units: [],
        significances: [],
        templates: []
    });

    useEffect(() => {
        const fetchData = () => {
            getLaboratoryData()
                .then(response => {
                    setData(response.data);
                }).catch(error => {
                    setData({});
                });
        }
        fetchData();
    }, []);

    const emptyResult = {
        name: '',
        result: '',
        unit: null,
        order: 0
    }

    if (!values.laboratories) {
        values.laboratories = [];
    }

    const handleSelectTemplate = (_name, selectedTemplates) => {
        if (selectedTemplates && selectedTemplates.length > 0) {
            let labos = [];
            selectedTemplates.forEach(selectedTemplate => {
                labos = labos.concat(
                    selectedTemplate.resultItems.map(item => {
                        return ({ name: { conceptId: item.conceptId, description: item.description }, result: '', unit: data.units.filter(x => x.title == item.unit)[0], order: item.order });
                    })
                );
            }
            );
            setFieldValue("laboratories", labos.sort(function (a, b) { return a.order - b.order }));
        }
        else
            setFieldValue("laboratories", null);
    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <div className="container-fluid card">
                <Row>
                    <Col md={4}>
                        <ControlLabel>{t('medicalRecords.laboratory.laboratoryDate')}</ControlLabel>
                        <CustomDate
                            value={values.laboratoryDate ?? (values.laboratories && values.laboratories.length > 0 ? values.laboratories[0].laboratoryDate : null)}
                            name={`laboratoryDate`}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            closeOnSelect={true}
                            dateFormat="DD/MM/YYYY"
                            inputProps={{ placeholder: "DD/MM/YYYY" }}
                        />
                    </Col>
                    {
                        !props.edit &&
                        <>
                            <Col md={2}></Col>
                            <Col md={6}>
                                <ControlLabel>{t('medicalRecords.laboratory.templates')}</ControlLabel>
                                <CustomSelect
                                    onChange={handleSelectTemplate}
                                    name="templates"
                                    placeholder={t("commons.selectTemplate")}
                                    options={data.templates}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    isClearable={true}
                                    isMulti={true}
                                />
                            </Col>
                        </>
                    }
                </Row>
                <br />

                <FieldArray
                    name="laboratories"
                    render={arrayHelper => (<div>
                        <table className="table table-responsive">
                            <thead>
                                <tr>
                                    <th>
                                        {t('medicalRecords.laboratory.laboratory')}
                                    </th>
                                    <th>
                                        {t('medicalRecords.laboratory.result')}
                                    </th>
                                    <th>
                                        {t('medicalRecords.laboratory.unit')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    values.laboratories && values.laboratories.length > 0 ? (
                                        values.laboratories.map((laboratory, index) => (
                                            <tr key={index}>
                                                <td className='col-md-6'>
                                                    <SnomedPicker
                                                        conceptType={conceptType.procedures}
                                                        id={`laboratories.${index}.name`}
                                                        selected={laboratory.name ? [laboratory.name] : []}
                                                        name={`laboratories.${index}.name`}
                                                        placeholder={t("commons.search")}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        conceptId={snomedExpressions.Procedimiento} />
                                                    <ErrorMessage name={`laboratories.${index}.name`} component={FormErrorMessage} />
                                                </td>
                                                <td className='col-md-2'>
                                                    <FormControl type="text" name={`laboratories.${index}.result`} value={laboratory.result} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                    <ErrorMessage name={`laboratories.${index}.result`} component={FormErrorMessage} /></td>
                                                <td className='col-md-3'>
                                                    <CustomSelect
                                                        value={laboratory.unit}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        name={`laboratories.${index}.unit`}
                                                        placeholder={t("commons.select")}
                                                        options={data.units} />
                                                    <ErrorMessage name={`laboratories.${index}.unit`} component={FormErrorMessage} />
                                                </td>
                                                <td className='col-md-1'>
                                                    {
                                                        !props.edit &&
                                                        <button
                                                            className="btn-table-actions btn-remove"
                                                            style={{ verticalAlign: 'middle' }}
                                                            type="button"
                                                            onClick={() => arrayHelper.remove(index)}>
                                                            <Remove />
                                                        </button>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    ) : ""}
                            </tbody>
                        </table>

                        {
                            (values.laboratories.length === 0 || (values.laboratories.length > 0 && !values.laboratories[0].id)) &&
                            <Row>
                                <Col md={4}><button type="button" className="btn btn-default" onClick={() => {
                                    emptyResult.order = values.laboratories.length + 1;
                                    arrayHelper.push(emptyResult)
                                }}>{t("buttons.add")}</button></Col>
                                <Col md={8}></Col>
                            </Row>
                        }
                    </div>
                    )}
                />
            </div>
            <button type="button" className="btn btn-default" onClick={props.onCancel} disabled={isSubmitting}>{t("buttons.cancel")}</button>
            {
                values.laboratories && values.laboratories.length > 0 &&
                <button type="submit" className="btn btn-success btn-right" disabled={isSubmitting}>{t("buttons.save")}</button>
            }
            <div className="clearfix"></div>
        </form >
    );
}