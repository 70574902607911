import React from "react";
import { Col, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Card from "../Cards/Card";
import { CellPhone, Email, Facebook, Instagram, Phone, Twitter } from "../Icons/Icons";

export const ContactData = props => {

    const { t } = useTranslation();
    const patient = props.data;

    if (!patient.telecom)
        return (<></>);

    return (
        <Card
            className="card-personal-contacts"
            content={
                <Grid fluid className="personal-contacts">
                    <Row>
                        <Col sm={4}>
                            <div className="">
                                <div className="icon">
                                    <div className="svg-container"><CellPhone /></div>
                                </div>
                                <div>
                                    <div className="group">{t("person.contact.cellPhone")}</div>
                                    <div className="title">{patient.telecom.cellPhone}</div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className="">
                                <div className="icon">
                                    <div className="svg-container"><Phone /></div>
                                </div>
                                <div>
                                    <div className="group">{t("person.contact.phone")}</div>
                                    <div className="title">{patient.telecom.phone}</div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className="">
                                <div className="icon">
                                    <div className="svg-container"><Email /></div>
                                </div>
                                <div>
                                    <div className="group">{t("person.contact.email")}</div>
                                    <div className="title">{patient.telecom.email}</div>
                                </div>
                            </div>
                        </Col>

                        <Col sm={4}>
                            <div className="">
                                <div className="icon">
                                    <div className="svg-container"><Instagram /></div>
                                </div>
                                <div>
                                    <div className="group">Instagram</div>
                                    <div className="title">{patient.telecom.instagram}</div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className="">
                                <div className="icon">
                                    <div className="svg-container"><Facebook /></div>
                                </div>
                                <div>
                                    <div className="group">Facebook</div>
                                    <div className="title">{patient.telecom.facebook}</div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className="">
                                <div className="icon">
                                    <div className="svg-container"><Twitter /></div>
                                </div>
                                <div>
                                    <div className="group">Twitter</div>
                                    <div className="title">{patient.telecom.twitter}</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Grid >
            }
        />
    );
}