import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../assets/sass/lbd/_documentViewer.scss";
import { ModalData } from "../../Modals/ModalData";
import { Spinner } from "../../Utils/Loaders";

export const DocumentViewer = ({ file, fileName, inModal, isLoading }) => {
    const { t } = useTranslation();
    const [isShowing, setIsShowing] = useState(true);

    const rigthClickBlock = (event) => {
        event.preventDefault()
    }
    useEffect(() => {
        if (file) {
            document.addEventListener('contextmenu', rigthClickBlock);
            setIsShowing(true);
        }
        else {
            document.removeEventListener('contextmenu', rigthClickBlock);
            setIsShowing(false);
        }

        return () => {
            document.removeEventListener('contextmenu', rigthClickBlock);
        };
    }, [file]);

    const toggleModal = () => {
        try { window.URL.revokeObjectURL(urlBlob); }
        catch (error) { console.error(error) }
        setIsShowing(!isShowing);
    };

    if (!file)
        return null;

    let urlBlob = window.URL.createObjectURL(file);

    const viewer = (
        <DocViewer
            documents={[{
                uri: urlBlob,
                fileName: fileName,
            }]}
            pluginRenderers={DocViewerRenderers}
            config={{
                header: {
                    disableFileName: true,
                }
            }}
        />
    );

    if (inModal)
        return (
            <ModalData
                title={`${t('commons.documentViewer_Title')}: ${fileName}`}
                isShowing={isShowing}
                hide={toggleModal}>
                <>
                    {isLoading ?? <Spinner />}
                    {viewer}
                </>
            </ModalData>
        )

    return viewer;
    /* <DocumentViewer
                queryParams="hl=es"
                url={urlBlob}
                style={{ width: '100%', height: '80vh' }}
                overrideLocalhost={"https://hc.alpha-cr.com"}
            />
    return (<DocViewer
        documents={[{
            uri: urlBlob,
            fileName: fileName,
        }]}
        pluginRenderers={DocViewerRenderers}
        config={{
            header: {
                disableFileName: true,
            }
        }}
    />);*/
}

DocumentViewer.defaultProps = {
    inModal: true
};

DocumentViewer.propTypes = {
    fileName: PropTypes.string,
    file: PropTypes.object,
    inModal: PropTypes.bool,
};