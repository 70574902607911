import { useState, useEffect } from "react";
import { getAuditTrail, getLastAuditTrail } from "../../api/admin";
import { getAdverseEvents, getPersonalHistories } from "../../api/medicalRecord";
import { getMasterData, getVisits, getStudyTasks, getProtocol, getProtocols, getPatients, closeProtocolApi, getApprovedVisits, getVisitsTracking, getVisitTrackingAdverseEvents, getDocumentNotes, getDocumentNote, createDocumentNote, updateDocumentNote, removeProtocolApi } from "../../api/protocol";
import { getCurrentUserRole } from "../../api/security";
import { HandleApiError } from "../../components/Notifications/APIErrorHandler";
import { capitalize } from "../../components/Utils/Commons";

export const useMasterData = () => {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let isSubscribed = true;
        const fetchData = () => {
            setIsLoading(true);
            getMasterData(["Laboratories", "Phases", "Status"]).then(response => {
                if (isSubscribed) {
                    setData(response.data);
                    setIsLoading(false);
                }
            }).catch(error => {
                if (isSubscribed) {
                    console.log(error);
                    setData({});
                    setIsLoading(false);
                }
            });
        };

        fetchData();

        return () => isSubscribed = false;
    }, []);

    return [data, isLoading];
}

export const useBriefMasterData = () => {
    const [data, setData] = useState({
        laboratories: [],
        phases: [],
        status: [],
        studyTasks: []
    });

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = () => {

            getMasterData(["Laboratories", "Phases", "Status", "StudyTasks"]).then(response => {
                if (isSubscribed)
                    setData(response.data);
            }).catch(error => {
                if (isSubscribed) {
                    console.log(error);
                    setData({
                        laboratories: [],
                        phases: [],
                        status: [],
                        studyTasks: []
                    });
                }

            });
        };

        fetchData();

        return () => isSubscribed = false;
    }, []);

    return data;
}

export const useStudyTasks = (protocolId) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        let isSubscribed = true;
        const fetchData = () => {

            getStudyTasks(protocolId)
                .then(response => {
                    if (isSubscribed) {
                        setData(response.data);
                    }
                })
                .catch(error => {
                    if (isSubscribed) {
                        console.log(error);
                        setData([]);
                    }
                });
        };

        fetchData();

        return () => isSubscribed = false;
    }, []);

    return data;
}

export const useProtocolVisits = (protocolId) => {
    const [visits, setVisits] = useState([]);

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = () => {

            getVisits(protocolId).then(response => {
                if (isSubscribed) {
                    setVisits(response.data);
                }
            }).catch(error => {
                if (isSubscribed) {
                    HandleApiError(error);
                    setVisits([]);
                }
            });
        };

        fetchData();

        return () => isSubscribed = false;
    }, [protocolId]);

    return visits;
}

export const useProtocolApprovedVisits = (protocolId) => {
    const [visits, setVisits] = useState([]);

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = () => {

            getApprovedVisits(protocolId).then(response => {

                if (isSubscribed) {
                    setVisits(response.data);
                }

            }).catch(error => {
                if (isSubscribed) {
                    HandleApiError(error);
                    setVisits([]);
                }
            });
        };

        fetchData();

        return () => isSubscribed = false;
    }, [protocolId]);

    return visits;
}

export const useProtocols = (queryParams) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalSize, setTotalSize] = useState(0);
    const [query, setQuery] = useState(queryParams ?? {
        pagination: {
            page: 1,
            sizePerPage: 10,
            totalSize: 0
        },
        sort: {
            sortField: "Created",
            sortOrder: "desc"
        },
        filters: {}
    });

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = () => {
            setIsLoading(true);
            getProtocols(query)
                .then(response => {

                    if (isSubscribed) {
                        const paginatedResult = response.data;

                        setTotalSize(paginatedResult.count);
                        setData(paginatedResult.data);
                        setIsLoading(false);
                    }

                }).catch(error => {
                    if (isSubscribed) {
                        HandleApiError(error);
                        setIsLoading(false);
                    }
                });
        }

        fetchData();

        return () => isSubscribed = false;
    }, [query]);

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {

        var newQuery = null;
        if (type === 'pagination') {
            newQuery = JSON.parse(JSON.stringify(query));

            newQuery.pagination.page = page;
            newQuery.pagination.sizePerPage = sizePerPage;

            setQuery(newQuery);
        }

        if (type === 'sort') {
            newQuery = JSON.parse(JSON.stringify(query));

            newQuery.sort.sortField = sortField;
            newQuery.sort.sortOrder = sortOrder;

            setQuery(newQuery);
        }
    }

    const handleFilterChange = (filterName, selectedValue) => {
        if (filterName) {
            var newQuery = JSON.parse(JSON.stringify(query));

            newQuery.pagination.page = 1;
            newQuery.filters[filterName] = selectedValue != null ? selectedValue.id : selectedValue;

            setQuery(newQuery);
        }
    }

    return [data, query, totalSize, isLoading, handleTableChange, handleFilterChange];
}

export const useProtocol = (protocolId) => {
    const [brief, setBrief] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = () => {
            setIsLoading(true);
            getProtocol(protocolId)
                .then(response => {
                    if (isSubscribed) {
                        setBrief(response.data);
                        setIsLoading(false);
                    }
                }).catch(error => {
                    if (isSubscribed) {
                        HandleApiError(error);
                        setIsLoading(false);
                    }
                });
        }

        fetchData();

        return () => isSubscribed = false;
    }, [protocolId]);

    const closeProtocol = (protocolId) => {
        setIsLoading(true);
        closeProtocolApi(protocolId)
            .then(response => {
                setIsLoading(false);
            }).catch(error => {
                HandleApiError(error);
                setIsLoading(false);
            });
    }

    const removeProtocol = (protocolId) => {
        setIsLoading(true);
        removeProtocolApi(protocolId)
            .then(response => {
                setIsLoading(false);
            }).catch(error => {
                HandleApiError(error);
                setIsLoading(false);
            });
    }

    return [isLoading, brief, closeProtocol, removeProtocol];
}

export const useCurrentUserRol = (protocolId) => {
    const [rol, setRole] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = () => {
            setIsLoading(true);
            getCurrentUserRole(protocolId)
                .then(response => {
                    if (isSubscribed) {
                        setRole(response.data);
                        setIsLoading(false);
                    }
                }).catch(error => {
                    if (isSubscribed) {
                        HandleApiError(error);
                        setIsLoading(false);
                    }
                });
        }

        fetchData();

        return () => isSubscribed = false;

    }, [protocolId]);

    return [rol, isLoading];
}

export const usePatients = (protocolId) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalSize, setTotalSize] = useState(0);

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = () => {
            setIsLoading(true);
            getPatients(protocolId)
                .then(response => {

                    if (isSubscribed) {
                        const paginatedResult = response.data;

                        setTotalSize(paginatedResult.count);
                        setData(paginatedResult.data);
                        setIsLoading(false);
                    }

                }).catch(error => {
                    if (isSubscribed) {
                        HandleApiError(error);
                        setIsLoading(false);
                    }
                });
        }

        fetchData();

        return () => isSubscribed = false;
    }, [protocolId]);

    return [data, isLoading, totalSize];
}

export const useVisitsTracking = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    const fetchData = async (protocolId, medicalRecordNumber) => {
        try {
            let newQuery = {
                pagination: {
                    page: 1,
                    sizePerPage: 10,
                    totalSize: 0
                },
                sort: {
                    sortField: 'MedicalRecordNumber',
                    sortOrder: 'desc'
                },
                filters: {}
            };

            if (medicalRecordNumber) {
                newQuery.filters.medicalRecordNumber = medicalRecordNumber;
            }

            const response = await getVisitsTracking(protocolId, newQuery);
            setData(response.data);
            setIsLoading(false);

        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);
            setData([]);
        }
    }

    return [data, isLoading, fetchData];
}

export const useProtocolAdverseEvents = (protocolId, visitId, medicalRecordNumber, patientNumber) => {

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    var groups = [
        {
            label: 'Eventos Adversos',
            options: [],
        },
        {
            label: 'Antecedentes Personales',
            options: [],
        },
        {
            label: 'Otros',
            options: [{ conceptId: 0, description: "Profilaxis" }],
        }
    ];

    const fetchData = async (isSubscribed) => {

        try {
            const promise1 = getVisitTrackingAdverseEvents(protocolId, visitId, patientNumber);
            const promise2 = getAdverseEvents({
                medicalRecordNumber: medicalRecordNumber,
                pagination: { page: 1, sizePerPage: 100, },
                actual: false
            });
            const promise3 = getPersonalHistories({
                medicalRecordNumber: medicalRecordNumber,
                pagination: { page: 1, sizePerPage: 100, },
                actual: false
            });

            Promise.all([promise1, promise2, promise3]).then((responses) => {
                if (isSubscribed) {

                    if (responses[0].data) {
                        groups[0].options = groups[0].options.concat(responses[0].data.adverseEvents);
                        groups[1].options = groups[1].options.concat(responses[0].data.personalHistory);
                    }
                    if (responses[1].data) {
                        var resEvents = responses[1].data.data;
                        if (resEvents.length > 0) { // adverseEffectId: "386661006", adverseEffect: "fiebre"
                            resEvents.forEach(event => {
                                event.conceptId = event.adverseEffectId;
                                event.description = event.adverseEffect;
                            });
                            groups[0].options = groups[0].options.concat(resEvents);
                        }
                    }
                    if (responses[2].data) {
                        let resPersonalHistory = responses[2].data.data;
                        if (resPersonalHistory.length > 0) {
                            resPersonalHistory.forEach(event => {
                                event.conceptId = event.adverseEffectId;
                                event.description = event.adverseEffect;
                            });
                            groups[1].options = groups[1].options.concat(resPersonalHistory);
                        }
                    }
                    setData(groups);
                    setIsLoading(false);
                }
            });
        }
        catch (error) {
            if (isSubscribed) {
                setIsLoading(false);
                setData(groups);
            }
        }
    }

    useEffect(() => {
        let isSubscribed = true

        if (protocolId && visitId && (medicalRecordNumber || patientNumber))
            fetchData(isSubscribed);
        else
            setIsLoading(false);

        return () => isSubscribed = false;
    }, [protocolId]);

    return [data, isLoading, fetchData];
}

export const useDocumentNotes = () => {
    const [isLoading, setIsLoading] = useState(true);

    const getNotes = async (protocolId, documentId) => {
        try {
            return getDocumentNotes(protocolId, documentId);
        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);

            return null;
        }
    }

    const getNote = async (protocolId, noteId) => {
        try {
            setIsLoading(true);
            const note = await getDocumentNote(protocolId, noteId);
            setIsLoading(false);

            return note;

        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);

            return null;
        }
    }

    const createNote = async (type, documentId, protocolId, visitId, patientId, note) => {
        try {
            setIsLoading(true);
            const documentNote = await createDocumentNote(documentId, protocolId, visitId, patientId, { note: note, type: type });
            setIsLoading(false);

            return documentNote;

        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);

            return null;
        }
    }

    const updateNote = async (protocolId, noteId, note) => {
        try {
            setIsLoading(true);
            const documentNote = await updateDocumentNote(protocolId, noteId, { note: note });
            setIsLoading(false);

            return documentNote;

        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);

            return null;
        }
    }

    return [isLoading, getNotes, getNote, createNote, updateNote];
}

export const useAudits = (entity, entityId, hasVisitTracking) => {
    const [audits, setAudits] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = async () => {
            setIsLoading(true);
            try {
                const resp = await getAuditTrail(entity, entityId);
                if (isSubscribed) {
                    setAudits(resp.data);
                    setIsLoading(false);
                }
            }
            catch (error) {
                if (isSubscribed) {
                    HandleApiError(error);
                    setAudits(null);
                    setIsLoading(false);
                }
            }
        }

        if (entityId && hasVisitTracking)
            fetchData();

        return () => isSubscribed = false;
    }, [entity, entityId, hasVisitTracking]);

    const getLastChange = (field) => {

        const fieldName = capitalize(field, false, ".");

        var audit = audits?.find(x => x.oldValues[fieldName] !== undefined);
        if (audit) {
            return {
                old: audit.oldValues[fieldName] === null ? "" : audit.oldValues[fieldName],
                current: audit.newValues[fieldName] === null ? "" : audit.newValues[fieldName],
            };
        }

        return null;
    }

    return [isLoading, audits, getLastChange];
}

export const useAuditsHelpers = () => {
    
    const getLastChange = (audits, field) => {

        const fieldName = capitalize(field, false, ".");

        var audit = audits?.find(x => x.oldValues[fieldName] !== undefined);
        if (audit) {
            return {
                old: audit.oldValues[fieldName] === null ? "" : audit.oldValues[fieldName],
                current: audit.newValues[fieldName] === null ? "" : audit.newValues[fieldName],
            };
        }

        return null;
    }

    return [getLastChange];
}

export const useLastAuditTrial = (entity, entityId) => {
    const [audit, setAudit] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = async () => {
            setIsLoading(true);
            try {
                const resp = await getLastAuditTrail(entity, entityId);
                if (isSubscribed) {
                    setAudit(resp.data);
                    setIsLoading(false);
                }
            }
            catch (error) {
                if (isSubscribed) {
                    HandleApiError(error);
                    setIsLoading(false);
                }
            }
        }

        if (entityId)
            fetchData();

        return () => isSubscribed = false;
    }, [entity, entityId]);

    return [isLoading, audit];
}