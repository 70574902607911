import axios from './index';

export const getAllNotifications = async () => {
    return axios.get(`api/v1/notifications/all`);
}
export const getUnreadNotifications = async () => {
    return axios.get(`api/v1/notifications`);
}
export const markAsReadedNotification = async (messageId) => {
    return axios.put(`api/v1/notifications/${messageId}`, null);
};