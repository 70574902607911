import React from 'react';
import { NavLink } from "react-router-dom";
import { WithAuth } from '../../../components/Authorization/WithAuthProtocol';
import { ExtraProtocol, Phone } from '../../../components/Icons/Icons';
import { getOffset, getOffsetFromRandom, isBetween } from '../../../components/Utils/Dates';
import { dateFormatter, dateTimeFormatter } from '../../../components/Utils/Formatter';
import { Popover } from '../../../components/Utils/Popover';
import { Tooltip } from '../../../components/Utils/Tooltips';
import { visitStatus, visitTypes } from '../../../variables/Enums';
import i18n from '../../../i18n';

export const getVisitTypeTitle = (type) => {
    switch (type) {
        case visitTypes.ExtraProtocolVisit:
            return i18n.t("protocols.visitTracking.visitTypes.extraProtocol");
        case visitTypes.Note:
            return i18n.t("protocols.visitTracking.visitTypes.note");
        case visitTypes.EarlyCompletion:
            return i18n.t("protocols.visitTracking.visitTypes.earlyCompletion");
        default:
            return i18n.t("protocols.visitTracking.visitTypes.visit");;
    }
}

/**
 * Obtiene las columnas de la tabla de VisitTracking, correspondiente a cada visita. 
 * @param {*} protocolId 
 * @param {*} visits 
 * @param {*} redirectFunction 
 * @param {*} translateFunction 
 * @returns 
 */
export const getVisitsTrackingColumns = (protocolId, visits, redirectFunction, translateFunction) => {
    let cols = [];

    // Armo columnas a mostrar.
    visits.forEach((visit, idx) => {
        cols.push({
            dataField: `visit${idx + 1}`,
            text: visit.title,
            headerStyle: { width: '150px' },
            headerFormatter: function (column) {
                return (
                    <div>
                        <div>
                            {column.text}
                            {visit.type === 1 && <Phone style={{ height: '20px', width: '20px', verticalAlign: 'bottom' }} />}
                        </div>
                        <div>
                            {translateFunction(`protocols.visit.offsetType.${visit.offsetType}`)} {visit.offset}
                        </div>
                    </div>
                );
            },
            headerAlign: 'center', align: 'center',
            formatter: function (cell, row, rowIndex) {
                if (!cell) return '';

                //if (!cell.estimatedDate) return null;

                const estimatedDateContent = (<Tooltip id={`e-${rowIndex}`} tooltip={i18n.t("protocols.visitTracking.visitEstimated")}>
                    <div className="visit-estimated">{cell.estimatedDate && dateFormatter(cell.estimatedDate, "DD/MMM/YYYY")}</div>
                </Tooltip>);

                let dateContent = null;
                switch (cell.status) {
                    case visitStatus.Started: dateContent = (
                        <WithAuth protocolId={protocolId} requiredPermission={["VisitTracking.Add", "VisitTracking.Edit", "VisitTracking.UploadFile", "VisitTracking.Publish"]}>
                            <Tooltip id={`g-${rowIndex}`} tooltip={i18n.t("protocols.visit.status.started")}>

                                <div className="visit-started">{dateFormatter(cell.date, "DD/MMM/YYYY")}</div>
                            </Tooltip>
                        </WithAuth>
                    ); break;
                    case visitStatus.Completed: dateContent = (<Tooltip id={`c-${rowIndex}`} tooltip={i18n.t("protocols.visit.status.completed")}>
                        <div className="visit-completed">{dateFormatter(cell.date, "DD/MMM/YYYY")}</div>
                    </Tooltip>); break;
                    case visitStatus.Skipped: dateContent = (<Tooltip id={`s-${rowIndex}`} tooltip={i18n.t("protocols.visit.status.skipped")}>
                        <div className="visit-skipped">{dateFormatter(cell.date, "DD/MMM/YYYY")}</div>
                    </Tooltip>); break;
                    case visitStatus.Failed: dateContent = (<Tooltip id={`f-${rowIndex}`} tooltip={i18n.t("protocols.visit.status.failed")} >
                        <div className="visit-failed">{dateFormatter(cell.date, "DD/MMM/YYYY")}</div>
                    </Tooltip>); break;
                    case visitStatus.Changed: dateContent = (<Tooltip id={`f-${rowIndex}`} tooltip={i18n.t("protocols.visit.status.changed")} >
                        <div className="visit-changed">{dateFormatter(cell.date, "DD/MMM/YYYY")}</div>
                    </Tooltip>); break;
                    case visitStatus.AutoCompleted: dateContent = (<Tooltip id={`ac-${rowIndex}`} tooltip={i18n.t("protocols.visit.status.autoCompleted")} >
                        <div className="visit-timeclosed">{dateFormatter(cell.date, "DD/MMM/YYYY")}</div>
                    </Tooltip>); break;
                    default: break;
                }

                return (
                    <div key={`ppp${rowIndex}`}>
                        <div className="visit-container">
                            <NavLink to={`/admin/protocols/${protocolId}/visitTracking/${cell.crossVersionId}/patient/${row.numberInProtocol}`} className="nav-link" activeClassName="active">
                                <div>{estimatedDateContent}</div>
                                <div>{dateContent}</div>
                            </NavLink>
                        </div>
                    </div>
                )
            },
        });
        // Columnas para Flag de Visita ExtraProtocolar. 
        cols.push({
            dataField: `visit${visit.order}extra`, text: "", headerStyle: { width: '25px' }, headerAlign: 'center', align: 'center',
            formatter: function (cell, _row, rowIndex, formatExtraData) {

                if (!cell)
                    return '';

                //let icons = [];
                let popoverContent = cell.map((item, idx) => {
                    let extraText = getVisitTypeTitle(item.type);
                    //icons.push(item.type === visitTypes.ExtraProtocolVisit ? "EP" : "NA");

                    return (
                        <div key={`visit-extra-container-${idx}`} className="visit-extra-container">
                            <a onClick={() => formatExtraData.push(`/admin/protocols/${protocolId}/visitTrackingExtra/${item.id}/patient/${item.numberInProtocol}`)} style={{ cursor: "pointer" }}>
                                {extraText}
                                {dateTimeFormatter(item.date)}
                            </a>
                        </div>
                    );
                });

                return (
                    <div key={`extraProtocolVisit${rowIndex}`}>
                        <Popover id={`extraProtocolVisit-pop${rowIndex}`} title={"Extras"} popover={popoverContent}>
                            <div className="visit-container" style={{ cursor: 'pointer' }}>
                                {
                                    <ExtraProtocol style={{ cursor: "pointer" }} />
                                    //icons.join()
                                }
                            </div>
                        </Popover>
                    </div>
                )
            },
            formatExtraData: redirectFunction
        })
    });

    return cols;
}

/**
 * Formatea Fechas de la Grilla de Hoja de Visita. 
 * @param {*} patients 
 * @param {*} visitTemplates 
 * @returns 
 */
export const getVisitsTrackingGrid = (patients, visitTemplates) => {
    let data = [];

    patients.forEach(patient => {

        let afterBaseline = false;
        let baselineVisit = null;
        var patientCopy = Object.assign({}, patient);

        // Por cada template de visita. 
        for (let i = 0; i < visitTemplates.length; i++) {
            var visitTemplate = visitTemplates[i];
            let templateOrder = i + 1;

            // creo property en Paciente para poder mapear en la tabla
            const visit = patientCopy.visits.find(x => { return x.crossVersionId === visitTemplate.crossVersionId });
            if (visit) // Visita Realizada y tiene permisos de add/edit
                patientCopy[`visit${templateOrder}`] = visit;
            else // Visita futura
                patientCopy[`visit${templateOrder}`] = Object.assign({}, visitTemplate);

            let patientVisit = patientCopy[`visit${templateOrder}`];
            if (patientVisit.order === 1) { // Primer visita. 
                if (patientCopy.visits.length === 0) {
                    patientVisit.estimatedDate = null; //new Date(); // Si no tuvo ninguna visita, pongo null. 
                }
                else {
                    // Ya tuvo la primer visita. 
                    patientVisit.estimatedDate = patientVisit.date;
                }
            }

            // Si ScreenFailure, corto el VisitTracking. 
            if (patientVisit.status === visitStatus.Failed) {
                // Verifico Visita ExtraProtocolar post fail
                if (patientVisit.date) {
                    const extraProtocolVisits = patient.visits.filter(x => x.type > 0 && isBetween(x.date, patientVisit.date, new Date()));
                    if (extraProtocolVisits.length > 0) {
                        patientCopy[`visit${templateOrder + 1}extra`] = extraProtocolVisits;
                    }
                }
                // Verifico Visita ExtraProtocolar pre fail
                const prevVisit = patientCopy[`visit${templateOrder - 1}`];
                if (prevVisit?.date) {
                    const extraProtocolVisits = patient.visits.filter(x => x.type > 0 && isBetween(x.date, prevVisit.date, patientVisit.date));
                    if (extraProtocolVisits.length > 0) {
                        patientCopy[`visit${templateOrder - 1}extra`] = extraProtocolVisits;
                    }
                }
                break;
            }

            // Calculo fechas de visitas futuras 
            if (templateOrder > 1) {

                const prevVisit = patientCopy[`visit${templateOrder - 1}`];

                // Verifico Visita ExtraProtocolar, solo si la visita anterior fue realizada
                if (prevVisit?.date) {
                    const extraProtocolVisits = patient.visits.filter(x => x.type > 0 && isBetween(x.date, prevVisit.date, patientVisit.date));
                    if (extraProtocolVisits.length > 0) {
                        patientCopy[`visit${templateOrder - 1}extra`] = extraProtocolVisits;
                    }
                }

                if (afterBaseline) {
                    // Si es posterior a baseline, calculo las fechas estimativas con respecto a la fecha real de la RANDOM. 
                    if (baselineVisit && (baselineVisit.date || baselineVisit.estimatedDate)) {
                        let date = baselineVisit.date ? new Date(baselineVisit.date) : new Date(baselineVisit.estimatedDate);
                        patientVisit.estimatedDate = getOffsetFromRandom(date, visitTemplate.offset, visitTemplate.offsetType);
                    }
                } else {
                    // Si es anterior a baseline, calculo fechas estimativas con respecto a la fecha estimada anterior. 
                    if (prevVisit && prevVisit.estimatedDate) {
                        let prevVisitDate = new Date(prevVisit.estimatedDate);
                        patientVisit.estimatedDate = getOffset(prevVisitDate, visitTemplates[i - 1].offset, visitTemplate.offset, visitTemplate.offsetType);
                    }
                }
            }

            if (visitTemplate.baseline || visitTemplate.offset === 0) {
                afterBaseline = true;
                baselineVisit = patientVisit;
            }
        }

        data.push(patientCopy);
    });

    return data;
}

// Formatea Fechas para mostrar en modal de NewEntry. 
export const getVisitsTrackingOptions = (patients, visitTemplates) => {
    let data = [];
    let futureVisitAdded = false;

    patients.forEach(patient => {

        let afterBaseline = false;
        let baselineVisit = null;
        var patientCopy = Object.assign({}, patient);

        // Por cada template de visita. 
        for (let i = 0; i < visitTemplates.length; i++) {
            var visitTemplate = visitTemplates[i];
            let templateOrder = i + 1;

            // creo property en Paciente para poder mapear en la tabla
            const visit = patientCopy.visits.find(x => { return x.crossVersionId === visitTemplate.crossVersionId });
            if (visit) // Visita Realizada
                patientCopy[`visit${templateOrder}`] = visit;
            else // Visita futura
            {
                patientCopy[`visit${templateOrder}`] = Object.assign({}, visitTemplate);
                futureVisitAdded = true;
            }

            let patientVisit = patientCopy[`visit${templateOrder}`];
            if (patientVisit.order === 1) { // Primer visita. 
                if (patientCopy.visits.length === 0) {
                    patientVisit.estimatedDate = new Date(); // Si no tuvo ninguna visita, agrego today. 
                }
                else {
                    patientVisit.estimatedDate = patientVisit.date;
                }
            }

            if (patientVisit.status !== undefined)
                patientVisit.isDisabled = true;

            // Si ScreenFailure, corto el VisitTracking. 
            if (patientVisit.status === visitStatus.Failed) {
                break;
            }

            // Calculo fechas de visitas futuras 
            if (templateOrder > 1) {
                const prevVisit = patientCopy[`visit${templateOrder - 1}`];

                // Verifico Visita ExtraProtocolar, solo si la visita anterior fue realizada
                if (prevVisit?.date) {
                    var extraProtocolVisits = patient.visits.filter(x => x.order === 0 && isBetween(x.date, prevVisit.date, patientVisit.date));
                    if (extraProtocolVisits.length > 0) {
                        patientCopy[`visit${templateOrder - 1}extra`] = extraProtocolVisits;
                    }
                }

                if (afterBaseline) {
                    // Si es posterior a baseline, calculo las fechas estimativas con respecto a la fecha real de la RANDOM. 
                    if (baselineVisit && (baselineVisit.date || baselineVisit.estimatedDate)) {
                        let date = baselineVisit.date ? new Date(baselineVisit.date) : new Date(baselineVisit.estimatedDate);
                        patientVisit.estimatedDate = getOffsetFromRandom(date, visitTemplate.offset, visitTemplate.offsetType);
                    }
                } else {
                    // Si es anterior a baseline, calculo fechas estimativas con respecto a la fecha estimada anterior. 
                    if (prevVisit && prevVisit.estimatedDate) {
                        let date = new Date(prevVisit.estimatedDate);
                        patientVisit.estimatedDate = getOffset(date, visitTemplates[i - 1].offset, visitTemplate.offset, visitTemplate.offsetType); //getOffset(date, visitTemplate.offset, visitTemplate.offsetType);
                    }
                }
            }

            if (visitTemplate.baseline || visitTemplate.offset === 0) {
                afterBaseline = true;
                baselineVisit = patientVisit;
            }

            data.push(patientVisit);
            if (futureVisitAdded)
                break;
        }
    });

    return data;
}

export const getVisitTrackingOptionFormat = (option) => {
    const rowIndex = option.id;
    const estimatedDateContent = (<Tooltip id={`e-${rowIndex}`} tooltip={i18n.t("protocols.visitTracking.visitEstimated")}>
        <div className="visit-estimated">{option.estimatedDate && dateFormatter(option.estimatedDate, "DD/MMM/YYYY")}</div>
    </Tooltip>);

    let dateContent = null;
    switch (option.status) {
        case visitStatus.Started: dateContent = (<Tooltip id={`g-${rowIndex}`} tooltip={`${i18n.t("protocols.visit.status.started")}: ${option.text ?? ""}`}>
            <div className="visit-started">{dateFormatter(option.date, "DD/MMM/YYYY")}</div>
        </Tooltip>); break;
        case visitStatus.Completed: dateContent = (<Tooltip id={`c-${rowIndex}`} tooltip={`${i18n.t("protocols.visit.status.completed")}: ${option.text ?? ""}`}>
            <div className="visit-completed">{dateFormatter(option.date, "DD/MMM/YYYY")}</div>
        </Tooltip>); break;
        case visitStatus.Skipped: dateContent = (<Tooltip id={`s-${rowIndex}`} tooltip={`${i18n.t("protocols.visit.status.skipped")}: ${option.text ?? ""}`}>
            <div className="visit-skipped">{dateFormatter(option.date, "DD/MMM/YYYY")}</div>
        </Tooltip>); break;
        case visitStatus.Failed: dateContent = (<Tooltip id={`f-${rowIndex}`} tooltip={`${i18n.t("protocols.visit.status.failed")}: ${option.text ?? ""}`} >
            <div className="visit-failed">{dateFormatter(option.date, "DD/MMM/YYYY")}</div>
        </Tooltip>); break;
        case visitStatus.AutoCompleted: dateContent = (<Tooltip id={`ac-${rowIndex}`} tooltip={`${i18n.t("protocols.visit.status.autoCompleted")}: ${option.text ?? ""}`} >
            <div className="visit-timeclosed">{dateFormatter(option.date, "DD/MMM/YYYY")}</div>
        </Tooltip>); break;
        default: break;
    }

    return (
        <div style={{ display: "flex" }}>
            <div>{option.title}</div>
            <div style={{ marginLeft: "10px" }}>{estimatedDateContent}</div>
            <div style={{ marginLeft: "10px", color: "#ccc" }}>
                {dateContent}
            </div>
        </div>
    )
}