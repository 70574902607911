import PropTypes from 'prop-types';
import React from 'react';
import avatarDefault from "../../assets/img/faces/face-0.jpg";
import { usePatientAvatar } from '../../containers/MedicalRecords/Hooks/usePatient';

export const PatientAvatarAsync = ({ medicalRecordNumber }) => {

    const [avatar, isLoading] = usePatientAvatar(medicalRecordNumber);

    return (<img
        alt="Avatar"
        src={isLoading || !avatar ? avatarDefault : avatar}
        onError={(e) => { e.target.onerror = null; e.target.src = avatarDefault }}
        className="avatar" />);
}

PatientAvatarAsync.propTypes = {
    medicalRecordNumber: PropTypes.string.isRequired
};