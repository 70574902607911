import { useState, useEffect } from "react";
import { getLaboratories, getLaboratory } from "../../../api/protocolAdmin";

export const useLaboratories = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        const fetchData = () => {
            setIsLoading(true);
            setReload(false);
            getLaboratories().then(response => {
                setData(response.data);
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setData([]);
                setIsLoading(false);
            });
        };

        fetchData();
    }, [reload]);

    return [data, isLoading, setReload];
}

export const useLaboratory = (laboratoryId) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [reload, setReload] = useState(false);

    useEffect(() => {
        const fetchData = () => {
            setIsLoading(true);
            setReload(false);
            getLaboratory(laboratoryId).then(response => {
                setData(response.data);
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setData({});
                setIsLoading(false);
            });
        };

        fetchData();
    }, [laboratoryId, reload]);

    return [data, isLoading, setReload];
}