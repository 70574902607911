import { getCurrentUserRole } from "../api/security";

const requestCurrentUserRole = 'CURRENTUSERROLE_REQUEST';
const receiveCurrentUserRole = 'CURRENTUSERROLE_RECEIVE';
const initialState = { protocolId: null, role: {}, isLoading: false };

export const roleActionCreator = {
    requestCurrentUserRole: (protocolId) => async (dispatch) => {

        dispatch({ type: requestCurrentUserRole });

        try {
            //console.log("Obtengo Rol de Redux")
            const response = await getCurrentUserRole(protocolId);

            dispatch({ type: receiveCurrentUserRole, role: response.data, protocolId });
        }
        catch (error) {
            console.log(error)
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestCurrentUserRole) {
        return {
            ...state,
            isLoading: true
        };
    }
    if (action.type === receiveCurrentUserRole) {
        return {
            ...state,
            role: action.role,
            protocolId: action.protocolId,
            isLoading: false
        };
    }
    
    return state;
};
