import { ErrorMessage, FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, ControlLabel, FormControl, Row } from 'react-bootstrap';
import { getLaboratoryData } from '../../../api/admin';
import { conceptType, snomedExpressions } from '../../../variables/Enums';
import { CustomDate } from '../../CustomControls/CustomDateTime';
import { CustomSelect } from "../../CustomControls/CustomSelect";
import { SnomedPicker } from '../../CustomControls/SnomedPicker';
import { Note, Remove } from '../../Icons/Icons';
import { FormErrorMessage } from '../../Validations/FormValidationErrors';

export const OrdersForm = props => {

    const {
        values,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        isSubmitting,
        handleSubmit,
        t,
        data,
        events
    } = props;

    const [showNote, setShowNote] = useState(null);
    const [laboData, setLaboData] = useState({
        units: [],
        significances: [],
        templates: []
    });

    useEffect(() => {
        const fetchData = () => {
            getLaboratoryData()
                .then(response => {
                    setLaboData(response.data);
                }).catch(error => {
                    setLaboData({});
                });
        }
        fetchData();
    }, []);

    const emptyResult = {
        procedure: '',
        dose: '',
        reason: '',
        unit: data && data.units && data.units.find(x => x.code == "comp"),
    };

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };
    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };
    const formatGroupLabel = data => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    const findReasonInEventList = (reasonId, events) => {
        if (!reasonId)
            return events[0].options[0]; // Devuelvo default

        var reason = events[1].options.find(x => x.id === reasonId); // Busco en patologias
        if (reason)
            return reason;
    }

    const getReasonTitle = (reason) => {

        if (!reason)
            return null;

        if (typeof reason === 'string')
            return reason;

        return reason.title;
    }

    const handleSelectTemplate = (_name, selectedTemplates) => {
        if (selectedTemplates && selectedTemplates.length > 0) {
            let labos = [];
            selectedTemplates.forEach(selectedTemplate => {
                labos = labos.concat(
                    selectedTemplate.resultItems.map(item => {
                        return ({ procedure: { conceptId: item.conceptId, description: item.description } });
                    })
                );
            }
            );
            setFieldValue("orders", labos.sort(function (a, b) { return a.order - b.order }));
        }
        else
            setFieldValue("orders", null);
    }

    const handleSelectGeneralReason = (_name, selectedReason) => {

        let ordersCopy = JSON.parse(JSON.stringify(values.orders));
        ordersCopy.forEach(order => {
            //order.reasonId = selectedReason.id;
            order.reason = selectedReason;
        });
        setFieldValue("orders", ordersCopy);
    }

    if (!values.orders) {
        values.orders = [emptyResult];
    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off">

            <Row>
                <Col md={3}>
                    <ControlLabel>{t("medicalRecords.orders.orderDate")}</ControlLabel>
                    <CustomDate
                        value={values.orderDate ?? new Date()}
                        name={`orderDate`}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        closeOnSelect={true}
                        dateFormat="DD/MM/YYYY"
                        inputProps={{ placeholder: "DD/MM/YYYY" }}
                    />
                    <ErrorMessage name={`orderDate`} component={FormErrorMessage} />
                </Col>
                <Col md={3}>
                    <ControlLabel>{t("medicalRecords.orders.patientMedicalInsurance")}</ControlLabel>
                    <FormControl type="text" name={`medicalInsurance`} value={values.medicalInsurance} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                    <ErrorMessage name={`medicalInsurance`} component={FormErrorMessage} />
                </Col>
                <Col md={3}>
                    <ControlLabel>Plan</ControlLabel>
                    <FormControl type="text" name={`medicalInsurancePlan`} value={values.medicalInsurancePlan} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                    <ErrorMessage name={`medicalInsurancePlan`} component={FormErrorMessage} />
                </Col>
                <Col md={3}>
                    <ControlLabel>Número de Afiliado</ControlLabel>
                    <FormControl type="text" name={`medicalInsuranceNumber`} value={values.medicalInsuranceNumber} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                    <ErrorMessage name={`medicalInsuranceNumber`} component={FormErrorMessage} />
                </Col>
            </Row>
            <br />
            <Row>
                <Col md={6}>
                    <ControlLabel>{t("medicalRecords.orders.templates")}</ControlLabel>
                    <CustomSelect
                        onChange={handleSelectTemplate}
                        name={"templates"}
                        placeholder={t("commons.selectTemplate")}
                        options={laboData.templates}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isClearable={true}
                        isMulti={true}
                    />
                </Col>
                {
                    values.orders.length > 0 &&
                    <Col md={6}>
                        <ControlLabel>{t("medicalRecords.orders.reason")}</ControlLabel>
                        <CustomSelect
                            defaultValue={findReasonInEventList(values.orders[0].reasonId, events)}
                            name={`montotoOrder`}
                            placeholder={t("commons.select")}
                            onChange={handleSelectGeneralReason}
                            options={events}
                            getOptionLabel={(option) => option.adverseEffect}
                            getOptionValue={(option) => option.id}
                            formatGroupLabel={formatGroupLabel}
                        />
                    </Col>
                }
            </Row>
            <br />
            <FieldArray
                name="orders"
                render={arrayHelper => (
                    <>
                        <Row className="table-row-header">
                            <Col md={3}>
                                {t("medicalRecords.drugs.reason")}
                            </Col>
                            <Col md={8}>
                                {t("medicalRecords.procedures.procedure")}
                            </Col>
                        </Row>
                        {
                            values.orders && values.orders.length > 0 ? (
                                values.orders.map((order, index) => (
                                    <Row className="table-row-body display-flex" key={index}>
                                        <Col md={3}>
                                            {
                                                getReasonTitle(order.reason) ??
                                                <CustomSelect
                                                    //defaultValue={findReasonInEventList(order.reasonId, events)}
                                                    value={order.reason ?? findReasonInEventList(order.reasonId, events)}
                                                    name={`orders.${index}.reason`}
                                                    placeholder={t("commons.select")}
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    options={events}
                                                    getOptionLabel={(option) => option.adverseEffect}
                                                    getOptionValue={(option) => option.id}
                                                    formatGroupLabel={formatGroupLabel} />
                                            }
                                        </Col>
                                        <Col md={7}>
                                            {
                                                order.procedure ? order.procedure.description :
                                                    <>
                                                        <SnomedPicker
                                                            conceptType={conceptType.procedures}
                                                            autoFocus
                                                            id={`orders.${index}.procedure`}
                                                            selected={order.procedure ? [order.procedure] : []}
                                                            //defaultInputValue={order.procedure ? order.procedure.description : ''}
                                                            name={`orders.${index}.procedure`}
                                                            placeholder={t("commons.search")}
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            conceptId={snomedExpressions.Procedimiento} />
                                                        <ErrorMessage name={`orders.${index}.procedure`} component={FormErrorMessage} />
                                                    </>
                                            }
                                        </Col>
                                        <Col md={2}>
                                            <div>
                                                <button
                                                    className="btn-table-actions"
                                                    style={{ verticalAlign: 'middle' }}
                                                    type="button"
                                                    onClick={() => { showNote === index ? setShowNote(null) : setShowNote(index) }}
                                                >
                                                    <Note />
                                                </button>
                                                {
                                                    !props.edit &&
                                                    <button
                                                        className="btn-table-actions btn-remove"
                                                        style={{ verticalAlign: 'middle' }}
                                                        type="button"
                                                        onClick={() => arrayHelper.remove(index)}>
                                                        <Remove />
                                                    </button>
                                                }
                                            </div>
                                        </Col>
                                        {
                                            showNote === index &&
                                            <Col md={12}>
                                                <ControlLabel>{t("medicalRecords.orders.indications")}</ControlLabel>
                                                <FormControl type="text" name={`orders.${index}.notes`} value={order.notes} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                            </Col>
                                        }
                                        <hr />
                                    </Row>
                                ))
                            ) : ""}
                        <Row style={{ marginTop: '20px' }}>
                            <Col md={4}><button type="button" className="btn btn-default" onClick={() => {
                                emptyResult.order = values.orders.length + 1;
                                arrayHelper.push(emptyResult)
                            }}>{t("medicalRecords.orders.addProcedure")}</button></Col>
                            <Col md={8}></Col>
                        </Row>
                    </>
                )
                }
            />
            <br />

            <button type="button" className="btn btn-default" onClick={props.onCancel} disabled={isSubmitting} > {t("buttons.cancel")}</button >
            {
                values.orders && values.orders.length > 0 &&
                <button type="submit" className="btn btn-success btn-right" disabled={isSubmitting}>{t("buttons.save")}</button>
            }

            < div className="clearfix" ></div >
        </form >
    );
}