import { useState, useEffect } from "react";
import { getRoleMasterData, getRoles } from "../../../api/security";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";

export const useRoleMasterData = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        let isSubscribed = true;
        const fetchData = () => {
            setIsLoading(true);
            getRoleMasterData().then(response => {
                if (isSubscribed) {
                    setData(response.data);
                    setIsLoading(false);
                }
            }).catch(error => {
                console.log(error);
                setData([]);
                setIsLoading(false);
            });
        };

        fetchData();

        return () => isSubscribed = false;
    }, []);

    return [isLoading, data];
}

export const useRoles = (query) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        let isSubscribed = true;
        const fetchData = () => {
            if (isSubscribed) {
                setIsLoading(true);
                setReload(false);
                getRoles(query).then(response => {

                    if (isSubscribed) {
                        setData(response.data);
                        setIsLoading(false);
                    }
                }).catch(error => {
                    HandleApiError(error);
                    setData([]);
                    setIsLoading(false);
                });
            }
        };

        fetchData();

        return () => isSubscribed = false;
    }, [query, reload]);

    return [data, isLoading, setReload];
}