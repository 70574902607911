import React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Card from '../Cards/Card';
import { RelatedContact } from '../Icons/Icons';

export const RelatedContactData = props => {

    const { t } = useTranslation();
    const patient = props.data;

    let relatedContactsContent = "";

    if (patient.relatedContacts && patient.relatedContacts.length > 0) {

        relatedContactsContent = patient.relatedContacts.map((relatedContact, index) => (
            <div key={`relatedContacts-${index}`} style={{ paddingTop: '10px', paddingBottom: '10px', borderBottom: '1px solid #cececed6' }}>
                <Row>
                    <Col sm={4}>
                        <div className="info">
                            <span className="patient-label">{t("person.relationship")}:</span> <span className="patient-text">{relatedContact.relationship}</span>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="info">
                            <span className="patient-label">{t("person.given")}:</span> <span className="patient-text">{relatedContact.given}</span>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="info">
                            <span className="patient-label">{t("person.familyName")}:</span> <span className="patient-text">{relatedContact.familyName}</span>
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Col sm={4}>
                        <div className="info">
                            <span className="patient-label">{t("person.address.streetName")}:</span> <span className="patient-text">{relatedContact.streetName} {relatedContact.streetNumber}</span>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="info">
                            <span className="patient-label">{t("person.contact.phone")}:</span> <span className="patient-text">{relatedContact.phone}</span>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="info">
                            <span className="patient-label">{t("person.contact.email")}:</span> <span className="patient-text">{relatedContact.email}</span>
                        </div>
                    </Col>
                </Row>
            </div>
        ));
    }

    return (
        <Card
            className="card-address-data"
            content={
                <Grid fluid>
                    <Row className="display-flex">
                        <Col sm={2}>
                            <div className="address-box"><RelatedContact /></div>
                        </Col>
                        <Col sm={10}>
                            {relatedContactsContent}
                        </Col>
                    </Row>
                </Grid>
            }
        />

    );
}