import { withFormik } from "formik";
import React from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FreezerForm } from "./FreezerForm";
import useFreezer from "../useFreezer";
import PropTypes from 'prop-types'
import FreezerService from "../../../api/Freezer/FreezerService";
import { getFreezerSchema } from "../../../components/Validations/FormValidationSchema";
import { ErrorMessage, Success } from "../../../components/Notifications/Notifications";

export default function EditFreezerContainer({ match: { params } }) {
    let history = useHistory();
    const [freezer, isLoading] = useFreezer(params.freezerId ?? "")

    const handleSubmit = () => {
        history.push(`/admin/settings/freezers`);
    }
    const handleCancel = () => {
        history.push(`/admin/settings/freezers`);
    }

    if (!freezer.Id)
        return null;

    return (
        <EditFreezerContainerFormik
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            freezer={freezer}
            loading={isLoading}
        />
    )
}

const EditFreezerContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: ({ freezer }) => ({ freezer }),
    validationSchema: getFreezerSchema(),
    handleSubmit: async (values, formikBag) => {
        try {
            const result = await FreezerService.Update(values.freezer)
            if (result.Succeeded)
                Success("commons.successSave")
            else
                ErrorMessage("errors.serverError")
        } catch (err) {
            ErrorMessage("errors.serverError")
        } finally {
            formikBag.setSubmitting(false);
        }
    },
    displayName: 'FreezerForm'
})(FreezerForm))

EditFreezerContainerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

