import React, { useState } from 'react';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
//Components
import { SettingsForm } from '../../components/Settings/Settings';
import { uploadHeader } from '../../api/settings';
import { Success } from '../../components/Notifications/Notifications';
import Card from '../../components/Cards/Card';
import { useSettings } from './Hooks/useSettings';
import { HandleApiError } from '../../components/Notifications/APIErrorHandler';

export const SettingsContainer = () => {
    const [modal, setModal] = useState(null);
    const [data, isLoading, update] = useSettings();

    const _isValidImageSize = (imageToCheck, width, height) => {
        var _URL = window.URL || window.webkitURL;
        var file, img, isValid = true;
        if ((file = imageToCheck)) {
            img = new Image();
            var objectUrl = _URL.createObjectURL(file);
            img.onload = function () {
                if (this.width != width || this.height != height) {
                    alert(`El tamaño de la imagen debe ser de ${width}x${height}`);
                    isValid = false;
                }
                _URL.revokeObjectURL(objectUrl);
            };
            img.src = objectUrl;
        }

        return isValid;
    }

    const handleFirstMRN = (value) => {
        if (!value)
            return;

        update("FirstMRN", value)
            .then(res => {
                Success("settingsNotifications.settings_Updated");
            }).catch(error => {
                HandleApiError(error);
            });
    }
    const handleAutoClose = (value) => {
        if (!value)
            return;

        update("AutoClose", value)
            .then(res => {
                Success("settingsNotifications.settings_Updated");
            }).catch(error => {
                HandleApiError(error);
            });
    }
    const handleHeader = async (header) => {
        //if (!_isValidImageSize(header.file, 1654, 245))
        //    return;

        // Initial FormData
        const formData = new FormData();
        formData.append("file", header.file);
        formData.append("title", header.fileName);

        // Make an AJAX upload request using Axios
        try {
            const res = await uploadHeader(formData, "header");
            Success("settingsNotifications.settings_Uploaded");
        }
        catch (error) {
            console.log(error.response);
        }
    }
    const handleFooter = async (footer) => {
        //if (!_isValidImageSize(footer.file, 1654, 90))
        //    return;

        // Initial FormData
        const formData = new FormData();
        formData.append("file", footer.file);
        formData.append("title", footer.fileName);

        // Make an AJAX upload request using Axios
        try {
            const res = await uploadHeader(formData, "footer");
            Success("settingsNotifications.settings_Uploaded");
        }
        catch (error) {
            console.log(error.response);
        }
    }

    return (
        <>
            {modal}
            <Card
                tabs
                content={
                    <SettingsFormik
                        onAutoClose={handleAutoClose}
                        onFirstMRN={handleFirstMRN}
                        onHeader={handleHeader}
                        onFooter={handleFooter}
                        settings={data}
                        isLoading={isLoading}
                    />
                }
            />

        </>
    );
}

const SettingsFormik = withTranslation()(withFormik({

    mapPropsToValues: (props) => (props),
    displayName: 'SettingsForm',

})(SettingsForm));

SettingsFormik.propTypes = {
    onAutoClose: PropTypes.func.isRequired,
    onFirstMRN: PropTypes.func.isRequired,
    onHeader: PropTypes.func.isRequired,
    onFooter: PropTypes.func.isRequired
};