import PropTypes from 'prop-types'
import React from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { useTranslation } from 'react-i18next'

export const ConfirmRemove = (props) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <SweetAlert
                warning
                title={t("commons.confirmRemove")}
                onConfirm={() => props.onConfirm(props.id)}
                onCancel={() => { props.onClose() }}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="info"
                confirmBtnText={t("buttons.yes")}
                cancelBtnText={t("buttons.no")}
                showCancel
            >
            </SweetAlert>
        </React.Fragment>
    );
}

ConfirmRemove.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired
};

export const RejectExternalNote = (props) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <SweetAlert
                input
                inputType="textarea"
                placeholder="Ingrese motivo de rechazo..."
                required
                validationMsg="Debe ingresar un motivo"
                title="¿Está seguro que desea rechazar el comentario?"
                onConfirm={(value) => props.onConfirm(props.id, props.status, value)}
                onCancel={() => { props.onClose() }}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="info"
                confirmBtnText={t("buttons.yes")}
                cancelBtnText={t("buttons.cancel")}
                showCancel
            >
            </SweetAlert>
        </React.Fragment>
    );
}

RejectExternalNote.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    status: PropTypes.number.isRequired
};