import React from 'react';
import { NavLink } from 'react-router-dom';
import '../../assets/sass/lbd/_error-pages.scss';

const NotFoundPage = () => (
    <div className="error-container">
        <h1>404</h1>
        <h2>Recurso no encontrado</h2>
        <p>El recurso al que quiere acceder no ha sido encontrado.</p>
        <NavLink to="/admin/dashboard">&larr; Volver</NavLink>
    </div>
);

export default NotFoundPage;
