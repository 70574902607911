import React from "react";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'

export const YesNoNotApply = (props) => {

    const { t } = useTranslation();
    const handleClick = event => {
        switch (event.currentTarget.name) {
            case "selected_yes":
                props.onChange(props.name, "yes");
                break;
            case "selected_no":
                props.onChange(props.name, "no");
                break;
            case "selected_na":
                props.onChange(props.name, "n/a");
                break;
            default:
                props.onChange(props.name, null);
                break;
        }
    };

    return (
        <>
            <div className="btn-group">
                <button type="button" disabled={props.disabled} className={"btn " + (props.value === "yes" ? "btn-primary" : "btn-default")} name="selected_yes" onClick={handleClick}>{t('commons.yes')}</button>
                <button type="button" disabled={props.disabled} className={"btn " + (props.value === "no" ? "btn-primary" : "btn-default")} name="selected_no" onClick={handleClick}>{t('commons.no')}</button>
                <button type="button" disabled={props.disabled} className={"btn " + (props.value === "n/a" ? "btn-primary" : "btn-default")} name="selected_na" onClick={handleClick}>{t('commons.na')}</button>
            </div>
            {
                props.isClearable  && !props.disabled ?
                    <a role="button" disabled={props.disabled} onClick={handleClick} name="selected_clear" style={{ marginLeft: '20px' }}>{t('commons.clear')}</a>
                    : null
            }
        </>
    );
}

YesNoNotApply.propTypes = {
    name: PropTypes.string.isRequired,
    isClearable: PropTypes.bool,
    disabled: PropTypes.bool,
};

export const YesNo = (props) => {

    const { t } = useTranslation();
    const handleClick = event => {
        switch (event.currentTarget.name) {
            case "selected_yes":
                props.onChange(props.name, true);
                break;
            case "selected_no":
                props.onChange(props.name, false);
                break;
            default:
                props.onChange(props.name, null);
                break;
        }
    };

    return (
        <>
            <div className="btn-group">
                <button type="button" disabled={props.disabled} className={"btn " + (props.value === true ? "btn-primary" : "btn-default")} name="selected_yes" onClick={handleClick}>{t('commons.yes')}</button>
                <button type="button" disabled={props.disabled} className={"btn " + (props.value === false ? "btn-primary" : "btn-default")} name="selected_no" onClick={handleClick}>{t('commons.no')}</button>
            </div>
            {
                props.isClearable  && !props.disabled ?
                    <a role="button" disabled={props.disabled} onClick={handleClick} name="selected_clear" style={{ marginLeft: '20px' }}>{t('commons.clear')}</a>
                    : null
            }
        </>
    );
}

YesNo.propTypes = {
    name: PropTypes.string.isRequired,
    isClearable: PropTypes.bool,
    disabled: PropTypes.bool,
};