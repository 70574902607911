import React from 'react'
import { toast } from 'react-toastify';
import i18n from 'i18next';

const ErrorMsg = ({ message }) => (
    <div>
        <span className="badge"><i className="pe-7s-attention rbc-event-confirmed"></i></span> {message}
    </div>
)

const ErrorMsgWithInfo = ({ message, info }) => (
    <div>
        <span className="badge"><i className="pe-7s-attention rbc-event-confirmed"></i></span> {message}: {info}
    </div>
)

const ErrorMsgWithArrayInfo = ({ message, arrayInfo }) => (
    <div>
        <span className="badge"><i className="pe-7s-attention rbc-event-confirmed"></i></span> {message}:
        <ul>
            {arrayInfo.map(item => <li>{i18n.t(item)}</li>)}
        </ul>
    </div>
)

const hashCode = (content) => {
    var hash = 0, i, chr;
    if (content.length === 0) return hash;
    for (i = 0; i < content.length; i++) {
        chr = content.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash.toString();
};

const getErrorMessage = (error) => {
    let errorMessage = "";
    if (error === undefined || error.response === undefined || error.response.data === undefined) {
        errorMessage = i18n.t("errors.serverError");
    }
    else
        if (error.response.status === 500) {
            errorMessage = i18n.t("errors.serverError");
        } else
            if (error.response.data.Error) {
                console.log(error.response.data.Error);
                console.log(error.response.data.StatusCode);
                errorMessage = i18n.t(error.response.data.Error);
            } else
                if (error.response.data.error) {
                    console.log(error.response.data.error);
                    console.log(error.response.data.statusCode);
                    errorMessage = i18n.t(error.response.data.error);
                } else
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ) {
                        let reader = new FileReader();
                        reader.onload = () => {

                            const errorContent = JSON.parse(reader.result);
                            errorMessage = i18n.t(errorContent.Error || errorContent.error);

                            toast.error(<ErrorMsg message={errorMessage} />, {
                                position: toast.POSITION.TOP_RIGHT,
                                toastId: hashCode(errorMessage)
                            });
                        };
                        reader.readAsText(error.response.data);
                        return null;
                    };
    return errorMessage;
}

export function HandleApiError(error) {

    let errorMessage = getErrorMessage(error);
    if (!errorMessage)
        return;
    toast.error(<ErrorMsg message={errorMessage} />, {
        position: toast.POSITION.TOP_RIGHT,
        toastId: hashCode(errorMessage)
    });
}

export function HandleApiErrorWithInfo(error, infoMessage, info) {

    let errorMessage = getErrorMessage(error);
    if (!errorMessage)
        return;
    toast.error(<ErrorMsgWithInfo message={i18n.t(errorMessage)} info={`${i18n.t(infoMessage)} ${info}`} />);
}

export function HandleError(error) {
    toast.error(<ErrorMsg message={i18n.t(error)} />, {
        position: toast.POSITION.TOP_RIGHT
    });
}

export function HandleMessageError(errorMessage, errorInfo) {

    toast.error(<ErrorMsgWithInfo message={i18n.t(errorMessage)} info={errorInfo} />);
}

export function HandleMessagesError(errorMessage, errorsInfo) {
    toast.error(<ErrorMsgWithArrayInfo message={i18n.t(errorMessage)} arrayInfo={errorsInfo} />);
}