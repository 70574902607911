import i18n from '../i18n';

const getLocalizedEnum = (prefix, montoto) => {
    if (!montoto)
        return "";

    return montoto.map(x => { x.title = i18n.t(`${prefix}.${x.code?.toLowerCase()}`); return x; })
}

export const bloodGroup = [
    { id: 1, title: "A" },
    { id: 2, title: "B" },
    { id: 3, title: "AB" },
    { id: 4, title: "O" }

]
export const bloodFactor = [
    { id: 1, title: "Rh+" },
    { id: 2, title: "Rh-" },
]

export const language = [
    { code: "es", title: "Español" },
    { code: "en", title: "Ingles" },
    { code: "pt", title: "Portugués" },
]

export const typeOptions = getLocalizedEnum("medicalRecords.typeOptions", [
    { id: 1, title: 'Consultorios Externos', code: "EXTERNAL" },
    { id: 2, title: 'Otros', code: "OTHER" },
    { id: 3, title: 'Investigación', code: "PROTOCOL" },
    { id: 4, title: 'Localización', code: "LOCALIZATION" }
    //{ id: 4, title: 'Proyectos', code: "PROJECT" }
])

export const visitTypesEnum = getLocalizedEnum("calendar.visitTypes", [
    { code: "PROTOCOL", title: 'Investigación' },
    { code: "PROJECT", title: 'Proyecto' },
    { code: "EXTERNAL", title: 'Consultorio Externo (Presencial)' },
    { code: "EXTERNAL_PHONE", title: 'Consultorio Externo (Teleconsulta)' },
    { code: "STUDIES", title: 'Estudios' },
    { code: "ESSENTIA", title: 'Consultorio Programa Essentia' },
    { code: "ORDER", title: 'Entrega de Receta' },
    { code: "OTHER", title: 'Otros' },
    { code: "PRESCREENING", title: 'PreScreening Investigación' },
    { code: "OVERSHIFT", title: 'Sobreturno' }
])
/*
export const eventOptions = getLocalizedEnum([
    { id: 1, title: 'Historial', code: "history" },
    { id: 2, title: 'Cancelados', code: "cancel" },
    { id: 3, title: 'Atendidos', code: "present" },
])
export const relatives = getLocalizedEnum([
    { id: 1, title: 'Madre' },
    { id: 2, title: 'Padre' },
    { id: 3, title: 'Hermano' },
    { id: 4, title: 'Abuelos' },
    { id: 5, title: 'Otros' },
])
export const routeOfAdministrations = getLocalizedEnum([
    { id: 1, title: 'Oral' },
    { id: 2, title: 'Sublingual' },
    { id: 3, title: 'Tópica' },
    { id: 4, title: 'Transdérmica' },
    { id: 5, title: 'Oftalmológica' },
    { id: 6, title: 'Inhalatoria' },
    { id: 7, title: 'Rectal' },
    { id: 8, title: 'Vaginal' },
    { id: 9, title: 'Parental' }
])
export const intensity = getLocalizedEnum([
    { id: 1, title: 'Leve' },
    { id: 2, title: 'Moderado' },
    { id: 3, title: 'Severo' }
])*/

export const units = [
    { id: 1, title: 'mg' },
    { id: 2, title: 'mL' },
    { id: 3, title: 'ug' },
    { id: 4, title: 'g' },
    { id: 5, title: 'U' },
    { id: 6, title: 'Other' }
]

export const snomedExpressions = {
    MotivoConsulta: "< 71388002 OR < 243796009 OR < 272379006 OR < 404684003", // "< 404684003",// 
    Diagnostico: "< 243796009 OR < 272379006 OR < 404684003", //"< 404684003",// "< 243796009 OR < 272379006 OR < 404684003 MINUS (<< 49632008 OR 198863002 OR 198756005 OR 785872000 OR 785869007)",
    Hallazgos: "404684003",
    Medicamentos: "781405001", // Producto, por ejemplo, Amoxidal, Ibupirac.
    Sustancia: "105590001", // Por ejemplo: Ibuprofeno, Amoxicilina. 
    Producto_O_Sustancia: "< 781405001 OR < 105590001",
    ViaAdministracion: "736479009",
    Procedimiento: "71388002",
    Laboratorio: "122869004" //   procedimiento de medición (procedimiento) 122869004
}

export const hcVisitStatus = {
    Draft: "Draft",
    Saved: "Saved",
    Published: "Published",
    AutoPublished: "AutoPublished"
}

export const visitStatus = {
    Started: 0,
    Completed: 1,
    Skipped: 2,
    Failed: 3,
    Changed: 4,
    AutoCompleted: 5
}

export const formStatus = {
    Initial: 0,
    Saved: 1,
    Draft: 2,
    Published: 9
}

export const visitTypes = {
    ProtocolVisit: 0,
    ExtraProtocolVisit: 1,
    Note: 2,
    EarlyCompletion: 3
}

export const patientInProtocolStatus = {
    Screening: 0,
    Random: 1,
    Treatment: 2,
    FollowUp: 3,
    Failed: 4,
    LostToFollowUp: 5,
    WithoutDrugs: 6,
    Discontinuation: 7,
    EndParticipationInProtocol: 8
}

export const visitStage = {
    Screening: 0,
    Random: 1,
    Treatment: 2,
    FollowUp: 3
}

export const visitTypeEnum = {
    OnSite: 0,
    Remote: 1
}

export const visitOffsetType = {
    Days: 0,
    Weeks: 1,
    Months: 2
}

export const noteEnum = {
    Initial: 0,
    Fixed: 1,
    Invalid: 2
}

export const formTypes = [
    { label: "NoSync", value: "NoSync" },
    { label: "Anthropometrics", value: "Anthropometrics" },
    { label: "Vitals", value: "Vitals" },
    { label: "AdverseEvents", value: "AdverseEvents" },
    { label: "Drugs", value: "Drugs" },
    { label: "Procedures", value: "Procedures" },
    { label: "LaboratoryResults", value: "LaboratoryResults" },
    { label: "Habits", value: "Habits" },
]

export const formTypeFields = [
    {
        type: "Anthropometrics",
        fields: [
            { value: "Weight", fieldType: "DecimalField" },
            { value: "Height", fieldType: "DecimalField" },
            { value: "Waist", fieldType: "DecimalField" },
            { value: "Sagittal", fieldType: "DecimalField" },
            { value: "Neck", fieldType: "DecimalField" },
            { value: "Others", fieldType: "SingleLineField" },
            { value: "Notes", fieldType: "MultiLineField" }
        ]
    },
    {
        type: "Vitals",
        fields: [
            { value: "BloodPressureHigh", fieldType: "IntegerField" },
            { value: "BloodPressureLow", fieldType: "IntegerField" },
            { value: "HeartRate", fieldType: "SingleLineField" },
            { value: "RespiratoryRate", fieldType: "SingleLineField" },
            { value: "TemperatureC", fieldType: "DecimalField" },
            { value: "OxygenSaturation", fieldType: "DecimalField" },
            { value: "Notes", fieldType: "MultiLineField" }
        ]
    },
    {
        type: "AdverseEvents",
        fields: [
            { value: "AdverseEvent", fieldType: "SnomedField" },
            { value: "Intensity", fieldType: "SelectAsyncField" },
            { value: "Serious", fieldType: "BooleanField" },
            { value: "Reported", fieldType: "BooleanField" },
            { value: "Relationwithdrug", fieldType: "SelectAsyncField" },
            { value: "Action", fieldType: "SelectAsyncField" },
            { value: "From", fieldType: "DateField" },
            { value: "To", fieldType: "DateField" },
            { value: "Notes", fieldType: "MultiLineField" }
        ]
    },
    {
        type: "Drugs",
        fields: [
            { value: "Drug", fieldType: "SnomedField" },
            { value: "RouteOfAdministration", fieldType: "SelectAsyncField" },
            { value: "Reason", fieldType: "AdverseEventsAsyncField" },
            { value: "Dose", fieldType: "IntegerField" },
            { value: "Unit", fieldType: "SelectAsyncField" },
            { value: "Frequency", fieldType: "SelectAsyncField" },
            { value: "From", fieldType: "DateField" },
            { value: "To", fieldType: "DateField" },
            { value: "Notes", fieldType: "MultiLineField" }
        ]
    },
    {
        type: "Procedures",
        fields: [
            { value: "Procedure", fieldType: "ProcedureSnomedField" },
            //{ value: "Procedure", fieldType: "SnomedField" },
            { value: "Reason", fieldType: "AdverseEventsAsyncField" },
            { value: "From", fieldType: "DateField" },
            { value: "To", fieldType: "DateField" },
            { value: "Notes", fieldType: "MultiLineField" }
        ]
    }
]

export const conditions = {
    observation: "problem-list-item",
    diagnosis: "encounter-diagnosis",
    adverseEvent: "adverse-event",
    personalHistory: "personal-history"
}

export const conceptType = {
    drugs: "Drugs",
    procedures: "Procedures",
    events: "Events",
    prescriptions: "Prescriptions",
    orders: "Orders"
}

export const interopSystemEnum = {
    SnomedCT: 0,
    LOINC: 10,
    ICD10: 20,
    ICD11: 21,
    BR_CMED: 30,
    BR_CBHPM: 31,
    VADEMECUM: 41
}
/*
module.exports = {
    conditions,
    formTypes,
    formTypeFields,
    //bloodGroup,
    //bloodFactor,
    typeOptions,
    eventOptions,
    relatives,
    routeOfAdministrations,
    intensity,
    units,
    snomedExpressions,
    hcVisitStatus,
    formStatus,
    visitStatus,
    visitTypes,
    visitOffsetType,
    visitStage,
    patientInProtocolStatus,
    noteEnum,
    visitTypesEnum,
    visitTypeEnum
};*/