export function WeatherMessage({ deviceId, freezerId, humidity, temperature, measureDate, uploadDate }) {
    this.DeviceId = deviceId;
    this.FreezerId = freezerId;
    this.Humidity = Number.parseFloat(humidity);
    this.Temperature = Number.parseFloat(temperature);
    this.MeasureDate = new Date(measureDate);
    this.UploadDate = new Date(uploadDate);
}

export function DoorMessage({ deviceId, freezerId, measureDate, state, uploadDate }) {
    this.DeviceId = deviceId;
    this.FreezerId = freezerId;
    this.State = state;
    this.MeasureDate = new Date(measureDate);
    this.UploadDate = new Date(uploadDate);
}
