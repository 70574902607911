import { useState, useEffect } from "react";
import { getStudyRoles } from "../../../api/security";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";

export const useStudyRoles = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        let isSubscribed = true;
        const fetchData = () => {
            setIsLoading(true);
            setReload(false);
            getStudyRoles().then(response => {
                if (isSubscribed) {
                    setData(response.data);
                    setIsLoading(false);
                }
            }).catch(error => {
                if (isSubscribed) {
                    setData([]);
                    setIsLoading(false);
                    HandleApiError(error);
                }
            });
        };

        fetchData();

        return () => isSubscribed = false;
    }, [reload]);

    return [data, isLoading, setReload];
}