import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Card from "../../../components/Cards/Card";
import { Col, Row, Grid, Modal, Button } from 'react-bootstrap';
import useIotDevice from "../useIotDevice";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/Common/Loader";
import moment from "moment";
import { DocumentViewer } from "../../../components/CustomControls/DocViewer/DocumentViewer";
import useLoading from "../../../components/Common/useLoading";
import { useModalContext } from "../../../components/Modals/useModalContext";
import DeviceService from "../../../api/Freezer/DeviceService";
import { ErrorMessage, Success } from "../../../components/Notifications/Notifications";
import { Field } from "../../../components/Common/Field/Field";

export default function DeviceDetails({ match: { params } }) {
    const history = useHistory();
    const { t } = useTranslation();
    const [device, isLoading, reload, getCertificate] = useIotDevice(params.deviceId ?? 0)

    const [certificate, setCertificate] = useState(null)
    const [modalContext, openModal] = useModalContext({
        handleEvent: (e) => {
            reload()
        }
    })

    const handleCancel = () => {
        history.push(`/admin/settings/devices`);
    }

    const handleCertificate = async () => {
        openModal(device.Id)
    }

    return (
        <>
            <Card className="card-patient" content={<>
                <Loader isLoading={isLoading}>
                    <Row>
                        <Col sm={12} md={6}>
                            <Row>
                                <Col sm={12}>
                                    <div className="text-center">
                                        <label className="info-title info-title-lg">{t('settings.device.detail.name')} - <strong>{device.Name}</strong></label>
                                    </div>
                                    <div className="info">
                                        <Field title={t('settings.device.detail.createAt')} value={moment(device.CreateAt).format('DD-MM-YYYY')} />
                                        <Field title={t('settings.device.detail.state')} value={t(`settings.device.states.${device.State.Name}`)} />
                                        <Field title={t('commons.certificate')} value={device.CertificatePath != null ? t('commons.uploaded') : '-'} />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} md={6}>
                            <div style={{ marginBottom: ".5rem" }}>
                                <button className="btn btn-success" type="button" onClick={handleCertificate}>{t('settings.device.detail.uploadCertificate')}</button>
                            </div>
                        </Col>
                    </Row>

                    <Grid fluid>
                        <Row>
                            <Col sm={1} smOffset={11}>
                                <button className="btn btn-secondary" type="button" onClick={handleCancel}>{t('buttons.back')}</button>
                            </Col>
                        </Row>
                    </Grid>
                </Loader>
            </>} />
            <DocumentViewer
                file={certificate?.file}
                fileName={certificate?.fileName}
                inModal={true}
            />
            <UploadCertificateModal state={modalContext} />
        </>)
}

function UploadCertificateModal({ state: { show, value, onClose, onEvent } }) {
    const [certificate, setCertificate] = useState(null)
    const [isLoading, withLoad] = useLoading()
    const { t } = useTranslation();
    const onHandleUpload = (e) => withLoad(async () => {
        try {
            const file = certificate.files[0]
            const result = await DeviceService.UploadCertificate(value, file)
            if (result.Succeeded)
                Success(t('commons.fileUploaded'))
            else
                ErrorMessage(t('errors.serverError'))
        } catch (err) {
            ErrorMessage(t('errors.serverError'))
        } finally {
            onEvent("reload")
            onClose()
        }
    });

    const setFile = (e) => {
        console.log(e)
        setCertificate(e.target)
    }

    if (!show) return null

    return (<>
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title >{t('commons.certificate')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ marginBottom: ".5rem" }}>
                    <input className="form-control" type="file" onChange={setFile} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>{t('buttons.close')}</Button>
                <button type="button" className="btn btn-success btn-right" disabled={isLoading} onClick={onHandleUpload}>{t('buttons.save')}</button>
            </Modal.Footer>
        </Modal>
    </>)
}