import PropTypes from 'prop-types';
import React from 'react';
import { ExpandableContent } from '../CustomControls/ExpandableContent';
import { RichTextControl } from '../CustomControls/RichTextControl';
import { customDateTimeFormatterString } from '../Table/Formatters';

const Entry = (props) => {
    const {
        entry,
        text,
        onEntryChange,
        rows,
        isDisabled,
        style
    } = props;

    const onChangeText = (_name, value) => {
        onEntryChange(value);
    }

    const getGroup = () => {
        if (!entry)
            return null;

        switch (entry.groupCode) {
            case "PROJECT": return `${entry.group}: ${entry.project} ${customDateTimeFormatterString(entry.created)}`;
            case "PROTOCOL": return `${entry.group} - ${entry.type}: ${entry.protocol} ${customDateTimeFormatterString(entry.created)}`;
            default:
                return `Evolución ${entry.group} ${customDateTimeFormatterString(entry.created)}`;
        }
    }
    
    if (!entry)
        return null;
    
    return (
        <ExpandableContent title={getGroup()} expandedAll={true} content={entry.groupCode.toLowerCase()}>
            <RichTextControl
                name="text"
                style={style}
                value={text ?? ""}
                isDisabled={isDisabled}
                height={250}
                onChange={onChangeText}
            />
        </ExpandableContent>
    );
}

Entry.defaultProps = {
    rows: 4,
    isDisabled: false
};

Entry.propTypes = {
    entry: PropTypes.object.isRequired,
    rows: PropTypes.number,
    isDisabled: PropTypes.bool,
};

export default Entry;

