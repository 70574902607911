import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import Card from '../../components/Cards/Card';
import { CustomSelect } from '../../components/CustomControls/CustomSelect';
import { CustomSearchBox } from '../../components/CustomControls/CustomSearchBox';
import { View } from '../../components/Icons/Icons';
import { NotesEnum } from '../../components/Protocols/Visit/Enums';
import { Tooltip } from "../../components/Utils/Tooltips";
import { dateFormatter } from "../../components/Utils/Formatter";
import { useProtocols } from '../Protocols/Hooks';
import { useExternalNotes } from './useExternalNotes';
import { getFullNameFromUserMention } from '../../components/Utils/Commons';

export const ExternalNotesContainer = () => {
    const { t } = useTranslation();
    const [selectedProtocol, setSelectedProtocol] = useState(null);
    const [data, query, totalSize, isLoading, handleTableChange, handleFilterChange] = useExternalNotes(selectedProtocol);
    const [protocols, , , isLoadingProtocols] = useProtocols({
        pagination: {
            page: 1,
            sizePerPage: 100,
            totalSize: 0
        },
        sort: {
            sortField: "Created",
            sortOrder: "desc"
        },
        filters: {}
    });

    const columns = [
        { dataField: 'note', text: t("notes.externalNotes.note"), formatter: function (cell) { return getFullNameFromUserMention(cell) } },
        { dataField: 'medicalRecordNumber', sort: true, text: t("notes.externalNotes.patientNumber") },
        { dataField: 'createdBy', sort: true, text: t("notes.externalNotes.createdBy") },
        { dataField: 'created', sort: true, text: t("notes.externalNotes.created"), formatter: function (cell) { return dateFormatter(cell, "DD/MM/YY") }, headerClasses: 'header-date' },
        { dataField: 'responseBy', sort: true, text: t("notes.externalNotes.responseBy") },
        {
            dataField: 'status', sort: true, text: t("notes.externalNotes.status"),
            formatter: function (cell) {
                return NotesEnum().find(x => x.id === cell)?.title;
            }
        },

        {
            dataField: 'view',
            text: t("commons.actions"),
            isDummyField: true,
            headerClasses: 'header-visit-actions',
            classes: 'row-visit-actions',
            formatter: function (_cell, row, rowIndex) {
                return (
                    <div key={rowIndex}>
                        <Tooltip tooltip="Ver Visita" id={"tooltipconfig-" + row.crossVersionId}>
                            <NavLink to={`/admin/protocols/${row.protocolId}/${row.type ?? "visitTracking"}/${row.visitId}/patient/${row.medicalRecordNumber}?&formId=${row.documentId}`} className="btn-table-actions" activeClassName="active">
                                <View />
                            </NavLink>
                        </Tooltip>
                    </div>
                )
            }
        }
    ];

    const expandedContent = (row) => {
        if (row.response)
            return (<div>
                [{dateFormatter(row.responseDate, "DD/MM/YY")}] - {row.response}
            </div>);
    }

    const expandRow = {
        renderer: expandedContent,
        onlyOneExpanding: true,
    };

    return (
        <Card
            header={
                <div className="row">
                    <div className="col-md-2">
                        <CustomSelect
                            name="protocols"
                            placeholder={t('filters.protocol')}
                            isLoading={isLoadingProtocols}
                            isMulti={false}
                            options={protocols}
                            onChange={(_name, selectedProtocol) => setSelectedProtocol(selectedProtocol.id)}
                        />
                    </div>
                    <div className="col-md-2">
                        <CustomSelect
                            name="status"
                            placeholder={t('notes.externalNotes.status')}
                            onChange={handleFilterChange}
                            isMulti={false}
                            isClearable={true}
                            options={NotesEnum()} />
                    </div>
                    <div className="col-md-2">
                        <CustomSearchBox handleSearch={(value) => handleFilterChange("patientNumber", value)} placeholder={t('protocols.visitTracking.searchByNumber')}></CustomSearchBox>
                    </div>
                </div>
            }
            content={
                <BootstrapTable
                    classes="table-spaced table-spaced-small"
                    wrapperClasses="table-responsive"
                    bordered={false}
                    hover
                    remote
                    keyField='id'
                    data={data}
                    columns={columns}
                    noDataIndication={() => <>{t("commons.noData")}</>}
                    loading={isLoading}
                    overlay={overlayFactory({ spinner: true })}
                    pagination={paginationFactory({
                        page: query.pagination.page,
                        sizePerPage: query.pagination.sizePerPage,
                        totalSize: totalSize,
                        hideSizePerPage: true,
                    })}
                    expandRow={expandRow}

                    onTableChange={handleTableChange}
                />
            }
        />
    );
}