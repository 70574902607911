import React from "react";
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Card from "../Cards/Card";

export const MedicalData = props => {

    const { t } = useTranslation();
    const patient = props.data;
    return (
        <Card
            className="card-medical-data"
            content={
                <Row>
                    <Col sm={6}>
                        <Row>
                            <Col sm={6}>
                                <div className="blood-container">
                                    <div className="group">{t("person.bloodGroup")}</div>
                                    <div className="blood-box">{patient.bloodGroup}</div>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="blood-container">
                                    <div className="group">{t("person.bloodFactor")}</div>
                                    <div className="blood-box">{patient.bloodFactor}</div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={6}>
                        <div className="info">
                            <div><span className="patient-label">{t('person.medicalRecord')}:</span> <span className="patient-text">{patient.medicalRecordNumber}</span></div>
                            <div><span className="patient-label">{t('person.medicalInsuranceInfo.medicalInsurance')}:</span> <span className="patient-text">{patient.medicalInsuranceInfo?.medicalInsurance?.title} ({patient.medicalInsuranceInfo?.medicalInsurancePlan?.title})</span></div>
                            <div><span className="patient-label">{t('person.medicalInsuranceInfo.medicalInsuranceNumber')}:</span> <span className="patient-text">{patient.medicalInsuranceInfo?.medicalInsuranceNumber}</span></div>
                        </div>
                        <div className="info">
                            <div><span className="patient-label">{t('person.familyDoctor')}:</span> <span className="patient-text">{patient.familyDoctor}</span></div>
                            <div><span className="patient-label">{t('person.internalPractitioner')}:</span> <span className="patient-text">{patient.internalPractitioner?.fullName}</span></div>
                        </div>
                    </Col>
                </Row>
            }
        />
    );
}