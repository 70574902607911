import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types'
import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';

import { HandleApiError } from '../../components/Notifications/APIErrorHandler';
import { Success } from '../../components/Notifications/Notifications';
import { PatientForm } from "../../components/Database/Patient";
import { useUsers } from "../Users/Hooks";
import { useMasterData, usePatient } from "./Hooks";
import { getPatientSchema } from "../../components/Validations/FormValidationSchema";
import { updatePatient } from "../../api/database";

export const EditPatientContainer = (props) => {
    const { match: { params } } = props;
    const medicalRecordNumber = params.medicalRecordNumber;
    let history = useHistory();

    const [patient, isLoadingPatient] = usePatient(medicalRecordNumber);
    const [practitioners, isLoadingPractitioners] = useUsers();
    const [masterData, isLoadingMasterdata] = useMasterData();

    if (isLoadingPractitioners || isLoadingMasterdata || isLoadingPatient) return null;

    return (
        <EditPatientFormik
            onSubmit={() => history.push(`/admin/database`)}
            onCancel={() => history.push(`/admin/database`)}
            form="edit"
            data={patient}
            masterData={masterData}
            practitioners={practitioners}
        />
    );
}

const EditPatientFormik = withTranslation()(withFormik({

    enableReinitialize: true,
    mapPropsToValues: (props) => (props.data),

    validationSchema: getPatientSchema(),

    handleSubmit: (patient, formikBag) => {
        //console.log(patient);

        var patientCopy = {
            ...patient,
            bloodGroup: patient.bloodGroup != null ? patient.bloodGroup.title : "",
            bloodFactor: patient.bloodFactor != null ? patient.bloodFactor.title : ""
        }

        updatePatient(patientCopy).then(res => {
            formikBag.setSubmitting(false);
            Success("databaseNotifications.Database_PatientUpdated");
            formikBag.props.onSubmit();
        }).catch(error => {
            console.log(error);
            formikBag.setSubmitting(false);
            HandleApiError(error);
        });
    },

    displayName: 'PatientForm',

})(PatientForm));

EditPatientFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    masterData: PropTypes.object.isRequired,
    practitioners: PropTypes.array.isRequired
};