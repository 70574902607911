import moment from "moment";
import React from "react";
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import profilePic from "../../assets/img/faces/face-0.jpg";
import Card from "../Cards/Card";
import { CustomImage } from "../CustomControls/Image";

export const PersonalData = props => {
    const patient = props.data;
    const { t } = useTranslation();

    const ageComponent = patient.age ? <>({patient.age} {t('person.age')})</> : null;
    const date = patient.birthPlace ? moment(patient.birthDate).format('DD/MM/YYYY') : "-";
    const id = patient.identificationType ? <div><span className="patient-label">{patient.identificationType?.title}:</span> <span className="patient-text">{patient.identificationNumber}</span></div> : null;
    const medicalRecord = patient.medicalRecordNumber ? <>(HC:{patient.medicalRecordNumber})</> : null;

    return (
        <Card
            className="card-patient"
            content={
                <Row>
                    <Col sm={3}>
                        <div className="patient-avatar">
                            <CustomImage
                                src={patient.photo ?? profilePic}
                                defaultSrc={profilePic}
                            />
                        </div>
                    </Col>
                    <Col sm={9}>
                        <Row>
                            <div className="info">
                                <div className="name">{patient.fullName} {medicalRecord} </div>
                            </div>
                        </Row>
                        <Row>
                            <Col sm={6} style={{ paddingLeft: 0 }}>
                                <div className="info">
                                    <div><span className="patient-label">{t('person.birthDate')}:</span> <span className="patient-text">{date} {ageComponent}</span></div>
                                    {id}
                                    <div><span className="patient-label">{t('person.gender')}:</span> <span className="patient-text">{patient.gender?.title}</span></div>
                                    <div><span className="patient-label">{t('person.maritalStatus')}:</span> <span className="patient-text">{patient.maritalStatus?.title}</span></div>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="info">
                                    <div><span className="patient-label">{t('person.birthPlace')}:</span> <span className="patient-text">{patient.birthPlace}</span></div>
                                    <div><span className="patient-label">{t('person.nationality')}:</span> <span className="patient-text">{patient.nationality}</span></div>
                                    {/* <div><span className="patient-label">{t("person.languageTitle")}:</span> <span className="patient-text">{patient.language}</span></div> */}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
        />
    );
}