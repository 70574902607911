import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AuditModal } from "../../../components/AuditTrail/AuditModal";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { Laboratory } from "../../../components/MedicalRecords/Laboratory";
import { AuditTrail } from "../../Admin/AuditTrail";
import { useLaboratories } from "../Hooks/useLaboratories";
import { ModalLaboHistory } from "../Modals/ModalLaboHistory";

export const LaboratoryListContainer = ({ medicalRecordNumber, mrn, entryId, expandedAll }) => {
    const { t } = useTranslation();
    const [laboratories, query, totalSize, isLoading, handleTableChange,] = useLaboratories(medicalRecordNumber, entryId, false);

    const [modal, setModal] = useState(null);
    const handleAuditTrail = (entityId) => {
        setModal(
            <AuditModal
                onClose={() => { setModal(null); }}
                title={t("auditTrail.audit")}
            >
                <AuditTrail entity="LaboratoryResult" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }

    const handleLaboratoryResultHistory = (laboratoryId) => {
        setModal(
            <ModalLaboHistory
                onClose={() => { setModal(null); }}
                title={t("medicalRecords.history.history")}
                medicalRecordNumber={medicalRecordNumber || mrn}
                laboratoryId={laboratoryId}
            >
            </ModalLaboHistory>
        )
    }

    if (entryId && totalSize === 0)
        return null;

    return (
        <ExpandableContent title={t("medicalRecords.laboratory.title")} content="laboratory" expandedAll={expandedAll}>
            {modal}
            <Laboratory
                withCard={false}
                data={laboratories}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onAuditTrail={handleAuditTrail}
                onLaboratoryResultHistory={handleLaboratoryResultHistory}
            />
        </ExpandableContent>
    );
}