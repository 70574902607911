import PropTypes from "prop-types";
import React from 'react';
import { Modal as BootstrapModal, Col, Grid, Row } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import { FormAdditional, FormEarlyCompleted, FormExtraProtocol } from '../../../components/Icons/Icons';

const NewExtraForm = ({ protocolId, isShowing, hide }) => {
    const { t } = useTranslation();

    return isShowing ? ReactDOM.createPortal(
        <React.Fragment>
            <BootstrapModal show={true} onHide={hide}>
                <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title>{t("protocols.extraForms.newForm")}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <div className="modal-create-entry">
                        <Grid fluid>
                            <Row className='display-flex'>
                                <Col md={4}>
                                    <div className="create-entry-container">
                                        <NavLink to={`/admin/protocols/${protocolId}/extraforms/create/0`} className="btn-create-entry" activeClassName="active">
                                            <FormAdditional />
                                        </NavLink>
                                        <div className="title-create-entry">{t("protocols.extraForms.extraFormType.0")}</div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="create-entry-container">
                                        <NavLink to={`/admin/protocols/${protocolId}/extraforms/create/1`} className="btn-create-entry" activeClassName="active">
                                            <FormExtraProtocol />
                                        </NavLink>
                                        <div className="title-create-entry">{t("protocols.extraForms.extraFormType.1")}</div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="create-entry-container">
                                        <NavLink to={`/admin/protocols/${protocolId}/extraforms/create/2`} className="btn-create-entry" activeClassName="active">
                                            <FormEarlyCompleted />
                                        </NavLink>
                                        <div className="title-create-entry">{t("protocols.extraForms.extraFormType.2")}</div>
                                    </div>
                                </Col>
                            </Row>
                        </Grid>
                    </div>
                </BootstrapModal.Body>
            </BootstrapModal>
        </React.Fragment>, document.body
    ) : null;
}

NewExtraForm.propTypes = {
    isShowing: PropTypes.bool.isRequired,
    hide: PropTypes.func.isRequired
};

export default NewExtraForm;