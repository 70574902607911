import React from 'react';
import { Col, ControlLabel, FormControl, Grid, Row } from 'react-bootstrap';
import Card from '../Cards/Card';
import { SnomedPicker } from "../CustomControls/SnomedPicker";
import { ExpandableContent } from '../CustomControls/ExpandableContent';

export const ProfessionalDataForm = props => {

    const {
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        t,
    } = props;

    var defaultSelected = [];
    if (values && values.speciality) {
        defaultSelected.push({ conceptId: "", description: values.speciality });
    }

    return (
        <ExpandableContent title={t("person.professionalData")} expandedAll={true} content="">
            <Card
                className="card-patient-edit card-form"
                content={
                    <Grid fluid>
                        <Row>
                            <Col sm={12}>
                                <Row>
                                    <Col sm={2}>
                                        <ControlLabel>{t("person.registrationNumber")}</ControlLabel>
                                        <FormControl type="text" name="registrationNumber" value={values.registrationNumber ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                    </Col>
                                    <Col sm={5}>
                                        <ControlLabel>{t("person.speciality")}</ControlLabel>
                                        <SnomedPicker
                                            defaultSelected={defaultSelected}
                                            id={`speciality`}
                                            name={`speciality`}
                                            placeholder={t("commons.search")}
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            conceptId={"223366009"} //  profesional de la salud (ocupación)
                                        />
                                    </Col>
                                    <Col sm={2}>
                                        <ControlLabel>{t("person.jurisdiction")}</ControlLabel>
                                        <FormControl type="text" name="jurisdiction" value={values.jurisdiction ?? ""} disabled autoComplete="off" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Grid>
                }
            />
        </ExpandableContent>
    );
}