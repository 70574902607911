import axios from './index';

// StudyTasks
export const getStudyTasksByProtocol = (protocolId) => {
    return axios.get(`api/v1/ProtocolAdmin/${protocolId}/studyTasks`);
};
export const getStudyTasks = () => {
    return axios.get(`api/v1/ProtocolAdmin/studyTasks`);
};
export const getStudyTask = (studyTaskId) => {
    return axios.get(`api/v1/ProtocolAdmin/studyTasks/${studyTaskId}`);
};
export const createStudyTask = (studytask) => {
    return axios.post(`api/v1/ProtocolAdmin/studyTasks`, studytask);
};
export const editStudyTask = (studyTaskId, studytask) => {
    return axios.put(`api/v1/ProtocolAdmin/studyTasks/${studyTaskId}`, studytask);
};
export const removeStudyTask = (studyTaskId) => {
    return axios.delete(`api/v1/ProtocolAdmin/studyTasks/${studyTaskId}`);
};

// Laboratories
export const getLaboratories = () => {
    return axios.get(`api/v1/ProtocolAdmin/laboratories`);
};
export const getLaboratory = (laboratoryId) => {
    return axios.get(`api/v1/ProtocolAdmin/laboratories/${laboratoryId}`);
};
export const createLaboratory = (laboratory) => {
    return axios.post(`api/v1/ProtocolAdmin/laboratories`, laboratory);
};
export const editLaboratory = (laboratoryId, laboratory) => {
    return axios.put(`api/v1/ProtocolAdmin/laboratories/${laboratoryId}`, laboratory);
};
export const removeLaboratory = (laboratoryId) => {
    return axios.delete(`api/v1/ProtocolAdmin/laboratories/${laboratoryId}`);
};

// Sync

export const syncProtocols = (tenantId) => {
    return axios.post(`api/v1/protocols/sync/${tenantId}`, tenantId);
};

export const syncUsersInProtocol = (tenantId) => {
    return axios.post(`api/v1/protocols/sync/${tenantId}/usersInProtocol`, tenantId);
};
export const syncPatientsInProtocol = (tenantId) => {
    return axios.post(`api/v1/protocols/sync/${tenantId}/patientsInProtocol`, tenantId);
};