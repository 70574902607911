import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { visitTypeColorGetter } from '../Agenda/Utils';
import Card from '../Cards/Card';
import { PatientAvatarAsync } from '../CustomControls/PatientAvatarAsync';
import { AddAppointment, PersonCheckLine, VideoCall, ViewMR, WaitingRoom } from '../Icons/Icons';
import { Tooltip } from '../Utils/Tooltips';

const DailyAgendaWidget = (props) => {
    const { events, isLoading, onAttendance } = props;
    const { t } = useTranslation();
    const settingsStore = useSelector(state => state.settings);

    let components = null;
    if (!isLoading) {
        components = events.map((event, index) => {

            let eventIdParam = event.present ? `?appointmentId=${event.id}` : '';

            return (
                <tr key={index} className="daily-agenda-row" style={{ borderLeft: `6px solid ${visitTypeColorGetter(event.visitType?.code)}` }}>
                    <td>
                        <PatientAvatarAsync medicalRecordNumber={event.patient.medicalRecordNumber} />
                    </td>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td className="card-desc">
                                        {moment(event.start).format('H:mm')} - {moment(event.end).format('H:mm')} [{event.visitType?.title}]
                                    </td>
                                </tr>
                                <tr>
                                    <td className="card-text">{event.patient.fullName} - {event.practitioner.fullName}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td style={{ width: '120px' }}>
                        <div className="daily-agenda-options">
                            {
                                event.present &&
                                <Tooltip tooltip={"Marcar como atendido"} id={"tooltipmarkattended-" + event.patient.medicalRecordNumber}>
                                    <button className="btn-table-actions" onClick={() => onAttendance(event.id)}>
                                        <PersonCheckLine stroke="#20ad9b" />
                                    </button>
                                </Tooltip>
                            }
                            <NavLink to={`/admin/medicalRecords/${event.patient.medicalRecordNumber}${eventIdParam}`} activeClassName="active">
                                <Tooltip tooltip={event.present ? t('calendar.patientPresent') : t('database.viewMR')} id={"tooltipadd-" + event.patient.medicalRecordNumber}>
                                    {
                                        event.present ?
                                            <WaitingRoom /> :
                                            <ViewMR />
                                    }
                                </Tooltip>
                            </NavLink>
                            <NavLink to={`/admin/agenda?medicalRecordNumber=${event.patient.medicalRecordNumber}`} activeClassName="active">
                                <Tooltip tooltip={t('medicalRecords.actionNewAppointment')} id={"tooltipaddAppointment-" + event.patient.medicalRecordNumber}>
                                    <AddAppointment />
                                </Tooltip>
                            </NavLink>
                            {
                                event.visitType.code === "EXTERNAL_PHONE" &&
                                <a
                                    href={settingsStore.settings.telemedicineUrl.replace("{callid}", event.telemedicineToken).replace("{medicoid}", event.practitioner.userId)} target="_blank">
                                    <Tooltip tooltip="Entrar a videollamada" id={"tooltipTelemedicine-" + event.telemedicineToken}>
                                        <VideoCall />
                                    </Tooltip>
                                </a>
                            }
                        </div>
                    </td>
                </tr >
            )
        });
    }

    return (
        <Card
            title={t("dashboard.dailyAgendaTitle")}
            className="card-daily-widget"
            content={
                <>
                    <table className="table table-responsive">
                        <tbody>
                            {components}
                        </tbody>
                    </table>
                </>
            }
        />
    )
}

export default DailyAgendaWidget;
