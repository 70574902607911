import { ErrorMessage, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, ControlLabel, FormControl, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useTranslation } from 'react-i18next';
import { getLaboratoryData } from '../../api/admin';
import { conceptType, snomedExpressions } from '../../variables/Enums';
import { CustomSelect } from '../CustomControls/CustomSelect';
import { SnomedPicker } from '../CustomControls/SnomedPicker';
import { Edit, Remove } from '../Icons/Icons';
import { FormErrorMessage } from '../Validations/FormValidationErrors';

export const ResultsTemplates = props => {

    const { data, query, totalSize, onTableChange, isLoading } = props;
    const { t } = useTranslation();

    const columns = [
        { dataField: 'name', text: t('settings.resultsTemplates.name'), sort: true },
        { dataField: 'speciality', text: t('settings.resultsTemplates.speciality'), sort: true },
        { dataField: 'type', text: t('settings.resultsTemplates.type'), sort: true },
        {
            dataField: 'view', text: t("commons.actions"), isDummyField: true, headerStyle: { width: '150px' },
            formatter: function (_cell, row) {
                if (props.onEdit && props.onRemove)
                    return (
                        <div>
                            <button className="btn-table-actions" onClick={() => props.onEdit(row.id)}>
                                <Edit />
                            </button>
                            <button className="btn-table-actions" onClick={() => props.onRemove(row.id)}>
                                <Remove />
                            </button>
                        </div>
                    );
                return null;
            },
        }
    ];

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        onTableChange(type, { page, sizePerPage, sortField, sortOrder });
    }

    return (
        <BootstrapTable
            bordered={false}
            hover
            remote
            keyField='id'
            classes="table-spaced table-spaced-small"
            wrapperClasses="table-responsive"

            data={data}
            columns={columns}
            noDataIndication={() => <>{t("commons.noData")}</>}
            pagination={paginationFactory({
                page: query.pagination.page,
                sizePerPage: query.pagination.sizePerPage,
                totalSize: totalSize,
                hideSizePerPage: true,
            })}
            onTableChange={handleTableChange}
            loading={isLoading}  //only loading is true, react-bootstrap-table will render overlay
            overlay={overlayFactory({ spinner: true })}
        />
    );
}

ResultsTemplates.propTypes = {
    data: PropTypes.array.isRequired,
    query: PropTypes.object.isRequired,
    totalSize: PropTypes.number.isRequired,
    onTableChange: PropTypes.func.isRequired,
};

export const ResultsTemplateForm = props => {

    const {
        values,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
    } = props;

    const { t } = useTranslation();
    const [data, setData] = useState({
        units: [],
        significances: []
    });

    useEffect(() => {
        const fetchData = () => {
            getLaboratoryData()
                .then(response => {
                    setData(response.data);

                }).catch(error => {
                    setData({});
                });
        }
        fetchData();
    }, []);

    const emptyResult = {
        name: null,
        refMin: null,
        refMax: null,
        reference: '',
        unit: null
    }

    if (!values.resultItems) {
        values.resultItems = [];
    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <div className="container-fluid card">
                <Row>
                    <Col md={12}>
                        <ControlLabel>{t("settings.resultsTemplates.name")}</ControlLabel>
                        <FormControl type="text" name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                        <ErrorMessage name="name" component={FormErrorMessage} />
                    </Col>
                </Row>
                <br />
                <div className="container-fluid card">
                    <FieldArray
                        name="resultItems"
                        render={arrayHelper => (<div>
                            <table className="table table-responsive">
                                <thead>
                                    <tr>
                                        <th>
                                            {t("settings.resultsTemplates.order")}
                                        </th>
                                        <th>
                                            {t('medicalRecords.laboratory.laboratory')}
                                        </th>
                                        <th>
                                            {t('medicalRecords.laboratory.unit')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        values.resultItems && values.resultItems.length > 0 ? (
                                            values.resultItems.map((result, index) => (
                                                <tr key={index}>
                                                    <td className='col-md-1'>
                                                        <FormControl type="text" name={`resultItems.${index}.order`} value={result.order} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                        <ErrorMessage name={`resultItems.${index}.order`} component={FormErrorMessage} />
                                                    </td>
                                                    <td className='col-md-7'>
                                                        <SnomedPicker
                                                            conceptType={conceptType.procedures}
                                                            id={`resultItems.${index}.name`}
                                                            selected={result.name ? [result.name] : []}
                                                            value={result.name}
                                                            name={`resultItems.${index}.name`}
                                                            placeholder={t("commons.search")}
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            conceptId={snomedExpressions.Procedimiento} />
                                                        <ErrorMessage name={`resultItems.${index}.name`} component={FormErrorMessage} />
                                                    </td>
                                                    <td className='col-md-3'>
                                                        <CustomSelect
                                                            value={result.unit}
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            name={`resultItems.${index}.unit`}
                                                            placeholder={t("commons.select")}
                                                            options={data.units} />
                                                        <ErrorMessage name={`resultItems.${index}.unit`} component={FormErrorMessage} />
                                                    </td>
                                                    <td className='col-md-1'>
                                                        {
                                                            !props.edit &&
                                                            <button
                                                                className="btn-table-actions btn-remove"
                                                                style={{ verticalAlign: 'middle' }}
                                                                type="button"
                                                                onClick={() => arrayHelper.remove(index)}>
                                                                <Remove />
                                                            </button>
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        ) : ""}
                                </tbody>
                            </table>

                            <button type="button" className="btn btn-default" onClick={() => arrayHelper.push(emptyResult)}>{t("buttons.add")}</button>
                        </div>
                        )}
                    />
                </div>
                <button type="button" className="btn btn-default" onClick={props.onCancel} disabled={isSubmitting}>{t("buttons.cancel")}</button>
                {
                    values.resultItems && values.resultItems.length > 0 &&
                    <button type="submit" className="btn btn-success btn-right" disabled={isSubmitting}>{t("buttons.save")}</button>
                }
                <div className="clearfix"></div>
            </div>
        </form>
    );
}