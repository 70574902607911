import { useEffect, useState } from "react";
import { getPatientByMedicalRecord, getPatientAvatarByMedicalRecord } from "../../../api/database";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";

export const usePatient = (medicalRecordNumber) => {
    const [patient, setPatient] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let isSubscribed = true
        const fetchData = async () => {
            try {
                const patient = await getPatientByMedicalRecord(medicalRecordNumber, true);

                if (isSubscribed) {
                    //console.log(patient.data)
                    setPatient(patient.data);
                    setIsLoading(false);
                }
            } catch (error) {
                HandleApiError(error);
                setIsLoading(false);
            }
        }

        fetchData();

        return () => isSubscribed = false;
    }, [medicalRecordNumber]);

    return [patient, isLoading];
}

export const usePatientAvatar = (medicalRecordNumber) => {
    const [avatar, setAvatar] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let isSubscribed = true
        const fetchData = async () => {
            try {
                const response = await getPatientAvatarByMedicalRecord(medicalRecordNumber);

                if (isSubscribed) {
                    setAvatar(response?.data);
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
                setAvatar(null);
            }
        }

        fetchData();

        return () => isSubscribed = false;
    }, [medicalRecordNumber]);

    return [avatar, isLoading];
}