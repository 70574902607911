import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { dateFormatter, yesNoFormatter } from "../Utils/Formatter";
import { EntryItemsTable } from './EntryItemsTable';

export const FamilyHistory = (props) => {
    const { t } = useTranslation();

    const columns = [
        {
            dataField: 'adverseEffect', text: t('medicalRecords.familyHistory.adverseEvent'), formatter: function (_cell, row, rowindex) {
                return (

                    <Row key={`adverseEvent-item-${rowindex}`} style={{ textAlign: 'left' }} className="item-data">
                        <Col md={12}><span className="item-label">{dateFormatter(row.created, "DD/MM/YY")}</span> - <span className="item-text">{row.adverseEffect}</span></Col>
                        <Col md={6}>
                            {row.relative && <div><span className="item-label">{t('medicalRecords.familyHistory.relative')}:</span> <span className="item-text">{row.relative}</span></div>}
                        </Col>
                        <Col md={6}>
                            {row.diagnosisDate && row.diagnosisDate !== "unk/unk/unk" && <div><span className="item-label">{t('medicalRecords.familyHistory.diagnosisDate')}:</span> <span className="item-text">{row.diagnosisDate}</span></div>}
                        </Col>
                    </Row>
                )
            }
        },
    ];

    const expandedContent = (row) => (
        <div className="info">
            {row.causeOfDeath !== null && row.causeOfDeath !== undefined && <div><span className="patient-label">{t('medicalRecords.familyHistory.causeOfDeath')}:</span> <span className="patient-text">{yesNoFormatter(row.causeOfDeath, t)}</span></div>}
            {row.notes && <div><span className="patient-label">{t('medicalRecords.familyHistory.notes')}:</span> <span className="patient-text">{row.notes}</span></div>}
        </div>
    )

    return (<EntryItemsTable columns={columns} {...props} expandContent={expandedContent} />);
}