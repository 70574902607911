import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom';
import { ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next'
import { FormGroup, ControlLabel, FormControl, Grid, Row, Col, InputGroup } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import { FormErrorMessage } from '../Validations/FormValidationErrors';
import { Tooltip } from "../Utils/Tooltips"
import { CustomSelect } from '../CustomControls/CustomSelect';
import { statusFormatter } from "../Table/Formatters"
import { Edit, ViewPractitioner, Config } from '../Icons/Icons';
import { WithAuth } from '../Authorization/WithAuth';

export const Users = props => {

    const { data, query, totalSize, onTableChange, isLoading } = props;
    const { t } = useTranslation();

    const columns = [
        { dataField: 'tenant', text: t('security.users.tenant') },
        { dataField: 'fullName', text: t('security.users.fullName'), sort: true },
        { dataField: 'userName', text: t('security.users.userName'), sort: true },
        //{ dataField: 'contact', text: t('security.users.contact'), headerStyle: { width: '150px' } },
        { dataField: 'role', text: t('security.users.role'), headerStyle: { width: '150px' } },
        { dataField: 'speciality', text: t('person.speciality'), headerStyle: { width: '200px' } },
        { dataField: 'active', text: t('security.users.status'), formatter: statusFormatter, headerStyle: { width: '100px' } },
        {
            dataField: 'view', text: t("commons.actions"), isDummyField: true, headerStyle: { width: '150px' },
            formatter: function (_cell, row, rowIndex, _formatExtraData) {
                return (
                    <div key={rowIndex}>
                        <NavLink to={`/admin/users/${row.userId}`} activeClassName="active" className="btn-table-actions">
                            <Tooltip tooltip={t('security.users.viewProfile')} id={"tooltipadd-" + row.id}>
                                <ViewPractitioner />
                            </Tooltip>
                        </NavLink>
                        <WithAuth requiredPermission={["Users.Create"]}>
                            <NavLink to={`/admin/users/${row.userId}/edit`} activeClassName="active" className="btn-table-actions">
                                <Tooltip tooltip={t('security.users.editProfile')} id={"tooltipadd-" + row.id}>
                                    <Edit />
                                </Tooltip>
                            </NavLink>
                            <NavLink to={`/admin/users/${row.userId}/config`} activeClassName="active" className="btn-table-actions">
                                <Tooltip tooltip={t('security.users.config')} id={"tooltipadd-" + row.id}>
                                    <Config />
                                </Tooltip>
                            </NavLink>
                        </WithAuth>
                    </div>
                )
            }
        }
    ];

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        onTableChange(type, { page, sizePerPage, sortField, sortOrder });
    }

    return (
        <BootstrapTable
            bordered={false}
            hover
            remote
            keyField='userId'
            classes="table-spaced table-spaced-small"
            wrapperClasses="table-responsive"

            data={data}
            columns={columns}
            noDataIndication={() => <>{t("commons.noData")}</>}
            pagination={paginationFactory({
                page: query.pagination.page,
                sizePerPage: query.pagination.sizePerPage,
                totalSize: totalSize,
                hideSizePerPage: true,
            })}
            onTableChange={handleTableChange}
            loading={isLoading}  //only loading is true, react-bootstrap-table will render overlay
            overlay={overlayFactory({ spinner: true })}
        />
    );
}

Users.propTypes = {
    data: PropTypes.array.isRequired,
    query: PropTypes.object.isRequired,
    totalSize: PropTypes.number.isRequired,
    onTableChange: PropTypes.func.isRequired,
};

export const UserForm = props => {
    const {
        handleSubmit,
        isSubmitting,
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
        roles,
        tenants
    } = props;

    const { t } = useTranslation();
    return (
        <Grid fluid>
            <Row>
                <Col md={12}>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="card">
                            <div className="header">
                                <h4 className="title">{t('security.users.tenant')}</h4>
                            </div>
                            <div className="content">
                                <fieldset>
                                    <FormGroup>
                                        <Grid fluid>
                                            {
                                                tenants && tenants.length > 0 &&
                                                <Row>
                                                    <Col sm={4}>
                                                        <ControlLabel>{t('security.users.tenant')}</ControlLabel>
                                                        <CustomSelect
                                                            value={values.tenant}
                                                            name={`tenant`}
                                                            placeholder={t("commons.select")}
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            options={tenants}
                                                            getOptionLabel={x => x.tenantName}
                                                            getOptionValue={x => x.tenantId}
                                                        />
                                                        <ErrorMessage name={`tenant`} component={FormErrorMessage} />
                                                    </Col>
                                                </Row>}
                                            <Row>
                                                <Col sm={4}>
                                                    <ControlLabel>{t('security.users.given')}</ControlLabel>
                                                    <FormControl type="text" name="given" value={values.given} className={errors.given && touched.given && "error"} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                    <ErrorMessage name="given" component={FormErrorMessage} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={4}>
                                                    <ControlLabel>{t('security.users.familyName')}</ControlLabel>
                                                    <FormControl type="text" name="familyName" value={values.familyName} className={errors.familyName && touched.familyName && "error"} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                    <ErrorMessage name="familyName" component={FormErrorMessage} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={4}>
                                                    <FormGroup>
                                                        <ControlLabel>{t('security.users.email')}</ControlLabel>
                                                        <InputGroup>
                                                            <FormControl type="text" name="userName" value={values.userName} className={errors.userName && touched.userName && "error"} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                            <InputGroup.Addon style={{ backgroundColor: '#a6a6a6' }}>@{values.tenant.tenantDomain}</InputGroup.Addon>
                                                        </InputGroup>
                                                        <ErrorMessage name="userName" component={FormErrorMessage} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={4}>
                                                    <ControlLabel>{t('security.users.workEmail')}</ControlLabel>
                                                    <FormControl type="text" name="workEmail" value={values.workEmail} className={errors.workEmail && touched.workEmail && "error"} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                    <ErrorMessage name="workEmail" component={FormErrorMessage} />
                                                </Col>
                                            </Row>
                                            {/*<Row>
                                                <Col sm={4}>
                                                    <ControlLabel>{t('security.users.password')}</ControlLabel>
                                                    <FormControl type="password" name="password" value={values.password} className={errors.password && touched.password && "error"} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                    <ErrorMessage name="password" component={FormErrorMessage} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={4}>
                                                    <ControlLabel>{t('security.users.confirmPassword')}</ControlLabel>
                                                    <FormControl type="password" name="confirmPassword" value={values.confirmPassword} className={errors.confirmPassword && touched.confirmPassword && "error"} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                    <ErrorMessage name="confirmPassword" component={FormErrorMessage} />
                                                </Col>
                                            </Row>*/}
                                            <Row>
                                                <Col sm={4}>
                                                    <ControlLabel>{t('security.users.role')}</ControlLabel>
                                                    <CustomSelect
                                                        value={values.role}
                                                        name={`role`}
                                                        placeholder={t("commons.select")}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        options={roles}
                                                        getOptionLabel={x => x.name}
                                                    />
                                                    <ErrorMessage name={`role`} component={FormErrorMessage} />
                                                </Col>
                                            </Row>
                                        </Grid>
                                    </FormGroup>
                                </fieldset>
                                <fieldset>
                                    <Grid fluid>
                                        <Row>
                                            <Col sm={1} smOffset={10} >
                                                <NavLink to="/admin/users" className="nav-link btn btn-info" activeClassName="active">
                                                    {t('buttons.back')}
                                                </NavLink>
                                            </Col>
                                            <Col sm={1} >
                                                <WithAuth requiredPermission={["Users.Create"]}>
                                                    <button className="btn btn-success" type="submit" disabled={isSubmitting}>
                                                        {t("buttons.save")}
                                                    </button>
                                                </WithAuth>
                                            </Col>
                                        </Row>
                                    </Grid>
                                </fieldset>
                            </div>
                        </div>
                    </form>
                </Col>
            </Row>
        </Grid >
    );
};