import PropTypes from 'prop-types';
import React from "react";
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { NavLink } from "react-router-dom";
import Card from "../Cards/Card";
import { Back, Next } from "../Icons/Icons";
import { dateFormatter } from '../Utils/Formatter';

export const EntryNav = (props) => {
    const {
        medicalRecordNumber,
        visits
    } = props;

    if (!visits)
        return <Card
            className="card-visitTracking-header"
            noHeader
            content={<></>}
        />;

    const Menu = () =>
        visits.sort(function (a, b) {
            return new Date(a.created) - new Date(b.created);
        }).map((visit, index) => {
            let path = `/admin/medicalRecords/${medicalRecordNumber}/${visit.status === "Published" ? "entry" : "newentry"}/${visit.id}`;
            let pathAux = `/admin/medicalRecords/${medicalRecordNumber}/${visit.status !== "Published" ? "entry" : "newentry"}/${visit.id}`;
            return (
                <div key={`header-visits-${index}`} className={`header-visit-container ${getNavLinkClass(path)} ${getNavLinkClass(pathAux)}`}>
                    <NavLink to={path} className="nav-link" activeClassName="active">
                        [{dateFormatter(visit.created, "DD/MMMM")}]{`${visit.practitionerFullName}`}
                    </NavLink>
                </div>
            );
        });

    const ArrowLeft = <Back />;
    const ArrowRight = <Next />;

    const getNavLinkClass = (path) => {
        return window.location.pathname === path ? 'active' : '';
    }

    return (
        <Card
            className="card-visitTracking-header"
            noHeader
            content={
                <ScrollMenu
                    data={Menu()}
                    arrowLeft={ArrowLeft}
                    arrowRight={ArrowRight}
                    selected={null}
                    onSelect={null}
                    hideSingleArrow={true}
                />
            }
        />
    );
}

EntryNav.propTypes = {
    medicalRecordNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    visits: PropTypes.array,
};