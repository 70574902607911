import React from "react";
import { NavLink, useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next'

import { Success } from "../../../components/Notifications/Notifications";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import Card from "../../../components/Cards/Card";
import { WithAuth } from "../../../components/Authorization/WithAuth";
import { useStudyTasks } from "./useStudyTasks";
import { removeStudyTask } from "../../../api/protocolAdmin";
import { StudyTasks } from "../../../components/Protocols/Admin/StudyTasks";
import { Add } from "../../../components/Icons/Icons";

export const StudyTasksContainer = (props) => {
    let history = useHistory();
    const { t } = useTranslation();
    const [studyTasks, isLoading, reload] = useStudyTasks();

    const handleEditStudyTask = (studyTaskId) => {
        history.push(`/admin/settings/studyTasks/${studyTaskId}`);
    }

    const handleRemoveStudyTask = (studyTaskId) => {
        if (studyTaskId) {
            removeStudyTask(studyTaskId)
                .then(res => {
                    reload(true);
                    Success("protocols.admin.studyTask.removed");
                })
                .catch(error => { HandleApiError(error); })
        }
    }

    return (
        <Card
            tabs
            header={
                <WithAuth requiredPermission={["Protocols.Create"]}>
                    <NavLink to={`/admin/settings/studyTasks/create`} className="btn btn-primary" activeClassName="active">
                        <Add /> {t("protocols.admin.studyTask.studyTaskAdd")}
                    </NavLink>
                </WithAuth>
            }
            content={
                <StudyTasks
                    studyTasks={studyTasks}
                    onEdit={handleEditStudyTask}
                    onRemove={handleRemoveStudyTask}
                    isLoading={isLoading}
                />
            }
        />
    );
}