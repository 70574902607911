import { useState } from 'react';
import { createEntry, assignVisitId } from '../../../api/medicalRecord';

const useNewEntry = () => {
    const [isShowing, setIsShowing] = useState(false);

    function toggleNewEntry() {
        setIsShowing(!isShowing);
    }

    function createExternal(medicalRecordNumber) {
        let entry = {
            groupId: 1,
            typeId: 1
        }
        return createEntry(medicalRecordNumber, entry);
    }

    function createProtocol(medicalRecordNumber, protocolId, visitCrossVersionId) {
        let entry = {
            groupId: 3,
            typeId: 2,
            protocolId,
            visitId: visitCrossVersionId
        }
        
        return createEntry(medicalRecordNumber, entry);
    }

    function createExtraProtocol(medicalRecordNumber, protocolId) {
        let entry = {
            groupId: 3,
            typeId: 3,
            protocolId
        }
        
        return createEntry(medicalRecordNumber, entry);
    }

    function updateVisitId(medicalRecordNumber, entryId, visitId) {        
        return assignVisitId(medicalRecordNumber, entryId, visitId);
    }

    return {
        isShowing,
        toggleNewEntry,
        createExternal,
        createProtocol,
        createExtraProtocol,
        updateVisitId
    }
};

export default useNewEntry;