import { useState } from "react";
import useLoading from "../../components/Common/useLoading";
import { IotDevice } from "../../api/Freezer/Entities/IotDevice";
import DeviceService from "../../api/Freezer/DeviceService";

export default function useIotDevice(deviceId) {
    const [device, setDevice] = useState(new IotDevice())
    const [isLoading, useLoad, doReload] = useLoading({
        onLoad: async () => {
            if (!deviceId || deviceId <= 0) return;
            const result = await DeviceService.GetById(deviceId)
            setDevice(result)
        },
        onError: () => {
            setDevice(new IotDevice())
        }
    })

    const getCertificate = async () => {
        return await DeviceService.GetCertificate(device.CertificatePath)
    }

    return [device, isLoading, doReload, getCertificate]
}