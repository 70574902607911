import React, { useContext } from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card } from '../../components/Cards/Card';
import { FormRestriction, IconSVG, Print, Remove } from '../Icons/Icons';
import { WithAuth } from '../Authorization/WithAuthProtocol';
import ProtocolContext from '../../containers/Protocols/ProtocolContext/ProtocolContext';
import { Spinner } from '../Utils/Loaders';

export const Actions = (props) => {
    const { t } = useTranslation();
    const context = useContext(ProtocolContext);

    const { onSave, onPublish, onEdit, onRemove, onBack, onPrint, onFormRestrictions, visit, isLoading } = props;

    var save = null;
    if (onSave && !context.isDisabled) {
        save = (
            <button className={`btn btn-primary btn-fill btn-full`} onClick={onSave}>
                <IconSVG name="save" /> {t('protocols.visit.actionSave')}
            </button>
        );
    }

    var back = null;
    if (onBack) {
        back = (
            <button className={`btn btn-primary btn-fill btn-full`} onClick={onBack}>
                <IconSVG name="back" /> {t('buttons.back')}
            </button>
        );
    }

    var edit = null;
    if (onEdit && !context.isDisabled) {
        edit = (
            <button className={`btn btn-primary btn-fill btn-full`} onClick={onEdit}>
                <IconSVG name="edit" /> {t('protocols.visit.actionEdit')}
            </button>
        );
    }

    var remove = null;
    if (onRemove && !context.isDisabled) {
        remove = (
            <button className={`btn btn-danger btn-fill btn-full`} onClick={onRemove}>
                <Remove /> {t('protocols.visit.actionRemove')}
            </button>
        );
    }

    var publish = null;
    if (onPublish && !context.isDisabled) {
        publish = (
            <button className={`btn btn-success btn-fill btn-full`} onClick={onPublish}>
                <IconSVG name="publish" /> {t('protocols.visit.actionPublish')}
            </button>
        );
    }

    var print = null;
    if (onPrint) {
        print = (
            <button className={`btn btn-success btn-fill btn-full`} onClick={onPrint}>
                <Print /> {t('protocols.visit.actionPrint')}
            </button>
        );
    }

    var formRestrictions = null;
    if (onFormRestrictions && !context.isDisabled) {
        formRestrictions = (
            <button
                type="button"
                className="btn btn-info btn-full "
                onClick={onFormRestrictions}
            >
                <FormRestriction style={{ marginRight: '5px' }} />{t('protocols.visitTracking.formRestrictions.title')}
            </button>)
    }

    if (isLoading || !visit)
        return <Card
            title={t('medicalRecords.actions')}
            content={<Spinner />}
        />

    return (
        <Card
            title={t('medicalRecords.actions')}
            className="card-actions"
            content={
                <>
                    <WithAuth protocolId={context.protocolId} requiredPermission={["Visits.Add"]}>
                        {save}
                        {publish}
                        {edit}
                        {formRestrictions}
                        {remove}
                        {print}
                    </WithAuth>
                    {back}
                </>
            }
        />
    );
}

Actions.propTypes = {
    onSave: PropTypes.func,
    onPublish: PropTypes.func,
    onEdit: PropTypes.func,
    onBack: PropTypes.func,
};
