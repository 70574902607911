import React from "react";
import { useTranslation } from 'react-i18next'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Tooltip } from "../../../components/Utils/Tooltips";
import { Edit, Remove, Attach } from "../../../components/Icons/Icons";
import { WithAuth } from "../../../components/Authorization/WithAuth";

export default function IotDevices({ pagedDevices, isLoading, onTableChange, onRemove, onDetail }) {
    const { t } = useTranslation();
    const columns = [
        { dataField: 'Id', text: 'ID', headerStyle: { width: '3rem' } },
        { dataField: 'Name', text: t("commons.name"), headerStyle: { width: '15.625rem' } },
        { dataField: 'State', text: t("commons.state"), headerStyle: { width: '10rem' }, formatter: function (_cell, device, rowIndex) { return device.State !== null ? t(`settings.device.states.${device.State.Name}`) : "-" } },
        {
            dataField: 'view',
            text: t(""),
            isDummyField: true,
            headerClasses: 'header-actions',
            classes: 'row-actions',
            formatter: function (_cell, row, rowIndex) {
                return (
                    <div key={rowIndex}>
                        <WithAuth requiredPermission={["Devices.Edit"]}>
                            <button className="btn-table-actions" onClick={() => onDetail(row.Id)}>
                                <Tooltip tooltip={t("settings.device.editTooltip")} id={"tooltipedit-" + row.id}>
                                    <span aria-hidden="true"><Edit /></span>
                                </Tooltip>
                            </button>
                        </WithAuth>
                        <WithAuth requiredPermission={["Devices.Delete"]}>
                            <button className="btn-table-actions" onClick={() => onRemove(row.Id)}>
                                <Tooltip tooltip={t("settings.device.removeTooltip")} id={"tooltipremove-" + row.id}>
                                    <span aria-hidden="true"><Remove /></span>
                                </Tooltip>
                            </button>
                        </WithAuth>
                    </div>
                )
            }
        }
    ]

    return (
        <>
            <BootstrapTable
                bordered={false}
                hover
                remote
                keyField='Id'
                classes="table-spaced table-spaced-small"
                wrapperClasses="table-responsive"

                pagination={paginationFactory({
                    page: pagedDevices.PageNumber,
                    sizePerPage: pagedDevices.PageSize,
                    totalSize: pagedDevices.TotalCount,
                    hideSizePerPage: true
                })}
                onTableChange={onTableChange}

                data={pagedDevices.Values}
                columns={columns}
                loading={isLoading}
                noDataIndication={() => <>{t("commons.noData")}</>}
            />
        </>
    )
}