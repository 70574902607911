import { useEffect, useState } from "react"
const props = {
    onLoad: async () => { },
    onError: async (err) => err,
}

export default function useLoading({ onLoad, onError } = props) {
    const [isLoading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)

    useEffect(() => {
        if (!onLoad) return;

        async function fetchData() {
            setLoading(true);
            setReload(false);
            try {
                await onLoad();
            } catch (err) {
                if (onError)
                    onError(err)
            } finally {
                setLoading(false);
            }
        }

        fetchData()
    }, [reload])

    const useLoad = async (callback, errCallback = null) => {
        setLoading(true);
        try {
            await callback();
        } catch (err) {
            if (errCallback) errCallback(err)
        } finally {
            setLoading(false);
        }
    }
    const doReaload = () => setReload(true)

    return [isLoading, useLoad, doReaload]
}