import PropTypes from 'prop-types';
import React from "react";
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";

export const ProtocolHeader = ({ protocolId }) => {
    const { t } = useTranslation();
    const getNavLinkClass = (path) => {
        return window.location.pathname === path ? 'active' : '';
    }

    return (
        <div id="protocol-header">
            <ul role="tablist" className="nav nav-tabs">
                <li role="presentation" className={getNavLinkClass(`/admin/protocols/${protocolId}`)}>
                    <NavLink to={`/admin/protocols/${protocolId}`} className="nav-link" activeClassName="active">
                        {t('protocols.header.description')}
                    </NavLink>
                </li>
                <li role="presentation" className={getNavLinkClass(`/admin/protocols/${protocolId}/practitioners`)}>
                    <NavLink to={`/admin/protocols/${protocolId}/practitioners`} className="nav-link" activeClassName="active">
                        {t('protocols.header.practitioners')}
                    </NavLink>
                </li>
                <li role="presentation" className={getNavLinkClass(`/admin/protocols/${protocolId}/visitsTemplate`)}>
                    <NavLink to={`/admin/protocols/${protocolId}/visitsTemplate`} className="nav-link" activeClassName="active">
                        {t('protocols.header.visits')}
                    </NavLink>
                </li>
                <li role="presentation" className={getNavLinkClass(`/admin/protocols/${protocolId}/visitTracking`)}>
                    <NavLink to={`/admin/protocols/${protocolId}/visitTracking`} className="nav-link" activeClassName="active">
                        {t('protocols.header.visitTracking')}
                    </NavLink>
                </li>
            </ul>
        </div>
    );
}

ProtocolHeader.propTypes = {
    protocolId: PropTypes.string.isRequired,
};