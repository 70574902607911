import { Formik } from "formik";
import React from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { uploadAttachmentEndpoint } from "../../../api/medicalRecord";
import { DocumentViewer } from "../../../components/CustomControls/DocViewer/DocumentViewer";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { FilesForm } from "../../../components/CustomControls/Files/Files";
import { useFiles } from "../../../components/CustomControls/Files/Hooks";
import { uploadStatusEnum } from "../../../components/CustomControls/Files/Utils";
import { Attachments } from "../../../components/MedicalRecords/Attachments";
import { Spinner } from "../../../components/Utils/Loaders";
import { useAttachments } from "../Hooks/useAttachments";

export const AttachmentsContainer = ({ entryId, expandedAll }) => {

    const [data, query, totalSize, isLoading, handleTableChange, , , blobData, viewAttachment] = useAttachments(null, entryId);
    const { t } = useTranslation();

    if (totalSize === 0)
        return null;

    return (
        <ExpandableContent title={t("medicalRecords.attachments.title")} expandedAll={expandedAll} content="attachments">
            <>
                <DocumentViewer
                    file={blobData?.blob}
                    fileName={blobData?.fileName}
                    inModal={true}
                />
                <Attachments
                    withCard={false}
                    data={data}
                    query={query}
                    totalSize={totalSize}
                    isLoading={isLoading}
                    onTableChange={handleTableChange}
                    onDownloadFile={viewAttachment}
                />
            </>
        </ExpandableContent>

    );
}

export const AttachmentsCreateContainer = ({ entryId, hide, onSubmit }) => {

    const [data, , postAPI] = useFiles(uploadAttachmentEndpoint(entryId));
    const { t } = useTranslation();

    useEffect(() => {
        if (data.status === uploadStatusEnum.Uploaded)
            onSubmit("attachments");
    }, [data.status])

    const handleSubmit = async (values) => {
        try {
            await postAPI(values.attachments);
        }
        catch (error) {
            console.log("handleSubmit", error);
        }
    }

    const handleCancel = () => hide();

    if (data.isLoading || data.status === uploadStatusEnum.Uploading)
        return <Spinner />;

    return (
        <Formik
            onSubmit={handleSubmit}
        >
            {props => (
                <form onSubmit={props.handleSubmit}>
                    <FilesForm
                        placeholder={t('medicalRecords.attachments.upload')}
                        name={`attachments`}
                        onChange={props.setFieldValue}
                    />
                    <Row>
                        <Col md={12}>
                            <button type="button" className="btn btn-default" onClick={handleCancel}>{t("buttons.cancel")}</button>
                            <button type="submit" className="btn btn-success btn-right" >{t('buttons.save')}</button>
                        </Col>
                    </Row>
                </form>
            )}
        </Formik>
    );
}