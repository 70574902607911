import { withFormik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { AppointmentForm, EditAppointmentForm } from '../../components/Agenda/AppointmentForm';
import { HandleApiError } from '../../components/Notifications/APIErrorHandler';
import { ModalSpinner, Spinner } from '../../components/Utils/Loaders';
import { getAgendaAppointmentSchema } from '../../components/Validations/FormValidationSchema';
import { useEvents } from './Hooks';

export const EditAppointmentContainer = (props) => {
    const [, , , getEvent] = useEvents();
    const [appointment, setAppointment] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchAppointment = async () => {
            try {
                setIsLoading(true);
                let res = await getEvent(props.appointment?.id);
                if (res && res.data) {
                    res.data.start = moment.utc(moment.utc(res.data.start)).local().toDate();
                    res.data.end = moment.utc(moment.utc(res.data.end)).local().toDate();
                    setAppointment(res.data);
                }
                setIsLoading(false);
            }
            catch (error) {
                console.log(error);
                HandleApiError(error);
                setIsLoading(false);
            }
        }

        if (props.appointment?.id)
            fetchAppointment();
    }, [])

    if (isLoading || !appointment)
        return <ModalSpinner isShowing={true} />

    return (
        <EditAppointmentFormik
            onSubmit={props.onSubmit}
            onClose={props.onClose}
            masterdata={props.masterdata}
            appointment={appointment}
            formType="edit"
        />
    );
}

EditAppointmentContainer.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    masterdata: PropTypes.object.isRequired,
    appointment: PropTypes.object.isRequired
};

const EditAppointmentFormik = withFormik({
    mapPropsToValues: (props) => (props.appointment),
    validationSchema: getAgendaAppointmentSchema(),
    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values);
    },
    displayName: 'EditAppointment'
})(AppointmentForm);

EditAppointmentFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};