import { useState } from "react"
import useLoading from "../../components/Common/useLoading"
import { PaginationRequest, PaginationResult } from "../../api/Freezer/Util"
import FreezerService from "../../api/Freezer/FreezerService"

const propsTemplate = { ...new PaginationRequest(), withDevices: true }

export default function useFreezers(props = propsTemplate) {
    const [pagedFreezers, setPagedFreezers] = useState(new PaginationResult())
    const [isLoading, useLoad, reload] = useLoading({
        onLoad: async () => {
            const result = await FreezerService.Find(props)
            setPagedFreezers(result)
        },
        onError: () => {
            setPagedFreezers(new PaginationResult())
        }
    })

    const Navigation = {
        ToPage: async ({ page }) => useLoad(async () => {
            const request = pagedFreezers.ToPage(page);

            if (request == null) return

            const result = await FreezerService.Find({ ...request, withDevices: true })
            setPagedFreezers(result)
        }),
        Reload: reload
    }

    return [pagedFreezers, isLoading, Navigation]
}