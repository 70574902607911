import PropTypes from 'prop-types';
import { useAuthProtocol } from './useAuthorization';

export const WithAuth = props => {
    const { requiredPermission, protocolId, excludePermission } = props;
    const [isAuthorized, isLoading] = useAuthProtocol(protocolId);

    if (isLoading)
        return null;

    if (isAuthorized(requiredPermission, excludePermission))
        return props.children;

    return null;
}

WithAuth.propTypes = {
    requiredPermission: PropTypes.array.isRequired,
    excludePermission:  PropTypes.array,
    protocolId: PropTypes.string.isRequired,
};