import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { dateFormatter, yesNoFormatter } from '../Utils/Formatter';
import { EntryItemsTable } from './EntryItemsTable';

export const PersonalHistory = (props) => {
    const { t } = useTranslation();

    const columns = [
        {
            dataField: 'adverseEffect', text: t('medicalRecords.adverseEvents.adverseEvent'), formatter: function (_cell, row, rowindex) {
                return (

                    <Row key={`adverseEvent-item-${rowindex}`} style={{ textAlign: 'left' }} className="item-data">
                        <Col md={12}><span className="item-label">{dateFormatter(row.created, "DD/MM/YY")}</span> - <span className="item-text">{row.adverseEffect}</span></Col>
                        <Col md={6}>
                            {row.from && <div><span className="item-label">{t('medicalRecords.from')}:</span> <span className="item-text">{row.from}</span></div>}
                        </Col>
                        <Col md={6}>
                            {row.to && <div><span className="item-label">{t('medicalRecords.to')}:</span> <span className="item-text">{row.to}</span></div>}
                        </Col>
                    </Row>
                )
            }
        },
    ];

    const expandedContent = (row) => (
        <div className="info">
            {row.intensity && <div><span className="patient-label">{t('medicalRecords.adverseEvents.intensity')}:</span> <span className="patient-text">{row.intensity}</span></div>}
            {row.action && <div><span className="patient-label">{t('medicalRecords.adverseEvents.action')}:</span> <span className="patient-text">{row.action}</span></div>}
            {row.serious && <div><span className="patient-label">{t('medicalRecords.adverseEvents.serious')}:</span> <span className="patient-text">{row.serious}</span></div>}
            {row.relationwithdrug && <div><span className="patient-label">{t('medicalRecords.adverseEvents.relationwithdrug')}:</span> <span className="patient-text">{row.relationwithdrug}</span></div>}
            {row.reported && <div><span className="patient-label">{t('medicalRecords.adverseEvents.reported')}:</span> <span className="patient-text">{yesNoFormatter(row.reported, t)}</span></div>}
            {row.notes && <div><span className="patient-label">{t('medicalRecords.adverseEvents.notes')}:</span> <span className="patient-text">{row.notes}</span></div>}
        </div>
    )

    const rowClasses = (row) => { return row.originalId && row.originalId !== row.id ? 'item-modified' : ''; };

    return (<EntryItemsTable columns={columns} {...props} rowClasses={rowClasses} expandContent={expandedContent} />);
}