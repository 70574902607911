import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, ControlLabel, FormControl, Grid, HelpBlock, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Card from '../../Cards/Card';
import { CustomCheckbox } from "../../CustomControls/CustomCheckbox";
import { CustomInput } from '../../CustomControls/CustomInput';
import { CustomSelect } from "../../CustomControls/CustomSelect";
import { Save } from '../../Icons/Icons';
import { dateFormatter } from '../../Utils/Formatter';
import { FormErrorMessage } from '../../Validations/FormValidationErrors';
import { OffsetTypes, Types, VisitStages } from './Enums';
import { ConfirmSaveModal } from './Modals';

export const VisitHeaderForm = props => {

    const {
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        t
    } = props;

    const [modal, setModal] = useState(null);
    const onSubmit = (e) => {
        e.preventDefault();
        if (values.visit.version % 1 === 0) {
            setModal(<ConfirmSaveModal
                title={t("protocols.visit.visitConfirmPublish")}
                subtitle={t("protocols.visit.visitConfirmPublishDesc")}
                onClose={() => setModal(null)}
                onConfirm={() => { setModal(null); handleSubmit(); }}
            />)
        }
        else {
            handleSubmit();
        }
    }

    const emptyVisit = {
        title: '',
        version: '0.1',
        stage: null,
        type: null,
        visitType: null,
        offsetType: null,
        offsetFrom: null,
        offset: 0,
        windowPos: 0,
        windowNeg: 0,
    };
    if (!values.visit) {
        values.visit = emptyVisit;
    }
    //{t("protocols.visit.visitData")} ({t("commons.version")}: {values.visit.version})

    return (
        <form onSubmit={onSubmit} autoComplete="off">
            {modal}
            <fieldset>
                <legend>{values.visit.title}</legend>
                <Grid fluid>
                    <Row>
                        <Col sm={3}>
                            <ControlLabel>{t("protocols.visit.visitName")}</ControlLabel>
                            <FormControl type="text" name={`visit.title`} value={values.visit.title} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            <ErrorMessage name={`visit.title`} component={FormErrorMessage} />
                        </Col>
                        <Col sm={3}>
                            <ControlLabel>{t("protocols.visit.visitType")}</ControlLabel>
                            <CustomSelect
                                defaultValue={values.visit.type}
                                name={`visit.type`}
                                placeholder={t("commons.select")}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                options={Types()}
                            />
                            <ErrorMessage name={`visit.type`} component={FormErrorMessage} />
                        </Col>
                        <Col sm={3}>
                            <ControlLabel>{t("protocols.visit.visitStage")}</ControlLabel>
                            <CustomSelect
                                defaultValue={values.visit.stage}
                                name={`visit.stage`}
                                placeholder={t("commons.select")}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                options={VisitStages()}
                            />
                            <ErrorMessage name={`visit.stage`} component={FormErrorMessage} />
                        </Col>
                        <Col sm={3}>
                            <ControlLabel>{t("protocols.visit.visitOrder")}</ControlLabel>
                            <FormControl type="number" name={`visit.order`} value={values.visit.order} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            <ErrorMessage name={`visit.order`} component={FormErrorMessage} />
                        </Col>
                        {/*<Col sm={3}>
                            <CustomCheckbox
                                name={`visit.baseline`}
                                label="Baseline"
                                isChecked={values.visit.baseline ? values.visit.baseline : ''}
                                onChange={handleChange} />
                            <ErrorMessage name={`visit.baseline`} component={FormErrorMessage} />
                        </Col>*/}
                    </Row>
                </Grid>

                <legend style={{ marginTop: '10px' }}>{t("protocols.visit.visitDate")}</legend>
                <Grid fluid>

                    <Row>
                        <Col sm={3}>
                            <ControlLabel>{t("protocols.visit.visitOffset")}</ControlLabel>
                            <FormControl type="number" name={`visit.offset`} value={values.visit.offset} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            <HelpBlock>{t("protocols.visit.visitOffsetHelp")}</HelpBlock>
                            <ErrorMessage name={`visit.offset`} component={FormErrorMessage} />
                        </Col>
                        <Col sm={3}>
                            <ControlLabel>{t("protocols.visit.visitOffsetType")}</ControlLabel>
                            <CustomSelect
                                defaultValue={values.visit.offsetType}
                                name={`visit.offsetType`}
                                placeholder={t("commons.select")}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                options={OffsetTypes()}
                            />
                            <ErrorMessage name={`visit.offsetType`} component={FormErrorMessage} />
                        </Col>
                        {/*<Col sm={6}>
                            <ControlLabel>{t("protocols.visit.visitOffsetFrom")}</ControlLabel>
                            <CustomSelect
                                value={values.visit.offsetFrom && props.visits && props.visits.find(x => x.crossVersionId === values.visit.offsetFrom)}
                                name={`visit.offsetFrom`}
                                placeholder={t("commons.select")}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                options={props.visits}
                                getOptionValue={(x) => x.crossVersionId}
                            />
                            <ErrorMessage name={`visit.offsetFrom`} component={FormErrorMessage} />
                        </Col>*/}
                        <Col sm={3}>
                            <ControlLabel>{t('protocols.visit.visitWindowNeg')}</ControlLabel>
                            <CustomInput name={`visit.windowNeg`} onChange={setFieldValue} onBlur={setFieldTouched} value={values.visit.windowNeg} />
                            <ErrorMessage name={`visit.windowNeg`} component={FormErrorMessage} />
                        </Col>
                        <Col sm={3}>
                            <ControlLabel>{t('protocols.visit.visitWindowPos')}</ControlLabel>
                            <CustomInput name={`visit.windowPos`} onChange={setFieldValue} onBlur={setFieldTouched} value={values.visit.windowPos} />
                            <ErrorMessage name={`visit.windowPos`} component={FormErrorMessage} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <ControlLabel>{t('protocols.visit.visitNotes')}</ControlLabel>
                            <FormControl componentClass="textarea" name={`visit.notes`} value={values.visit.notes} onChange={handleChange} onBlur={handleBlur} />
                            <ErrorMessage name={`visit.notes`} component={FormErrorMessage} />
                        </Col>
                    </Row>
                </Grid>
            </fieldset>
            <Grid fluid style={{ marginTop: '10px' }}>
                <Row>
                    <Col sm={12}>
                        <button type="submit" className="btn btn-success btn-right" ><Save /> {t("buttons.save")}</button>
                        <button type="button" className="btn btn-default btn-right" onClick={props.onCancel} style={{ marginRight: '10px' }}>{t("buttons.cancel")}</button>
                    </Col>
                </Row>
            </Grid>
        </form>
    );
}
VisitHeaderForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    visits: PropTypes.array.isRequired,
};

export const VisitHeader = props => {

    const { visit, visits } = props;
    const { t } = useTranslation();

    const title = visit && (
        <div>
            {visit.title} - {t("commons.version")}: {visit.version}
        </div>
    );
    const content = (visit && <Grid fluid>
        <Row>
            <Col componentClass={ControlLabel} sm={2}>
                <ControlLabel>{t("protocols.visit.visitType")}</ControlLabel>
            </Col>
            <Col sm={2}>
                {Types().find(x => x.id === visit.type)?.title}
            </Col>
            <Col sm={2}>
                <CustomCheckbox
                    name={`visit.baseline`}
                    label="Baseline"
                    isChecked={visit.baseline ? visit.baseline : ''}
                    disabled={true}
                />
            </Col>
        </Row>
        <Row>
            <Col componentClass={ControlLabel} sm={2}>
                <ControlLabel>{t("protocols.visit.visitOffset")}</ControlLabel>
            </Col>
            <Col sm={2}>
                {visit.offset}
            </Col>
            <Col componentClass={ControlLabel} sm={2}>
                <ControlLabel>{t("protocols.visit.visitOffsetType")}</ControlLabel>
            </Col>
            <Col sm={2}>
                {OffsetTypes().find(x => x.id === visit.offsetType)?.title}
            </Col>
        </Row>
        <Row>
            <Col componentClass={ControlLabel} sm={2}>
                <ControlLabel>{t("protocols.visit.visitWindow")}</ControlLabel>
            </Col>
            <Col sm={2}>
                - {visit.windowNeg} + {visit.windowPos}
            </Col>
            <Col componentClass={ControlLabel} sm={2}>
                <ControlLabel>{t("protocols.visit.visitOffsetFrom")}</ControlLabel>
            </Col>
            <Col sm={2}>
                {visits.find(x => x.id === visit.offsetFrom)?.title}
            </Col>
        </Row>
        <Row>
            <Col componentClass={ControlLabel} sm={2}>
                <ControlLabel>{t("protocols.visit.visitStage")}</ControlLabel>
            </Col>
            <Col sm={8}>
                {VisitStages().find(x => x.id === visit.stage)?.title}
            </Col>
        </Row>
        <Row>
            <Col componentClass={ControlLabel} sm={2}>
                <ControlLabel>{t("protocols.visit.visitNotes")}</ControlLabel>
            </Col>
            <Col sm={8}>
                {visit.notes}
            </Col>
        </Row>
    </Grid>);

    return (
        <Card
            title={title}
            subtitle={visit && (<div>{t("commons.created")}: {dateFormatter(visit.created, "DD/MMM/YYYY HH:mm")} - {t("commons.createdBy")}: {visit.createdBy?.fullName}</div>)}
            content={content}
        />
    );
}

VisitHeader.propTypes = {
    visit: PropTypes.object,
    onRemoveVisit: PropTypes.func.isRequired,
    onPublishVisit: PropTypes.func.isRequired,
};