import React from 'react';
import { NavLink } from 'react-router-dom';
import image from "../../assets/img/forbidden.png";
import '../../assets/sass/lbd/_error-pages.scss';

const UnauthorizedPage = () => {
    //const [isAuthenticated] = useAuthorization();
    //let history = useHistory();
    //
    //if (isAuthenticated) {
    //    history.push(`/`);
    //}
    return (
        <div className="error-container">
            <img src={image} alt="Acceso Denegado" />
            
            <h1>401</h1>
            <h2>Acceso Denegado</h2>            
            <p>No tiene los permisos suficientes para acceder al recurso. En caso de que lo necesite, contactese con el administrador del sistema.</p>
            <NavLink to="/admin/dashboard">&larr; Volver</NavLink>
        </div>
    );
}

export default UnauthorizedPage;
