import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { setIsReadOnlyAPI } from '../../../api/settings';
import { AdminNotifications } from '../../../components/AdminNotifications/AdminNotifications';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { Warning } from '../../../components/Notifications/Notifications';
import { useAdminNotification, useAdminNotifications } from './useAdminNotifications';

const AdminNotificationsContainer = (props) => {
  const [data, query, totalSize, isLoading, handleTableChange, setReload] = useAdminNotifications();
  const [, , create, update, remove, isNotifLoading] = useAdminNotification();

  async function handleCreate(item) {
    var notification = await create(item);
    if (notification)
      setReload(true);
  }
  async function handleEdit(item) {
    var notification = await update(item);
    if (notification)
      setReload(true);
  }
  async function handleRemove(itemId) {
    await remove(itemId);
    setReload(true);
  }
  async function handleSetReadOnly(readonly) {
    try {
      await setIsReadOnlyAPI(readonly);
      if (readonly)
        Warning("service_isReadOnlyEnabled")
      else
        Warning("service_isReadOnlyDisabled")
    }
    catch (error) {
      console.log(error);
      HandleApiError(error);
    }

  }
  return (
    <AdminNotifications
      data={data}
      query={query}
      totalSize={totalSize}
      isLoading={isLoading}
      handleTableChange={handleTableChange}
      handleCreate={handleCreate}
      handleEdit={handleEdit}
      handleRemove={handleRemove}
      handleSetReadOnly={handleSetReadOnly}
    />);
}

export default AdminNotificationsContainer;