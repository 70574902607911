import moment from 'moment';
import PropTypes from 'prop-types';
import React from "react";
import DateTime from 'react-datetime';

function formatDateForInput(date, dateFormat) {
    if (date)
        if (!moment.isMoment(date))
            return moment(date).format(dateFormat);
        else
            return date.format(dateFormat);

    return ''
}

export const CustomDateTime = (props) => {
    const { value, dateFormat, onChange, onBlur, ...rest } = props;

    const handleChange = value => {
        var date = moment(value, dateFormat, true);
        if (date.isValid() && onChange)
            onChange(props.name, date);
    }

    const handleBlur = () => {
        if (!onBlur)
            return;
        onBlur(props.name, true);
    }

    return (
        <DateTime
            {...rest}
            inputProps={{
                disabled: props.disabled,
                placeholder: "DD/MM/YYYY HH:mm",
                value: formatDateForInput(value, dateFormat), // Formato personalizado en el input
            }}
            dateFormat="DD/MM/YYYY"
            timeFormat="HH:mm"
            initialValue={value}
            onChange={handleChange}
            onBlur={handleBlur}
        />
    );

}

CustomDateTime.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
};
CustomDateTime.defaultProps = {
    dateFormat: "DD/MM/YYYY HH:mm",
    inputProps: { placeholder: "DD/MM/YYYY HH:mm" },
};

export const CustomTime = (props) => {
    const { value, onChange, ...rest } = props;

    const handleChange = value => {
        var date = moment(value, "HH:mm", true);
        if (date.isValid() && onChange)
            onChange(props.name, date);
    };

    const handleBlur = () => {
        if (props.onBlur)
            props.onBlur(props.name, true);
    };

    return (
        <DateTime
            initialValue={value}
            {...rest}
            dateFormat={false}
            minuteFormat="HH:mm"
            onChange={handleChange}
            onBlur={handleBlur}
            inputProps={{
                placeholder: "HH:mm",
                disabled: props.disabled,
                value: formatDateForInput(value, "HH:mm"), // Formato personalizado en el input
            }}
        />
    );
}

CustomTime.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
};

export const CustomDate = (props) => {
    const { value, onChange, onBlur, dateFormat, ...rest } = props;

    const handleChange = value => {
        var date = moment(value, dateFormat, true);
        if (date.isValid() && onChange)
            onChange(props.name, date);
    }

    const handleBlur = () => {
        if (onBlur)
            onBlur(props.name, true);
    };

    return (
        <DateTime
            {...rest}
            inputProps={{
                placeholder: 'DD/MM/AAAA',
                disabled: props.disabled,
                value: formatDateForInput(value, dateFormat), // Formato personalizado en el input
            }}
            initialValue={value}
            dateFormat={dateFormat}
            timeFormat={false}
            onChange={handleChange}
            onBlur={handleBlur}
        />
    );
}

CustomDate.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
};
CustomDate.defaultProps = {
    dateFormat: "DD/MM/YYYY",
    inputProps: { placeholder: "DD/MM/YYYY" },
};
