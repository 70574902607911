import { useState, useEffect } from "react";
import { getStudyTasks, getStudyTask } from "../../../api/protocolAdmin";

export const useStudyTasks = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        const fetchData = () => {
            setIsLoading(true);
            setReload(false);
            getStudyTasks().then(response => {
                setData(response.data);
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setData([]);
                setIsLoading(false);
            });
        };

        fetchData();
    }, [reload]);

    return [data, isLoading, setReload];
}

export const useStudyTask = (studyTaskId) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [reload, setReload] = useState(false);

    useEffect(() => {
        const fetchData = () => {
            setIsLoading(true);
            setReload(false);
            getStudyTask(studyTaskId).then(response => {
                setData(response.data);
                setIsLoading(false);
            }).catch(error => {
                console.log(error);
                setData({});
                setIsLoading(false);
            });
        };

        fetchData();
    }, [studyTaskId, reload]);

    return [data, isLoading, setReload];
}