import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { Modal as BootstrapModal } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { getPractitioner } from '../../../api/protocol';
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import { PractitionerForm } from "../../../components/Protocols/Practitioners";
import { getProtocolPractitionersSchema } from "../../../components/Validations/FormValidationSchema";
import { useStudyRoles } from "../../Security/Authorization/useStudyRoles";
import { useStudyTasks } from '../Hooks';

export const EditPractitionerModal = (props) => {
    const { isShowing, hide, protocolId, practitionerId, submit } = props;
    const { t } = useTranslation();

    return isShowing ? ReactDOM.createPortal(
        <React.Fragment>
            <BootstrapModal show={true} onHide={hide} bsSize="large" backdrop="static" >
                <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title>{t('protocols.practitioner.editPractitionerTitle')}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <ProtocolEditPractitionerContainer protocolId={protocolId} practitionerId={practitionerId} onClose={hide} onSubmit={submit} />
                </BootstrapModal.Body>
            </BootstrapModal>
        </React.Fragment>, document.body
    ) : null;
};

const ProtocolEditPractitionerContainer = (props) => {
    const protocolId = props.protocolId;
    const practitionerUserId = props.practitionerId;
    const studyTasks = useStudyTasks(protocolId);
    const [studyRoles] = useStudyRoles();

    const [isLoading, setIsLoading] = useState(false);
    const [practitioner, setPractitioner] = useState(null);
    useEffect(() => {
        const fetchfData = () => {
            setIsLoading(true);
            getPractitioner(protocolId, practitionerUserId)
                .then(response => {
                    var res = response.data
                    //console.log("GET Practitioner In Protocol: ", res);

                    setPractitioner(res);
                    setIsLoading(false);
                }).catch(error => {
                    HandleApiError(error);
                    setIsLoading(false);
                });
        }

        fetchfData();
    }, [protocolId, practitionerUserId]);

    const handleSubmit = (practitioner) => {
        props.onSubmit(practitioner);
    }

    const handleCancel = () => {
        props.onClose();
    }

    if (!practitioner || !studyTasks || !studyRoles)
        return '';

    return (
        <ProtocolEditPractitionerFormik
            protocolId={protocolId}
            practitionerUserId={practitionerUserId}
            practitioner={practitioner}
            studyTasks={studyTasks}
            roles={studyRoles ? studyRoles : []}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            isLoading={isLoading}
        />
    );
}

const ProtocolEditPractitionerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => ({ practitioner: props.practitioner }),

    validationSchema: getProtocolPractitionersSchema(),

    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values.practitioner);
    },

    displayName: 'PractitionerForm',

})(PractitionerForm));

ProtocolEditPractitionerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    protocolId: PropTypes.string.isRequired,
    practitionerUserId: PropTypes.string.isRequired,
    studyTasks: PropTypes.array.isRequired,
};