import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Button, Modal as BootstrapModal } from 'react-bootstrap';

const RouteChangeDetect = ({ when, confirmCallback }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState(null);

    const handleBlockedNavigation = (nextLocation, _action) => {

        if (!lastLocation || lastLocation.pathname !== nextLocation.pathname) {
            setModalVisible(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = () => {
        setModalVisible(false);
        confirmCallback(lastLocation.pathname, false);
    };

    const handleSaveClick = () => {
        setModalVisible(false);
        confirmCallback(lastLocation.pathname, true);
    };

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            <ConfirmChangeModal
                onCancel={handleConfirmNavigationClick}
                onConfirm={handleSaveClick}
                visible={modalVisible} />
        </>
    );
};
export default RouteChangeDetect;

RouteChangeDetect.propTypes = {
    when: PropTypes.bool.isRequired,
    confirmCallback: PropTypes.func.isRequired,
};

const ConfirmChangeModal = (props) => {
    const { t } = useTranslation();

    return props.visible ? ReactDOM.createPortal(
        <React.Fragment>
            <SweetAlert
                warning
                closeOnClickOutside={false}
                title="Hay cambios pendientes sin guardar. Si sale de esta pantalla, perderá los datos"
                onConfirm={() => props.onConfirm()}
                onCancel={() => { props.onCancel() }}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText={t("buttons.saveAndContinue")}
                cancelBtnText={t("buttons.exit")}
                showCancel
                reverseButtons
            >
            </SweetAlert>
        </React.Fragment>, document.body
    ) : null;
}

ConfirmChangeModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
};

export function RouterPrompt(props) {
    const { when, title } = props;

    const history = useHistory();

    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState("");

    const unblockRef = useRef();

    function handleShowModal() {
        setShowPrompt(true);
    }

    function onCancel() {
        setShowPrompt(false);
    }

    useEffect(() => {
        unblockRef.current = history.block((location) => {
            if (when) {
                setCurrentPath(location.pathname);
                handleShowModal();
                return false;
            }
            return true;
        });
        return () => {
            unblockRef.current && unblockRef.current();
        };
    }, [when]);

    function handleConfirm() {
        if (unblockRef) {
            unblockRef.current();
        }
        setShowPrompt(false);
        history.push(currentPath);
    }

    return (
        <BootstrapModal show={showPrompt} onHide={onCancel} bsSize="large" backdrop="static" >
            <BootstrapModal.Header closeButton>
                <BootstrapModal.Title>{title}</BootstrapModal.Title>
            </BootstrapModal.Header>
            <BootstrapModal.Body>
                pepepepep
                <Button onClick={onCancel}>Stay on page</Button>
                <Button onClick={handleConfirm} autoFocus>
                    Leave
                </Button>
            </BootstrapModal.Body>
        </BootstrapModal>
    );
}