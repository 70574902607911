import React from "react";
import { Button, Modal as BootstrapModal } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation } from "react-i18next";

const SessionTimeoutDialog = ({ open, countdown, onLogout, onContinue }) => {
  const { t } = useTranslation();
  return open ? ReactDOM.createPortal(
    <React.Fragment>
      <BootstrapModal show={true} backdrop="static" >
        <BootstrapModal.Header>
          <BootstrapModal.Title>{t("security.logout.logout")}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <div>
            {t("security.logout.inactivity_logout", { countdown })}
          </div>
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
          <Button onClick={onLogout}>
            {t("security.logout.logout_button")}
          </Button>
          <Button onClick={onContinue}>
            {t("security.logout.logout_continue")}
          </Button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    </React.Fragment>, document.body
  ) : null;
};

export default SessionTimeoutDialog;