import React from "react";
export default function Dot({ color }) {
    return (
        <span
            style={{
                height: '10px',
                width: '10px',
                backgroundColor: color,
                borderRadius: '50%',
                display: 'inline-block',
            }}
        />
    );
};