import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import { WithAuth } from '../../components/Authorization/WithAuth';
//Components
import Card from '../../components/Cards/Card';
import { Add } from '../../components/Icons/Icons';
import { Tenants } from '../../components/Tenants/Tenants';
import { useTenants } from './Hooks';

export const TenantsContainer = () => {
    const { t } = useTranslation();

    const [tenants, isLoading, getAll] = useTenants(); //, get, create, update, remove

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = async () => {
            await getAll();
        }

        fetchData();

        return () => isSubscribed = false;
    }, []);

    return (
        <Card
            tabs
            header={
                <>
                    <div className="row">
                        <div className="col-md-2">
                            <WithAuth requiredPermission={["Tenant.Create"]}>
                                <NavLink to="/admin/tenant/create" className="btn btn-primary" activeClassName="active">
                                    <Add /> {t('security.tenant.add')}
                                </NavLink>
                            </WithAuth>
                        </div>
                    </div>
                </>
            }
            content={
                <Tenants
                    data={tenants}
                    isLoading={isLoading}
                />
            }
        />
    );
}