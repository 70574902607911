import { useState } from 'react';

export const useModalData = () => {
    const [isShowing, setIsShowing] = useState(false);
    const [content, setContent] = useState("");

    function toggle() {
        setIsShowing(!isShowing);
    }

    return [
        isShowing,
        toggle,
        content,
        setContent
    ]
};