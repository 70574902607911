import React from 'react'
import { Col, Grid, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Card from '../Cards/Card'
import { IconSVG } from '../Icons/Icons'

export const PersonalHabits = (props) => {
    const { t } = useTranslation();
    const { data, withCard } = props;

    const content = (
        <Grid fluid className="personal-habits">
            <Row>
                {
                    data && data.habits && data.habits.length > 0 ? (
                        data.habits.map((habit, index) => (
                            <Col sm={3} md={6} key={index}>
                                <div className="">
                                    <div className="icon"><IconSVG name={habit.habit} fill={habit.hasHabit ? "#20ad9b" : "#cecece"} /></div>
                                    <div>
                                        <div className="group">{habit.habit}</div>
                                        <div className="title">{habit.hasHabit ? habit.count : "No consume"}</div>
                                        <div className="description">{habit.hasHabit ? habit.fromTo : ""}</div>
                                    </div>
                                </div>
                            </Col>
                        ))
                    ) : ""
                }
                <Col sm={3} md={6}>
                    <div className="">
                        <div className="icon"><IconSVG name="Educacion" fill="#20ad9b" /></div>
                        <div>
                            <div className="group">{t("medicalRecords.personalHabits.education")} {data.scholarshipYears && data.scholarshipYears > 0 && data.scholarshipYears + " años"}</div>
                            <div className="title">{data.scholarship} {data.scholarshipCompleted && "Completo"} </div>
                            <div className="description">{data.readsWrites ? "Lee y escribe" : ""}</div>
                        </div>
                    </div>
                </Col>
                <Col sm={3} md={6}>
                    <div className="">
                        <div className="icon"><IconSVG name="Ocupacion" fill="#20ad9b" /></div>
                        <div>
                            <div className="group">{t("medicalRecords.personalHabits.occupation")}</div>
                            <div className="title">{data.occupation}</div>
                            <div className="description">{data.workHours} hs</div>
                        </div>
                    </div>
                </Col>
                <Col sm={3} md={6}>
                    <div className="">
                        <div className="icon"><IconSVG name="Dieta" fill="#20ad9b" /></div>
                        <div>
                            <div className="group">{t("medicalRecords.personalHabits.diet")}</div>
                            <div className="title">{data.diet}</div>
                            <div className="description"></div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Grid>
    );

    if (withCard)
        return (
            <Card
                tabs
                className="card-personal-habits"
                content={content}
            />
        );
    else
        return <div className="card-personal-habits">{content}</div>;
}

PersonalHabits.defaultProps = {
    withCard: true,
}

