import { useState } from "react"
import useLoading from "../../components/Common/useLoading"
import DeviceService from "../../api/Freezer/DeviceService"
import { PaginationRequest, PaginationResult } from "../../api/Freezer/Util"
import { ErrorMessage } from "../../components/Notifications/Notifications"

const propsTemplate = { ...new PaginationRequest() }
export default function useIotDevices(props = propsTemplate) {
    const [pagedDevices, setPagedDevices] = useState(new PaginationResult())
    const [isLoading, useLoad, reload] = useLoading({
        onLoad: async () => {
            const result = await DeviceService.Find({ ...propsTemplate })
            setPagedDevices(result)
        },
        onError: () => {
            setPagedDevices(new PaginationResult())
        }
    })

    const Navigation = {
        ToPage: async ({ page }) => useLoad(async () => {
            const request = pagedDevices.ToPage(page);

            if (request == null) return

            const result = await DeviceService.Find(request)
            setPagedDevices(result)
        }),
        Reload: reload
    }

    return [pagedDevices, isLoading, Navigation]
}