import PropTypes from 'prop-types';
import React from 'react';
import { conditions } from '../../variables/Enums';
import { Audit } from '../Icons/Icons';
import { Tooltip } from '../Utils/Tooltips';
import { useTranslation } from 'react-i18next';

const Conditions = ({ data, onAuditTrail }) => {
    const { t } = useTranslation();
    if (!data)
        return null;

    return (
        <div className='condition'>
            {
                data.map((condition, idx) =>
                    <div key={idx + condition.id} className="label-condition" bsStyle="info" style={{ marginRight: 6 }}>
                        {condition.adverseEffect}
                        {
                            condition.hasAudit &&
                            <Tooltip tooltip={t("auditTrail.view")} id={"tooltipaudit-" + condition.id}>
                                <button className="btn-label-audit" onClick={() => onAuditTrail(condition.originalId && condition.originalId > 0 ? condition.originalId : condition.id)}>
                                    <Audit />
                                </button>
                            </Tooltip>
                        }
                        {
                            condition.intensity &&
                            <div style={{ fontSize: 12 }}>
                                {t("medicalRecords.adverseEvents.intensity")}: {condition.intensity}
                            </div>
                        }
                        {
                            condition.category === conditions.diagnosis && condition.from &&
                            <div style={{ fontSize: 12 }}>
                                {t("medicalRecords.adverseEvents.from")}: {condition.from}
                            </div>
                        }
                        {
                            condition.category === conditions.diagnosis && condition.to &&
                            <div style={{ fontSize: 12 }}>
                                {t("medicalRecords.adverseEvents.to")}: {condition.to}
                            </div>
                        }
                    </div>)
            }
        </div>
    );
}

Conditions.propTypes = {
    data: PropTypes.object.isRequired,
    onAuditTrail: PropTypes.func,
};

export default Conditions;