import PropTypes from 'prop-types';
import React from "react";
import { useTranslation } from 'react-i18next';
import { CustomSelect } from "../../CustomControls/CustomSelect";


export const VisitTrackingStatusFilter = (props) => {
    const { t } = useTranslation();
    const {
        selectedStatus,
        onChangeFilter,
    } = props;

    return (
        <CustomSelect
            value={selectedStatus}
            name="status"
            placeholder="Estados del Paciente"
            onChange={onChangeFilter}
            isMulti={false}
            isClearable={true}
            options={[
                { id: 0, code: 'screening', title: t('protocols.patient.statuses.screening') },
                { id: 1, code: 'random', title: t('protocols.patient.statuses.random') },
                { id: 2, code: 'treatment', title: t('protocols.patient.statuses.treatment') },
                { id: 3, code: 'followUp', title: t('protocols.patient.statuses.followUp') },
                { id: 4, code: 'failed', title: t('protocols.patient.statuses.failed') },
            ]}
        />
    );
}

VisitTrackingStatusFilter.propTypes = {
    onChangeFilter: PropTypes.func.isRequired,
    selectedStatus: PropTypes.object,
};

export const VisitTrackingStageFilter = (props) => {
    const { selectedStage, onChangeFilter, } = props;
    const { t } = useTranslation();
    return (
        <>
            <CustomSelect
                value={selectedStage}
                name="stage"
                placeholder="Todas las etapas"
                onChange={onChangeFilter}
                isMulti={false}
                isClearable={true}
                options={[
                    //{ id: null, code: 'all', title: "Todas" },
                    { id: 0, code: 'screening', title: t("protocols.visit.screening") },
                    { id: 1, code: 'random', title: t("protocols.visit.random") },
                    { id: 2, code: 'treatment', title: t("protocols.visit.treatment") },
                    { id: 3, code: 'followUp', title: t("protocols.visit.followUp") },
                ]}
            />
            {/*<Row>
                <Col md={2}>
                    <h5>Etapas a mostrar:</h5>
                </Col>
                <Col md={10}>
                    <button onClick={() => onChangeFilter("stage", null)} className={"btn btn-primary" + (selectedStage === null ? " btn-fill" : "")}>Todas</button>
                    <button onClick={() => onChangeFilter("stage", 0)} className={"btn btn-primary" + (selectedStage === 0 ? " btn-fill" : "")}>{t("protocols.visit.screening")}</button>
                    <button onClick={() => onChangeFilter("stage", 1)} className={"btn btn-primary" + (selectedStage === 1 ? " btn-fill" : "")}>{t("protocols.visit.random")}</button>
                    <button onClick={() => onChangeFilter("stage", 2)} className={"btn btn-primary" + (selectedStage === 2 ? " btn-fill" : "")}>{t("protocols.visit.treatment")}</button>
                    <button onClick={() => onChangeFilter("stage", 3)} className={"btn btn-primary" + (selectedStage === 3 ? " btn-fill" : "")}>{t("protocols.visit.followUp")}</button>
                </Col>
            </Row>*/}
        </>
    );
}

VisitTrackingStageFilter.propTypes = {
    onChangeFilter: PropTypes.func.isRequired,
    selectedStage: PropTypes.object,
};