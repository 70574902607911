import moment from "moment"

export const visitTypeColorGetter = (code, event, returnRGB = true) => {
    switch (code) {
        case "EXTERNAL": return returnRGB ? "#247dbd" : "rbc-event-blue";
        case "EXTERNAL_PHONE": return returnRGB ? "#20ad9b" : "rbc-event-green"
        case "OVERSHIFT": return returnRGB ? "#e0e32c" : "rbc-event-yellow"
        case "PROTOCOL": return returnRGB ? "#ffa534" : "rbc-event-orange"
        case "OTHER": return returnRGB ? "#5e5e5e" : "rbc-event-black"
        case "PROJECT": return returnRGB ? "#9368e9" : "rbc-event-purple"
        case "ESSENTIA": return returnRGB ? "#a6a6a6" : "rbc-event-gray"
        case "STUDIES": return returnRGB ? "#ca6e6e" : "rbc-event-red"
        case "PRESCREENING": return returnRGB ? "#e98101" : "rbc-event-orange";
        case "ORDER": return returnRGB ? "#23ccef" : "rbc-event-default";
        case "SCHEDULELOCK": {
            if (event?.practitioner)
                return returnRGB ? "rgba(255,165,52,0.2)" : "rbc-event-practitioner-lock";
            else
                return returnRGB ? "rgba(94,94,94,0.2)" : "rbc-event-lock";
        }
        default: return returnRGB ? "#23ccef" : "rbc-event-default";
    }
}

const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

export const colourStyles = {
    option: (styles, { data }) => {
        const color = visitTypeColorGetter(data.code);
        return { ...styles, ...dot(color) };
    }
};

export const validateAvailabilityDate = (current, _selected, practitionerAvailability) => {
    if (!practitionerAvailability || !practitionerAvailability.availableWeekDays)
        return false;

    if (!current.isSameOrAfter(moment().startOf('day')))
        return false;

    var availableDate = practitionerAvailability.availableDates.filter(x => moment(x.date).format('DD/MM/YYYY') === current.format('DD/MM/YYYY') && x.available);
    var availableWeekDays = practitionerAvailability.availableWeekDays.includes(current.day());

    return availableWeekDays && availableDate.length > 0;
}

export const validateIsSameOfAfter = (current, dateToCompare) => {
    if (!dateToCompare)
        dateToCompare = moment().startOf('day');

    return moment(current).isSameOrAfter(dateToCompare);
}

export const isValidEventStart = (startDate) => {
    if (moment().isSameOrAfter(startDate)) {
        return false;
    }
    return true;
}

export function getAvailableTimes(start, end, interval) {
    var startTime = moment(start, 'HH:mm');
    var startTimeEnd = moment(start, 'HH:mm').add(interval, 'minutes');
    var endTime = moment(end, 'HH:mm');

    if (endTime.isBefore(startTime)) {
        endTime.add(1, 'day');
    }

    var timeStops = [];

    while (startTime <= endTime) {
        timeStops.push({ appointmentInit: startTime.format('HH:mm'), appointmentEnd: startTimeEnd.format('HH:mm') });
        startTime.add(interval, 'minutes');
        startTimeEnd.add(interval, 'minutes')
    }
    return timeStops;
}