import React, { useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import PropTypes from 'prop-types';
import { FormControl } from "react-bootstrap";

export const CustomFormControl = (props) => {
    const { onChange, onBlur, value, name, type, placeholder, disabled, rows } = props;
    const [innerValue, setInnerValue] = useState('');
    const INPUT_DELAY = 500;

    useEffect(() => {
        if (value !== null && value !== undefined) {
            setInnerValue(value);
        } else {
            setInnerValue('');
        }
    }, [value]);

    const debouncedHandleOnChange = useCallback(
        debounce((name, value) => {
            if (onChange) {
                onChange(name, value);
            }
        }, INPUT_DELAY),
        [],
    );

    const handleOnChange = useCallback((event) => {
        const newValue = event.currentTarget.value;
        setInnerValue(newValue);
        debouncedHandleOnChange(event.currentTarget.name, event.currentTarget.value);
    }, [debouncedHandleOnChange]);

    if (type === "textarea")
        return (
            <FormControl
                {...props}
                disabled={disabled}
                type={type}
                placeholder={placeholder}
                name={name}
                value={innerValue}
                onChange={handleOnChange}
                onBlur={onBlur ?? function () { }}
                rows={rows ?? 1}
                componentClass={"textarea"}
            />
        );

    return (
        <FormControl
            {...props}
            disabled={disabled}
            type={type}
            placeholder={placeholder}
            name={name}
            value={innerValue}
            onChange={handleOnChange}
            onBlur={onBlur ?? function () { }}
        />
    );
};

CustomFormControl.propTypes = {
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    rows: PropTypes.number,
};