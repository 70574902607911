import React from "react";
import { Modal as BootstrapModal } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { Spinner } from "../Utils/Loaders";

export const ModalSpinner = (props) => {
    const { isShowing, hide } = props;

    return isShowing ? ReactDOM.createPortal(
        <React.Fragment>
            <BootstrapModal show={true} onHide={hide} backdrop="static" >
                <BootstrapModal.Body>
                    <Spinner />
                </BootstrapModal.Body>
            </BootstrapModal>
        </React.Fragment>, document.body
    ) : null;
};