import React, { useEffect, useState } from 'react';
import { getPractitioners, updateFormRestrictionsAPI } from "../../../api/protocol";
import { HandleApiError, HandleMessageError } from "../../../components/Notifications/APIErrorHandler";
import { Success } from "../../../components/Notifications/Notifications";
import { FormsRestrictions } from "../../../components/Protocols/Tracking/FormsRestrictions";

export const FormRestrictionsContainer = ({ protocolId, forms, onCancel, onClose }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [practitioners, setPractitioners] = useState(null);

    useEffect(() => {
        let isSubscribed = true;

        const fetchPractitionersData = () => {
            setIsLoading(true);

            getPractitioners(protocolId, {
                pagination: { page: 1, sizePerPage: 1000, totalSize: 0 },
                sort: { sortField: "FullName", sortOrder: "desc" }
            })
                .then(response => {
                    if (isSubscribed) {
                        var paginatedData = response.data
                        setPractitioners(paginatedData.data);
                        setIsLoading(false);
                    }
                }).catch(error => {
                    if (isSubscribed) {
                        HandleApiError(error);
                        setIsLoading(false);
                    }
                });
        }

        fetchPractitionersData();

        return () => isSubscribed = false;
    }, [protocolId]);

    const handleSave = async (userId, restrictions) => {
        try {
            if (!userId) return HandleMessageError("protocolNotifications.visitTracking_formRestrictions_selectUser");
            setIsLoading(true);
            await updateFormRestrictionsAPI(protocolId, restrictions);
            Success("protocolNotifications.visitTracking_formRestrictions_restrictionsSaved");
            setIsLoading(false);
            if (onClose)
                onClose();
        }
        catch (error) {
            console.log(error);
            HandleApiError(error);
            setIsLoading(false);
        }
    }

    const handleCancel = () => {
        if (onCancel)
            onCancel();
    }

    return <FormsRestrictions
        forms={forms}
        practitioners={practitioners}
        onSave={handleSave}
        onCancel={handleCancel}
        isLoading={isLoading}
    />
}