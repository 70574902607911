import React from "react";

export const CustomCheckbox = (props) => {

  const { isChecked, name, label, inline, id, ...rest } = props;
  const classes = inline !== undefined ? "checkbox checkbox-inline" : "checkbox";

  const handleClick = (event) => {
    if (props.onChange)
      props.onChange(props.name, event.currentTarget.checked, id);
  }

  return (
    <div className={classes}>
      <input
        id={id ?? name}
        name={name}
        type="checkbox"
        onClick={handleClick}
        checked={isChecked}
        {...rest}
      />
      <label htmlFor={id ?? name}>{label}</label>
    </div>
  );
}
