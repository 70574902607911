import React from "react";
import PropTypes from 'prop-types';
import Select from 'react-select'
import { usePractitioners } from "../../containers/Users/Hooks";

export const PractitionerSelect = (props) => {

    const [users, isLoading] = usePractitioners({
        pagination: {
            sizePerPage: 100,
        },
        onlyDoctor: props.onlyDoctor
    });

    const handleChange = value => {
        if (props.onChange)
            props.onChange(props.name, value);
    };

    const handleBlur = () => {
        if (props.onBlur)
            props.onBlur(props.name, true);
    };

    return (
        <Select
            {...props}
            className="react-select"
            classNamePrefix="practitioner-select"
            isLoading={isLoading}
            placeholder={props.placeholder}
            options={users}
            onChange={handleChange}
            onBlur={handleBlur}
            value={props.value}
        />
    );
}

PractitionerSelect.defaultProps = {
    getOptionLabel: (option) => option.fullName,
    getOptionValue: (option) => option.userId,
    isClearable: false,
    onlyDoctor: true
};
PractitionerSelect.propTypes = {
    isMulti: PropTypes.bool,
    optionValue: PropTypes.func,
    optionLabel: PropTypes.func,
    isClearable: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onlyDoctor: PropTypes.bool
};