import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React, { Fragment, useContext } from 'react';
import { Col, FormControl, FormGroup, Grid, Row } from 'react-bootstrap';
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from 'react-i18next';
import { getAttachmentEndpoint } from '../../api/protocol';
import ProtocolContext from '../../containers/Protocols/ProtocolContext/ProtocolContext';
import { conceptType, snomedExpressions } from '../../variables/Enums';
import Card from '../Cards/Card';
import { CustomSelect } from "../CustomControls/CustomSelect";
import { Files, FilesForm } from '../CustomControls/Files/Files';
import { PractitionerSelect } from "../CustomControls/PractitionerSelect";
import { SnomedPicker } from "../CustomControls/SnomedPicker";
import { Save, Statistics } from '../Icons/Icons';
import { dateFormatter } from '../Utils/Formatter';
import { FormErrorMessage } from '../Validations/FormValidationErrors';
import { useAuthorization } from '../Authorization/useAuthorization';

export const Brief = () => {
    const { t } = useTranslation();
    const context = useContext(ProtocolContext);

    if (!context || context.isLoading) {
        return null;
    }

    return (
        <Fragment>
            <Card
                tabs
                content={
                    <Fragment>
                        <Grid fluid>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Row>
                                            <Col sm={3}>
                                                <div className="card-label">{t("protocols.brief.fullTitle")}</div>
                                            </Col>
                                            <Col sm={9}>
                                                <div className="card-text">{context.protocol.fullTitle}</div>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col sm={3}>
                                                <div className="card-label">{t("protocols.brief.specialty")}</div>
                                            </Col>
                                            <Col sm={9}>
                                                <div className="card-text">{context.protocol.specialty?.description}</div>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col sm={3}>
                                                <div className="card-label">{t("protocols.brief.indication")}</div>
                                            </Col>
                                            <Col sm={9}>
                                                <div className="card-text">{context.protocol.indication?.description}</div>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col sm={3}>
                                                <div className="card-label">{t("protocols.brief.patientsObjective")}</div>
                                            </Col>
                                            <Col sm={9}>
                                                <div className="card-text">{context.protocol.patientsObjective}</div>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col sm={3}>
                                                <div className="card-label">{t("protocols.brief.keywords.title")}</div>
                                            </Col>
                                            <Col sm={9}>
                                                {context.protocol.keywords && context.protocol.keywords.map((x, idx) => {
                                                    return <div key={`keywords-${idx}`} className="card-text">{x.description}</div>
                                                })}
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col sm={3}>
                                                <div className="card-label">{t("protocols.brief.protocolNumber")}</div>
                                            </Col>
                                            <Col sm={4}>
                                                <div className="card-text">{context.protocol.protocolNumber}</div>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col sm={3}>
                                                <div className="card-label">{t("protocols.brief.centerNumber")}</div>
                                            </Col>
                                            <Col sm={4}>
                                                <div className="card-text">{context.protocol.centerNumber}</div>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <div className="card-label">{t("protocols.brief.attachments")}</div>
                                    <Files
                                        attachments={context.protocol.attachments}
                                        endpoint={getAttachmentEndpoint(context.protocol.id)}
                                        showRemove={false}
                                    />
                                </Col>
                            </Row>
                        </Grid>
                    </Fragment>
                }
            />
            <Card
                className="card-icon-data"
                content={
                    <Grid fluid>
                        <Row className="display-flex">
                            <Col sm={2}>
                                <div className="icon-box"><Statistics /></div>
                            </Col>
                            <Col sm={10}>
                                <table className="table table-responsive">
                                    <thead>
                                        <tr>
                                            <th>
                                                {t("protocols.brief.stats.goal")}
                                            </th>
                                            <th>
                                                {t("protocols.brief.stats.goal_percent")}
                                            </th>
                                            <th>
                                                {t("protocols.brief.stats.screening")}
                                            </th>
                                            <th>
                                                {t("protocols.brief.stats.select")}
                                            </th>
                                            <th>
                                                {t("protocols.brief.stats.fail")}
                                            </th>
                                            <th>
                                                {t("protocols.brief.stats.random")}
                                            </th>
                                            <th>
                                                {t("protocols.brief.stats.treatment")}
                                            </th>
                                            <th>
                                                {t("protocols.brief.stats.nodrugs")}
                                            </th>
                                            <th>
                                                {t("protocols.brief.stats.lostoffollowup")}
                                            </th>
                                            <th>
                                                {t("protocols.brief.stats.finished")}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="statistics">
                                            <td className="goal">{context.protocol.patientsObjective}</td>
                                            <td className="default">{context.protocol.patientsGoalPercent}</td>
                                            <td className="scrn">{context.protocol.patientsScreening}</td>
                                            <td className="scrn">{context.protocol.patientsSelection}</td>
                                            <td className="fail">{context.protocol.patientsFailed}</td>
                                            <td className="default">{context.protocol.patientsRandom}</td>
                                            <td className="default">{context.protocol.patientsTreatment}</td>
                                            <td className="default">{context.protocol.patientsWithoutDrugs}</td>
                                            <td className="fail">{context.protocol.patientsLostToFollowUp}</td>
                                            <td className="default">{context.protocol.patientsCompleted}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Grid>
                }
            />
        </Fragment>
    );
}

export const BriefForm = props => {

    const {
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched
    } = props;

    const { t } = useTranslation();
    const [, user] = useAuthorization();

    if (!values.brief) {
        values.brief = {
            laboratory: '',
            title: '',
            fullTitle: '',
            phase: '',
            attachments: [],
            patientsObjective: 0,
            status: {},
            indication: '',
            principalInvestigator: '',
            protocolNumber: '',
            centerNumber: '',
            studyRole: ''
        };
    }

    if (!values.brief.id && !values.brief.studyTasks)
        values.brief.studyTasks = props.masterData.StudyTasks;

    if (values.brief.id && !values.brief.studyTasks && props.masterData.StudyTasks && props.masterData.StudyTasks.length > 0) {
        values.brief.studyTasks = props.masterData.StudyTasks.filter(x => -1 !== values.brief.protocolStudyTasks.findIndex(y => (y.studyTaskId === x.id)));
    }

    const title = values.brief.created ? t("protocols.brief.editProtocol") + " " + values.brief.title : t("protocols.brief.newProtocol");
    const subtitle = values.brief.created && (<div>{t("protocols.brief.created")} {dateFormatter(values.brief.created, "DD/MMM/YYYY HH:mm")}</div>);

    return (
        <Card
            title={title}
            subtitle={subtitle}
            ctFullWidth
            content={
                <form onSubmit={handleSubmit} autoComplete="off">
                    <Grid fluid>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Row>
                                        <Col sm={3}>
                                            <div className="card-label">{t("protocols.brief.laboratory")}</div>
                                        </Col>
                                        <Col sm={9}>
                                            <CustomSelect
                                                value={values.brief.laboratory}
                                                name={`brief.laboratory`}
                                                placeholder={t("commons.select")}
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                options={props.masterData.Laboratories} />
                                            <ErrorMessage name={`brief.laboratory`} component={FormErrorMessage} />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col sm={3}>
                                            <div className="card-label">{t("protocols.brief.title")}</div>
                                        </Col>
                                        <Col sm={9}>
                                            <FormControl type="text" name={`brief.title`} value={values.brief.title} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                            <ErrorMessage name={`brief.title`} component={FormErrorMessage} />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col sm={3}>
                                            <div className="card-label">{t("protocols.brief.fullTitle")}</div>
                                        </Col>
                                        <Col sm={9}>
                                            <FormControl componentClass="textarea" name={`brief.fullTitle`} value={values.brief.fullTitle} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                            <ErrorMessage name={`brief.fullTitle`} component={FormErrorMessage} />
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Row>
                                        <Col sm={3}>
                                            <div className="card-label">{t("protocols.brief.phase")}</div>
                                        </Col>
                                        <Col sm={4}>
                                            <CustomSelect
                                                value={values.brief.phase}
                                                name={`brief.phase`}
                                                placeholder={t("commons.select")}
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                options={props.masterData.Phases} />
                                            <ErrorMessage name={`brief.phase`} component={FormErrorMessage} />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col sm={3}>
                                            <div className="card-label">{t("protocols.brief.protocolNumber")}</div>
                                        </Col>
                                        <Col sm={4}>
                                            <FormControl type="text" name={`brief.protocolNumber`} value={values.brief.protocolNumber} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                            <ErrorMessage name={`brief.protocolNumber`} component={FormErrorMessage} />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col sm={3}>
                                            <div className="card-label">{t("protocols.brief.centerNumber")}</div>
                                        </Col>
                                        <Col sm={4}>
                                            <FormControl type="text" name={`brief.centerNumber`} value={values.brief.centerNumber} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                            <ErrorMessage name={`brief.centerNumber`} component={FormErrorMessage} />
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Grid>
                    <Grid fluid>
                        <FormGroup>
                            <Row>
                                <Col sm={2}>
                                    <div className="card-label">{t("protocols.brief.specialty")}</div>
                                </Col>
                                <Col sm={9}>
                                    <SnomedPicker
                                        defaultSelected={values.brief.specialty ? [values.brief.specialty] : []}
                                        id={`brief.specialty`}
                                        value={values.brief.specialty}
                                        name={`brief.specialty`}
                                        placeholder={t("commons.search")}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        conceptId={"394658006"} // Especialidad clínica
                                    />
                                    <ErrorMessage name={`brief.specialty`} component={FormErrorMessage} />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm={2}>
                                    <div className="card-label">{t("protocols.brief.indication")}</div>
                                </Col>
                                <Col sm={9}>
                                    <SnomedPicker
                                        conceptType={conceptType.events}
                                        defaultSelected={values.brief.indication ? [values.brief.indication] : []}
                                        id={`brief.indication`}
                                        value={values.brief.indication}
                                        name={`brief.indication`}
                                        placeholder={t("commons.search")}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        conceptId={"64572001"} // Trastorno
                                    />
                                    <ErrorMessage name={`brief.indication`} component={FormErrorMessage} />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm={2}>
                                    <div className="card-label">{t("protocols.brief.patientsObjective")}</div>
                                </Col>
                                <Col sm={2}>
                                    <FormControl type="number" name={`brief.patientsObjective`} value={values.brief.patientsObjective} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                    <ErrorMessage name={`brief.patientsObjective`} component={FormErrorMessage} />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm={2}>
                                    <div className="card-label">{t("protocols.brief.principalInvestigator")}</div>
                                </Col>
                                <Col sm={2}>
                                    <PractitionerSelect
                                        value={values.brief.principalInvestigator}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        name={`brief.principalInvestigator`}
                                        placeholder={t("commons.select")}
                                    />
                                    <ErrorMessage name={`brief.principalInvestigator`} component={FormErrorMessage} />
                                </Col>
                                {
                                    !values.brief.id && // O sea, si estoy creando protocolo (No muestra en Edición)
                                    <>
                                        <Col sm={2}>
                                            <div className="card-label">{t("protocols.brief.studyRole")}</div>
                                        </Col>
                                        <Col sm={2}>
                                            <CustomSelect
                                                value={values.brief.role}
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                name={`brief.studyRole`}
                                                options={props.studyRoles}
                                                getOptionLabel={(x) => (x.name)}
                                                placeholder={t("commons.select")}
                                            />
                                            <ErrorMessage name={`brief.studyRole`} component={FormErrorMessage} />
                                        </Col>
                                    </>
                                }
                            </Row>
                        </FormGroup>
                        {
                            !values.brief.id && // O sea, si estoy creando protocolo (No muestra en Edición)
                            <FormGroup>
                                <Row>
                                    <Col sm={2}>
                                        <div className="card-label">{t("protocols.brief.currentUser")}</div>
                                    </Col>
                                    <Col sm={2}>
                                    </Col>
                                    <Col sm={2}>
                                        <div className="card-label">{t("protocols.brief.studyRole")}</div>
                                    </Col>
                                    <Col sm={2}>
                                        <CustomSelect
                                            value={values.brief.currentUserStudyRole}
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            name={`brief.currentUserStudyRole`}
                                            options={props.studyRoles}
                                            getOptionLabel={(x) => (x.name)}
                                            placeholder={t("commons.select")}
                                        />
                                        <ErrorMessage name={`brief.currentUserStudyRole`} component={FormErrorMessage} />
                                    </Col>
                                </Row>
                            </FormGroup>
                        }
                    </Grid>
                    <Grid fluid>
                        {
                            values.brief.id ?
                                <FormGroup>
                                    <Row>
                                        <Col sm={2}>
                                            <div className="card-label">{t("protocols.brief.attachments")} Actual</div>
                                        </Col>
                                        <Col sm={10}>
                                            <Files
                                                attachments={values.brief.attachments}
                                                endpoint={getAttachmentEndpoint(values.brief.id)}
                                                onRemove={props.onRemoveAttach}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup> : null
                        }
                        <FormGroup>
                            <Row>
                                <Col sm={2}>
                                    <div className="card-label">Nueva {t("protocols.brief.attachments")}</div>
                                </Col>
                                <Col sm={9}>
                                    <FilesForm
                                        placeholder={t("protocols.brief.attachments")}
                                        name={`brief.attachments`}
                                        onChange={setFieldValue}
                                        showRemove={false}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm={2}>
                                    <div className="card-label">{t("protocols.brief.keywords.title")}</div>
                                </Col>
                                <Col sm={9}>
                                    <SnomedPicker
                                        conceptType={conceptType.drugs}
                                        defaultSelected={values.brief.keywords ?? []}
                                        id={`brief.keywords`}
                                        value={values.brief.keywords}
                                        name={`brief.keywords`}
                                        placeholder={t("commons.search")}
                                        multiple={true}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        //conceptId={"64572001"} // Trastorno
                                        expression={`< 64572001 OR < ${snomedExpressions.Sustancia} OR < ${snomedExpressions.Medicamentos}`} // Trastorno o Sustancia o Producto
                                    />
                                    <ErrorMessage name={`brief.keywords`} component={FormErrorMessage} />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm={2}>
                                    <div className="card-label">{t("protocols.brief.studyTasks")}</div>
                                </Col>
                                <Col sm={9}>
                                    <CustomSelect
                                        value={values.brief.studyTasks}
                                        name={`brief.studyTasks`}
                                        placeholder={t("commons.select")}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        isMulti={true}
                                        options={props.masterData.StudyTasks}
                                        isClearable={true}
                                        closeMenuOnSelect={false} />
                                </Col>
                            </Row>
                        </FormGroup>
                    </Grid>
                    <Grid fluid>
                        <Row>
                            <Col sm={12}>
                                <button type="submit" className="btn btn-success btn-right" disabled={props.isSubmitting}><Save />{t("buttons.save")}</button>
                                <button type="button" className="btn btn-default  btn-right" onClick={props.onCancel} style={{ marginRight: '10px' }}>{t("buttons.cancel")}</button>
                            </Col>
                        </Row>
                    </Grid>
                </form>
            }
        />
    );
}

export const CloseProtocolModal = (props) => {
    const { t } = useTranslation();
    return (
        <SweetAlert
            warning
            title={props.title}
            onConfirm={props.onConfirm}
            onCancel={() => { props.onClose() }}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="info"
            confirmBtnText={t("buttons.confirm")}
            cancelBtnText={t("buttons.cancel")}
            showCancel
        >
            <span>{props.children}</span>
        </SweetAlert>
    );
}
CloseProtocolModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
};