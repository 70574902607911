import React from 'react';
import useFreezers from '../useFreezers';
import { EnumValue } from '../../../api/Freezer/Entities/Utils';
import Card from '../../../components/Cards/Card';
import Loader from '../../../components/Common/Loader';
import { Col, Row } from 'react-bootstrap';
import { useIotTelemetry } from '../useIotMessages';
import { SearchHeader } from './Components/SearchHeader';
import { DoorChart, HumidityChart, TemperatureChart } from './Components/TelemetryCharts';
import { SearchHeaderContext } from './Components/SearchHeader';

export function FreezerTelemetryContainer() {
    return (
        <SearchHeaderContext>
            <FreezerTelemetry />
        </SearchHeaderContext>
    )
}

export function FreezerTelemetry() {
    const [freezers, isFreezersLoading] = useFreezers({ pageSize: 50 })
    const [telemetry, isTelemetryLoading, search] = useIotTelemetry()

    const handleSearch = (searchObject) => {
        if (!searchObject || !searchObject.selected) return
        search({ from: searchObject.from, to: searchObject.to, freezerId: searchObject.selected.Value })
    }
    const freezerOptions = () => freezers.Values.map(f => new EnumValue(f.Id, f.Description))

    return (<>
        <Loader isLoading={isFreezersLoading}>
            <Card
                header={<SearchHeader options={freezerOptions()} handleSearch={handleSearch} name={"FreezerTelemetry"} />}
                content={
                    <div style={{ margin: '0 auto' }} className="text-center">
                        <Loader isLoading={isTelemetryLoading}>
                            <div style={{ width: '90%', margin: '0 auto' }}>
                                <Row>
                                    <Col md={12} lg={6}>
                                        <Card content={<TemperatureChart data={telemetry.temperature} />} />
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <Card content={<HumidityChart data={telemetry.humidity} />} />
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <Card content={<DoorChart data={telemetry.door} />} />
                                    </Col>
                                </Row>
                            </div>
                        </Loader>
                    </div>
                }
            />

        </Loader >
    </>)
}


