import React from "react"
import './Field.css'

export const Field = ({ title, value }) => {
    return (<div className='info-container'>
        <div>
            <span className="info-label">{title}: </span>
        </div>
        <div>
            <span className="info-text">{value}</span>
        </div>
    </div>)
}