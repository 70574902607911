import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { FormGroup, InputGroup } from 'react-bootstrap';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { searchProtocolsAPI } from '../../api/agenda';
import '../../assets/sass/lbd/_asyncTypeahead.scss';
import { Search } from '../Icons/Icons';
import { HandleApiError } from "../Notifications/APIErrorHandler";
import { useTranslation } from 'react-i18next';

export const ProtocolPicker = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const { t } = useTranslation();

    const handleChange = value => {
        if (value.length > 0)
            props.onChange(props.name, value);
        else
            props.onChange(props.name, null);
    };

    const handleBlur = () => {
        if (props.onBlur)
            props.onBlur(props.name, true);
    };

    const onSearch = async (query) => {
        setIsLoading(true);
        try {
            let response = await searchProtocolsAPI(query);
            setOptions(response.data);
            setIsLoading(false);
        }
        catch (error) {
            setIsLoading(false);
            setOptions([]);
            HandleApiError(error);
        }
    }

    const renderMenuItemChildren = (option, { text }, index) => {
        return (
            <Fragment key={index}>
                <Highlighter search={text}>
                    {option.title}
                </Highlighter>
                <div>
                    <small>
                        {option.specialty && <div><b>{t("person.speciality")}:</b> <Highlighter search={text}>{option.specialty.description}</Highlighter></div>}
                        {option.protocolNumber && <div><b>{t("protocols.brief.protocolNumber")}</b>: <Highlighter search={text}>{option.protocolNumber}</Highlighter></div>}
                    </small>
                </div>
            </Fragment>
        )
    };

    const typeahead = (<AsyncTypeahead
        {...props}
        renderMenuItemChildren={renderMenuItemChildren}
        labelKey="title"
        filterBy={() => true}
        options={options}
        isLoading={isLoading}
        clearButton={true}
        highlightOnlyResult={true}
        allowNew={false}
        delay={1000}
        onSearch={onSearch}
        onChange={handleChange}
        onBlur={handleBlur}
    />);

    if (props.showSearchIcon)
        return (
            <FormGroup>
                <InputGroup>
                    <InputGroup.Addon><Search /></InputGroup.Addon>
                    {typeahead}
                </InputGroup>
            </FormGroup>
        );

    return typeahead;
}

ProtocolPicker.propTypes = {
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    minLength: PropTypes.number,
    showSearchIcon: PropTypes.bool,
    onlyDoctor: PropTypes.bool,
};

ProtocolPicker.defaultProps = {
    multiple: false,
    onChange: () => { },
    minLength: 2,
    showSearchIcon: false,
};