import { Freezer } from "./Freezer";

export function ProtocolFullData({ protocol, configuration, freezerDaysResume, freezer }) {
    this.Protocol = protocol;
    this.Configuration = configuration;
    this.FreezerDaysResume = freezerDaysResume;
    this.Freezer = freezer;
}

export function FreezerTelemetryResume({ freezerId, days, freezer, protocol }) {
    this.FreezerId = freezerId;
    this.Freezer = freezer ?? new Freezer({ description: "Empty", type: "ultra freezer" });
    this.Days = days?.map(d => new TemperatureDayResume(d)) ?? []
    this.Protocol = protocol ?? null;

    this.HasAnyInvalidTemperature = function (protocolConfiguration) {
        return this.Days.some(d => d.HasInvalidTemperature(protocolConfiguration))
    }
}

export function TemperatureDayResume({ day, minTemperature, maxTemperature }) {
    this.Day = day;
    this.MinTemperature = minTemperature;
    this.MaxTemperature = maxTemperature;

    this.HasInvalidTemperature = function (protocolConfiguration) {
        return protocolConfiguration.IsTemperatureValid(this.MinTemperature)
            && protocolConfiguration.IsTemperatureValid(this.MaxTemperature)
    }
}