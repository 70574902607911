import React from 'react';
import { useTranslation } from 'react-i18next';
import { Warning } from '../Icons/Icons';
import { dateFormatter, toLocaleDateTimeFormatter } from '../Utils/Formatter';
import { EntryItemsTable } from './EntryItemsTable';

export const Anthropometrics = (props) => {
    const { t } = useTranslation();

    const columns = [
        {
            dataField: 'created', text: t('commons.date'), formatter: function (cell) {
                return props.inProtocol ? toLocaleDateTimeFormatter(cell, "DD/MM/YY") : dateFormatter(cell, "DD/MM/YY")
            }, headerClasses: 'header-date'
        },
        { dataField: 'weight', text: t('medicalRecords.anthropometrics.weight') },
        { dataField: 'height', text: t('medicalRecords.anthropometrics.height') },
        {
            dataField: 'bmi', text: t('medicalRecords.anthropometrics.bmi'), formatter: function (_cell, row) {
                var bmiColor = "#20ad9b"; //Normopeso (18.5-24.9 kg/m2 ) 
                var showWarning = false;
                if (row.bmi < 18.5) {
                    showWarning = true;
                    bmiColor = "#ca6e6e";  //Bajo peso (<18.5 kg/m2)
                } else if (row.bmi >= 25 && row.bmi < 30) {
                    showWarning = true;
                    bmiColor = "#ffa534"; //Sobrepeso (25-29.9 kg/m2 ) 
                } else if (row.bmi >= 30) {
                    bmiColor = "#ca6e6e"; //Obesidad
                    showWarning = true;
                }

                return <div style={{ color: bmiColor }}>{row.bmi} {showWarning && <Warning />}</div>;
            }
        },
        { dataField: 'waist', text: t('medicalRecords.anthropometrics.waist') }
    ];

    const expandedContent = (row) => {
        return (
            <div className="info">
                {row.sagittal && <div><span className="patient-label">{t('medicalRecords.anthropometrics.sagittal')}:</span> <span className="patient-text">{row.sagittal}</span></div>}
                {row.neck && <div><span className="patient-label">{t('medicalRecords.anthropometrics.neck')}:</span> <span className="patient-text">{row.neck}</span></div>}
                {row.others && <div><span className="patient-label">{t('medicalRecords.anthropometrics.others')}:</span> <span className="patient-text">{row.others}</span></div>}
                {row.notes && <div><span className="patient-label">{t('medicalRecords.drugs.notes')}:</span> <span className="patient-text">{row.notes}</span></div>}
            </div>
        )
    }

    return (<EntryItemsTable columns={columns} {...props} expandContent={expandedContent} />);
}

