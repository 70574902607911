import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { Button, Col, Grid, Modal, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import { Procedures } from '../../../components/MedicalRecords/Procedures';
import { useProcedureHistory } from '../Hooks/useProcedures';

export const ModalProcedureHistory = (props) => {
    const { t } = useTranslation();
    var { medicalRecordNumber, conceptId } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [fetchHistory] = useProcedureHistory();

    useEffect(() => {
        let isSubscribed = true;
        const fetchData = async () => {
            try {
                const res = await fetchHistory(medicalRecordNumber, conceptId);
                if (isSubscribed) {
                    setData(res.data);
                    setIsLoading(false);
                }
            } catch (error) {
                HandleApiError(error);
                setIsLoading(false);
                setData(null);
            }
        }

        fetchData();

        return () => isSubscribed = false;
    }, [medicalRecordNumber, conceptId]);

    if (isLoading)
        return null;

    let component = <Procedures
        query={{ medicalRecordNumber }}
        data={data}
        isLoading={isLoading}
        withCard={false}
    />;

    return (
        <Modal show={true} onHide={props.onClose} bsSize="large">
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            {component}
                        </Col>
                    </Row>
                </Grid>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onClose}>{t('buttons.close')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalProcedureHistory.propTypes = {
    onClose: PropTypes.func.isRequired,
    medicalRecordNumber: PropTypes.string,
    title: PropTypes.string,
};