import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from 'react-i18next';
import { AuditModal, CreateAuditModal } from "../../../components/AuditTrail/AuditModal";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { SnomedPicker } from '../../../components/CustomControls/SnomedPicker';
import Conditions from '../../../components/MedicalRecords/Conditions';
import { DiagnosisForm } from '../../../components/MedicalRecords/Forms/DiagnosisForm';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { getRemovedElement } from '../../../components/Utils/Commons';
import { getMedicalRecordsSchema } from "../../../components/Validations/FormValidationSchema";
import { conceptType, snomedExpressions } from '../../../variables/Enums';
import { AuditTrail } from '../../Admin/AuditTrail';
import { useObservation } from '../Hooks/useObservation';

export const ObservationsContainer = (props) => {
    const { medicalRecordNumber, entryId } = props;
    const [data] = useObservation(entryId);
    const [observations, setObservations] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        setObservations(data.map(x => {
            return {
                ...x,
                conceptId: x.adverseEffectId,
                description: x.adverseEffect
            }
        }))
    }, [data]);

    const [modal, setModal] = useState(null);
    const handleAuditTrail = (entityId) => {
        setModal(
            <AuditModal
                onClose={() => { setModal(null); }}
                title={t("auditTrail.audit")}
            >
                <AuditTrail entity="AdverseEvents" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }

    //console.log(observations)
    if (observations?.length === 0)
        return null

    return (
        <>
            {modal}
            <ExpandableContent title={t("medicalRecords.condition.observation")} expandedAll={true} content="adverseEvents">
                <Conditions
                    data={observations}
                    onAuditTrail={handleAuditTrail}
                />
            </ExpandableContent>
        </>
    );
}

export const ObservationCreateContainer = (props) => {
    const { entryStatus, entryId, onSubmit } = props;
    const [data, , , , , , create, remove] = useObservation(entryId);
    const [content, setContent] = useState(null);
    const [observations, setObservations] = useState([]);
    let _observations = [];
    const { t } = useTranslation();

    useEffect(() => {
        setObservations(data.map(x => {
            return {
                ...x,
                conceptId: x.adverseEffectId,
                description: x.adverseEffect
            }
        }))
    }, [data]);

    const handleSubmit = async (data) => {
        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {

        const today = new Date();
        let observation = { condition: { name: data, fromYear: today.getFullYear() } };
        if (reason) {
            observation.auditReason = reason;
            observation.auditComments = comments;
        }

        try {
            const res = await create(entryId, observation);
            let createdObservation = res.data;
            Object.assign(createdObservation, data);
            const lastObservations = _observations.map((condition) => condition.conceptId === createdObservation.conceptId ? res.data : condition);

            setObservations(lastObservations);
        }
        catch (error) {
            console.log(JSON.stringify(error));
            HandleApiError(error);
        }
        setContent(null);
        //onSubmit("adverseEvents");
        onSubmit("observations", _observations?.length > 0); // Indico al padre sobre q contenido hacer Reload. 
    }

    const handleChangeObservation = async (_name, value) => {
        _observations = value;

        // Determino si se agregó o eliminó un elemento (o todos)
        if (value == null || observations?.length > value.length) { // Elemento/s eliminado/s
            const elementoEliminado = getRemovedElement(observations, value);
            if (elementoEliminado && elementoEliminado.id) {
                try {
                    await remove(entryId, elementoEliminado.id);
                }
                catch (error) {
                    console.log(error)
                }
            }
            setObservations(value);
            onSubmit("observations", _observations?.length > 0); // Indico al padre sobre q contenido hacer Reload. 
        }
        else { // Elemento agregado
            const addedObservation = value.at(-1); // Obtengo último diagnostico agregado, para poder modificarlo. 
            if (addedObservation) {
                handleSubmit(addedObservation);
            }
        }
    }

    return (
        <>
            {content}
            <ExpandableContent title={t("medicalRecords.condition.observation")} expandedAll={true} content="adverseEvents">
                <SnomedPicker
                    conceptType={conceptType.events}
                    disabled={props.disabled}
                    autoFocus
                    id="observation"
                    name="observation"
                    placeholder={t("commons.search")}
                    selected={observations}
                    onChange={handleChangeObservation}
                    expression={snomedExpressions.MotivoConsulta}
                    multiple={true}
                    clearButton={false}
                />
            </ExpandableContent>
        </>
    );
}
/*
const ObservationEditContainer = (props) => {
    const { entryId, id, onSubmit, onCancel } = props;
    const [data, isLoading] = useDiagnosis(entryId, id);

    if (!data || isLoading)
        return null;

    return (
        <DiagnosisContainerFormik
            onSubmit={onSubmit}
            onCancel={onCancel}
            adverseEvent={data}
        />
    );
}*/

const DiagnosisContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => (props.adverseEvent && { adverseEvents: [props.adverseEvent] }),

    validationSchema: getMedicalRecordsSchema().adverseEvent,

    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values);
    },

    displayName: 'DiagnosisForm',

})(DiagnosisForm));

DiagnosisContainerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    adverseEvent: PropTypes.object.isRequired,
};