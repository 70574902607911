import { ErrorMessage } from "formik";
import React, { useState } from "react";
import { Col, ControlLabel, FormControl, Row } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import profilePic from "../../assets/img/faces/face-0.jpg";
import Card from "../Cards/Card";
import { CustomDate } from "../CustomControls/CustomDateTime";
import { CustomSelect } from "../CustomControls/CustomSelect";
import { ModalTakePic } from "../Database/ModalTakePic";
import { Camera } from "../Icons/Icons";
import { searchPatients } from "../../api/database";
import { FormErrorMessage } from "../Validations/FormValidationErrors";
import { ModalDuplicatedPatient } from "../Database/ModalDuplicatedPatient";
import { googleAPIKey } from "../../variables/Constants";
import { language } from "../../variables/Enums";
import { ExpandableContent } from "../CustomControls/ExpandableContent";

export const PersonalDataForm = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    t,
    isSubmitting,
    masterData,
  } = props;

  const [modal, setModal] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);

  function handlePhotoTaken(dataUri) {
    setFieldValue("photo", dataUri, false);
    setModal(null);
  }


  function handleShowTakePhoto() {
    setModal(
      <ModalTakePic
        onHide={() => setModal(null)}
        onPhotoTaken={handlePhotoTaken}
      />
    );
  }

  const getNationality = (addressArray) => {
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "country" === addressArray[i].types[0]
        ) {
          return addressArray[i].long_name;
        }
      }
    }
  };

  const onPlaceSelected = (place) => {
    // Set these values in the state.
    setFieldValue(
      "nationality",
      getNationality(place.address_components),
      false
    );
  };

  const onFamilyNameBlur = (evt) => {
    if (evt.currentTarget && evt.currentTarget.value.length > 2) {
      searchPatients(`${values.given} ${values.familyName}`)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            setModal(
              <ModalDuplicatedPatient
                patients={response.data}
                onHide={() => setModal(null)}
              ></ModalDuplicatedPatient>
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setFieldTouched(evt.currentTarget.name);
  };

  const onSelectChange = (_name, value) => {
    setSelectedValue(value);
  }

  const onIdentificationNumberBlur = (evt) => {
    if (evt.currentTarget && evt.currentTarget.value.length > 6) {
      searchPatients(values.identificationNumber)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            setModal(
              <ModalDuplicatedPatient
                patients={response.data}
                onHide={() => setModal(null)}
              ></ModalDuplicatedPatient>
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setFieldTouched(evt.currentTarget.name);
  };

  return (
    <ExpandableContent title={t("person.personalData")} expandedAll={true} content="">
      <Card
        className="card-patient-edit card-form"
        content={
          <Row>
            <Col sm={3}>
              <div className="patient-avatar">
                <img alt="image" src={values.photo ?? profilePic} />
                <div className="middle">
                  <button
                    className="btn btn-primary btn-fill"
                    type="button"
                    onClick={handleShowTakePhoto}
                    disabled={isSubmitting}
                  >
                    <Camera /> {t("buttons.takePhoto")}
                  </button>
                </div>
                {modal}
              </div>
            </Col>
            <Col sm={9}>
              <Row>
                <Col sm={1}>
                  <ControlLabel>{t("person.prefix")}</ControlLabel>
                  <FormControl
                    type="text"
                    name="prefix"
                    value={values.prefix ?? ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                </Col>
                <Col sm={5}>
                  <ControlLabel>{t("person.given")}</ControlLabel>
                  <FormControl
                    type="text"
                    name="given"
                    value={values.given ?? ""}
                    className={errors.given && touched.given && "error"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                  <ErrorMessage name="given" component={FormErrorMessage} />
                </Col>
                <Col sm={5}>
                  <ControlLabel>{t("person.familyName")}</ControlLabel>
                  <FormControl
                    type="text"
                    name="familyName"
                    value={values.familyName ?? ""}
                    className={
                      errors.familyName && touched.familyName && "error"
                    }
                    onChange={handleChange}
                    onBlur={onFamilyNameBlur}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    name="familyName"
                    component={FormErrorMessage}
                  />
                </Col>
                <Col sm={1}>
                  <ControlLabel>{t("person.suffix")}</ControlLabel>
                  <FormControl
                    type="text"
                    name="suffix"
                    value={values.suffix ?? ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <ControlLabel>{t("person.birthDate")}</ControlLabel>
                  <CustomDate
                    className={errors.birthDate && touched.birthDate && "error"}
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                    inputProps={{ placeholder: "DD/MM/AAAA" }}
                    value={values.birthDate}
                    name="birthDate"
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    viewMode="years"
                    closeOnSelect={true}
                  />
                  <ErrorMessage name="birthDate" component={FormErrorMessage} />
                </Col>
                <Col sm={3}>
                  <ControlLabel>{t("person.birthPlace")}</ControlLabel>
                  <FormControl
                    type="text"
                    name="birthPlace"
                    value={values.birthPlace ?? ""}
                    className={
                      errors.birthPlace && touched.birthPlace && "error"
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                </Col>
                <Col sm={3}>
                  <ControlLabel>{t("person.nationality")}</ControlLabel>
                  <Autocomplete
                    apiKey={googleAPIKey}
                    className="form-control"
                    id="nationality"
                    name="nationality"
                    onPlaceSelected={onPlaceSelected}
                    options={{
                      types: ["(regions)"],
                      fields: [
                        "address_components",
                        "geometry.location",
                        "place_id",
                        "formatted_address",
                      ],
                    }}
                    defaultValue={values.nationality ?? ""}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="nationality"
                    component={FormErrorMessage}
                  />
                </Col>
                <Col sm={3}>
                  <ControlLabel>{t("person.languageTitle")}</ControlLabel>
                  <CustomSelect
                    name="language"
                    placeholder={t("person.selectLanguage")}
                    options={[
                      { code: "es", title: t("person.spanish") },
                      { code: "en", title: t("person.english") },
                      { code: "pt", title: t("person.portuguese") }
                    ]}
                    defaultValue={values.language && language.filter(x => x.code === values.language)}
                    //value={values.language ?? ""}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    onPlaceSelected={onPlaceSelected}
                  />
                  <ErrorMessage name="language" component={FormErrorMessage} />
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <ControlLabel>{t("person.identificationType")}</ControlLabel>
                  <CustomSelect
                    value={values.identificationType}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    name="identificationType"
                    placeholder={t("commons.select")}
                    options={masterData?.identificationTypes ?? []}
                  />
                  <ErrorMessage
                    name="identificationType"
                    component={FormErrorMessage}
                  />
                </Col>
                <Col sm={5}>
                  <ControlLabel>
                    {t("person.identificationNumber")}
                  </ControlLabel>
                  <FormControl
                    type="text"
                    name="identificationNumber"
                    value={values.identificationNumber ?? ""}
                    className={
                      errors.identificationNumber &&
                      touched.identificationNumber &&
                      "error"
                    }
                    onChange={handleChange}
                    onBlur={onIdentificationNumberBlur}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    name="identificationNumber"
                    component={FormErrorMessage}
                  />
                </Col>
                <Col sm={2}>
                  <ControlLabel>{t("person.gender")}</ControlLabel>
                  <CustomSelect
                    value={values.gender}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    name="gender"
                    placeholder={t("commons.select")}
                    options={masterData?.genders ?? []}
                  />
                  <ErrorMessage name="gender" component={FormErrorMessage} />
                </Col>
                <Col sm={3}>
                  <ControlLabel>{t("person.maritalStatus")}</ControlLabel>
                  <CustomSelect
                    value={values.maritalStatus}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    name="maritalStatus"
                    placeholder={t("commons.select")}
                    options={masterData?.maritalStatuses ?? []}
                  />
                  <ErrorMessage
                    name="maritalStatus"
                    component={FormErrorMessage}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        }
      />
    </ExpandableContent>
  );
};
