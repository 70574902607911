import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from 'react-i18next';
import { putVital, removeVitalAPI } from "../../../api/medicalRecord";
import { AuditModal, CreateAudit, CreateAuditModal } from "../../../components/AuditTrail/AuditModal";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { VitalsForm } from "../../../components/MedicalRecords/Forms/VitalsForm";
import { Vitals } from "../../../components/MedicalRecords/Vitals";
import { getMedicalRecordsSchema } from "../../../components/Validations/FormValidationSchema";
import { AuditTrail } from "../../Admin/AuditTrail";
import { useVital, useVitals } from "../Hooks/useVitals";
import { ModalVitalsHistory } from '../Modals/ModalVitalsHistory';
import { Success } from '../../../components/Notifications/Notifications';
import { ModalConfirm } from '../../../components/Modals/ModalConfirm';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { ModalSpinner } from '../../../components/Utils/Loaders';

export const VitalsContainer = (props) => {
    const [content, setContent] = useState(null);
    const { medicalRecordNumber, mrn, entryStatus, entryId, expandedAll } = props;
    const [vitals, query, totalSize, isLoading, handleTableChange, setReload] = useVitals(null, entryId);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.contentToReload?.contentName === "vitals")
            setReload(true);
    }, [props.contentToReload]);

    const handleEdit = (id) => {
        setContent(<VitalEditContainer
            entryId={entryId}
            id={id}
            onSubmit={handleSubmit}
            onCancel={handleClose}
        />);
    }

    const handleSubmit = async (data) => {

        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (reason) {
                data.auditReason = reason;
                data.auditComments = comments;
            }
            //console.log("edit ", data)
            await putVital(entryId, data.id, data);
            setContent(null);
            setReload(true);
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleClose = () => {
        setContent(null);
    }

    const handleAuditTrail = (entityId) => {
        setContent(
            <AuditModal
                onClose={() => { setContent(null); }}
                title={t("auditTrail.audit")}
            >
                <AuditTrail entity="Vitals" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }

    const handleHistory = () => {
        setContent(<ModalVitalsHistory
            onClose={() => { setContent(null); }}
            title={t("medicalRecords.history.history")}
            medicalRecordNumber={medicalRecordNumber || mrn}
        />)
    }

    //#region Delete item with audit
    const handleRemove = (id) => {
        setContent(
            <ModalConfirm
                onConfirm={() => confirmRemoveItem(id)}
                onCancel={() => setContent(null)}
                title={t("medicalRecordsNotifications.item_delete_title")}
                description={t("medicalRecordsNotifications.item_delete_description")}
                confirmStyle="danger"
            />);
    }
    const confirmRemoveItem = (id) => {
        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => _removeItemAudited(id, reason, comments)}
            />)
        }
        else {
            _removeItemAudited(id, null, null);
        }
    }
    const _removeItemAudited = async (id, reason, comments) => {
        try {
            setContent(<ModalSpinner isShowing={true} hide={null} />);
            await removeVitalAPI(entryId, id, reason?.code, comments);
            setReload(true);
            setContent(null);
            Success("medicalRecordsNotifications.item_DeletedSuccessfully");
        }
        catch (error) {
            setContent(null);
            console.log(error);
            HandleApiError(error);
        }
    }
    //#endregion

    if (totalSize === 0)
        return null;

    return (
        <ExpandableContent title={t("medicalRecords.vitals.title")} expandedAll={expandedAll} content="vitals">
            {content}
            <Vitals
                entryId={entryId}
                withCard={false}
                data={vitals}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onEdit={handleEdit}
                onAuditTrail={handleAuditTrail}
                onHistory={handleHistory}
                onRemove={handleRemove}
            />
        </ExpandableContent>
    );
}

export const VitalCreateContainer = (props) => {
    const { entryStatus, entryId, onSubmit } = props;
    const [, , create,] = useVital(entryId);
    const [content, setContent] = useState(null);

    const handleSubmit = async (data) => {

        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAudit
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (reason) {
                data.auditReason = reason;
                data.auditComments = comments;
            }
            //console.log("create ", data)
            await create(entryId, data);
            setContent(null);
            onSubmit("vitals");
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleCancel = () => { props.hide() }

    return (
        <>
            {
                content ??
                <VitalContainerFormik
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    vital={{
                        bloodPressureHigh: '',
                        bloodPressureLow: '',
                        heartRate: ''
                    }}
                />
            }
        </>

    );
}

const VitalEditContainer = (props) => {
    const { entryId, id, onSubmit } = props;
    const [data, isLoading] = useVital(entryId, id);

    if (!data || isLoading)
        return null;

    return (
        <VitalContainerFormik
            onSubmit={onSubmit}
            onCancel={props.onCancel}
            vital={data}
        />
    );
}

const VitalContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => (props.vital),

    validationSchema: getMedicalRecordsSchema().vitals,

    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values);
    },

    displayName: 'VitalsForm',

})(VitalsForm));

VitalContainerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    vital: PropTypes.object.isRequired,
};