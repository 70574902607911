import React from "react";
import { Modal, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import FreezerService from "../../../api/Freezer/FreezerService";
import { ErrorMessage, Success } from "../../../components/Notifications/Notifications";
import useLoading from "../../../components/Common/useLoading";

export function DeleteFreezerModal({ state: { show, value, onClose, onEvent } }) {
    const { t } = useTranslation();
    const [isLoading, withLoad] = useLoading()


    const onDelete = async () => {
        await withLoad(async () => {
            try {
                const result = await FreezerService.Delete(value.Id)
                if (result.Succeeded)
                    Success(t('commons.deleteSuccess'))
                else
                    ErrorMessage(t('commons.deleteError'))
            } finally {
                onClose()
                onEvent("reload")
            }
        })
    }

    if (!show) return null

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title >{t("commons.deleteTitle")} - {value?.Description}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>{t('commons.confirmRemove')}</span>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>{t('buttons.close')}</Button>
                <button type="button" className="btn btn-danger btn-right" disabled={isLoading} onClick={onDelete}>{t('buttons.delete')}</button>
            </Modal.Footer>
        </Modal>
    )
}