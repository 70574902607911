import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, ControlLabel, FormControl, FormGroup, Grid, Label, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { useTranslation } from 'react-i18next';
import Card from '../Cards/Card';
import { CustomSwitch } from '../CustomControls/CustomSwitch';
import { Edit, Remove } from '../Icons/Icons';
import { Tooltip } from "../Utils/Tooltips";
import { FormErrorMessage } from '../Validations/FormValidationErrors';
import { ConfirmRemoveRole } from './Modals';
import { StudyPermissions } from './StudyPermissions';
import { WithAuth } from './WithAuth';

export const StudyRoles = props => {
    const { t } = useTranslation();
    const { roles, onEdit, onRemove, isLoading } = props;
    const [modal, setModal] = useState(null);

    const columns = [
        { dataField: 'name', text: t("security.studyRoles.name"), headerStyle: { width: '250px' }, },
        {
            dataField: 'view',
            text: t("protocols.practitioner.actions"),
            isDummyField: true,
            headerClasses: 'header-actions',
            classes: 'row-actions',
            formatter: function (_cell, row, rowIndex, _formatExtraData) {
                return (
                    <div key={rowIndex}>
                        <WithAuth requiredPermission={["Protocols.Delete"]}>
                            <button className="btn-table-actions" onClick={() => onEdit(row.id)}>
                                <Tooltip tooltip={t("security.edit")} id={"tooltipedit-" + row.id}>
                                    <span aria-hidden="true"><Edit /></span>
                                </Tooltip>
                            </button>
                            <WithAuth requiredPermission={["Protocols.Delete"]}>
                                <button className="btn-table-actions" onClick={() => showModal(row.id)}>
                                    <Tooltip tooltip={t("security.remove")} id={"tooltipremove-" + row.id}>
                                        <span aria-hidden="true"><Remove /></span>
                                    </Tooltip>
                                </button>
                            </WithAuth>
                        </WithAuth>
                    </div>
                )
            }
        }
    ];

    const showModal = (roleId) => {
        setModal(
            <ConfirmRemoveRole
                onClose={() => setModal(null)}
                onConfirm={(confirmRemove)}
                roleId={roleId}
            />
        );
    }
    const confirmRemove = (roleId) => {
        setModal(null);
        onRemove(roleId);
    }

    const expandedContent = (row) => {
        const modules = [
            { id: 1, code: "Brief", title: t("protocolStudyRoles.modules.brief") },
            { id: 2, code: "Practitioners", title: t("protocolStudyRoles.modules.practitioners") },
            { id: 3, code: "Patients", title: t("protocolStudyRoles.modules.patients") },
            { id: 4, code: "Visits", title: t("protocolStudyRoles.modules.visits") },
            { id: 5, code: "VisitTracking", title: t("protocolStudyRoles.modules.visitTracking") },
            { id: 6, code: "InternalNotes", title: t("protocolStudyRoles.modules.internalNotes") },
            { id: 7, code: "ExternalNotes", title: t("protocolStudyRoles.modules.externalNotes") },
        ];

        const permissions = row.permissions;
        const yes = <CustomSwitch value={true} disabled={true} />;
        const no = <CustomSwitch value={false} disabled={true} />;
        return (
            <table className="table table-hover table-spaced-small">
                <thead>
                    <tr>
                        <th>{t('security.permissions.module')}</th>
                        <th>{t('security.permissions.view')}</th>
                        <th>{t('security.permissions.list')}</th>
                        <th>{t('security.permissions.create')}</th>
                        <th>{t('security.permissions.edit')}</th>
                        <th>{t('security.permissions.delete')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {modules.map((x, idx) => {
                        return (<tr key={`studyPermissions-${idx}`}>
                            <td>{x.title}</td>
                            <td>{permissions.filter(c => c == `${x.code}.View`).length > 0 ? yes : no}</td>
                            <td>{permissions.filter(c => c == `${x.code}.List`).length > 0 ? yes : no}</td>
                            <td>{permissions.filter(c => c == `${x.code}.Add`).length > 0 ? yes : no}</td>
                            <td>{permissions.filter(c => c == `${x.code}.Edit`).length > 0 ? yes : no}</td>
                            <td>{permissions.filter(c => c == `${x.code}.Delete`).length > 0 ? yes : no}</td>
                            {
                                (idx === 0) &&
                                <td>{t('security.permissions.close')}{permissions.filter(c => c == `${x.code}.Close`).length > 0 ? yes : no}</td>
                            }
                            {
                                (idx === 3 || idx === 4) &&
                                <td>{t('security.permissions.publish')}{permissions.filter(c => c == `${x.code}.Publish`).length > 0 ? yes : no}</td>
                            }
                            {
                                (idx === 4) &&
                                <td>{t('security.permissions.upload')}{permissions.filter(c => c === `${x.code}.UploadFile`).length > 0 ? yes : no}</td>
                            }
                            {
                                (idx === 4) &&
                                <td>{t('security.permissions.exportPatientVisit')}{permissions.filter(c => c === `${x.code}.ExportPatientVisit`).length > 0 ? yes : no}</td>
                            }
                        </tr>
                        )
                    })}
                </tbody>
            </table>
        );
    }

    const expandRow = {
        renderer: expandedContent,
        onlyOneExpanding: true,
    };

    return (
        <>
            {modal}
            <BootstrapTable
                bordered={false}
                hover
                remote
                keyField='id'
                classes="table-spaced table-spaced-small"
                wrapperClasses="table-responsive"
                loading={isLoading}
                data={roles}
                columns={columns}
                noDataIndication={() => <>{t("commons.noData")}</>}
                expandRow={expandRow}
            />
        </>
    );
}

StudyRoles.propTypes = {
    roles: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
};

export const StudyRoleForm = props => {

    const {
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
    } = props;

    const { t } = useTranslation();

    if (!values.role) {
        values.role = {
            name: '',
            permissions: []
        };
    }


    return (
        <Card
            title={t("security.studyRoles.title")}
            ctFullWidth
            content={
                <form onSubmit={handleSubmit} autoComplete="off">
                    <FormGroup>
                        <Grid fluid>
                            <Row>
                                <Col sm={4}>
                                    <ControlLabel>{t("security.studyRoles.name")}</ControlLabel>
                                    <FormControl type="text" name={`role.name`} value={values.role.name} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                    <ErrorMessage name={`role.name`} component={FormErrorMessage} />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <ControlLabel>{t("security.roles.permissions")}</ControlLabel>
                                    <StudyPermissions
                                        claims={values.role.permissions || []}
                                        name={`role.permissions`}
                                        onChange={setFieldValue}
                                    />
                                    <ErrorMessage name={`role.permissions`} component={FormErrorMessage} />
                                </Col>
                            </Row>
                        </Grid>
                    </FormGroup>
                    <Grid fluid>
                        <Row>
                            <Col sm={1} smOffset={10}>
                                <button type="button" className="btn btn-info btn-right" onClick={props.onCancel} >{t("buttons.back")}</button>
                            </Col>
                            <Col sm={1} >
                                <button type="submit" className="btn btn-success btn-right" disabled={props.isSubmitting} >{t("buttons.save")}</button>
                            </Col>
                        </Row>
                    </Grid>
                </form>
            }
        />
    );
}