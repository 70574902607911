import { useEffect, useState } from "react";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import { getExtraForms } from "../../../api/protocolsExtraForms";

export const useExtraForms = (protocolId) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalSize, setTotalSize] = useState(0);
    const [query, setQuery] = useState({
        pagination: {
            page: 1,
            sizePerPage: 10,
            totalSize: 0
        },
        sort: {
            sortField: "Created",
            sortOrder: "desc"
        },
        //filters: { extraFormType: 0 }
    });

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await getExtraForms(protocolId, query);
                if (isSubscribed) {
                    const paginatedResult = response.data;

                    setTotalSize(paginatedResult.count);
                    setData(paginatedResult);
                    setIsLoading(false);
                }
            }
            catch (error) {
                console.log(error);
                if (isSubscribed) {
                    HandleApiError(error);
                    setIsLoading(false);
                }
            }
        }

        fetchData();

        return () => isSubscribed = false;
    }, [protocolId, query]);

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {

        var newQuery = null;
        if (type === 'pagination') {
            newQuery = JSON.parse(JSON.stringify(query));

            newQuery.pagination.page = page;
            newQuery.pagination.sizePerPage = sizePerPage;

            setQuery(newQuery);
        }

        if (type === 'sort') {
            newQuery = JSON.parse(JSON.stringify(query));

            newQuery.sort.sortField = sortField;
            newQuery.sort.sortOrder = sortOrder;

            setQuery(newQuery);
        }
    }

    const handleFilterChange = (filterName, selectedValue) => {
        if (filterName) {
            var newQuery = JSON.parse(JSON.stringify(query));

            newQuery.filters[filterName] = selectedValue != null ? selectedValue.id : selectedValue;

            setQuery(newQuery);
        }
    }

    return [setData, data, query, totalSize, isLoading, handleTableChange, handleFilterChange];
}