import { ErrorMessage } from 'formik';
import React from 'react';
import { Col, ControlLabel, FormControl, FormGroup, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { WithAuth } from '../Authorization/WithAuth';
import { YesNo } from '../CustomControls/YesNoControls';
import { FormErrorMessage } from '../Validations/FormValidationErrors';

export const TenantForm = props => {
    const {
        handleSubmit,
        isSubmitting,
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
        touched,
    } = props;

    const { t } = useTranslation();

    return (
        <Grid fluid>
            <Row>
                <Col md={12}>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="card">
                            <div className="header">
                                <h4 className="title">Datos del Cliente</h4>
                            </div>
                            <div className="content">
                                <fieldset>
                                    <FormGroup>
                                        <Grid fluid>
                                            <Row>
                                                <Col sm={4}>
                                                    <ControlLabel>{t('security.tenant.name')}</ControlLabel>
                                                    <FormControl type="text" name="tenantName" value={values.tenantName} className={errors.tenantName && touched.tenantName && "error"} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                    <ErrorMessage name="tenantName" component={FormErrorMessage} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={4}>
                                                    <ControlLabel>{t('security.tenant.domain')}</ControlLabel>
                                                    <FormControl type="text" name="tenantDomain" value={values.tenantDomain} className={errors.tenantDomain && touched.tenantDomain && "error"} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                    <ErrorMessage name="tenantDomain" component={FormErrorMessage} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={4}>
                                                    <ControlLabel>{t('security.tenant.servicePlan')}</ControlLabel>
                                                    <FormControl type="text" name="servicePlan" value={values.servicePlan} className={errors.servicePlan && touched.servicePlan && "error"} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                                    <ErrorMessage name="servicePlan" component={FormErrorMessage} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={4}>
                                                    <ControlLabel>{t('security.tenant.isActive')}</ControlLabel>
                                                    <YesNo
                                                        disabled={false}
                                                        value={values.isActive}
                                                        onChange={setFieldValue}
                                                        name="isActive"
                                                        isClearable={false}
                                                    />
                                                    <ErrorMessage name="isActive" component={FormErrorMessage} />
                                                </Col>
                                            </Row>
                                        </Grid>
                                    </FormGroup>
                                </fieldset>
                                <fieldset>
                                    <Grid fluid>
                                        <Row>
                                            <Col sm={1} smOffset={10} >
                                                <NavLink to="/admin/tenant" className="nav-link btn btn-info" activeClassName="active">
                                                    {t('buttons.cancel')}
                                                </NavLink>
                                            </Col>
                                            <Col sm={1} >
                                                <WithAuth requiredPermission={["Tenant.Create"]}>
                                                    <button className="btn btn-success" type="submit" disabled={isSubmitting}>
                                                        {t("buttons.save")}
                                                    </button>
                                                </WithAuth>
                                            </Col>
                                        </Row>
                                    </Grid>
                                </fieldset>
                            </div>
                        </div>
                    </form>
                </Col>
            </Row>
        </Grid >
    );
};