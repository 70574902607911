import React from "react";
import { Attachments } from "../../../components/MedicalRecords/Attachments";
import { DocumentViewer } from '../../../components/CustomControls/DocViewer/DocumentViewer';
import { useAttachments } from "../Hooks/useAttachments";

export const AttachmentsListContainer = ({ medicalRecordNumber, entryId }) => {

    const [data, query, totalSize, isLoading, handleTableChange, , , blobData, viewAttachment] = useAttachments(medicalRecordNumber, entryId);

    if (entryId && totalSize === 0)
        return null;

    return (
        <>
            <DocumentViewer
                file={blobData?.blob}
                fileName={blobData?.fileName}
                inModal={true}
            />
            <Attachments
                data={data}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onDownloadFile={viewAttachment}
            />
        </>
    );
}