import React, { useState } from 'react';
import AdminContext from './AdminContext';

export const AdminProvider = (props) => {

    const [selectedTenant, setSelectedTenant] = useState(null);

    return (
        <AdminContext.Provider
            value={{
                selectedTenant,
                setSelectedTenant
            }}
        >
            {props.children}
        </AdminContext.Provider>
    );
}