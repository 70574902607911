import React, { useState } from "react"
import PropTypes from 'prop-types'
import { Button, Grid, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import SweetAlert from "react-bootstrap-sweetalert"
import { dateFormatter } from "../../Utils/Formatter"
import { visitTypeEnum } from "../../../variables/Enums"
import { IconSVG, OnSite, Phone } from "../../Icons/Icons"
import { ModalData } from "../../Modals/ModalData"
import { ModalConfirm } from "../../Modals/ModalConfirm";

export const ConfirmVisitTracking = (props) => {
    const {
        onConfirm,
        onClose
    } = props;
    const { t } = useTranslation();

    return (
        <SweetAlert
            warning
            title={t("protocolNotifications.visitTracking_confirmSave_title")}
            onConfirm={onConfirm}
            onCancel={onClose}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="info"
            confirmBtnText={t("buttons.yes")}
            cancelBtnText={t("buttons.no")}
            showCancel
        >
            <>
                <span>{t("protocolNotifications.visitTracking_confirmSave_description")}</span>

                <div>Rango: {dateFormatter(props.data.fromDate, "DD/MMMM/YYYY")} - {dateFormatter(props.data.toDate, "DD/MMMM/YYYY")}</div>
            </>
        </SweetAlert>
    );
}

ConfirmVisitTracking.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export const SkipVisitTracking = ({ onConfirm, onClose }) => {
    const { t } = useTranslation();
    return (
        <SweetAlert
            warning
            title={t("protocolNotifications.visitTracking_confirmSkip_title")}
            onConfirm={onConfirm}
            onCancel={onClose}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            confirmBtnText={t("buttons.confirm")}
            cancelBtnText={t("buttons.cancel")}
            showCancel
        >
            <span>{t("protocolNotifications.visitTracking_confirmSkip_description")}</span>
        </SweetAlert>
    );
}

SkipVisitTracking.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export const ScreenFailure = ({ onConfirm, onClose }) => {
    const { t } = useTranslation();
    return (
        <SweetAlert
            warning
            title={t("protocolNotifications.visitTracking_screenFailure_title")}
            onConfirm={onConfirm}
            onCancel={onClose}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            confirmBtnText={t("buttons.confirm")}
            cancelBtnText={t("buttons.cancel")}
            showCancel
        >
            <span>{t("protocolNotifications.visitTracking_screenFailure_description")}</span>
        </SweetAlert>
    );
}

ScreenFailure.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export const LostToFollowUp = ({ onConfirm, onClose }) => {
    const { t } = useTranslation();
    return (
        <SweetAlert
            warning
            title={t("protocolNotifications.visitTracking_lostToFollowUp_title")}
            onConfirm={onConfirm}
            onCancel={onClose}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            confirmBtnText={t("buttons.confirm")}
            cancelBtnText={t("buttons.cancel")}
            showCancel
        >
            <span>{t("protocolNotifications.visitTracking_lostToFollowUp_description")}</span>
        </SweetAlert>
    );
}
LostToFollowUp.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export const WithoutDrugs = ({ onConfirm, onClose }) => {
    const { t } = useTranslation();
    return (
        <SweetAlert
            warning
            title={t("protocolNotifications.visitTracking_withoutDrugs_title")}
            onConfirm={onConfirm}
            onCancel={onClose}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            confirmBtnText={t("buttons.confirm")}
            cancelBtnText={t("buttons.cancel")}
            showCancel
        >
            <span>{t("protocolNotifications.visitTracking_withoutDrugs_description")}</span>
        </SweetAlert>
    );
}
WithoutDrugs.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export const ConfirmModal = ({ onConfirm, onClose, title, description }) => {
    const { t } = useTranslation();
    return (
        <SweetAlert
            warning
            title={title}
            onConfirm={onConfirm}
            onCancel={onClose}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            confirmBtnText={t("buttons.confirm")}
            cancelBtnText={t("buttons.cancel")}
            showCancel
        >
            <span>{description}</span>
        </SweetAlert>
    );
}
ConfirmModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
};

export const ChangeVisitType = ({ visit, onChange, onClose }) => {
    const { t } = useTranslation();
    const [modal, setModal] = useState(null);

    const handleChangeType = (type) => {
        setModal(
            <ModalConfirm
                onConfirm={() => onChange(type)}
                onCancel={() => { setModal(null); onClose() }}
                title={t("protocolNotifications.visitTracking_changeType_title")}
                description={t("protocolNotifications.visitTracking_changeType_description")}
            />);
    }

    if (modal)
        return modal;

    return (<ModalData
        size=""
        className=""
        title={t("protocolNotifications.visitTracking_changeType_title")}
        isShowing={true}
        hide={onClose}>
        <div className="modal-create-entry">
            <Grid fluid>
                <Row className='display-flex'>
                    <Col md={4}>
                        <div className="create-entry-container">
                            <Button onClick={() => handleChangeType(visitTypeEnum.OnSite)} className="btn-create-entry">
                                <OnSite />
                            </Button>
                            <div className="title-create-entry">{t("protocols.visitTracking.onsite")}</div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="create-entry-container">
                            <Button onClick={() => handleChangeType(visitTypeEnum.Remote)} className="btn-create-entry">
                                <Phone />
                            </Button>
                            <div className="title-create-entry">{t("protocols.visitTracking.remote")}</div>
                        </div>
                    </Col>
                </Row>
            </Grid>
        </div>
    </ModalData>);
}