import { useEffect, useState } from "react";
import { HandleApiError } from "../../components/Notifications/APIErrorHandler";
import { getExternalNotes } from "../../api/protocol";

export const useExternalNotes = (protocolId) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalSize, setTotalSize] = useState(0);
    const [query, setQuery] = useState({
        pagination: {
            page: 1,
            sizePerPage: 10,
            totalSize: 0
        },
        sort: {
            sortField: "Created",
            sortOrder: "desc"
        },
        filters: {}
    });

    useEffect(() => {
        let isSubscribed = true

        const fetchData = async () => {

            setIsLoading(true);
            try {
                const response = await getExternalNotes(protocolId, query);
                if (isSubscribed) {
                    setTotalSize(response.data.count);
                    setData(response.data.data);
                    setIsLoading(false);
                }

            } catch (error) {
                if (isSubscribed) {
                    console.log(error);
                    HandleApiError(error);
                    setIsLoading(false);
                    setTotalSize(0);
                    setData([]);
                }
            }
        }

        if (protocolId)
            fetchData();

        return () => isSubscribed = false;
    }, [protocolId, query]);


    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        var newQuery = JSON.parse(JSON.stringify(query));
        switch (type) {
            case 'pagination': {
                newQuery.pagination.page = page;
                newQuery.pagination.sizePerPage = sizePerPage;
                setQuery(newQuery);
                break;
            }
            case 'sort': {
                newQuery.sort.sortField = sortField;
                newQuery.sort.sortOrder = sortOrder;
                setQuery(newQuery);
                break;
            }
            default: break;
        }
    }

    const handleFilterChange = (filterName, selectedValue) => {
        if (filterName) {
            var newQuery = JSON.parse(JSON.stringify(query));

            if (filterName === "patientNumber")
                newQuery.filters[filterName] = selectedValue;
            else
                newQuery.filters[filterName] = selectedValue != null ? selectedValue.id : selectedValue;
                
            newQuery.pagination.page = 1;
            setQuery(newQuery);
        }
    }

    return [data, query, totalSize, isLoading, handleTableChange, handleFilterChange];
}