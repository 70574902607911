import PropTypes from 'prop-types';
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Add, Contract, Expand, IconSVG } from "../Icons/Icons";
import { Card } from '../Cards/Card';

export const EntryOptions = (props) => {
    const items = ['personalHistory', 'familyHistory', 'personalHabits', 'vitals', 'anthropometrics', 'drugs', 'procedures', 'laboratory', 'attachments'];
    const [showOthers, setShowOthers] = useState(false)
    const { t } = useTranslation();

    const handleClick = (contentName) => {
        props.onClick(contentName)
    }

    const handleOthersClick = () => { setShowOthers(!showOthers); }

    const otherContent = (
        <>
            <button key={`button-personalHabits`} name="personalHabits" type="button"
                className="btn btn-primary btn-fill btn-full"
                onClick={() => handleClick("personalHabits")}
                style={{ marginBottom: '5px', textAlign: 'left' }}>
                <Add /> {t(`medicalRecords.personalHabits.title`)}
            </button>
            <button key={`button-familyHistory`} name="familyHistory" type="button"
                className="btn btn-primary btn-fill btn-full"
                onClick={() => handleClick("familyHistory")}
                style={{ marginBottom: '5px', textAlign: 'left' }}>
                <Add /> {t(`medicalRecords.familyHistory.title`)}
            </button>
            <button key={`button-attachments`} name="attachments" type="button"
                className="btn btn-primary btn-fill btn-full"
                onClick={() => handleClick("attachments")}
                style={{ marginBottom: '5px', textAlign: 'left' }}>
                <Add /> {t(`medicalRecords.attachments.title`)}
            </button>
        </>
    );

    const content = items.map(function (name, index) {

        if (name === "others") {
            return (<button key={`button${index}`} name={name} type="button"
                className="btn btn-primary btn-fill btn-full"
                onClick={(handleOthersClick)}
                style={{ marginBottom: '5px', textAlign: 'left' }}>
                {showOthers ? <Contract /> : <Expand />} Otros
            </button>);
        }

        return (<button key={`button${index}`} name={name} type="button"
            className="btn btn-primary btn-fill btn-full"
            onClick={() => handleClick(name)}
            style={{ marginBottom: '5px', textAlign: 'left' }}>
            <IconSVG name={name} fill='#fff' /> {t(`medicalRecords.${name}.title`)}
        </button>);
    });

    return (
        <Card
            title={t('medicalRecords.addItem')}
            className="card-actions"
            content={
                <>
                    {!props.isDisabled && content}
                    {showOthers && otherContent}
                </>
            }
        />
    );
}

EntryOptions.propTypes = {
    onClick: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
};