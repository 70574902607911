import React from "react";
import PropTypes from 'prop-types';
import Switch from 'react-bootstrap-switch';

export const CustomSwitch = (props) => {

    const handleChange = () => {
        if (props.onChange)
            props.onChange(props.name, !props.value);
    };

    const handleBlur = () => {
        if (props.onBlur)
            props.onBlur(props.name, true);
    };

    return (
        <Switch
            {...props}
            options={props.options}
            onChange={handleChange}
            onBlur={handleBlur}
            value={props.value}
            wrapperClass="switch-wrapper"
        />
    );
}

CustomSwitch.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.bool.isRequired
};

CustomSwitch.defaultProps = {
    onText: "Si",
    offText: "No",
    bsSize: "small"
};