import debounce from "lodash.debounce";
import isequal from "lodash.isequal";
import PropTypes from 'prop-types';
import moment from "moment";
import React from "react";
import { Alert } from "react-bootstrap";

const AutoSaveDisplay = ({ isSaving, lastSaved }) => {
    const [time, setTime] = React.useState();
    let timer = null;

    React.useEffect(() => {
        if (lastSaved) {
            timer = setInterval(() => {
                setTime(moment(lastSaved).fromNow())
            }, 1000)
        }

        return () => {
            clearInterval(timer);
        }

    }, [lastSaved]);

    return (<Alert bsStyle="info" style={{ backgroundColor: "#20ad9b", position: "fixed", bottom: "-6px", padding: "0 10px" }}>
        {!!isSaving
            ? "Guardando..."
            : lastSaved && time
                ? `Autoguardado ${time}`
                : null}
    </Alert>);
};

/**
 * AutoSave component only has one job
 * on each `values` change, trigger debounced save function
 */
export const AutoSave = ({ doSave, isSaving, needsResave, values, lastSaved }) => {

    React.useEffect(() => {
        doSave(values);
    }, [doSave, values, needsResave]);

    return <AutoSaveDisplay isSaving={isSaving} lastSaved={lastSaved} />;
};

export const AutoSavingForm = (props) => {
    const [isSaving, setIsSaving] = React.useState(false);
    const [lastSaved, setLastSaved] = React.useState(null);
    const [needsResave, setNeedsResave] = React.useState(false);

    const isSavingRef = React.useRef(isSaving);
    const lastSubmittedValues = React.useRef(props.initialValues);
    const currentValues = React.useRef(props.initialValues);

    const mounted = React.useRef(false);
    React.useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        };
    }, []);

    const debouncedAutoSave = React.useCallback(
        debounce(async (valuesToSave) => {
            if (isSavingRef.current || !mounted.current) {
                return;
            }

            if (!isequal(lastSubmittedValues.current, valuesToSave)) {
                // prevent this check from passing again until onSave returns
                lastSubmittedValues.current = valuesToSave;

                console.log("debouncedAutoSave: received new values, saving.");
                isSavingRef.current = true;
                setIsSaving(isSavingRef.current);

                const returnedValues = await props.onSave(valuesToSave, props.formikProps);

                setLastSaved(new Date());

                // check again, did the user change the values?
                //console.log("debouncedAutoSave: recomparing");

                if (isequal(currentValues.current, valuesToSave)) {
                    // check again, did the user change the values?
                    //console.log("debouncedAutoSave: user has not changed values inbewteensave, updating");
                    // prevent this check from passing again when return value is updated
                    lastSubmittedValues.current = returnedValues;
                    props.setInitialValues(returnedValues);
                } else {
                    props.setInitialValues(currentValues.current);
                    //console.log("debouncedAutoSave: user changed values after autosave submission, trying again.");
                    setNeedsResave(true);
                }

                isSavingRef.current = false;
                setIsSaving(isSavingRef.current);
            } else {
                console.log("debouncedAutoSave: values are equal, save skipped.");
            }
        }, props.debounceTime),
        []
    );

    const autoSave = React.useCallback(
        async (valuesToSave) => {
            setNeedsResave(false);

            if (currentValues.current.id !== valuesToSave.id) { // Comparo forms id. Si cambie de form, cancelo autosave. 
                currentValues.current = valuesToSave;
                return;
            }
            
            currentValues.current = valuesToSave;
            await debouncedAutoSave(valuesToSave);
        },
        [debouncedAutoSave]
    );

    return (
        <AutoSave
            needsResave={needsResave}
            doSave={autoSave}
            isSaving={isSaving}
            values={props.valuesToSave}
            lastSaved={lastSaved}
        />
    );
};

AutoSavingForm.defaultProps = {
    debounceTime: 4000,
};
AutoSavingForm.propTypes = {
    initialValues: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object]).isRequired,
    valuesToSave: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object]).isRequired,
    debounceTime: PropTypes.number,
    onSave: PropTypes.func.isRequired,
    setInitialValues: PropTypes.func.isRequired
};

export const AutoSaving = (props) => {
    const [isSaving, setIsSaving] = React.useState(false);
    const [lastSaved, setLastSaved] = React.useState(null);
    const [needsResave, setNeedsResave] = React.useState(false);

    const isSavingRef = React.useRef(isSaving);
    const lastSubmittedValues = React.useRef(props.initialValues);
    const currentValues = React.useRef(props.initialValues);

    const mounted = React.useRef(false);
    React.useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        };
    }, []);

    // note, if props.onSubmit is changed,
    // the debounce interval will change, possibly double-firing
    const debouncedAutoSave = React.useCallback(
        debounce(async (valuesToSave) => {
            if (isSavingRef.current || !mounted.current) {
                return;
            }

            //if (lastSubmittedValues.current && !isequal(lastSubmittedValues.current, valuesToSave)) {
            if (!isequal(lastSubmittedValues.current, valuesToSave)) {
                // prevent this check from passing again until onSave returns
                lastSubmittedValues.current = valuesToSave;

                //console.log("debouncedAutoSave: received new values, saving.");
                isSavingRef.current = true;
                setIsSaving(isSavingRef.current);

                const returnedValues = await props.onSave(valuesToSave);

                setLastSaved(new Date());

                // check again, did the user change the values?
                //console.log("debouncedAutoSave: recomparing");

                if (isequal(currentValues.current, valuesToSave)) {
                    // check again, did the user change the values?
                    //console.log("debouncedAutoSave: user has not changed values inbewteensave, updating");
                    // prevent this check from passing again when return value is updated
                    lastSubmittedValues.current = valuesToSave;
                    if (returnedValues && returnedValues.data)
                        props.setInitialValues(returnedValues.data.text);
                } else {
                    props.setInitialValues(currentValues.current);
                    //console.log("debouncedAutoSave: user changed values after autosave submission, trying again.");
                    setNeedsResave(true);
                }

                isSavingRef.current = false;
                setIsSaving(isSavingRef.current);
            } else {
                //console.log("debouncedAutoSave: values are equal, save skipped.");
            }
        }, props.debounceTime),
        []
    );

    const autoSave = React.useCallback(
        async (valuesToSave) => {
            setNeedsResave(false);
            currentValues.current = valuesToSave;
            await debouncedAutoSave(valuesToSave);
        },
        [debouncedAutoSave]
    );

    return (
        <AutoSave
            needsResave={needsResave}
            doSave={autoSave}
            isSaving={isSaving}
            values={props.valuesToSave}
            lastSaved={lastSaved}
        />
    );
};

AutoSaving.defaultProps = {
    debounceTime: 4000,
};
AutoSaving.propTypes = {
    initialValues: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object]).isRequired,
    valuesToSave: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object]).isRequired,
    debounceTime: PropTypes.number,
    onSave: PropTypes.func.isRequired,
    setInitialValues: PropTypes.func.isRequired
};
