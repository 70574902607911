import debounce from "lodash.debounce";
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from "react";
import { Mention, MentionsInput } from 'react-mentions';
import { getUsers } from "../../api/identity";
import { HandleApiError } from "../Notifications/APIErrorHandler";
import { getPractitionersByProtocol } from "../../api/protocol";

export const TextBoxUsersMentions = (props) => {
    const { name, value, disabled, onChange, onBlur, maxLength, isProtocol, protocolId } = props;
    const [innerValue, setInnerValue] = useState('');
    const INPUT_DELAY = 500;

    useEffect(() => {
        if (value) {
            setInnerValue(value);
        } else {
            setInnerValue('');
        }
    }, [value]);

    const debouncedHandleOnChange = useCallback(
        debounce((name, value) => {
            if (onChange) {
                onChange(name, value);
            }
        }, INPUT_DELAY),
        []
    );

    const handleChange = useCallback((event) => {
        const newValue = event.target.value;
        if (maxLength && newValue.length > maxLength)
            return;

        setInnerValue(newValue);
        debouncedHandleOnChange(name, event.target.value);
    }, [debouncedHandleOnChange]);

    const handleBlur = () => {
        if (onBlur)
            onBlur(name, true);
    };

    const debouncedHandleOnSearch = useCallback(
        debounce((query, callback) => {
            if (isProtocol)
                getPractitionersByProtocol(protocolId, query)
                    .then(response => {
                        return response.data.data.map(user => ({ display: user.fullName, id: user.userId }))
                    })
                    .then(callback)
                    .catch(error => {
                        HandleApiError(error);
                    });
            else
                getUsers({ query: query })
                    .then(response => {
                        return response.data.data.map(user => ({ display: user.fullName, id: user.userId }))
                    })
                    .then(callback)
                    .catch(error => {
                        HandleApiError(error);
                    });
        }, INPUT_DELAY),
        []
    );

    const handleSearch = useCallback((query, callback) => {
        if (!query) return;

        debouncedHandleOnSearch(query, callback);
    }, [debouncedHandleOnSearch]);

    const onSearch = (query, callback) => {
        if (!query) return;
        if (isProtocol)
            getPractitionersByProtocol(protocolId, query)
                .then(response => {
                    return response.data.data.map(user => ({ display: user.fullName, id: user.userId }))
                })
                .then(callback)
                .catch(error => {
                    HandleApiError(error);
                });
        else
            getUsers({ query: query })
                .then(response => {
                    return response.data.data.map(user => ({ display: user.fullName, id: user.userId }))
                })
                .then(callback)
                .catch(error => {
                    HandleApiError(error);
                });
    }

    return (
        <>
            <MentionsInput
                name={name}
                value={innerValue}
                onChange={handleChange}
                onBlur={handleBlur}
                style={defaultStyle}
                placeholder={props.placeholder}
                disabled={disabled}
            >
                <Mention
                    trigger="@"
                    data={handleSearch}
                    style={defaultMentionStyle}
                />
            </MentionsInput>
            {
                maxLength &&
                <div style={{ float: 'right', font: '12px Lato', color: '#b8b8b8' }}>{innerValue.length}/{maxLength}</div>
            }
        </>
    );
}

TextBoxUsersMentions.defaultProps = {
    maxLength: null,
    isProtocol: false
}

TextBoxUsersMentions.propTypes = {
    value: PropTypes.string,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    isProtocol: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    max_length: PropTypes.number,
    protocolId: PropTypes.number,
};

const defaultMentionStyle = {
    backgroundColor: '#cee4e5',
}
const defaultStyle = {
    control: {
        backgroundColor: '#fff',

        fontSize: 14,
        fontWeight: 'normal',
    },

    highlighter: {
        overflow: 'hidden',
    },

    input: {
        margin: 0,
    },

    '&singleLine': {
        control: {
            display: 'inline-block',
            width: 130,
        },

        highlighter: {
            padding: 1,
            border: '2px inset transparent',
        },

        input: {
            padding: 1,
            border: '2px inset',
        },
    },

    '&multiLine': {
        control: {
            //fontFamily: 'monospace',
            border: '1px solid silver',
            backgroundColor: '#ffffff',
            borderRadius: '4px',
            color: '#565656',
            boxShadow: 'none'
        },

        highlighter: {
            padding: 9,
        },

        input: {
            padding: 9,
            minHeight: 63,
            outline: 0,
            border: 0,
        },
    },

    suggestions: {
        list: {
            backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 14,
        },

        item: {
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',

            '&focused': {
                backgroundColor: '#cee4e5',
            },
        },
    },
}