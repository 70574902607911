import React from "react";
import PropTypes from 'prop-types';
const jsdiff = require('diff');

export const Diff = ({ inputA, inputB, type, cssContainer }) => {

    var fnMap = {
        'chars': jsdiff.diffChars,
        'words': jsdiff.diffWords,
        'sentences': jsdiff.diffSentences,
        'json': jsdiff.diffJson
    };

    var diff = fnMap[type](inputA, inputB);
    var result = diff.map(function (part, index) {
        var bgStyle = {
            backgroundColor: part.added ? 'lightgreen' : part.removed ? 'salmon' : 'lightgrey',
            textDecoration: part.removed ? 'line-through' :  'none',
        };
        return (
            <span key={index} style={bgStyle}>
                {part.value}
            </span>
        );
    });

    return (
        <div className={cssContainer} style={{ width: '100%' }}>
            {result}
        </div>
    )
}

Diff.propTypes = {
    inputA: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    inputB: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    type: PropTypes.oneOf(['chars', 'words', 'sentences', 'json']),
    cssContainer: PropTypes.string
};
Diff.defaultProps = {
    inputA: '',
    inputB: '',
    type: 'words',
    cssContainer: 'diff-result'
};