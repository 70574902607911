import React from 'react'
import { useTranslation } from 'react-i18next';
import { EntryItemsTable } from './EntryItemsTable';

export const Attachments = (props) => {

    const { t } = useTranslation();

    const columns = [
        {
            dataField: 'fileName', text: t('medicalRecords.attachments.fileName'), formatter: ((cell, row) =>
                (<a onClick={() => props.onDownloadFile(row.id)} style={{ cursor: 'pointer' }}>
                    {cell}
                </a>))
        },
    ];

    return (<EntryItemsTable columns={columns} {...props} />);
}