import { ErrorMessage } from 'formik';
import React from 'react';
import { Col, ControlLabel, FormControl, Grid, HelpBlock, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Card from '../Cards/Card';
import { FormErrorMessage } from '../Validations/FormValidationErrors';

export const DigitalSignDataForm = props => {

    const {
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        currentUser,
    } = props;
    const { t } = useTranslation();

    if (!currentUser || currentUser !== props.user.userId)
        return null;

    let fileReader;
    const handleFileChosen = (file) => {
        fileReader = new FileReader();
        fileReader.onloadend = (evt) => {

            if (evt.target.readyState === FileReader.DONE) {

                const arrayBuffer = fileReader.result;
                setFieldValue("rsaPublicCert", arrayBuffer, false);
            }
        };
        fileReader.readAsBinaryString(file);
    }

    let fileReaderPrivate;
    const handleFilePrivateChosen = (file) => {
        fileReaderPrivate = new FileReader();
        fileReaderPrivate.onloadend = (evt) => {

            if (evt.target.readyState === FileReader.DONE) {

                const arrayBuffer = fileReaderPrivate.result;
                setFieldValue("rsaPrivateCert", arrayBuffer, false);
            }
        };
        fileReaderPrivate.readAsBinaryString(file);
    }

    return (
        <fieldset>
            <legend>{t("digitalSign.title")}</legend>
            <Card
                className="card-patient-edit card-form"
                content={
                    <Grid fluid>
                        <Row>
                            <Col md={12}>
                                <ControlLabel>{t("digitalSign.publicCert")}</ControlLabel>
                                <FormControl type="file" id="rsaPublicCert" onChange={e => handleFileChosen(e.target.files[0])} />
                            </Col>
                            <Col md={12}>
                                <ControlLabel>{t("digitalSign.privateCert")}</ControlLabel>
                                <FormControl type="file" id="rsaPivateCert" onChange={e => handleFilePrivateChosen(e.target.files[0])} />
                            </Col>
                            <Col md={6}>
                                <ControlLabel>{t("digitalSign.certPin")}</ControlLabel>
                                <FormControl type="password" name="password" value={values.password ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                <ErrorMessage name="password" component={FormErrorMessage} />
                                <HelpBlock>{t("digitalSign.certHelp")}</HelpBlock>
                            </Col>
                        </Row>
                    </Grid>
                }
            />
        </fieldset>
    );
}