import axios from './index';

export const getQueryString = (newActiveFilters, search) => {
    const searchParams = new URLSearchParams(search || '');

    searchParams.delete('pageIndex'); // se resetea el paginado
    if (newActiveFilters.pagination.page && newActiveFilters.pagination.page !== 1) {
        searchParams.append('pageIndex', newActiveFilters.pagination.page);
    }
    searchParams.delete('pageSize');
    if(newActiveFilters.pagination.sizePerPage && newActiveFilters.pagination.sizePerPage !== 1){
        searchParams.append('pageSize', newActiveFilters.pagination.sizePerPage);
    }
    searchParams.delete('sortField');
    if (newActiveFilters.sort.sortField) {
        searchParams.append('sortField', newActiveFilters.sort.sortField);
    }
    searchParams.delete('sortOrder');
    if (newActiveFilters.sort.sortOrder) {
        searchParams.append('sortOrder', newActiveFilters.sort.sortOrder);
    }

    searchParams.delete('q');
    if (newActiveFilters.q) {
        searchParams.append('q', newActiveFilters.q);
    }

    searchParams.delete('projects');
    (newActiveFilters.projects || []).forEach(project => searchParams.append('projects', project.id));
    searchParams.delete('protocols');
    (newActiveFilters.protocols || []).forEach(protocol => searchParams.append('protocols', protocol.id));
    searchParams.delete('professionals');
    (newActiveFilters.professionals || []).forEach(professional => searchParams.append('professionals', professional.userId));
    searchParams.delete('type');
    if (newActiveFilters.type != null && newActiveFilters.type.id) {
        searchParams.append('type', newActiveFilters.type.id);
    }

    return searchParams.toString();
}

export const getPatients = async filters => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(queryString ? `api/v1/database/patients?${queryString}` : 'api/v1/database/patients');
};

export const getPatient = (medicalRecordNumber, withProfileImage = false) => {
    return axios.get(`api/v1/database/patient/${medicalRecordNumber}/${withProfileImage}`);
};

export const getPatientByMedicalRecord = (medicalRecordNumber, withProfileImage = false) => {
    return axios.get(`api/v1/database/patient/medicalRecord/${medicalRecordNumber}/${withProfileImage}`);
};

export const getPatientAvatarByMedicalRecord = (medicalRecordNumber) => {
    return axios.get(`api/v1/database/patient/medicalRecord/${medicalRecordNumber}/base64`);
};

export const createPatient = (patient) => {
    return axios.post('api/v1/database/patient', patient);
};

export const updatePatient = (patient) => {
    return axios.put('api/v1/database/patient', patient);
};

export const deletePatient = (id) => {
    return axios.delete(`api/v1/database/patient/${id}`);
};

export const getPractitionersBasic = () => {
    return axios.get('api/v1/agenda/professionals');
};

export const getMasterData = () => {
    return axios.get('api/v1/admin/masterdata');
};

export const searchPatients = q => {
    return axios.get('api/v1/database/patients/search', { params: { q } });
}

export const exportToExcel = (filters) => {
    const queryString = getQueryString(filters, window.location.search);

    return axios.get(queryString ? `api/v1/database/patients/exportToExcel?${queryString}` : 'api/v1/database/patients/exportToExcel', {
        responseType: 'arraybuffer'
    });
};

export const syncPatients = (tenantId) => {
    return axios.post(`api/v1/database/sync/${tenantId}`, tenantId);
};