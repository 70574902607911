import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { useSignalR } from '../../../services/signalRService';
import { useAdminNotification } from './useAdminNotifications';

export function SystemNotification() {
    const { connection } = useSignalR();
    const [getLastActiveNotification] = useAdminNotification();

    useEffect(() => {
        if (connection) {
            connection.on('AdminNotification', res => {
                showNotification(res);
            });

            return () => {
                // Leave group when component unmounts
                if (connection.connection._connectionState === "Connected")
                    connection.invoke('LeaveGroup', "SystemNotifications");
            };
        }
    }, [connection]);

    useEffect(() => {
        let isSubscribed = true

        const fetchData = async () => {
            try {
                const notif = await getLastActiveNotification();
                if (notif && isSubscribed) {
                    showNotification(notif.data);
                }
            } catch (error) {
                if (isSubscribed) {
                    console.log(error);
                }
            }
        }

        fetchData();

        return () => isSubscribed = false;
    }, []);

    function showNotification(notification) {
        if (notification)
            toast.info(notification.message, { containerId: "system-notifications" });
    }

    return (
        <div>
            <ToastContainer
                style={{ width: "80%" }}
                containerId="system-notifications"
                position="top-center"
                autoClose={false}
                newestOnTop={false}
                closeOnClick
                pauseOnFocusLoss
                draggable
                theme="light"
                enableMultiContainer={true}
            />
        </div>
    );
}