import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';

import { createRole } from "../../../api/security";
import { useRoleMasterData } from "./useRoles";
import { getRoleSchema } from "../../../components/Validations/FormValidationSchema";
import { RoleForm } from "../../../components/Authorization/Roles";
import { Success } from '../../../components/Notifications/Notifications';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { useTenants } from "../../Tenants/Hooks";
import { Spinner } from "../../../components/Utils/Loaders";
import { useAuthorization } from "../../../components/Authorization/useAuthorization";

export const CreateRoleContainer = () => {
    let history = useHistory();
    const [isLoading, data] = useRoleMasterData();
    const [tenants, isLoadingTenants, getAll] = useTenants();
    const [,, isAuthorized] = useAuthorization();

    useEffect(() => {
        let isSubscribed = true;
        const fetchData = async () => { await getAll(); }

        if (isAuthorized(["Tenant.List"]))
            fetchData();

        return () => isSubscribed = false;
    }, []);

    const handleSubmit = () => {
        history.push(`/admin/settings/roles`);
    }
    const handleCancel = () => {
        history.goBack()
        //history.push(`/admin/settings/roles`);
    }

    if (isLoading || isLoadingTenants) return <Spinner />;

    return (
        <CreateRoleContainerFormik
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            masterData={data}
            tenants={tenants ? tenants : []}
        />
    );
}

const CreateRoleContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: () => ({}),

    validationSchema: getRoleSchema(),

    handleSubmit: (values, formikBag) => {
        //console.log("Posting Role:", values.role);

        const role = {
            name: values.role.name,
            claims: values.role.claims,
            tenant: values.role.tenant
        }

        createRole(role)
            .then(res => {
                var role = res.data;
                formikBag.setSubmitting(false);
                Success("securityNotifications.role_Created");
                formikBag.props.onSubmit(role);
            }).catch(error => {
                console.log(error);
                formikBag.setSubmitting(false);
                HandleApiError(error);
            });
    },

    displayName: 'RoleForm',

})(RoleForm));
