import React from 'react';
import { Route } from 'react-router-dom';
import Breadcrumbs from '../Utils/Breadcrumbs';

export const CustomRoute = props => {

    const { routes, route, ...rest } = props;

    return <Route
        {...rest}
        render={props => {
            const crumbs = routes
                // Get all routes that contain the current one.
                .filter(({ path }) => props.match.path.includes(path))
                // Swap out any dynamic routes with their param values.
                .map(({ path, ...rest }) => ({
                    path: Object.keys(props.match.params).length
                        ? Object.keys(props.match.params).reduce(
                            (item, param) =>
                                item.replace(`:${param}`, props.match.params[param]),
                            route.layout + path
                        )
                        : route.layout + path,
                    ...rest
                }));

            return (
                <>
                    <Breadcrumbs crumbs={crumbs} />
                    <route.component {...props} />
                </>
            );
        }
        }
    />

}