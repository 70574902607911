import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert"
import { useTranslation } from 'react-i18next'

export const ModalConfirm = ({ title, description, timer, onConfirm, onCancel, confirmStyle, cancelStyle }) => {
    const { t } = useTranslation();
    const [counter, setCounter] = useState(timer); // Establece aquí el valor inicial del contador
    const [confirmDisabled, setConfirmDisabled] = useState(true);

    useEffect(() => {
        let interval;
        interval = setInterval(() => {
            setCounter((prevCounter) => prevCounter - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setConfirmDisabled(counter > 0);
    }, [counter]);

    return (
        <SweetAlert
            warning
            title={title}
            onConfirm={onConfirm}
            onCancel={onCancel}
            confirmBtnBsStyle={confirmStyle}
            cancelBtnBsStyle={cancelStyle}
            confirmBtnText={counter > 0 ? `${t("buttons.yes")} (${counter})` : t("buttons.yes")}
            cancelBtnText={t("buttons.no")}
            showCancel
            disabled={confirmDisabled} // Deshabilita el botón de confirmación según el estado del contador
        >
            {description}
        </SweetAlert>
    );
}

ModalConfirm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    timer: PropTypes.number
};

ModalConfirm.defaultProps = {
    timer: 3,
    confirmStyle: "success",
    cancelStyle: "info"
};