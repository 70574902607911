import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class CustomImage extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            src: props.src,
            errored: false,
        };
    }

    onError = () => {
        if (!this.state.errored) {
            this.setState({
                src: this.props.defaultSrc,
                errored: true,
            });
        }
    }

    render() {
        const { src } = this.state;
        return (
            <img
                alt="image"
                src={src}
                onError={this.onError}
            />
        );
    }
}

CustomImage.propTypes = {
    src: PropTypes.string,
    defaultSrc: PropTypes.string.isRequired,
    isLoading: PropTypes.bool
};