import React from "react";
import { commonOptions, commonScale, commonPlugins } from "../Utils";
import { Chart } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

export function TemperatureChart({ data, min, max }) {
    const { t } = useTranslation()
    const hasNotData = HasNotData(data)
    return (
        <Chart data={data} type={'line'} options={{
            ...commonOptions,
            scales: {
                ...commonScale,
                y: {
                    offset: true,
                    grid: {
                        color: function (context) {
                            if ((min !== null && context.tick.value == min) || (max !== null && context.tick.value == max))
                                return "#ff6b67"
                            return '#e5e5e5';
                        }
                    },
                    suggestedMin: min,
                    suggestedMax: max,
                    ticks: {
                        includeBounds: true,
                    },
                    grace: '10%'
                }
            },
            plugins: {
                ...commonPlugins,
                title: {
                    display: hasNotData,
                    text: hasNotData && t("telemetry.noInformation")
                }
            }
        }} />
    )
}

export function HumidityChart({ data }) {
    const { t } = useTranslation()
    const hasNotData = HasNotData(data)
    return (
        <Chart data={data} type={'line'} options={{
            ...commonOptions,
            scales: {
                ...commonScale,
                y: {
                    offset: true
                }
            },
            plugins: {
                ...commonPlugins,
                title: {
                    display: hasNotData,
                    text: hasNotData && t("telemetry.noInformation")
                }
            }
        }} />
    )
}

export function DoorChart({ data }) {
    const { t } = useTranslation()
    const hasNotData = HasNotData(data)

    return (
        <Chart data={data} type={'line'} options={{
            ...commonOptions,
            scales: {
                ...commonScale,
                y: {
                    type: 'category',
                    labels: [t('settings.freezer.open'), t('settings.freezer.close')],
                    offset: true,
                    position: 'left',
                    stackWeight: 0,
                }
            },
            plugins: {
                ...commonPlugins,
                title: {
                    display: hasNotData,
                    text: hasNotData && t("telemetry.noInformation")
                }
            }
        }} />
    )
}

const HasNotData = (data) => {
    return !(data.datasets?.reduce((has, value) => {
        return has = value.data.length > 0
    }, false) ?? false)
}
