import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Tooltip } from '../Utils/Tooltips';
import { Readed, Unread, View } from '../Icons/Icons';
import { actionCreators } from '../../store/Notifications';
import { generateLink } from '../Utils/Commons';
import Card from '../Cards/Card';
import { useTranslation } from 'react-i18next';

const UserNotificationsCode = ({ notifications, requestUnreadNotifications, requestMarkAsReadedNotification }) => {
    const { t } = useTranslation();
    useEffect(() => {
        requestUnreadNotifications();
    }, []);

    const montotos = notifications.map((notification, index) => {
        //var params = "&from=VisitTracking&protocolId=1&visitId=1&patientId=28294&formId=21";
        return (
            <tr key={index}>
                <td>{notification.content}</td>
                <td>
                    <NavLink to={generateLink(notification.params)} activeClassName="active">
                        <View style={{ fontSize: '20px' }} />
                    </NavLink>
                </td>
                <td>
                    {
                        notification.status === 0 ?
                            <Tooltip tooltip={"Marcar como leído."} id={`tt-${index}`}>
                                <button className="btn-simple btn-table-actions" type="button" onClick={() => requestMarkAsReadedNotification(notification.id)}>
                                    <Unread />
                                </button>
                            </Tooltip>
                            :
                            <Tooltip tooltip={"Leída."} id={`tt-${index}`}>
                                <div className="btn-simple btn-table-actions">
                                    <Readed />
                                </div>
                            </Tooltip>
                    }
                </td>
            </tr>
        )
    });

    return (
        <Card
            title={t("notifications.notifications")}
            className="card-notifications"
            content={
                <table className="table">
                    <tbody>
                        {montotos}
                    </tbody>
                </table>
            }
        />
    )
}

const UsersNotifications = connect(
    state => state.notifications,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(UserNotificationsCode);

export default UsersNotifications;
