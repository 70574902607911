import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import { ErrorMessage } from 'formik';

import { FormErrorMessage } from '../../Validations/FormValidationErrors'
import Card from '../../Cards/Card';
import { Tooltip } from "../../Utils/Tooltips"
import { Edit, Remove } from '../../Icons/Icons';
import { WithAuth } from '../../Authorization/WithAuth';
import { ConfirmRemove } from './Modals';

export const Laboratories = ({ laboratories, onEdit, onRemove, isLoading }) => {
    const { t } = useTranslation();
    const [modal, setModal] = useState(null);

    const columns = [
        { dataField: 'code', text: t("protocols.admin.laboratory.code"), headerStyle: { width: '250px' }, },
        { dataField: 'title', text: t("protocols.admin.laboratory.title"), headerStyle: { width: '250px' }, },
        {
            dataField: 'view',
            text: t("protocols.practitioner.actions"),
            isDummyField: true,
            headerClasses: 'header-actions',
            classes: 'row-actions',
            formatter: function (_cell, row, rowIndex) {
                return (
                    <div key={rowIndex}>
                        <WithAuth requiredPermission={["Settings.Edit"]}>
                            <button className="btn-table-actions" onClick={() => onEdit(row.id)}>
                                <Tooltip tooltip={t("protocols.admin.edit")} id={"tooltipedit-" + row.id}>
                                    <span aria-hidden="true"><Edit /></span>
                                </Tooltip>
                            </button>
                        </WithAuth>
                        <WithAuth requiredPermission={["Settings.Delete"]}>
                            <button className="btn-table-actions" onClick={() => showModal(row.id)}>
                                <Tooltip tooltip={t("protocols.admin.remove")} id={"tooltipremove-" + row.id}>
                                    <span aria-hidden="true"><Remove /></span>
                                </Tooltip>
                            </button>
                        </WithAuth>
                    </div>
                )
            }
        }
    ];

    const showModal = (id) => {
        setModal(
            <ConfirmRemove
                onClose={() => setModal(null)}
                onConfirm={(confirmRemove)}
                id={id}
            />
        );
    }
    const confirmRemove = (id) => {
        setModal(null);
        onRemove(id);
    }

    return (
        <>
            {modal}
            <BootstrapTable
                bordered={false}
                hover
                remote
                keyField='id'
                classes="table-spaced table-spaced-small"
                wrapperClasses="table-responsive"

                data={laboratories}
                columns={columns}
                loading={isLoading}
                noDataIndication={() => <>{t("commons.noData")}</>}
            />
        </>
    );
}

Laboratories.propTypes = {
    laboratories: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
};

export const LaboratoryForm = props => {

    const {
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        t
    } = props;

    if (!values.laboratory) {
        values.laboratory = {
            title: '',
            code: '',
            description: '',
        };
    }

    return (
        <Card
            title={t("protocols.admin.laboratory.laboratoryTitle")}
            ctFullWidth
            content={
                <form onSubmit={handleSubmit} autoComplete="off">
                    <FormGroup>
                        <ControlLabel>{t("protocols.admin.laboratory.code")}</ControlLabel>
                        <FormControl type="text" name={`laboratory.code`} value={values.laboratory.code} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                        <ErrorMessage name={`laboratory.code`} component={FormErrorMessage} />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{t("protocols.admin.laboratory.title")}</ControlLabel>
                        <FormControl type="text" name={`laboratory.title`} value={values.laboratory.title} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                        <ErrorMessage name={`laboratory.title`} component={FormErrorMessage} />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{t("protocols.admin.laboratory.description")}</ControlLabel>
                        <FormControl type="text" name={`laboratory.description`} value={values.laboratory.description} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                        <ErrorMessage name={`laboratory.description`} component={FormErrorMessage} />
                    </FormGroup>
                    <Grid fluid>
                        <Row>
                            <Col sm={1} smOffset={10}>
                                <button type="button" className="btn btn-info btn-right" onClick={props.onCancel} >{t("buttons.back")}</button>
                            </Col>
                            <Col sm={1} >
                                <button type="submit" className="btn btn-success btn-right" disabled={props.isSubmitting} >{t("buttons.save")}</button>
                            </Col>
                        </Row>
                    </Grid>
                </form>
            }
        />
    );
}