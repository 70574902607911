import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import * as Configuration from './Configuration';
import * as Notifications from './Notifications';
import * as Authentication from './AuthUser';
import * as Protocol from './Protocol';
import * as Agenda from './Agenda';

export default function configureStore(history, initialState) {
  const reducers = {
    notifications: Notifications.reducer,
    authentication: Authentication.reducer,
    settings: Configuration.reducer,
    protocol: Protocol.reducer,
    agenda: Agenda.reducer
  };

  const middleware = [
    thunk,
    routerMiddleware(history)
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history)
  });

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
