import React, { useEffect, useMemo, useState } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import '../../assets/sass/lbd/_attachments.scss';
import { File, IconSVG, Remove } from '../Icons/Icons';
import PropTypes from 'prop-types';

export const CustomFileUpload = (props) => {
    const {
        name,
        value,
        placeholder,
        onChange,
        disabled,
        onDownload,
        onRemove,
        accept,
        showRemove
    } = props;

    const activeStyle = {
        borderColor: '#2196f3'
    };

    const [file, setFile] = useState({});
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: acceptedFiles => {
            var attach = acceptedFiles.map(file => Object.assign({}, {
                file: file,
                fileName: file.path,
                size: file.size
            }));

            setFile(attach[0]);
            onChange(name, attach[0])
        },
        multiple: false,
        accept: accept,//'image/*,.pdf,.docx,.doc,.xls,.xlsx',
        maxSize: 5000000
    });

    const style = useMemo(() => ({
        ...(isDragActive ? activeStyle : {})
    }), [isDragActive]);

    useEffect(() => {
        if (value)
            setFile(value);
        else
            setFile({})
    }, [value]);

    const handleRemove = () => {
        onChange(name, null);
        setFile({});
        if (onRemove)
            onRemove(name, value);
    }

    const handleView = () => {
        if (onDownload)
            onDownload(file.fileName)
    }

    return (
        <div className="attachments-container">
            {
                !file || !file.fileName &&
                <section>
                    <div {...getRootProps({ style })} className="dropzone">
                        <input {...getInputProps()} disabled={disabled} />
                        <div className="dropzone-content">
                            <IconSVG name="upload" />
                            <p>{placeholder}</p>
                        </div>
                    </div>
                </section>
            }
            {
                file && file.fileName ?
                    <Grid fluid>
                        <Row>
                            <Col md={10}>
                                <div className="file-icon"><File name="file" /></div>
                                <div className="file-title">
                                    <a onClick={handleView} style={{ cursor: 'pointer' }}>
                                        {file.fileName}
                                    </a>
                                    {
                                        showRemove && <button className="btn btn-fill btn-danger btn-sm btn-right" onClick={handleRemove}>
                                            <Remove />
                                        </button>
                                    }
                                </div>
                            </Col>
                            <Col md={2}>
                                <div className="file-size"></div>
                            </Col>
                        </Row>
                        <hr style={{ marginTop: '50px' }}></hr>
                    </Grid>
                    :
                    null
            }
        </div>
    )
}

CustomFileUpload.defaultProps = {
    accept: 'image/*,.pdf,.docx,.doc,.xls,.xlsx',
    showRemove: true,
}

CustomFileUpload.propTypes = {
    accept: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onDownload: PropTypes.func,
    onRemove: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.object,
    showRemove: PropTypes.bool,
}