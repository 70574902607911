import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { getFormTemplates } from '../../../api/protocolTemplates';
import { createExtraForm, editExtraForm, getExtraForm, removeExtraForm } from '../../../api/protocolsExtraForms';
import { useAuthProtocol } from '../../../components/Authorization/useAuthorization.js';
import { DynamicFormCreator } from '../../../components/DynamicForm/DynamicFormCreator';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { Success } from '../../../components/Notifications/Notifications';

export const ExtraForm = (props) => {
    const { match: { params: { protocolId, formId, extraformtype } } } = props;
    let history = useHistory();
    const [isAuthorized] = useAuthProtocol(protocolId);
    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await getExtraForm(protocolId, formId);
                setForm(response.data);
                setIsLoading(false);
            }
            catch (error) {
                HandleApiError(error);
                setIsLoading(false);
            }
        }

        if (formId)
            fetchData();
        else
            setForm({
                type: '',
                title: ''
            });
    }, [protocolId, formId]);

    const [formTemplates, setFormTemplates] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await getFormTemplates(protocolId);
                setFormTemplates(response.data);
                setIsLoading(false);
            }
            catch (error) {
                setIsLoading(false);
            }
        }
        fetchData();
    }, [protocolId]);

    const handleSaveClick = async (form) => {
        if (!form) return;

        setIsLoading(true);
        if (!formId) {
            try {
                await createExtraForm(protocolId, extraformtype, form);
                setIsLoading(false);
                Success("protocolNotifications.form_Created");
                history.push(`/admin/protocols/${protocolId}/section/extraforms`);
            }
            catch (error) {
                HandleApiError(error);
                setIsLoading(false);
            }
        }
        else {
            try {
                await editExtraForm(protocolId, formId, form);
                setIsLoading(false);
                Success("protocolNotifications.form_Updated");
                history.push(`/admin/protocols/${protocolId}/section/extraforms`);
            }
            catch (error) {
                HandleApiError(error);
                setIsLoading(false);
            }
        }
    }

    const handleRemove = (formId) => {
        removeExtraForm(protocolId, formId)
            .then(() => {
                history.push(`/admin/protocols/${protocolId}/section/extraforms`);
            }).catch(error => {
                console.error(error);
                HandleApiError(error);
            });
    }

    const isDisabled = !isAuthorized(["Visits.Add"]);

    return (
        <DynamicFormCreator
            initialForm={form}
            formTemplates={formTemplates}
            protocolId={protocolId}
            formId={formId}
            backUrl={`/admin/protocols/${protocolId}/section/extraforms`}
            onRemove={handleRemove}
            onSave={handleSaveClick}
            isDisabled={isDisabled}
            isLoading={isLoading}
        />
    );
}