import moment from "moment";
import React from "react";
import { Col, Grid, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { WithAuth } from "../Authorization/WithAuth";
import Card from "../Cards/Card";
import { View, ViewMR } from "../Icons/Icons";
import { Tooltip } from "../Utils/Tooltips";

export const PatientInfo = ({ patient }) => {
    const { t } = useTranslation();

    const ageComponent = patient.age ? <>({patient.age} {t('medicalRecords.patientInfo.age')})</> : null;

    return (
        <Card
            className="card-patient"
            content={
                <Grid fluid>
                    <Row>
                        <Col sm={12}>
                            <Row>
                                <div className="info">
                                    <div className="name">
                                        <span>{patient.fullName}</span>
                                        <span style={{ marginLeft: '10px', verticalAlign: 'middle' }}>
                                            <WithAuth requiredPermission={["MedicalRecords.View"]}>
                                                <NavLink to={`/admin/medicalRecords/${patient.medicalRecordNumber}`} activeClassName="active">
                                                    <Tooltip tooltip={t('database.viewMR')} id={"tooltipviewMR-" + patient.medicalRecordNumber}>
                                                        <ViewMR />
                                                    </Tooltip>
                                                </NavLink>
                                            </WithAuth>
                                        </span>
                                        <span style={{ marginLeft: '10px', verticalAlign: 'middle' }}>
                                            <NavLink to={"/admin/database/" + patient.medicalRecordNumber} className="nav-link" activeClassName="active">
                                                <Tooltip tooltip={t('database.view')} id={"tooltipview-" + patient.medicalRecordNumber}>
                                                    <View />
                                                </Tooltip>
                                            </NavLink>
                                        </span>
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <Col sm={6} style={{ paddingLeft: 0 }}>
                                    <div className="info">
                                        <div><span className="card-label">{t('medicalRecords.patientInfo.birthDate')}:</span> <span className="card-text">{moment(patient.birthDate).format('DD/MM/YY')} {ageComponent}</span></div>
                                        {patient.identificationType && <div><span className="card-label">{patient.identificationType}:</span> <span className="card-text">{patient.identificationNumber}</span></div>}
                                        {patient.medicalInsurance && <div><span className="card-label">{t('medicalRecords.patientInfo.medicalInsurance')}:</span> <span className="card-text">{patient.medicalInsurance} {patient.medicalInsurancePlan ? <>({patient.medicalInsurancePlan})</> : null}</span></div>}
                                        {patient.medicalInsuranceNumber && <div><span className="card-label">{t('medicalRecords.patientInfo.medicalInsuranceNumber')}:</span> <span className="card-text">{patient.medicalInsuranceNumber}</span></div>}
                                        <div><span className="card-label">{t('medicalRecords.patientInfo.medicalRecord')}:</span> <span className="card-text">{patient.medicalRecordNumber}</span></div>
                                    </div>
                                </Col>
                                <Col sm={6} style={{ paddingLeft: 0 }}>
                                    <div className="info">
                                        <div><span className="card-label">{t('person.address.address')}: </span><span className="card-text">{patient.address}</span></div>
                                        <div><span className="card-label">{t('person.contact.cellPhone')}:</span> <span className="card-text">{patient.cellPhone}</span></div>
                                        <div><span className="card-label">{t('person.contact.email')}:</span> <span className="card-text">{patient.email}</span></div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Grid>
            }
        />
    );
}