import { withFormik } from 'formik';
import React from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { copyVisit, createVisit } from '../../../api/protocol';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { Success } from '../../../components/Notifications/Notifications';
import { OffsetTypes, Types, VisitStages } from '../../../components/Protocols/Visit/Enums';
import { VisitHeaderForm } from '../../../components/Protocols/Visit/Header';
import { getProtocolVisitHeaderSchema } from "../../../components/Validations/FormValidationSchema";
import { useProtocolVisits } from '../Hooks';


export const AddVisitModal = (props) => {
    const { isShowing, hide, protocolId, submit } = props;
    const { t } = useTranslation();

    return isShowing ? ReactDOM.createPortal(
        <React.Fragment>
            <BootstrapModal show={true} onHide={hide} bsSize="large" backdrop="static" >
                <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title>{t('protocols.visit.addVisitTitle')}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <CreateVisitContainer protocolId={protocolId} onClose={hide} onSubmit={submit} />
                </BootstrapModal.Body>
            </BootstrapModal>
        </React.Fragment>, document.body
    ) : null;
};

const CreateVisitContainer = (props) => {
    let history = useHistory();
    const protocolId = props.protocolId;
    const visits = useProtocolVisits(protocolId);

    const handleSubmit = (visit) => {
        history.push(`/admin/protocols/${protocolId}/visitsTemplate/${visit.crossVersionId}`);
    }
    const handleCancel = () => {
        //history.push(`/admin/protocols/${protocolId}/section/visitsTemplate`);
        props.onClose();
    }

    return (
        <CreateVisitFormik
            protocolId={protocolId}
            visits={visits}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
        />
    );
}

const CreateVisitFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => ({ visit: props.visit }),
    //enableReinitialize

    validationSchema: getProtocolVisitHeaderSchema(),

    handleSubmit: (value, formikBag) => {
        //console.log("Posting Visit Header:", value.visit);

        let visit = value.visit;
        visit.type = visit.type.id;
        visit.stage = visit.stage.id;
        visit.offsetType = visit.offsetType?.id
        visit.offsetFrom = visit.offsetFrom?.id

        createVisit(formikBag.props.protocolId, visit)
            .then(res => {
                var item = res.data

                formikBag.setSubmitting(false);
                Success("protocolNotifications.visit_Created");
                formikBag.props.onSubmit(item);

            }).catch(error => {
                console.error(error);
                formikBag.setSubmitting(false);
                HandleApiError(error);
            });
    },

    displayName: 'CreateVisit',

})(VisitHeaderForm));

export const CopyVisitModal = (props) => {
    const { isShowing, hide, protocolId, visitId, submit } = props;
    const { t } = useTranslation();

    return isShowing ? ReactDOM.createPortal(
        <React.Fragment>
            <BootstrapModal show={true} onHide={hide} bsSize="large" backdrop="static" >
                <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title>{t('protocols.visit.copyVisitTitle')}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <CopyVisitContainer protocolId={protocolId} visitId={visitId} onClose={hide} onSubmit={submit} />
                </BootstrapModal.Body>
            </BootstrapModal>
        </React.Fragment>, document.body
    ) : null;
};


const CopyVisitContainer = (props) => {
    let history = useHistory();
    const { protocolId, visitId } = props;

    const visits = useProtocolVisits(protocolId);

    const handleSubmit = (visit) => {
        history.push(`/admin/protocols/${protocolId}/visitsTemplate/${visit.crossVersionId}`);
    }
    const handleCancel = () => {
        //history.push(`/admin/protocols/${protocolId}/section/visitsTemplate`);
        props.onClose();
    }

    if (!visitId || visits.length === 0)
        return null;

    let visit = visits.filter(x => x.crossVersionId == visitId)[0];
    if (!visit) return null;

    let visitCopy = JSON.parse(JSON.stringify(visit));
    visitCopy.title = visit.title + " - Copia";
    visitCopy.type = Types().find(x => x.id == visit.type);
    visitCopy.stage = VisitStages().find(x => x.id == visit.stage);
    visitCopy.offsetType = OffsetTypes().find(x => x.id == visit.offsetType);
    visitCopy.version = 0.1;

    return (
        <CopyVisitFormik
            protocolId={protocolId}
            visit={visitCopy}
            visits={visits}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
        />
    );
}

const CopyVisitFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => ({ visit: props.visit }),

    validationSchema: getProtocolVisitHeaderSchema(),

    handleSubmit: (value, formikBag) => {
        //console.log("Posting Visit Header:", value.visit);

        let visit = value.visit;
        visit.type = visit.type.id;
        visit.stage = visit.stage.id;
        visit.offsetType = visit.offsetType?.id
        visit.offsetFrom = visit.offsetFrom?.id

        copyVisit(formikBag.props.protocolId, visit.id, visit)
            .then(res => {
                var item = res.data

                formikBag.setSubmitting(false);
                Success("protocolNotifications.visit_Created");
                formikBag.props.onSubmit(item);

            }).catch(error => {
                console.error(error);
                formikBag.setSubmitting(false);
                HandleApiError(error);
            });
    },

    displayName: 'CopyVisit',

})(VisitHeaderForm));
