import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const WelcomeWidget = () => {
    const { t } = useTranslation();
    const auth = useSelector(state => state.authentication);

    return (
        <div className="welcome-widget">
            <div className="welcome">
                <span>{t("dashboard.welcome")}</span> {auth.isAuthenticated && auth.user?.given_name}!
            </div>
            {/*<div className="notifications">
                {t("dashboard.noNotifications")}
            </div>*/}
        </div>
    )
}

export default WelcomeWidget;
