import React from "react";
import { useTranslation } from 'react-i18next';
import { useUserInfoSignData } from "../../containers/Users/Hooks";
import Card from "../Cards/Card";
import { dateTimeFormatter, toLocaleDateTimeFormatter } from '../Utils/Formatter'

const Sign = ({ fullName, speciality, registrationNumber, sign, created, locale }) => {
    const { t } = useTranslation();

    if (!fullName)
        return null;

    return (
        <Card
            className="card-actions"
            content={
                <div>
                    <div className="card-label">{t("sign.signedBy")}: <span className="card-text">{fullName}</span></div>
                    <div className="card-label">{t("sign.specialty")}: <span className="card-text">{`${speciality} ${registrationNumber || ""}`}</span></div>
                    <div className="card-label">{t("sign.signed")}:
                        <span className="card-text">
                            {locale ? toLocaleDateTimeFormatter(created) : dateTimeFormatter(created)}
                        </span>
                    </div>
                    {
                        sign && <img className="center sign" src={`data:image/jpeg;base64,${sign}`} style={{ maxWidth: '350px' }} />
                    }
                </div>
            }
        />
    );
}

export const SignWithOutInfo = ({ signedBy, created, locale }) => {

    const [isLoading, userInfo] = useUserInfoSignData(signedBy);

    if (!signedBy || isLoading)
        return null;

    return <Sign
        fullName={userInfo.fullName}
        speciality={userInfo.speciality}
        registrationNumber={userInfo.registrationNumber}
        sign={userInfo.sign}
        created={created}
        locale={locale}
    />
}

SignWithOutInfo.defaultProps = {
    locale: false
}