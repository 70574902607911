import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from "react-router-dom"
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import { WithAuth } from '../../../components/Authorization/WithAuthProtocol'
import { Spinner } from '../../../components/Utils/Loaders'
import ProtocolContext from '../../../containers/Protocols/ProtocolContext/ProtocolContext'
import Card from '../../Cards/Card'
import { Add, Config } from '../../Icons/Icons'
import { arrayMoveImmutable } from '../../Utils/Commons'

export const VisitForms = props => {
    const { t } = useTranslation();
    const context = useContext(ProtocolContext);

    if (props.isLoading || !props.visitId)
        return <Card
            title={t("protocols.visit.formTitle")}
            content={<Spinner />}
        />

    //#region Sort
    function handleSortEnd({ oldIndex, newIndex }) {
        if (oldIndex == newIndex)
            return;
        const orderedForms = arrayMoveImmutable(props.forms, oldIndex, newIndex);
        orderedForms.forEach((form, index) => {
            form.order = index + 1;
        });

        if (props.onSortEnd)
            props.onSortEnd(orderedForms);
    }

    const DragHandle = sortableHandle(() => <div className="SortableHelper"></div>);
    const SortableItem = sortableElement(({ form }) => (
        <li className="sortable-field-item list-items">
            <DragHandle />
            {form}
        </li>
    ));
    const SortableContainer = sortableContainer(({ children }) => {
        return <ul className="list-container sortable-form-list" style={{ overflow: 'inherit' }}>{children}</ul>;
    });
    //#endregion

    const subTitleComponent = !context.isDisabled && (<NavLink to={`/admin/protocols/${context.protocolId}/visitsTemplate/${props.visitId}/forms/create`} className="nav-link" activeClassName="active">
        <Add /> {t("protocols.visit.addVisitForm")}
    </NavLink>);

    const content = (<SortableContainer onSortEnd={handleSortEnd} useDragHandle>
        {props.forms.map((form, index) => (
            <SortableItem
                key={`item-${index}`}
                index={index}
                form={<NavLink style={{ marginLeft: '10px' }} to={`/admin/protocols/${context.protocolId}/visitsTemplate/${props.visitId}/forms/${form.id}`}>
                    <span aria-hidden="true"><Config /></span> {form.title}
                </NavLink>}
            />
        ))}
    </SortableContainer>);

    return (
        <Card
            title={t("protocols.visit.formTitle")}
            subtitle={
                <WithAuth protocolId={context.protocolId} requiredPermission={["Visits.Add"]}>
                    {subTitleComponent}
                </WithAuth>
            }
            content={content}
        />
    );
}

VisitForms.propTypes = {
    onSortEnd: PropTypes.func.isRequired,
    forms: PropTypes.array,
    visitId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export const VisitExtraForms = props => {
    const { t } = useTranslation();

    const content = (props.forms && <ul className="list-container">
        {props.forms.map((form, index) => (
            <li className="list-items" key={`swap${index}`}>
                <NavLink
                    to={`/admin/protocols/${props.protocolId}/extraforms/${form.id}`}
                    style={{ marginLeft: '10px' }}>
                    <span aria-hidden="true"><Config /></span> {form.title}
                </NavLink>
            </li>
        ))}
    </ul>);

    if (props.isLoading || !props.forms)
        return <Card
            title={t("protocols.extraForms.extraFormType.0")}
            content={<Spinner />}
        />

    return (
        <Card
            title={t("protocols.extraForms.extraFormType.0")}
            content={content}
        />
    );
}

VisitExtraForms.propTypes = {
    forms: PropTypes.array
};