import React from "react";
import moment from 'moment';
import { Tooltip } from "../Utils/Tooltips";
import { Check, PersonCheck } from "../Icons/Icons";
import { useTranslation } from "react-i18next";

const EventPatient = ({ event }) => {
    const { t } = useTranslation();

    var title = event.attended === false ? <span style={{ textDecoration: 'line-through' }}>{event.title}</span> : event.title
    var confirmed = event.confirmed === true ? <Check className="rbc-event-confirmed" /> : null;
    var present = event.present && !event.attended ? <PersonCheck className="rbc-event-confirmed" /> : null;
    var tooltip = (
        <>
            <div>{t("calendar.patient")}: {event.patient?.fullName}</div>
            <div>{t("calendar.medicalRecord")}: {event.patient?.medicalRecordNumber}</div>
            <div>{t("calendar.practitioner")}: {event.practitioner?.fullName}</div>
            <div>{t("calendar.visitType")}: {event.visitType?.title}</div>
            {event.protocol ? <div>{t("calendar.protocol")}: {event.protocol.title}</div> : null}
        </>
    );

    return (
        <Tooltip id={`event-tooltip-${event.id}`} tooltip={tooltip}>
            <div>
                <div>{confirmed} {present} {title} - {moment(event.start).format('H:mm')}</div>
                <div className="event-professional">{event.practitioner?.fullName}</div>
            </div>
        </Tooltip>
    );
}
const EventScheduleLock = ({ event }) => {
    const { t } = useTranslation();
    var tooltip = (
        <div>
            <div>{t("calendar.scheduleLock")}</div>
            {event.practitioner && <div className="event-professional">{t("calendar.practitioner")}: {event.practitioner?.fullName}</div>}
            <div>{moment(event.start).format('H:mm')} -  {moment(event.end).format('H:mm')}</div>
        </div>
    );
    return (
        <Tooltip id={`event-tooltip-${event.id}`} tooltip={tooltip}>
            <div>
                <div> {t("calendar.scheduleLock")} - {moment(event.start).format('H:mm')}</div>
                <div className="event-professional">{event.practitioner?.fullName}</div>
            </div>
        </Tooltip>
    );
}
export const Event = ({ event }) => {

    if (event.visitType?.code === "SCHEDULELOCK")
        return <EventScheduleLock event={event} />
    else
        return <EventPatient event={event} />
}

const EventMonthPatient = (props) => {
    const { t } = useTranslation();
    let event = props.event;
    var title = event.attended === false ? <span style={{ textDecoration: 'line-through' }}>{event.title}</span> : event.title
    var confirmed = event.confirmed === true ? <Check className="rbc-event-confirmed" /> : null;
    var tooltip = (
        <>
            <div>{t("calendar.patient")}: {event.patient?.fullName}</div>
            <div>{t("calendar.medicalRecord")}: {event.patient?.medicalRecordNumber}</div>
            <div>{t("calendar.practitioner")}: {event.practitioner?.fullName}</div>
            {event.protocol ? <div>{t("calendar.protocol")}: {event.protocol?.title}</div> : null}
        </>
    );

    return (
        <Tooltip id={`event-tooltip-${event.id}`} tooltip={tooltip}>
            <div>{confirmed} {title} - {moment(event.start).format('H:mm')}</div>
        </Tooltip>
    );
}
const EventMonthScheduleLock = ({ event }) => {
    const { t } = useTranslation();

    var tooltip = (
        <div>
            <div>{t("calendar.scheduleLock")}</div>
            {event.practitioner && <div>{t("calendar.practitioner")}: {event.practitioner?.fullName}</div>}
            <div>{moment(event.start).format('H:mm')} -  {moment(event.end).format('H:mm')}</div>
        </div>
    );
    return (
        <Tooltip id={`event-tooltip-${event.id}`} tooltip={tooltip}>
            <div>{t("calendar.scheduleLock")} - {moment(event.start).format('H:mm')} -  {moment(event.end).format('H:mm')}</div>
        </Tooltip>
    );
}
export const EventMonth = ({ event }) => {

    if (event.visitType?.code === "SCHEDULELOCK")
        return <EventMonthScheduleLock event={event} />
    else
        return <EventMonthPatient event={event} />
}

const EventAgendaPatient = (props) => {
    const { t } = useTranslation();
    let event = props.event;
    var confirmed = event.confirmed === true ? <Check className="rbc-event-confirmed" /> : null;

    return (
        <>
            <div>{t("calendar.patient")}: {event.patient?.fullName}</div>
            <div>{t("calendar.medicalRecord")}: {event.patient?.medicalRecordNumber}</div>
            <div>{t("calendar.practitioner")}: {event.practitioner?.fullName}</div>
            {event.protocol ? <div>{t("calendar.protocol")}: {event.protocol?.title}</div> : null}

            {confirmed && <>{t("calendar.appointmentConfirmed")} {confirmed}</>}
        </>
    );
}
const EventAgendaScheduleLock = ({ event }) => {
    const { t } = useTranslation();
    return (<div>{t("calendar.scheduleLock")} {event.practitioner?.fullName}</div>);
}
export const EventAgenda = ({ event }) => {

    if (event.visitType?.code === "SCHEDULELOCK")
        return <EventAgendaScheduleLock event={event} />
    else
        return <EventAgendaPatient event={event} />
}