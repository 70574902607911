import { useState } from "react";
import useLoading from "../../components/Common/useLoading";
import FreezerService from "../../api/Freezer/FreezerService";
import { Freezer } from "../../api/Freezer/Entities/Freezer";


export default function useFreezer(freezerId) {
    const [freezer, setFrezeer] = useState(new Freezer())
    const [isLoading, reload] = useLoading({
        onLoad: async () => {
            if (freezerId == "") return;
            const result = await FreezerService.GetById(freezerId)
            setFrezeer(result)
        },
        onError: () => {
            setFrezeer(new Freezer())
        }
    })

    return [freezer, isLoading, reload]
}