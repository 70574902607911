import React from "react";
import { useTranslation } from 'react-i18next';
import { useUserInfo } from "../../containers/Users/Hooks";
import Card from "../Cards/Card";
import { dateTimeUTCFormatter } from '../Utils/Formatter';
import { Spinner } from "../Utils/Loaders";

export const DigitalSign = props => {

    const { signedBy, signStatus, signedDate } = props;
    const { t } = useTranslation();
    const [isLoading, userInfo] = useUserInfo(signedBy);

    if (isLoading)
        return <Spinner />;

    const getDigitalSignStatus = (status) => {
        switch (status) {
            case 0: return <>{t('digitalSign.status.valid')}</>;
            case 1: return <>{t('digitalSign.status.invalid')}</>;
            case 2: return <>{t('digitalSign.status.verifError')}</>;
            case 3: return <>{t('digitalSign.status.notVerified')}</>;
            case 3: return <>{t('digitalSign.status.notSigned')}</>;
            default:
                return <>{t('digitalSign.status.notSigned')}</>;
        }
    }

    if (!signedBy)
        return null;
    
    return (
        <Card
            title={t('digitalSign.title')}
            className="card-actions"
            content={
                <div>
                    <div className="card-label">{t("digitalSign.signedBy")}: <span className="card-text">{userInfo?.fullName}</span></div>
                    <div className="card-label">{t("digitalSign.signed")}: <span className="card-text">{dateTimeUTCFormatter(signedDate)}</span></div>
                    <div className="card-label">{t("sign.specialty")}: <span className="card-text">{`${userInfo?.speciality} ${userInfo?.registrationNumber || ""}`}</span></div>
                    <div className="card-label">{t("digitalSign.state")}: <span className="card-text">{getDigitalSignStatus(signStatus)}</span></div>
                </div>
            }
        />
    );
}