import React from "react";

export const Card = (props) => {

  return (
    <div className={`card ${props.tabs ? " card-tabs " : ""} ${props.shadow ? " card-shadow " : ""} ${props.className ?? ""}`}>
      {
        props.header &&
        <div className="header">
          {props.header}
        </div>
      }
      {
        props.title &&
        <div className="header">
          <h4 className="title">{props.title}</h4>
          <div className="category">{props.subtitle}</div>
        </div>
      }
      <div
        className={
          "content" +
          (props.ctAllIcons ? " all-icons" : "") +
          (props.ctFullWidth ? " content-full-width" : "") +
          (props.ctTextCenter ? " text-center" : "") +
          (props.tableFullWidth ? " table-full-width" : "")
        }
      >
        {props.content}
      </div>
      {
        props.footer && <div className="footer">
          {props.footer}
        </div>
      }
    </div>
  );
}

Card.defaultProps = {
  shadow: true,
  tabs: false,

};

export default Card;
