import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { CustomSwitch } from "../CustomControls/CustomSwitch";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "./useAuthorization";

export const Permissions = (props) => {
    const { claims, modules, onChange, disabled, name } = props;
    const [selectedClaims, setSelectedClaims] = useState([]);
    const { t } = useTranslation();
    const [, , isAuthorized] = useAuthorization();

    useEffect(() => {
        setSelectedClaims(claims);
    }, [])

    const handleChange = (claim, value) => {
        let claimsCopy = null;

        if (value) {
            // Agregado
            claimsCopy = [...JSON.parse(JSON.stringify(selectedClaims)), claim];
            setSelectedClaims(claimsCopy)
        }
        else {
            // Eliminado
            claimsCopy = JSON.parse(JSON.stringify(selectedClaims)).filter(x => x !== claim)
            setSelectedClaims(claimsCopy)
        }

        if (onChange)
            onChange(name, claimsCopy);
    };

    const yes = (name) => (<CustomSwitch value={true} name={name} disabled={disabled} onChange={handleChange} />);
    const no = (name) => (<CustomSwitch value={false} name={name} disabled={disabled} onChange={handleChange} />);

    return (
        <table className="table table-hover table-spaced-small">
            <thead>
                <tr>
                    <th>{t('security.permissions.module')}</th>
                    <th>{t('security.permissions.view')}</th>
                    <th>{t('security.permissions.list')}</th>
                    <th>{t('security.permissions.create')}</th>
                    <th>{t('security.permissions.edit')}</th>
                    <th>{t('security.permissions.delete')}</th>
                </tr>
            </thead>
            <tbody>
                {modules.map((x, idx) => {
                    if (!isAuthorized(["Tenant.View"]) && x === "Tenant")
                        return "";

                    return (<tr key={`roleClaims-${idx}`}>
                        <td>{t(`security.permissions.modules.${x}`)}</td>
                        <td>{selectedClaims.filter(c => c == `${x}.View`).length > 0 ? yes(`${x}.View`) : no(`${x}.View`)}</td>
                        <td>{selectedClaims.filter(c => c == `${x}.List`).length > 0 ? yes(`${x}.List`) : no(`${x}.List`)}</td>
                        <td>{selectedClaims.filter(c => c == `${x}.Create`).length > 0 ? yes(`${x}.Create`) : no(`${x}.Create`)}</td>
                        <td>{selectedClaims.filter(c => c == `${x}.Edit`).length > 0 ? yes(`${x}.Edit`) : no(`${x}.Edit`)}</td>
                        <td>{selectedClaims.filter(c => c == `${x}.Delete`).length > 0 ? yes(`${x}.Delete`) : no(`${x}.Delete`)}</td>
                    </tr>
                    )
                })}
            </tbody>
        </table>
    );
}

Permissions.propTypes = {
    onChange: PropTypes.func,
    claims: PropTypes.array.isRequired,
    modules: PropTypes.array.isRequired
};

Permissions.defaultProps = {
    disabled: false,
    onText: "Si",
    offText: "No",
    bsSize: "small"
};