import debounce from "lodash.debounce";
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

export const RichTextControl = (props) => {
    const { onChange, value, name, isDisabled, toolbar, autoFocus, height } = props;
    const [innerValue, setInnerValue] = useState('');
    const INPUT_DELAY = 300;

    useEffect(() => {
        if (value) {
            setInnerValue(value);
        } else {
            setInnerValue('');
        }
    }, [value]);

    const debouncedHandleOnChange = useCallback(
        debounce((name, value) => {
            if (onChange) {
                onChange(name, value);
            }
        }, INPUT_DELAY),
        [],
    );

    const handleOnChange = useCallback((content) => {
        setInnerValue(content);
        debouncedHandleOnChange(name, content);
    }, [debouncedHandleOnChange]);

    /*const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
        ['link', 'image', 'video', 'formula'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
    ];
   */

    return (
        <ReactQuill
            //style={{ height: height }}
            theme="snow"
            onChange={handleOnChange}
            value={innerValue}
            readOnly={isDisabled}
            modules={{
                toolbar: toolbar
            }}
            //formats={formats}
        />
    );
};

RichTextControl.defaultProps = {
    autoFocus: false,
    isDisabled: false,
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'align': [] }],
        ['clean']
    ],
    height: 400
}

RichTextControl.propTypes = {
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isDisabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    autoFocus: PropTypes.bool
};
