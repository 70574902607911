import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Audit, Copy, Print, View } from '../Icons/Icons';
import { dateFormatter } from '../Utils/Formatter';
import { Tooltip } from '../Utils/Tooltips';
import { EntryItemsTable } from './EntryItemsTable';

export const Prescriptions = (props) => {
    const { t } = useTranslation();
    var { onEdit, onAuditTrail, onLaboratoryResultHistory, ...rest } = props;

    function print(prescription) {
        if (prescription.hasExternalPrescription)
            if (prescription.externalPrescriptionURL)
                return (<Tooltip tooltip="Imprimir" id={"tooltipprint-" + prescription.id}>
                    <a className="btn-table-actions" href={prescription.externalPrescriptionURL} target="_blank">
                        <Print />
                    </a>
                </Tooltip>);
            else {
                if (prescription.entryId == props.entryId)
                    return (<Tooltip tooltip="Recrear" id={"tooltipprint-" + prescription.id}>
                        <button className="btn-table-actions" onClick={() => props.onRecreateExternal(prescription.entryId, prescription.originalId ?? prescription.id)}>
                            recrear
                        </button>
                    </Tooltip>);
            }
        else
            if (props.onPrint)
                return (<Tooltip tooltip="Imprimir" id={"tooltipprint-" + prescription.id}>
                    <button className="btn-table-actions" onClick={() => props.onPrint(prescription.entryId, prescription.originalId ?? prescription.id)}>
                        <Print />
                    </button>
                </Tooltip>);
    }

    const columns = [
        { dataField: 'prescriptionDate', text: t('commons.date'), formatter: function (cell) { return dateFormatter(cell, "DD/MM/YY") }, headerClasses: 'header-date' },
        {
            isDummyField: true,
            dataField: 'resultsCount',
            text: t("medicalRecords.prescriptions.drugs.count"),
            headerClasses: 'header-date',
            formatter: function (_cell, row) {
                return <div>{row.prescriptions?.length}</div>
            }
        },
        {
            dataField: 'actionsParent',
            text: "",
            isDummyField: true,
            headerClasses: 'header-actions',
            classes: 'row-actions',
            formatter: function (_cell, row, rowIndex) {
                return (
                    <div key={`actions2-${rowIndex}`} style={{ marginRight: "-80px" }}>
                        {print(row)}
                        {
                            props.onCopy &&
                            <Tooltip tooltip="Copiar" id={"tooltipcopy-" + row.id}>
                                <button className="btn-table-actions" onClick={() => props.onCopy(row.entryId, row.originalId ?? row.id)}>
                                    <Copy />
                                </button>
                            </Tooltip>
                        }
                    </div>
                )
            }
        }
    ];

    const columnsPrescriptions = [
        {
            dataField: 'drug', text: t('medicalRecords.prescriptions.prescription'),
            formatter: function (_cell, row) {
                if (row.drug.includes("["))
                    return <div>{row.packageCount} {row.drug}</div>
                else
                    return <div>{row.packageCount} {`${row.drug} [${row.dose} ${row.genericUnit.title}] ${row.unit.title} por ${row.count} UNIDADES`} </div>
            }
        },
        {
            dataField: '', text: t('medicalRecords.prescriptionsItems.posology'),
            formatter: function (_cell, row) {
                return <div>{row.posology && <div><span className="item-text">{row.posology} {row.unit?.title} {row.frequency?.title}</span></div>}</div>
            }
        },
        {
            dataField: 'actions',
            text: t("commons.actions"),
            isDummyField: true,
            headerClasses: 'header-actions',
            classes: 'row-actions',
            formatter: function (_cell, row, rowIndex) {
                return (
                    <div key={`${Math.random()}-actions-${rowIndex}`}>
                        {
                            row.entryId != props.entryId && props.query.medicalRecordNumber !== 0 &&
                            <Tooltip tooltip={t("medicalRecords.gotoVisit")} id={"tooltipview-" + row.id}>
                                <NavLink to={`/admin/medicalRecords/${props.query.medicalRecordNumber}/entry/${row.entryId}`} className="nav-link" activeClassName="active">
                                    <View />
                                </NavLink>
                            </Tooltip>
                        }
                        {
                            row.hasAudit && onAuditTrail &&
                            <Tooltip tooltip={t("auditTrail.view")} id={"tooltipaudit-" + row.id}>
                                <button className="btn-table-actions" onClick={() => onAuditTrail(row.id)}>
                                    <Audit />
                                </button>
                            </Tooltip>
                        }
                    </div>
                )
            }
        }
    ];

    const expandedContent = (row, _rowIndex) => {
        return (
            <BootstrapTable
                bordered={false}
                classes="table-spaced table-spaced-small"
                wrapperClasses="table-responsive"
                striped
                hover
                keyField='id'
                data={row.prescriptions}
                columns={columnsPrescriptions}
                noDataIndication={() => <>{t("commons.noData")}</>}
                pagination={paginationFactory({
                    page: 1,
                    sizePerPage: 6,
                    totalSize: row.prescriptions.length,
                    hideSizePerPage: true,
                    hidePageListOnlyOnePage: true
                })}
            />
        );
    };

    return (<EntryItemsTable
        {...rest}
        columns={columns}
        onEdit={onEdit}
        expandContent={expandedContent}
        onPrint={props.onPrint}
        onCopy={props.onCopy}
    />);
}
