import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types'
import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import { getStudyRole, editStudyRole } from "../../../api/security";
import { getStudyRoleSchema } from "../../../components/Validations/FormValidationSchema";
import { StudyRoleForm } from "../../../components/Authorization/StudyRoles";
import { Success } from '../../../components/Notifications/Notifications';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { groupBy } from "../../../components/Utils/Commons";
import { Spinner } from "../../../components/Utils/Loaders";

export const EditStudyRoleContainer = (props) => {
    let history = useHistory();
    const { match: { params } } = props;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [role, setRole] = useState(null);

    useEffect(() => {
        const fetchData = () => {
            setIsLoading(true);
            getStudyRole(params.roleId)
                .then(response => {
                    var role = response.data;
                    //console.log("DatabaseRoles ", role)
                    //role.permissions = groupBy(role.permissions.map(x => ({ ...x, title: t(`protocolStudyRoles.actions.${x.action}`) })), "module");
                    setRole(role);
                    setIsLoading(false);
                }).catch(error => {
                    HandleApiError(error);
                    setIsLoading(false);
                });
        }
        fetchData();
    }, [params.roleId]);

    const handleSubmit = () => {
        history.push(`/admin/settings/studyRoles`);
    }
    const handleCancel = () => {
        history.push(`/admin/settings/studyRoles`);
    }

    if (!role)
        return null;

    if (isLoading)
        return <Spinner />

    return (
        <EditStudyRoleContainerFormik
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            role={role}
        />
    );
}

const EditStudyRoleContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => ({ role: props.role }),

    validationSchema: getStudyRoleSchema(),

    handleSubmit: (values, formikBag) => {
        const role = {
            id: values.role.id,
            name: values.role.name,
            permissions: values.role.permissions
        }

        //let permissions = [];
        //for (let index = 1; index < 6; index++) { // Hay 5 modulos.
        //    if (values.role.permissions[index]) {
        //        permissions = permissions.concat(values.role.permissions[index]);
        //    }
        //}
        //role.permissions = permissions;

        editStudyRole(role.id, role)
            .then(res => {
                var role = res.data;
                formikBag.setSubmitting(false);
                Success("protocolNotifications.protocol_Updated");
                formikBag.props.onSubmit(role);
            }).catch(error => {
                console.log(error);
                formikBag.setSubmitting(false);
                HandleApiError(error);
            });
    },

    displayName: 'RoleForm',

})(StudyRoleForm));

EditStudyRoleContainerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};