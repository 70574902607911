import React from "react";
import { ErrorMessage } from 'formik';
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import { CustomSelect } from "../../../components/CustomControls/CustomSelect";
import Card from "../../../components/Cards/Card";
import { FormErrorMessage } from "../../../components/Validations/FormValidationErrors";
import { DeviceStates, IotDevice } from "../../../api/Freezer/Entities/IotDevice";

export function DeviceForm({ values, handleSubmit, handleChange, handleBlur, t, onCancel, isSubmitting, setFieldValue, setFieldTouched }) {
    if (!values.device) {
        values.device = new IotDevice()
    }
    const {  device } = values;
    const isEdition = (device.Id !== null &&  device.Id > 0)
    return (
        <Card
            title={isEdition ? t("settings.device.form.edit") : t("settings.device.form.create")}
            ctFullWidth
            content={
                <form onSubmit={handleSubmit} autoComplete="off">
                    <FormGroup>
                        <ControlLabel>{t("commons.name")}</ControlLabel>
                        <FormControl type="text" name={'device.Name'} value={device.Name ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                        <ErrorMessage name={"device.Name"} component={FormErrorMessage} />
                    </FormGroup>

                    {isEdition && <FormGroup>
                        <ControlLabel>{t("settings.device.detail.state")}</ControlLabel>
                        <CustomSelect
                            value={device.State}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            name={"device.State"}
                            options={DeviceStates.ToArray()}
                            getOptionLabel={(option) => t(`settings.device.states.${option.Name}`)}
                            getOptionValue={(option) => option.Value}
                        />
                    </FormGroup>}

                    <Grid fluid>
                        <Row>
                            <Col sm={1} smOffset={10}>
                                <button type="button" className="btn btn-info btn-right" onClick={onCancel} >{t("buttons.back")}</button>
                            </Col>
                            <Col sm={1}>
                                <button type="submit" className="btn btn-success btn-right" disabled={isSubmitting} >{t("buttons.save")}</button>
                            </Col>
                        </Row>
                    </Grid>
                </form>
            }
        />
    )
}