import i18n from 'i18next';

export const VisitStages = () => {
    return [
        { id: 0, code: 'screening', title: i18n.t("protocols.visit.screening") },
        { id: 1, code: 'random', title: i18n.t("protocols.visit.random") },
        { id: 2, code: 'treatment', title: i18n.t("protocols.visit.treatment") },
        { id: 3, code: 'followUp', title: i18n.t("protocols.visit.followUp") }
    ]
};

export const NotesEnum = () => {
    return [
        { id: 0, code: 'initial', title: i18n.t("notes.status.initial") },
        { id: 1, code: 'fixed', title: i18n.t("notes.status.fixed") },
        { id: 2, code: 'invalid', title: i18n.t("notes.status.invalid") }
    ]
};

export const OffsetTypes = () => {
    return [{ id: 0, code: 'days', title: i18n.t("protocols.visit.days") }, { id: 1, code: 'weeks', title: i18n.t("protocols.visit.weeks") }, { id: 2, code: 'months', title: i18n.t("protocols.visit.months") }]
};

export const Types = () => {
    return [{ id: 0, code: 'P', title: i18n.t("protocols.visitTracking.onsite") }, { id: 1, code: 'EP', title: i18n.t("protocols.visitTracking.remote") }]
};