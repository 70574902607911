import axios from './index';

export const getBlob = async (endpoint) => {
    return axios.get(endpoint, { responseType: 'blob' });
};

export const postFile = (endpoint, formData) => {
    return axios.post(endpoint, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

const getPagingAndOrderParams = (newActiveFilters, searchParams) => {

    if (!newActiveFilters)
        return;

    searchParams.delete('pageIndex'); // se resetea el paginado
    if (newActiveFilters.pagination?.page) {
        searchParams.append('pageIndex', newActiveFilters.pagination.page);
    }
    searchParams.delete('pageSize');
    if (newActiveFilters.pagination?.sizePerPage) {
        searchParams.append('pageSize', newActiveFilters.pagination.sizePerPage);
    }
    searchParams.delete('sortField');
    if (newActiveFilters.sort?.sortField) {
        searchParams.append('sortField', newActiveFilters.sort.sortField);
    }
    searchParams.delete('sortOrder');
    if (newActiveFilters.sort?.sortOrder) {
        searchParams.append('sortOrder', newActiveFilters.sort.sortOrder);
    }

    return searchParams;
}
export const getQueryString = (query, search) => {

    var searchParams = new URLSearchParams(search || '');

    if (!query)
        return searchParams.toString();

    searchParams = getPagingAndOrderParams(query, searchParams);

    if (query.filters) {
        for (const property in query.filters) {

            searchParams.delete(property);
            if (query.filters[property] !== null && query.filters[property] !== undefined) {
                searchParams.append(property, query.filters[property]);
            }
        }
    }

    return searchParams.toString();
}