import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { NavDropdown, MenuItem } from "react-bootstrap";
import { generateLink } from '../Utils/Commons';
import { IconSVG, View } from '../Icons/Icons';
import { useTranslation } from 'react-i18next';
import { useSignalR } from '../../services/signalRService';
import { HubConnectionState } from '@microsoft/signalr';

const TestNotifications = (props) => {
    const { t } = useTranslation()
    const { connection } = useSignalR();
    const dispatch = useDispatch();

    useEffect(() => {
        if (connection) {
            connection.on('TestNotification', res => {
                dispatch({
                    type: 'NOTIFICATION_RECEIVED',
                    payload: {
                        ...res
                    }
                });
            });

            return () => {
                // Leave group when component unmounts
                if (connection.connection._connectionState === HubConnectionState.Connected)
                    connection.invoke('LeaveGroup', "SystemNotifications");
            };
        }
    }, [connection]);

    var notifications = <MenuItem eventKey={`3.1`}>{t("notifications.noNotifications")}</MenuItem>;
    var notifCountContainer = null;

    if (props.notifications && props.notifications.length > 0) {
        let unreaded = 0;
        notifications = props.notifications.map((notification, index) => {
            if (notification.status === 0) {
                unreaded++;
                return (
                    <li role="presentation" key={`3.${index}`}>
                        <NavLink to={generateLink(notification.params)} activeClassName="active" role="menuitem" >
                            {notification.content}  <View style={{ fontSize: '20px' }} />
                        </NavLink>
                    </li>
                )
            }

            return null;
        });

        if (unreaded > 0)
            notifCountContainer = <span className="notification">{unreaded}</span>;
    }

    const title = (
        <>
            <IconSVG name="notification" fill="#c5c5c5" />
            {notifCountContainer}
            <p className="hidden-md hidden-lg">
                {t("notifications.notifications")}
                <b className="caret" />
            </p>
        </>
    );

    return (
        <NavDropdown
            eventKey={"3"}
            title={title}
            noCaret
            id="notifications-dropdown"
            bsClass="notifications-dropdown dropdown"
        >
            {notifications}
        </NavDropdown>
    )
}

const AdminNavbarNotificationsContainer = connect(state => state.notifications)(TestNotifications);

export default AdminNavbarNotificationsContainer;