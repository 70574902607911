import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { PatientNumberForm } from '../../../components/Protocols/Patients';
import { getProtocolPatientNumberSchema } from '../../../components/Validations/FormValidationSchema';

export const PatientNumberModal = (props) => {
    const { isShowing, hide, submit, patient } = props;
    const { t } = useTranslation();

    return isShowing ? ReactDOM.createPortal(
        <React.Fragment>
            <BootstrapModal show={true} onHide={hide} bsSize="large" backdrop="static" >
                <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title>{t('protocols.patient.changePatientNumber')}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <ProtocolPatientNumberContainer patient={patient} onCancel={hide} onSubmit={submit} />
                </BootstrapModal.Body>
            </BootstrapModal>
        </React.Fragment>, document.body
    ) : null;
};

const ProtocolPatientNumberContainer = withTranslation()(withFormik({
    mapPropsToValues: (props) => (props.patient),

    validationSchema: getProtocolPatientNumberSchema(),

    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values);
    },

    displayName: 'PatientNumberForm',

})(PatientNumberForm));

ProtocolPatientNumberContainer.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    protocolId: PropTypes.string.isRequired,
};