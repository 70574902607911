import React from "react";
import { NavLink, useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next'

import { removeStudyRole } from "../../../api/security";
import { Success } from "../../../components/Notifications/Notifications";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import Card from "../../../components/Cards/Card";
import { StudyRoles } from "../../../components/Authorization/StudyRoles";
import { WithAuth } from "../../../components/Authorization/WithAuth";
import { useStudyRoles } from "./useStudyRoles";
import { Add } from "../../../components/Icons/Icons";

export const StudyRolesContainer = (props) => {
    let history = useHistory();
    const { t } = useTranslation();

    const [roles, isLoading, reloadRoles] = useStudyRoles();

    const handleEditRole = (roleId) => {
        history.push(`/admin/settings/studyRoles/${roleId}`);
    }

    const handleRemoveRole = (roleId) => {
        if (roleId) {
            removeStudyRole(roleId)
                .then(res => {
                    reloadRoles(true);
                    Success("security.studyRole.removed");
                })
                .catch(error => { HandleApiError(error); })
        }
    }

    return (
        <Card
            tabs
            header={
                <WithAuth requiredPermission={["Protocols.Create"]}>
                    <NavLink to={`/admin/settings/studyRoles/create`} className="btn btn-primary" activeClassName="active">
                        <Add /> {t("security.studyRoles.add")}
                    </NavLink>
                </WithAuth>
            }
            content={
                <StudyRoles
                    roles={roles}
                    onEdit={handleEditRole}
                    onRemove={handleRemoveRole}
                    isLoading={isLoading}
                />
            }
        />
    );
}