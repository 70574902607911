import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { editVisit, getVisit } from '../../../api/protocol';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { Success } from '../../../components/Notifications/Notifications';
import { OffsetTypes, Types, VisitStages } from '../../../components/Protocols/Visit/Enums';
import { VisitHeaderForm } from '../../../components/Protocols/Visit/Header';
import { getProtocolVisitHeaderSchema } from "../../../components/Validations/FormValidationSchema";
import { useProtocolVisits } from '../Hooks';

export const EditVisitModal = (props) => {
    const { isShowing, hide, protocolId, visitId, submit } = props;
    const { t } = useTranslation();

    return isShowing ? ReactDOM.createPortal(
        <React.Fragment>
            <BootstrapModal show={true} onHide={hide} bsSize="large" backdrop="static" >
                <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title>{t('protocols.practitioner.editPractitionerTitle')}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <EditVisitContainer protocolId={protocolId} visitId={visitId} onClose={hide} onSubmit={submit} />
                </BootstrapModal.Body>
            </BootstrapModal>
        </React.Fragment>, document.body
    ) : null;
};

const EditVisitContainer = (props) => {
    const { protocolId, visitId } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [visit, setVisit] = useState(null);
    const visits = useProtocolVisits(protocolId);
    useEffect(() => {
        const fetchData = () => {
            setIsLoading(true);
            getVisit(protocolId, visitId)
                .then(response => {
                    var resVisit = response.data;
                    resVisit.type = Types().find(x => x.id == resVisit.type);
                    resVisit.stage = VisitStages().find(x => x.id == resVisit.stage);
                    resVisit.offsetType = OffsetTypes().find(x => x.id == resVisit.offsetType);

                    setVisit(resVisit);
                    setIsLoading(false);
                }).catch(error => {
                    HandleApiError(error);
                    setIsLoading(false);
                });
        }

        fetchData();
    }, [protocolId, visitId]);

    const handleSubmit = () => {
        props.onSubmit();
    }
    const handleCancel = () => {
        props.onClose();
    }

    if (isLoading || !visit)
        return null;

    return (
        <EditVisitFormik
            protocolId={protocolId}
            visit={visit}
            visits={visits}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
        />
    );
}
EditVisitContainer.propTypes = {
    protocolId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    visitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const EditVisitFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => ({ visit: props.visit }),

    validationSchema: getProtocolVisitHeaderSchema(),

    handleSubmit: (value, formikBag) => {
        let visit = value.visit;
        visit.type = visit.type.id;
        visit.stage = visit.stage.id;
        visit.offsetType = visit.offsetType?.id
        visit.offsetFrom = visit.offsetFrom?.id

        editVisit(formikBag.props.protocolId, value.visit.crossVersionId, visit)
            .then(res => {
                formikBag.setSubmitting(false);
                Success("protocolNotifications.visit_Updated");
                formikBag.props.onSubmit(res.data);

            }).catch(error => {
                console.log(error);
                formikBag.setSubmitting(false);
                HandleApiError(error);
            });
    },

    displayName: 'EditVisit',

})(VisitHeaderForm));

EditVisitFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    protocolId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    visit: PropTypes.object.isRequired,
};