import React, { useContext, useState } from "react";
import { useTranslation } from 'react-i18next'
import { NavLink, useHistory } from "react-router-dom"
import useFreezers from '../useFreezers';
import { Freezers } from "./Freezers";
import Card from "../../../components/Cards/Card";
import { Add } from "../../../components/Icons/Icons";
import { DeleteFreezerModal } from "./DeleteFreezerModal.jsx";
import FreezerService from "../../../api/Freezer/FreezerService";
import { useModalContext } from "../../../components/Modals/useModalContext";
import Loader from "../../../components/Common/Loader";
import { WithAuth } from "../../../components/Authorization/WithAuth";
import { DocumentViewer } from "../../../components/CustomControls/DocViewer/DocumentViewer";
import DeviceService from "../../../api/Freezer/DeviceService";

export default function FreezersContainer({ }) {
    const { t } = useTranslation()
    let history = useHistory();
    const [pagedFreezers, isLoading, navigation] = useFreezers()
    const [certificate, setCertificate] = useState(null)
    const [modalContext, openDeleteModal] = useModalContext({
        handleEvent: (e) => navigation.Reload()
    })


    const handleEdit = (freezerId) => {
        history.push(`/admin/settings/freezers/${freezerId}`);
    }
    const handleRemove = async (freezerId) => {
        if (freezerId) {
            const freezer = await FreezerService.GetById(freezerId)
            openDeleteModal(freezer)
        }
    }

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        navigation.ToPage({ page })
    }

    const handleOpenCertificate = async (path) => {
        const result = await DeviceService.GetCertificate(path)
        setCertificate(result)
    }

    return (
        <>
            <Card
                header={
                    <>
                    <WithAuth requiredPermission={["Freezers.Create"]}>
                            <NavLink to={`/admin/settings/freezers/create`} className="btn btn-primary" activeClassName="active">
                                <Add /> {t("settings.freezer.add")}
                            </NavLink>
                        </WithAuth>
                    </>
                }
                content={
                    <Loader isLoading={isLoading}>
                        <Freezers
                            pagedFreezers={pagedFreezers} isLoading={isLoading}
                            onEdit={handleEdit} onRemove={handleRemove} onTableChange={handleTableChange} onOpenCertificate={handleOpenCertificate} />
                    </Loader>}
            />
            <DeleteFreezerModal state={modalContext} />
            <DocumentViewer
                file={certificate?.blob}
                fileName={t('commons.certificate')}
                inModal={true}
                isLoading={false}
            />
        </>
    )
}



