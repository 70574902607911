import React from "react";
import { Col, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Card from "../Cards/Card";
import { ContactInfo } from "../Icons/Icons";

export const AddressData = props => {

    const patient = props.data;

    const { t } = useTranslation();

    return (
        <Card
            className="card-address-data"
            content={
                <Grid fluid>
                    <Row className="display-flex">
                        <Col sm={2}>
                            <div className="address-box"><ContactInfo /></div>
                        </Col>
                        <Col sm={10}>
                            <Row>
                                <Col sm={12}>
                                    <div className="info">
                                        <span className="patient-label">{t('person.address.address')}:</span> <span className="patient-text">{patient.address?.fullAddress}</span>
                                        <span className="patient-label">{t('person.address.postalCode')}:</span> <span className="patient-text">{patient.address?.postalCode}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '20px' }}>
                                <Col sm={12}>
                                    <div className="info">
                                        {patient.address?.floor && <><span className="patient-label">{t('person.address.floor')}:</span> <span className="patient-text">{patient.address?.floor}</span></>}
                                        {patient.address?.department && <><span className="patient-label">{t('person.address.department')}:</span> <span className="patient-text">{patient.address?.department}</span></>}
                                        {patient.address?.body && <><span className="patient-label">{t('person.address.body')}:</span> <span className="patient-text">{patient.address?.body}</span></>}
                                        {patient.address?.between && <><span className="patient-label">{t('person.address.between')}:</span> <span className="patient-text">{patient.address?.between}</span></>}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Grid>
            }
        />
    );
}