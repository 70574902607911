import { withFormik } from "formik";
import React from "react";
import { Modal as BootstrapModal } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { useAuthorization } from "../../components/Authorization/useAuthorization";
import { DigitalSignForm } from '../../components/DigitalSign/DigitalSignForm';
import { Spinner } from "../../components/Utils/Loaders";
import { getDigitalSignSchema } from "../../components/Validations/FormValidationSchema";

export const ModalDigitalSign = (props) => {
    const { isShowing, hide, isLoading, onSubmit } = props;
    const [, user] = useAuthorization();
    const { t } = useTranslation();

    return isShowing ? ReactDOM.createPortal(
        <React.Fragment>
            <BootstrapModal show={true} onHide={hide} bsSize="large" backdrop="static" >
                <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title>{t("digitalSign.title")}</BootstrapModal.Title>
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    {
                        isLoading ?
                            <Spinner /> :
                            <DigitalSignContainerFormik
                                currentUser={user.family_name ? `${user.family_name} ${user.given_name}` : user.email}
                                onSubmit={onSubmit}
                            />
                    }
                </BootstrapModal.Body>
            </BootstrapModal>
        </React.Fragment>, document.body
    ) : null;
};

const DigitalSignContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: () => ({ password: '' }),
    validationSchema: getDigitalSignSchema(),
    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values);
    },
    displayName: 'DigitalSignForm',
})(DigitalSignForm));