import React, { useEffect, useState } from 'react';
import Card from "../../../components/Cards/Card";
import { SearchHeader, SearchHeaderContext } from './Components/SearchHeader';
import { EnumValue } from '../../../api/Freezer/Entities/Utils';
import './telemetry.css';
import Loader from "../../../components/Common/Loader";
import useFreezerResume from './hooks/useFreezerResume';
import { useTranslation } from 'react-i18next';
import useProtocolResume from './hooks/useProtocolResume';
import Alert from './Components/Alert';
import FreezerTogglePanel from './Components/FreezerTogglePanel';
import { useProtocolPageAuth } from '../../../components/Authorization/useAuthorization';


export function TelemetryGeneralViewContainer() {
    return <SearchHeaderContext>
        <TelemetryGeneralView />
    </SearchHeaderContext>
}

function TelemetryGeneralView() {
    const [isAuthorized, ,] = useProtocolPageAuth();
    const [searchContext, setContext] = useState(null)
    const headerOptions = [new EnumValue(2, "Vista por Heladeras")]

    if (isAuthorized(["Freezers.View"])) {
        headerOptions.push(new EnumValue(1, "Vista por Protocolos"))
    }
    const SelectedView = ({ searchContext }) => {
        switch (searchContext?.selected.Value) {
            case 2:
                return (<FreezerPanelView from={searchContext.from} to={searchContext.to} />)
            case 1:
                return (<ProtocolPanelView from={searchContext.from} to={searchContext.to} />)
            default:
                return <div style={{ height: '4.6875rem' }}></div>
        }
    }

    const handleSearch = (search) => {
        setContext(search)
    }

    return (
        <Card
            header={<div style={{ marginBottom: '2rem' }}>
                <SearchHeader options={headerOptions} handleSearch={handleSearch} />
            </div>}
            content={<SelectedView searchContext={searchContext} />}
        />
    )
}

function FreezerPanelView({ from, to }) {
    const { t } = useTranslation()
    const [resumes, isLoading, reload] = useFreezerResume(from, to)

    useEffect(() => {
        reload()
    }, [from, to])

    const Header = ({ resume }) => {
        return (<div className='panel-header-data' style={{ width: '80%' }}>
            <HeaderData title={t('commons.freezer')} value={resume.Freezer?.Description} />
            <HeaderData title={t('commons.type')} value={t(`settings.freezer.types.${resume.Freezer.Type.Name}`)} />
        </div >)
    }

    const mapped = resumes.map(r => <FreezerTogglePanel
        key={r.Freezer.Id}
        header={<Header resume={r} />}
        daysResume={r.Days} />)

    return (<Loader isLoading={isLoading}>
        {resumes.length > 1 ? mapped : <Alert message={t("commons.noData")} />}
    </Loader>)
}

function ProtocolPanelView({ from, to }) {
    const { t } = useTranslation()
    const [resumes, isLoading, reload] = useProtocolResume(from, to)
    console.log(isLoading)
    useEffect(() => {
        reload()
    }, [from, to])

    const Header = ({ resume }) => (
        <div className='panel-header-data' style={{ width: '80%' }}>
            <HeaderData title={t('commons.protocol')} value={resume.Protocol?.title} />
            <HeaderData title={t('commons.laboratory')} value={resume.Protocol?.laboratory.title} />
            <HeaderData title={t('commons.freezer')} value={`${resume.Freezer.Description} (${t(`settings.freezer.types.${resume.Freezer.Type.Name}`)})`} />
        </div >
    )

    const mapped = resumes.map(r => <FreezerTogglePanel
        header={<Header resume={r} />}
        daysResume={r.FreezerDaysResume}
        protocolConfig={r.Configuration} />)

    return (<Loader isLoading={isLoading}>
        {resumes.length > 1 ? mapped : <Alert message={t("commons.noData")} />}
    </Loader>)
}

function HeaderData({ title, value }) {
    return (
        <span>
            <strong>{title}:</strong>{' '}<span>{value}</span>
        </span>
    )
}





