import axios from './index';

export const getPagingAndOrderParams = (newActiveFilters, searchParams) => {

    if (!newActiveFilters)
        return;

    searchParams.delete('pageIndex'); // se resetea el paginado
    if (newActiveFilters.pagination?.page) {
        searchParams.append('pageIndex', newActiveFilters.pagination.page);
    }
    searchParams.delete('pageSize');
    if (newActiveFilters.pagination?.sizePerPage) {
        searchParams.append('pageSize', newActiveFilters.pagination.sizePerPage);
    }
    searchParams.delete('sortField');
    if (newActiveFilters.sort?.sortField) {
        searchParams.append('sortField', newActiveFilters.sort.sortField);
    }
    searchParams.delete('sortOrder');
    if (newActiveFilters.sort?.sortOrder) {
        searchParams.append('sortOrder', newActiveFilters.sort.sortOrder);
    }

    return searchParams;
}

export const getQueryString = (query, search) => {

    var searchParams = new URLSearchParams(search || '');

    if (!query)
        return searchParams.toString();

    searchParams = getPagingAndOrderParams(query, searchParams);

    if (query.filters) {
        for (const property in query.filters) {

            searchParams.delete(property);
            if (query.filters[property] !== null && query.filters[property] !== undefined) {
                searchParams.append(property, query.filters[property]);
            }
        }
    }

    return searchParams.toString();
}

export const getTemplates = async (filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(queryString ? `api/v1/resultsTemplates/?${queryString}` : `api/v1/resultsTemplates`);
};
export const getTemplate = async (templateId) => {
    return axios.get(`api/v1/resultsTemplates/${templateId}`);
};
export const createTemplate = async (template) => {
    return axios.post(`api/v1/resultsTemplates/`, template);
};
export const editTemplate = async (templateId, template) => {
    return axios.put(`api/v1/resultsTemplates/${templateId}`, template);
};
export const removeTemplate = async (templateId) => {
    return axios.delete(`api/v1/resultsTemplates/${templateId}`);
};