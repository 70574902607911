import React from 'react';
import { Col, ControlLabel, FormControl, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const AdherenceControl = props => {

    const {
        value,
        onChange,
        onBlur,
        isDisabled,
    } = props;
    const { t } = useTranslation();

    const getAdherence = () => {
        return Math.round((value.deilveredPillsCount - value.returnedPillsCount) * 100 / (value.daysCount * value.pillFrequency), -2);
    }

    const handleChange = (e) => {
        let copyValue = Object.assign({}, value);
        copyValue[e.currentTarget.name] = e.currentTarget.value;
        onChange(props.name, copyValue);
    }

    return (
        <Row>
            <Col sm={12}>
                <ControlLabel>{t("medicalRecords.adherence.daysCount")}</ControlLabel>
                <FormControl type="number" name={`daysCount`} disabled={isDisabled} value={value?.daysCount} onChange={handleChange} onBlur={onBlur} autoComplete="off" />
            </Col>
            <Col sm={12}>
                <ControlLabel>{t("medicalRecords.adherence.deilveredPillsCount")}</ControlLabel>
                <FormControl type="number" name={`deilveredPillsCount`} disabled={isDisabled} value={value?.deilveredPillsCount} onChange={handleChange} onBlur={onBlur} autoComplete="off" />
            </Col>
            <Col sm={12}>
                <ControlLabel>{t("medicalRecords.adherence.returnedPillsCount")}</ControlLabel>
                <FormControl type="number" name={`returnedPillsCount`} disabled={isDisabled} value={value?.returnedPillsCount} onChange={handleChange} onBlur={onBlur} autoComplete="off" />
            </Col>
            <Col sm={12}>
                <ControlLabel>{t("medicalRecords.adherence.pillFrequency")}</ControlLabel>
                <FormControl type="number" name={`pillFrequency`} disabled={isDisabled} value={value?.pillFrequency} onChange={handleChange} onBlur={onBlur} autoComplete="off" />
            </Col>
            <Row>
                <Col sm={12}>
                    <h5>
                        {t("medicalRecords.adherence.title")} :
                                    {
                            (value?.daysCount > 0 && value?.pillFrequency > 0)
                                ? getAdherence() + "%"
                                : 0
                        }
                    </h5>
                </Col>
            </Row>
        </Row>
    );
}