import React from 'react';
import PropTypes from 'prop-types';

import { AppointmentsScrollable } from '../../components/Agenda/Appointments';
import { useAppointment } from './Hooks';

const AppointmentsHistory = (props) => {

    const { medicalRecordNumber } = props;
    const [entries, query, entriesTotalSize, isEntriesLoading, getMore, handleFilterChange] = useAppointment(medicalRecordNumber);

    return (
        <AppointmentsScrollable
            data={entries}
            currentPage={query.pagination.page}
            totalSize={entriesTotalSize}
            isLoading={isEntriesLoading}
            getMore={getMore}
            onFilterChange={handleFilterChange} />
    );
}

export default AppointmentsHistory;

AppointmentsHistory.propTypes = {
    medicalRecordNumber: PropTypes.string.isRequired,
};