import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React from "react";
import { withTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { HandleApiError } from '../../components/Notifications/APIErrorHandler';
import { Success } from '../../components/Notifications/Notifications';
import { TenantForm } from '../../components/Tenants/Tenant';
import { getTenantSchema } from '../../components/Validations/FormValidationSchema';
import { useTenants } from "./Hooks";

export const CreateTenantContainer = () => {
    let history = useHistory();
    const [, isLoading, , , create] = useTenants();

    const handleCreate = async (tenant) => {
        try {
            await create(tenant);
            Success("tenantNotifications.tenant_Created");
            history.push(`/admin/tenant`)
        }
        catch (error) {
            HandleApiError(error);
            history.push(`/admin/tenant`)
        }
    }

    return (
        <CreateTenantFormik
            onSubmit={handleCreate}
            onCancel={() => history.push(`/admin/tenant`)}
        />
    );
}

const CreateTenantFormik = withTranslation()(withFormik({

    mapPropsToValues: () => ({ tenantName: '', tenantDomain: '', servicePlan: '', isActive: null }),
    enableReinitialize: true,
    validationSchema: getTenantSchema(),

    handleSubmit: (tenant, formikBag) => {
        formikBag.props.onSubmit(tenant);
    },

    displayName: 'TenantForm',

})(TenantForm));

CreateTenantFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};