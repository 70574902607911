import moment from "moment";
import { getWaitingList, updateEventAttendance } from "../api/agenda";

const requestWaitingListAllType = 'WAITINGLIST_ALL_REQUEST';
const receiveWaitingListAllType = 'WAITINGLIST_ALL_RECEIVE';
const requestMarkAsAttendedType = 'MARKASATTENDED_REQUEST';
const receiveMarkAsAttendedType = 'MARKASATTENDED_RECEIVE';
const receiveErrorType = 'RECEIVE_ERROR';
const clearErrors = 'CLEAR_ERROR';

const initialState = { events: [], isLoading: false, error: null };

export const agendaActionCreators = {
    requestWaitingList: () => async (dispatch) => {

        dispatch({ type: requestWaitingListAllType });

        try {
            const response = await getWaitingList();

            var events = response.data.map(event => {
                event.start = moment.utc(moment.utc(event.start)).local().toDate();
                event.end = moment.utc(moment.utc(event.end)).local().toDate();
                return event;
            });

            dispatch({ type: receiveWaitingListAllType, events });
        }
        catch (error) {
            console.log(error)
        }
    },

    requestMarkAsAttended: (eventId) => async (dispatch) => {

        dispatch({ type: requestMarkAsAttendedType });

        try {
            await updateEventAttendance(eventId);
            dispatch({ type: receiveMarkAsAttendedType, eventId });
        }
        catch (error) {
            console.log(error);
            dispatch({ type: receiveErrorType, error });
        }
    },

    clearErrors: () => async (dispatch) => {

        dispatch({ type: clearErrors });
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestWaitingListAllType) {
        return {
            ...state,
            isLoading: true,
            error: null
        };
    }
    if (action.type === receiveWaitingListAllType) {
        return {
            ...state,
            events: action.events,
            isLoading: false,
            error: null
        };
    }

    if (action.type === requestMarkAsAttendedType) {
        return {
            ...state,
            isLoading: true,
            error: null
        };
    }
    if (action.type === receiveMarkAsAttendedType) {
        return {
            ...state,
            events: state.events.filter((item) => item.id !== action.eventId),
            isLoading: false,
            error: null
        };
    }

    if (action.type === clearErrors) {
        return {
            ...state,
            error: null
        };
    }
    if (action.type === receiveErrorType) {
        return {
            ...state,
            isLoading: false,
            error: action.error
        };
    }

    return state;
};
