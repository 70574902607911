import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { exportEntries, exportEntry, getEntries, getEntry, saveEntry, publishEntry, deleteEntry } from '../../../api/medicalRecord';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';

export const useEntries = (medicalRecordNumber) => {
    let history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [entries, setEntries] = useState([]);
    const [totalSize, setTotalSize] = useState(0);
    const [query, setQuery] = useState({
        medicalRecordNumber: medicalRecordNumber,
        pagination: {
            page: 1,
            sizePerPage: 5,
            totalSize: 0
        },
        sort: {
            sortField: "Created",
            sortOrder: "desc"
        },
        filters: {}
    });

    useEffect(() => {
        let isSubscribed = true
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const paginatedItems = await getEntries(medicalRecordNumber, query);

                if (isSubscribed) {
                    setTotalSize(paginatedItems.data.count);
                    setEntries(paginatedItems.data.data);
                    setIsLoading(false);
                }

            } catch (error) {
                HandleApiError(error);
                if (error.response.data.StatusCode === 403)
                    history.push('/admin/forbidden');
                setIsLoading(false);
                setTotalSize(0);
                setEntries([]);
            }
        }

        if (medicalRecordNumber)
            fetchData();

        return () => isSubscribed = false;
    }, [medicalRecordNumber, query]);

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        var newQuery = JSON.parse(JSON.stringify(query));

        switch (type) {
            case 'pagination': {
                newQuery.pagination.page = page;
                newQuery.pagination.sizePerPage = sizePerPage;
                setQuery(newQuery);
                break;
            }
            case 'sort': {
                newQuery.sort.sortField = sortField;
                newQuery.sort.sortOrder = sortOrder;
                setQuery(newQuery);
                break;
            }
            default: break;
        }
    }

    const handleFilterChange = (filterName, selectedValue) => {
        if (filterName) {
            var newQuery = JSON.parse(JSON.stringify(query));
            newQuery.filters[filterName] = selectedValue;
            newQuery.pagination.page = 1;
            setQuery(newQuery);
        }
    }

    const getMore = () => {
        var newQuery = JSON.parse(JSON.stringify(query));
        newQuery.pagination.page = newQuery.pagination.page + 1;
        setQuery(newQuery);
    }

    const exportToPDF = (pin, mrn) => {
        return exportEntries(medicalRecordNumber || mrn, btoa(pin));
    }

    return [entries, query, totalSize, isLoading, handleTableChange, exportToPDF, getMore, handleFilterChange];
    //{entries, entriesCount, entriesQuery, isEntriesLoading} ;;
}

export const useEntry = (medicalRecordNumber, entryId) => {

    const [isLoading, setIsLoading] = useState(true);
    const [entry, setEntry] = useState(null);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        let isSubscribed = true
        const fetchData = async () => {
            try {
                setReload(false);
                const entryRes = await getEntry(medicalRecordNumber, entryId);

                //console.log(entryRes.data)
                if (isSubscribed) {
                    setEntry(entryRes.data);
                    setIsLoading(false);
                }

            } catch (error) {
                HandleApiError(error);
                setIsLoading(false);
                setEntry(null);
            }
        }

        fetchData();

        return () => isSubscribed = false;
    }, [medicalRecordNumber, entryId, reload]);

    const save = (entry) => {
        return saveEntry(medicalRecordNumber, entryId, entry);
    }

    const publish = (entry) => {
        return publishEntry(medicalRecordNumber, entryId, entry);
    }

    const remove = () => {
        return deleteEntry(medicalRecordNumber, entryId);
    }

    const exportToPDF = (entry, pin) => {
        return exportEntry(medicalRecordNumber, entryId, btoa(pin));
    }

    return [entry, isLoading, save, publish, remove, setReload, exportToPDF];
}