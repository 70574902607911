import React from "react";
import { Col, Row } from "react-bootstrap";
import { ProtocolInfo } from "../../components/Protocols/ProtocolInfo";
import { ProtocolProvider } from "../../containers/Protocols/ProtocolContext/ProtocolProvider";
import { VisitTracking } from "../../containers/Protocols/Tracking/VisitTracking";
import VisitHeaderContainer from "../../containers/Protocols/VisitHeaderContainer";

export const VisitTrackingView = props => {
    const { match: { params: { protocolId, patientId, visitId } } } = props;

    return (
        <ProtocolProvider protocolId={protocolId}>
            <Row>
                <Col md={12} lg={12}>
                    <ProtocolInfo />
                </Col>
                <Col md={12}>
                    <VisitHeaderContainer protocolId={protocolId} patientId={patientId} />
                </Col>
                <Col md={12} lg={12}>
                    <VisitTracking protocolId={protocolId} patientId={patientId} visitId={visitId} />
                </Col>
            </Row>
        </ProtocolProvider>
    );
}