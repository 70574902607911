import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import { Logout } from '../Icons/Icons';
import { Tooltip } from '../Utils/Tooltips';
import { Spinner } from '../Utils/Loaders';
import { ApplicationPaths } from './ApiAuthorizationConstants';

export const LoginMenu = (props) => {
    const authenticationState = useSelector(state => state.authentication);
    
    function authenticatedView(_userName, _profilePath, logoutPath) {
        return (<li>
            <NavLink to={logoutPath} className="current-user-logout">
                <Tooltip tooltip="Cerrar Sesión" id="ddadk222kds" placement="bottom">
                    <Logout fill="#c5c5c5" />
                </Tooltip>
            </NavLink>
        </li>);
    }

    function anonymousView(_registerPath, loginPath) {
        return (<li>
            <NavLink className="current-user-login" activeClassName="active" to={loginPath}>Login</NavLink>
        </li>);
    }

    if (authenticationState.isLoading)
        return <Spinner />;

    if (!authenticationState.isAuthenticated) {
        const registerPath = `${ApplicationPaths.Register}`;
        const loginPath = `${ApplicationPaths.Login}`;
        return anonymousView(registerPath, loginPath);
    } else {
        const profilePath = `${ApplicationPaths.Profile}`;
        const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
        return authenticatedView(authenticationState.user?.email, profilePath, logoutPath);
    }
}
