import { useEffect, useState } from 'react';
import { getSettings, setSettings } from '../../../api/settings';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';

export const useSettings = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        let isSubscribed = true
        const fetchData = async () => {
            try {
                setReload(false);
                setIsLoading(true);
                const res = await getSettings();

                if (isSubscribed && res.data) {
                    setData(res.data);
                    setIsLoading(false);
                }

            } catch (error) {
                HandleApiError(error);
                setIsLoading(false);
                setData(null);
            }
        }

        fetchData();

        return () => isSubscribed = false;
    }, [reload]);

    const update = (key, value) => {
        return setSettings(key, value);
    }

    return [data, isLoading, update, setReload];
}