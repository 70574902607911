import React, { useContext, useEffect, useState } from "react";
import Card from "../../../components/Cards/Card";
import Loader from "../../../components/Common/Loader";
import { useTranslation } from "react-i18next";
import { useIotTelemetry } from "../useIotMessages";
import { Col, Row } from 'react-bootstrap';
import { CustomSelect } from "../../../components/CustomControls/CustomSelect";
import { useLaboratoryProtocol } from "./hooks/useLaboratoryProtocol";
import { ErrorMessage } from "../../../components/Notifications/Notifications";
import { ProtocolFreezerConfiguration } from "../../../api/Freezer/Entities/ProtocolFreezerConfiguration";
import './telemetry.css'
import { SearchHeader, SearchHeaderContext, SearchContext } from "./Components/SearchHeader";
import { DoorChart, HumidityChart, TemperatureChart } from "./Components/TelemetryCharts";

export function ProtocolTelemetryContainer() {
    return (
        <SearchHeaderContext>
            <ProtocolTelemetry />
        </SearchHeaderContext>)
}

export function ProtocolTelemetry() {
    const { t } = useTranslation()
    const context = useContext(SearchContext)
    const [protocolState, handleLaboratoryChange] = useLaboratoryProtocol();
    const [selectedLaboratory, setSelectedLaboratory] = useState(null)
    const [telemetry, isTelemetryLoading, search] = useIotTelemetry()
    const [telemetryContext, setTelemetryContext] = useState({
        protocolConfig: new ProtocolFreezerConfiguration(),
        selectedFreezer: null,
    })

    useEffect(() => {
        if (selectedLaboratory === null) return
        handleLaboratoryChange(selectedLaboratory.Value)
    }, [selectedLaboratory])
    useEffect(() => {
        if (selectedLaboratory !== null
            && !protocolState.isLoading
            && protocolState.protocols.length <= 0) {
            ErrorMessage(t("telemetry.notProtocolConfigured"))
        }

    }, [protocolState.isLoading])
    useEffect(() => {
        if (context?.selected == null) return
        const config = protocolState.GetConfig(context.selected.Value);
        const firstFreezer = config.AssignedFreezers[0];
        setTelemetryContext({
            protocolConfig: config,
            selectedFreezer: firstFreezer.Id
        })
        search({ from: context.from, to: context.to, freezerId: firstFreezer.Id })
    }, [context])

    const handleFreezerChange = (freezerId) => {
        setTelemetryContext({
            ...telemetryContext,
            selectedFreezer: freezerId
        })

        search({ from: context.from, to: context.to, freezerId: freezerId })
    }

    return (
        <Loader isLoading={protocolState.isFirstloading}>
            <Card
                header={<>
                    <div>
                        <SearchHeader
                            Prepend={
                                <Col sm={2}>
                                    <CustomSelect
                                        name={`select_laboratory`}
                                        value={selectedLaboratory}
                                        onChange={(e, v) => { setSelectedLaboratory(v) }}
                                        options={protocolState.laboratories}
                                        getOptionLabel={(option) => option?.Name}
                                        getOptionValue={(option) => option?.Value}
                                    />
                                </Col>
                            }
                            disabled={(selectedLaboratory === null || !(protocolState.protocols.length > 0))}
                            options={protocolState.protocols}
                        />
                    </div>
                </>
                }

                content={
                    <div style={{ margin: '0 auto' }} className="text-center" >
                        <Loader isLoading={isTelemetryLoading}>
                            <div style={{ width: '90%', margin: '0 auto' }}>
                                <Row>
                                    <Col md={12} lg={6}>
                                        <Card content={
                                            <TemperatureChart
                                                min={telemetryContext.protocolConfig.MinTemperature}
                                                max={telemetryContext.protocolConfig.MaxTemperature}
                                                data={telemetry.temperature}
                                            />
                                        } />
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <Card content={<HumidityChart data={telemetry.humidity} />} />
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <Card content={<DoorChart data={telemetry.door} />} />
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <Card content={
                                            <SelecteableFreezers
                                                freezers={telemetryContext.protocolConfig?.AssignedFreezers ?? []}
                                                handleSelectedFreezer={handleFreezerChange}
                                                selectedFreezer={telemetryContext?.selectedFreezer ?? 0} />
                                        } />
                                    </Col>
                                </Row>
                            </div>
                        </Loader>
                    </div>
                }
            />
        </Loader>
    )
}

function SelecteableFreezers({ freezers, handleSelectedFreezer, selectedFreezer }) {
    const { t } = useTranslation()
    const handleSelected = (freezer) => {
        handleSelectedFreezer(freezer.Id)
    }
    return (
        <table className="table table-spaced table-spaced-small">
            <thead>
                <tr>
                    <th>{t("commons.description")}</th>
                    <th>{t("commons.location")}</th>
                </tr>
            </thead>
            <tbody>
                {freezers?.length > 0 && freezers.map(f => (
                    <tr key={f.Id} onClick={() => handleSelected(f)} className={f.Id == selectedFreezer ? "selected pointer" : "pointer "}>
                        <td>{f.Description}</td>
                        <td>{f.Location}</td>
                    </tr>)) || <tr><td colSpan={100}>{t("commons.noData")}</td></tr>}
            </tbody>
        </table>
    )
}