import { Freezer } from "./Freezer";

export function ProtocolFreezerConfiguration({ id, minTemperature, maxTemperature, assignedFreezers } = {}) {
    this.Id = id ?? null;
    this.MinTemperature = minTemperature ?? 0;
    this.MaxTemperature = maxTemperature ?? 0;
    this.AssignedFreezers = assignedFreezers?.map(a => new Freezer(a)) ?? [];

    this.IsTemperatureValid = function (value) {
        return (value >= this.MinTemperature) && (value <= this.MaxTemperature) 
    }
}