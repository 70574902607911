import React, { useContext, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import { syncPatients } from '../../api/database';
import { syncUsers } from '../../api/identity';
import { syncPatientsInProtocol, syncProtocols, syncUsersInProtocol } from '../../api/protocolAdmin';
import { WithAuth } from '../../components/Authorization/WithAuth';
//Components
import Card from '../../components/Cards/Card';
import { Sync } from '../../components/Icons/Icons';
import { HandleApiError } from '../../components/Notifications/APIErrorHandler';
import { Success } from '../../components/Notifications/Notifications';
import AdminContext from './AdminContext/AdminContext';

const ConfirmSync = (props) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <SweetAlert
                warning
                title={props.title}
                onConfirm={() => props.onConfirm()}
                onCancel={() => { props.onClose() }}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="info"
                confirmBtnText={t("buttons.yes")}
                cancelBtnText={t("buttons.no")}
                showCancel
            >
                <span>{props.subtitle}</span>
            </SweetAlert>
        </React.Fragment>
    );
}

export const SyncContainer = () => {
    const { t } = useTranslation();

    //const [, , isAuthorized] = useAuthorization();
    const { selectedTenant } = useContext(AdminContext);
    const [modal, setModal] = useState(null);

    const syncUsersClick = () => {
        setModal(<ConfirmSync
            title={t("settings.sync.confirm_users_sync_title")}
            subtitle={t("settings.sync.confirm_users_sync_subtitle")}
            onConfirm={syncUsersConfirm}
            onClose={() => setModal(null)}
        />);
    }

    const syncUsersConfirm = async () => {
        setModal(null);
        if (!selectedTenant) return;

        try {
            await syncUsers(selectedTenant.tenantId);
            Success("settings.sync.users_sync_started");
        }
        catch (ex) {
            console.log(ex);
            HandleApiError(ex);
        }
    }

    const syncPatientsClick = () => {
        setModal(<ConfirmSync
            title={t("settings.sync.confirm_patients_sync_title")}
            subtitle={t("settings.sync.confirm_patients_sync_subtitle")}
            onConfirm={syncPatientsConfirm}
            onClose={() => setModal(null)}
        />);
    }

    const syncPatientsConfirm = async () => {
        setModal(null);
        if (!selectedTenant) return;

        try {
            await syncPatients(selectedTenant.tenantId);
            Success("settings.sync.database_sync_started");
        }
        catch (ex) {
            console.log(ex);
            HandleApiError(ex);
        }
    }

    const syncProtocolsClick = () => {
        setModal(<ConfirmSync
            title={t("settings.sync.confirm_protocols_sync_title")}
            subtitle={t("settings.sync.confirm_protocols_sync_subtitle")}
            onConfirm={syncProtocolsConfirm}
            onClose={() => setModal(null)}
        />);
    }

    const syncProtocolsConfirm = async () => {
        setModal(null);
        if (!selectedTenant) return;

        try {
            await syncProtocols(selectedTenant.tenantId);
            Success("settings.sync.database_sync_started");
        }
        catch (ex) {
            console.log(ex);
            HandleApiError(ex);
        }
    }

    const syncUsersInProtocolsClick = () => {
        setModal(<ConfirmSync
            title={t("settings.sync.confirm_protocols_sync_title")}
            subtitle={t("settings.sync.confirm_protocols_sync_subtitle")}
            onConfirm={syncUsersInProtocolsConfirm}
            onClose={() => setModal(null)}
        />);
    }

    const syncUsersInProtocolsConfirm = async () => {
        setModal(null);
        if (!selectedTenant) return;

        try {
            await syncUsersInProtocol(selectedTenant.tenantId);
            Success("settings.sync.database_sync_started");
        }
        catch (ex) {
            console.log(ex);
            HandleApiError(ex);
        }
    }

    const syncPatientsInProtocolsClick = async () => {
        setModal(null);
        if (!selectedTenant) return;

        try {
            await syncPatientsInProtocol(selectedTenant.tenantId);
            Success("settings.sync.database_sync_started");
        }
        catch (ex) {
            console.log(ex);
            HandleApiError(ex);
        }
    }

    return (
        <Card
            tabs
            content={
                <WithAuth requiredPermission={["Tenant.View"]}>
                    {
                        selectedTenant ?
                            <div style={{ marginTop: '40px' }}>
                                {modal}
                                <Row>
                                    <Col xs="4">
                                        <Card
                                            title="Usuarios"
                                            content={
                                                <Button bsStyle="success" onClick={syncUsersClick}><Sync /> Sincronizar</Button>
                                            }
                                        />
                                    </Col>
                                    <Col xs="4">
                                        <Card
                                            title="Pacientes"
                                            content={
                                                <Button bsStyle="success" onClick={syncPatientsClick}><Sync /> Sincronizar</Button>
                                            }
                                        />
                                    </Col>
                                    <Col xs="4">
                                        <Card
                                            title="Protocolos"
                                            content={
                                                <Button bsStyle="success" onClick={syncProtocolsClick}><Sync /> Sincronizar</Button>
                                            }
                                        />
                                    </Col>
                                    <Col xs="4">
                                        <Card
                                            title="Usuarios en Protocolos"
                                            content={
                                                <Button bsStyle="success" onClick={syncUsersInProtocolsClick}><Sync /> Sincronizar</Button>
                                            }
                                        />
                                    </Col>
                                    <Col xs="4">
                                        <Card
                                            title="Pacientes en Protocolos"
                                            content={
                                                <Button bsStyle="success" onClick={syncPatientsInProtocolsClick}><Sync /> Sincronizar</Button>
                                            }
                                        />
                                    </Col>
                                </Row>
                            </div> :
                            <div>Seleccione Tenant</div>
                    }
                </WithAuth>
            }
        />
    );
}