import { ErrorMessage } from 'formik';
import React from 'react';
import { Col, ControlLabel, FormControl, Grid, Row } from 'react-bootstrap';
import Card from '../Cards/Card';
import { FormErrorMessage } from '../Validations/FormValidationErrors';
import { Facebook, Instagram, Twitter } from '../Icons/Icons';
import { ExpandableContent } from '../CustomControls/ExpandableContent';



export const ContactDataForm = props => {

    const {
        values,
        handleChange,
        handleBlur,
        t,
    } = props;

    if (!values.telecom) values.telecom = { cellPhone: '', email: '' };

    return (
        <ExpandableContent title={t("person.contact.title")} expandedAll={true} content="">
            <Card
                className="card-form"
                content={
                    <Grid fluid>
                        <Row>
                            <Col sm={4}>
                                <ControlLabel>{t("person.contact.cellPhone")}</ControlLabel>
                                <FormControl type="text" name="telecom.cellPhone" value={values.telecom?.cellPhone ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                <ErrorMessage name="telecom.cellPhone" component={FormErrorMessage} />
                            </Col>
                            <Col sm={4}>
                                <ControlLabel>{t("person.contact.phone")}</ControlLabel>
                                <FormControl type="text" name="telecom.phone" value={values.telecom?.phone ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                <ErrorMessage name="telecom.phone" component={FormErrorMessage} />
                            </Col>
                            <Col sm={4}>
                                <ControlLabel>{t("person.contact.email")}</ControlLabel>
                                <FormControl type="text" name="telecom.email" value={values.telecom?.email ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                <ErrorMessage name="telecom.email" component={FormErrorMessage} />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <ControlLabel><Instagram /> Instagram</ControlLabel>
                                <FormControl type="text" name="telecom.instagram" value={values.telecom?.instagram ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            </Col>
                            <Col sm={4}>
                                <ControlLabel><Facebook /> Facebook</ControlLabel>
                                <FormControl type="text" name="telecom.facebook" value={values.telecom?.facebook ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            </Col>
                            <Col sm={4}>
                                <ControlLabel><Twitter /> Twitter</ControlLabel>
                                <FormControl type="text" name="telecom.twitter" value={values.telecom?.twitter ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            </Col>
                        </Row>
                    </Grid>
                }
            />
        </ExpandableContent>
    );
}