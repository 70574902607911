import { after } from 'lodash';
import React, { useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { createVisitTracking, createVisitTrackingExtra, getPreviousVisitTracking, getVisitTracking } from '../../api/protocol.js';
import Card from '../../components/Cards/Card.jsx';
import { Actions } from "../../components/MedicalRecords/Actions.jsx";
import { LastVitals } from '../../components/MedicalRecords/LastVitals.jsx';
import ModalNewEntry from '../../components/MedicalRecords/ModalNewEntry.jsx';
import { PatientInfo } from "../../components/MedicalRecords/PatientInfo.jsx";
import { HandleApiError } from '../../components/Notifications/APIErrorHandler.js';
import { Success } from '../../components/Notifications/Notifications.js';
import { ConfirmVisitTracking } from '../../components/Protocols/Tracking/Modals.jsx';
import { getQueryParam } from '../../components/Utils/Commons.jsx';
import { addDays, getOffset, isBetween } from '../../components/Utils/Dates.jsx';
import { ModalDigitalSign } from '../DigitalSign/ModalDigitalSign.jsx';
import { useEntries } from './Hooks/useEntries.js';
import { useModalData } from './Hooks/useModalData.js';
import useNewEntry from './Hooks/useNewEntry.js';
import { usePatient } from './Hooks/usePatient.js';
import { AdverseEventsListContainer } from './MedicalRecordItems/AdverseEventsListContainer.jsx';
import { AnthropometricsListContainer } from './MedicalRecordItems/AnthropometricsListContainer.jsx';
import { AttachmentsListContainer } from './MedicalRecordItems/AttachmentsListContainer.jsx';
import { DrugsListContainer } from './MedicalRecordItems/DrugsListContainer.jsx';
import EntriesContainer from './MedicalRecordItems/EntriesContainer.js';
import { FamilyHistoryListContainer } from './MedicalRecordItems/FamilyHistoryListContainer';
import { LaboratoryListContainer } from './MedicalRecordItems/LaboratoryListContainer.jsx';
import { OrdersListContainer } from './MedicalRecordItems/OrdersListContainer.js';
import { PersonalHabitsListContainer } from './MedicalRecordItems/PersonalHabitsListContainer.jsx';
import { PersonalHistoryListContainer } from './MedicalRecordItems/PersonalHistoryListContainer.jsx';
import { PrescriptionsListContainer } from './MedicalRecordItems/PrescriptionsListContainer.js';
import { ProceduresListContainer } from './MedicalRecordItems/ProceduresListContainer.jsx';
import { VitalsListContainer } from './MedicalRecordItems/VitalsListContainer.jsx';
import AppointmentsHistory from '../Agenda/AppointmentsHistory.js';
import { ConditionsListContainer } from './MedicalRecordItems/ConditionsListContainer.js';
import { useTranslation } from 'react-i18next';
import { ObservationsListContainer } from './MedicalRecordItems/ObservationsListContainer.js';

const MedicalRecordContainer = (props) => {

    const { match: { params: { medicalRecordNumber } } } = props;
    //const currentAppointment = getQueryParam(props.location.search, "appointmentId");
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [modal, setModal] = useState(null);
    const [patient, isPatientLoading] = usePatient(medicalRecordNumber);
    const { isShowing, toggleNewEntry, createExternal } = useNewEntry();
    const [, , , , , exportToPDF] = useEntries();
    const [isShowingDigitalSign, toggleDigitalSign] = useModalData();
    const settingsStore = useSelector(state => state.settings);

    const _checkVisitDates = async (patientVisitsData, selectedVisit, callback) => {
        console.log(patientVisitsData)
        if (selectedVisit.order > 1) {
            // Obtengo visita anterior para verificar fechas. 
            try {
                const response = await getPreviousVisitTracking(selectedVisit.protocolId, selectedVisit.id, medicalRecordNumber);
                var prevVisit = response.data;

                let prevVisitTemplate = patientVisitsData.visits?.find(x => x.id === prevVisit.visitId);
                const baseLineVisit = patientVisitsData.visits?.find(x => x.baseline);
                let afterBaseline = selectedVisit.order > baseLineVisit.order ? true : false;

                // Calculo fechas.
                let estimatedDate = null;
                if (afterBaseline) // Si es posterior a baseline, calculo las fechas estimativas con respecto a la fecha real anterior. 
                    estimatedDate = getOffset(new Date(prevVisit.date), selectedVisit.offset, prevVisitTemplate?.offset, selectedVisit.offsetType);
                else {
                    // Si es anterior a baseline, calculo fechas estimativas con respecto a la fecha estimada anterior. 
                    //const prevVistitEstimatedDate = getOffset(new Date(prevVisit.date), prevVisitTemplate?.offset, selectedVisit.offset, selectedVisit.offsetType);
                    estimatedDate = getOffset(new Date(prevVisit.date), prevVisitTemplate?.offset, selectedVisit.offset, selectedVisit.offsetType);
                }

                const fromDate = addDays(estimatedDate, -selectedVisit.windowNeg);
                const toDate = addDays(estimatedDate, selectedVisit.windowPos);

                if (!isBetween(new Date(), fromDate, toDate)) {
                    // Muestro Alert para confirmar que desea guardar datos fuera del rango de fechas de la visita. 
                    setModal(<ConfirmVisitTracking
                        onClose={() => { setModal(null); }}
                        onConfirm={callback}
                        data={{ fromDate: fromDate, toDate: toDate }}
                    />);
                }
                else {
                    callback();
                }
            }
            catch (error) {
                console.log(error);
                HandleApiError(error);
            }
        }
        else {
            callback();
        }
    }

    //#region Handlers
    const handleCreateExternal = async () => {
        setIsLoading(true);
        try {
            setIsLoading(true);
            var createdEntry = await createExternal(medicalRecordNumber);
            setIsLoading(false);
            props.history.push(`/admin/medicalRecords/${medicalRecordNumber}/newentry/${createdEntry.data.id}`);
        }
        catch (error) {
            setIsLoading(false);
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleCreateProtocol = async (_controlName, selectedVisit) => {
        setModal(null);

        try {
            setIsLoading(true);
            // Creo Visita en Protocolo (en backend crea Entry y Visit)
            var response = await createVisitTracking(selectedVisit.protocolId, selectedVisit.crossVersionId, patient.medicalRecordNumber);
            setIsLoading(false);
            props.history.push(`/admin/protocols/${selectedVisit.protocolId}/visitTracking/${selectedVisit.crossVersionId}/patient/${response?.data.visit?.numberInProtocol}`);
        }
        catch (error) {
            setIsLoading(false);
            console.log(error);
            HandleApiError(error);
        }
        /*_checkVisitDates(selectedVisit, async function () {
            //if (modal)
                setModal(null);

            try {
                setIsLoading(true);
                // Creo Visita en Protocolo (en backend crea Entry y Visit)
                var response = await createVisitTracking(selectedVisit.protocolId, selectedVisit.crossVersionId, patient.medicalRecordNumber);
                setIsLoading(false);
                props.history.push(`/admin/protocols/${selectedVisit.protocolId}/visitTracking/${selectedVisit.crossVersionId}/patient/${response?.data.visit?.numberInProtocol}`);
            }
            catch (error) {
                setIsLoading(false);
                console.log(error);
                HandleApiError(error);
            }
        });*/
    }

    const handleCreateExtraProtocol = async (protocolId, type) => {
        setModal(null);
        try {
            setIsLoading(true);
            // Creo Visita Extra protocolar en Protocolo (Y su correspondiente en H.C.)
            const res = await createVisitTrackingExtra(protocolId, medicalRecordNumber, { type: type });
            setIsLoading(false);
            props.history.push(`/admin/protocols/${protocolId}/visitTrackingExtra/${res.data.id}/patient/${res?.data?.numberInProtocol}`);
        }
        catch (error) {
            setIsLoading(false);
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleExportAction = () => {
        if (settingsStore.settings.digitalSignEnabled)
            toggleDigitalSign();
        else
            handleExportToPDF()
    }

    const handleExportToPDF = async (pin) => {
        setIsLoading(true);
        if (settingsStore.settings.digitalSignEnabled)
            toggleDigitalSign();

        try {
            var response = await exportToPDF(pin?.password, medicalRecordNumber);

            const blob = response.data;
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            let fileName = `${patient.fullName}-${patient.medicalRecordNumber}.pdf`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

            setIsLoading(false);
            Success("medicalRecordsNotifications.entry_Exported");
        }
        catch (error) {
            setIsLoading(false);
            HandleApiError(error);
            console.log(error);
        };
    }
    //#endregion

    return (
        <div>
            <Row className="display-flex">
                <Col md={4} lg={6}>
                    <PatientInfo patient={patient} isLoading={isPatientLoading} />
                </Col>
                <Col md={8} lg={6}>
                    <LastVitals medicalRecordNumber={medicalRecordNumber} bloodGroup={patient?.bloodGroup} bloodFactor={patient?.bloodFactor} />
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={2}>
                    {modal}
                    <Actions
                        onCreateEntry={toggleNewEntry}
                        onCreateAppointment={() => props.history.push(`/admin/agenda?medicalRecordNumber=${patient?.medicalRecordNumber}&source=${props.location.pathname}`)}
                        onEditPatient={() => props.history.push(`/admin/database/${patient.medicalRecordNumber}`)}
                        onExportToPDF={handleExportAction}
                        //onRedirectTo={() => props.history.push("/admin/database")}//(evt) => { evt.preventDefault(); history.goBack() }
                        onRedirectTo={(evt) => { evt.preventDefault(); props.history.goBack() }}
                        isLoading={isLoading}
                    />
                    <ModalDigitalSign
                        isShowing={isShowingDigitalSign}
                        hide={toggleDigitalSign}
                        onSubmit={handleExportToPDF}
                    />
                    <ModalNewEntry patient={patient} isShowing={isShowing} hide={toggleNewEntry} isLoading={isLoading}
                        onCreateExternal={handleCreateExternal}
                        onCreateProtocol={handleCreateProtocol}
                        onCreateExtraProtocol={handleCreateExtraProtocol}
                    />
                </Col>
                <Col md={12} lg={10}>
                    <Tabs defaultActiveKey="entries" id="medical-record-home" mountOnEnter={true}>
                        <Tab eventKey="entries" title={t("medicalRecords.tabs.entries")}>
                            <EntriesContainer medicalRecordNumber={medicalRecordNumber} view="scroll" />
                        </Tab>
                        <Tab eventKey="personalHabits" title={t("medicalRecords.tabs.personalHabits")}>
                            <Card
                                tab
                                content={
                                    <Row>
                                        <Col md={6}>
                                            <PersonalHabitsListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />
                                        </Col>
                                        <Col md={6}>
                                            <FamilyHistoryListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />
                                        </Col>
                                    </Row>
                                }
                            />
                        </Tab>
                        {
                            patient.protocols?.length === 0 &&
                            <Tab eventKey="observations" title={t("medicalRecords.tabs.observations")}>
                                <Card
                                    tab
                                    content={
                                        <Row>
                                            <Col md={12}>
                                                <ObservationsListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />
                                            </Col>
                                        </Row>
                                    }
                                />
                            </Tab>
                        }
                        {
                            patient.protocols?.length === 0 &&
                            <Tab eventKey="conditions" title={t("medicalRecords.tabs.conditions")}>
                                <Card
                                    tab
                                    content={
                                        <Row>
                                            <Col md={4}>
                                                <ConditionsListContainer medicalRecordNumber={medicalRecordNumber} title={t("medicalRecords.condition.title_open")} actual expandedAll />
                                            </Col>
                                            <Col md={4}>
                                                <ConditionsListContainer medicalRecordNumber={medicalRecordNumber} title={t("medicalRecords.condition.title_closed")} actual={false} expandedAll />
                                            </Col>
                                            <Col md={4}>
                                                <ConditionsListContainer medicalRecordNumber={medicalRecordNumber} title={t("medicalRecords.condition.title_chronic")} actual chronic expandedAll />
                                            </Col>
                                        </Row>
                                    }
                                />
                            </Tab>
                        }
                        {
                            patient.protocols?.length > 0 &&
                            <Tab eventKey="events" title={t("medicalRecords.tabs.events")}>
                                <Card
                                    tab
                                    content={
                                        <Row>
                                            <Col md={6}>
                                                <PersonalHistoryListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />
                                            </Col>
                                            <Col md={6}>
                                                <AdverseEventsListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />
                                            </Col>
                                        </Row>
                                    }
                                />
                            </Tab>
                        }
                        <Tab eventKey="drugsprocedures" title={`${t("medicalRecords.tabs.drugs")}/${t("medicalRecords.tabs.procedures")}`}>
                            <Card
                                tab
                                content={
                                    <Row>
                                        <Col md={6}>
                                            <DrugsListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />
                                        </Col>
                                        <Col md={6}>
                                            <ProceduresListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />
                                        </Col>
                                    </Row>
                                }
                            />
                        </Tab>
                        <Tab eventKey="prescriptions" title={`${t("medicalRecords.tabs.prescriptions")}/${t("medicalRecords.tabs.orders")}`}>
                            <Card
                                tab
                                content={
                                    <Row>
                                        <Col md={6}>
                                            <PrescriptionsListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />
                                        </Col>
                                        <Col md={6}>
                                            <OrdersListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />
                                        </Col>
                                    </Row>
                                }
                            />
                        </Tab>
                        <Tab eventKey="laboratories" title={t("medicalRecords.tabs.laboratories")}>
                            <Card
                                tab
                                content={
                                    <Row>
                                        <Col md={12}>
                                            <LaboratoryListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />
                                        </Col>
                                    </Row>
                                }
                            />
                        </Tab>
                        <Tab eventKey="vitals" title={`${t("medicalRecords.tabs.vitals")}/${t("medicalRecords.tabs.anthropometrics")}`}>
                            <Card
                                tab
                                content={
                                    <Row>
                                        <Col md={6}>
                                            <VitalsListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />
                                        </Col>
                                        <Col md={6}>
                                            <AnthropometricsListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />
                                        </Col>
                                    </Row>
                                }
                            />
                        </Tab>
                        <Tab eventKey="attachments" title={t("medicalRecords.tabs.attachments")}>
                            <AttachmentsListContainer medicalRecordNumber={medicalRecordNumber} expandedAll={true} />
                        </Tab>
                        <Tab eventKey="appointments" title={t("medicalRecords.tabs.appointments")}>
                            <AppointmentsHistory medicalRecordNumber={medicalRecordNumber} />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </div>
    );
}

export default MedicalRecordContainer;