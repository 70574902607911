import React from "react";
import { Col, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Doctor, Identification, Date, Address } from "../Icons/Icons";
import { dateFormatter } from "../Utils/Formatter";

export const ExpandedContent = props => {

    const { t } = useTranslation();
    const patient = props.data;

    if (!patient)
        return (<></>);

    return (
        <div className="card-expanded-content">
            <Grid fluid>
                <Row>
                    <Col md={6}>
                        <Col sm={12}>
                            <div>
                                <div className="icon">
                                    <div className="svg-container"><Address /></div>
                                </div>
                                <div>
                                    <div className="group">{t("person.address.address")}</div>
                                    <div className="title">{patient.address}</div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} className="marginTop">
                            <div>
                                <div className="icon">
                                    <div className="svg-container"><Date /></div>
                                </div>
                                <div>
                                    <div className="group">{t("person.birthDate")}</div>
                                    <div className="title">{dateFormatter(patient.birthDate)} ({patient.age} años)</div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} className="marginTop">
                            <div>
                                <div className="icon">
                                    <div className="svg-container"><Identification /></div>
                                </div>
                                <div>
                                    <div className="group">{t("person.identificationNumber")}</div>
                                    <div className="title">{`${patient.identificationType} ${patient.identificationNumber}`}</div>
                                </div>
                            </div>
                        </Col>
                    </Col>
                    <Col md={6}>
                        <Col sm={12}>
                            <div>
                                <div className="icon">
                                    <div className="svg-container"><Doctor /></div>
                                </div>
                                <div>
                                    <div className="group">{t("person.familyDoctor")}</div>
                                    <div className="title">{patient.familyDoctor}</div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} className="marginTop">
                            <div>
                                <div className="icon">
                                    <div className="svg-container"><Doctor /></div>
                                </div>
                                <div>
                                    <div className="group">{t("person.internalPractitioner")}</div>
                                    <div className="title">{patient.internalPractitioner}</div>
                                </div>
                            </div>
                        </Col>
                    </Col>
                </Row>
            </Grid >
        </div>
    );
}