import React from "react";

import { ProtocolPractitionersContainer } from "../../containers/Protocols/Practitioners/Practitioners";
import { ProtocolHeader } from "../../components/Protocols/Header";

export const PractitionersView = props => {
    const { match: { params } } = props;

    if (!params.protocolId)
        return "";

    return (
        <>
            <ProtocolHeader protocolId={params.protocolId} />
            <ProtocolPractitionersContainer protocolId={params.protocolId} />
        </>
    );
}