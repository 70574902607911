import { useEffect, useState } from "react"
import FreezerProtocolServices from "../../../../api/Freezer/FreezerProtocolServices";
import { getMasterData, getProtocols } from "../../../../api/protocol";
import { EnumValue } from "../../../../api/Freezer/Entities/Utils";
import { useProtocolAuthFilter } from "../../../../components/Authorization/useAuthorization";

function ProtocolTelemetryState({ isFirstLoading, isLoading, laboratories, protocols, protocolsConfig } = {}) {
    this.isFirstloading = isFirstLoading ?? false;
    this.isLoading = isLoading ?? false;
    this.laboratories = laboratories ?? [];
    this.protocols = protocols ?? [];
    this.protocolsConfig = protocolsConfig ?? []

    this.StartLoading = function () {
        return new ProtocolTelemetryState({ ...this, isLoading: true, isFirstLoading: false})
    }
    this.EndLoading = function ({ protocols } = {}) {
        return new ProtocolTelemetryState({ ...this, isLoading: false, protocols , isFirstLoading: false})
    }
    this.ContainsProtocolConfig = function (protocolId) {
        const config = this.protocolsConfig.get(protocolId)
        return config !== null && config?.AssignedFreezers?.length > 0;
    }

    this.GetConfig = function (protocolId) {
        return this.protocolsConfig.get(protocolId)
    }
}

export function useLaboratoryProtocol() {
    const [state, setState] = useState(new ProtocolTelemetryState());
    const [canViewProtocol, isAuthLoading] = useProtocolAuthFilter()
    useEffect(() => {
        async function LoadLaboratories() {
            const masterData = await getMasterData(["Laboratories"]);
            const protocolConfigurations = (await FreezerProtocolServices
                .GetProtocolConfigs()).Value.reduce((m, v) => m.set(v.Id, v), new Map())
                ?? [];
            setState(new ProtocolTelemetryState({
                isFirstLoading: false,
                laboratories: masterData.data?.Laboratories.map(l => new EnumValue(l.id, l.title)) ?? [],
                protocolsConfig: protocolConfigurations
            }))
        }
        LoadLaboratories();
    }, [isAuthLoading])

    const HandleSelectedLaboratoryAsync = async (laboratoryId) => {
        setState(state.StartLoading())

        const protocols = (await getProtocols({
            pagination: { page: 1, sizePerPage: 1000, totalSize: 0 },
            sort: { sortField: "Created", sortOrder: "desc" },
            filters: { laboratoryId: laboratoryId }
        })).data.data

        var filteredProtocols = protocols
            ?.filter(p => canViewProtocol(p.id))
            ?.filter(p => state.ContainsProtocolConfig(p.id))
            ?.map(p => new EnumValue(p.id, p.title)) ?? []

        setState(state.EndLoading({ protocols: filteredProtocols }))
    }

    return [state, HandleSelectedLaboratoryAsync]
}