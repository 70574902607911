import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

export const NullableDatePicker = (props) => {
    const {
        dayLabel,
        monthLabel,
        yearLabel,
        value,
        onChange,
        onBlur,
        futureDates
    } = props;

    const [day, setDay] = useState(null);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);

    const [selectDay, setSelectDay] = useState("UNK");
    const [selectMonth, setSelectMonth] = useState("UNK");
    const [selectYear, setSelectYear] = useState("UNK");

    useEffect(() => {
        let day = [], month = [], year = [];
        let maxDays = 31;
        let maxMonths = 11;

        if (selectYear && selectYear !== "UNK" && selectMonth && selectMonth !== "UNK") {
            maxDays = moment(`${selectYear}-${parseInt(selectMonth) + 1}`, "YYYY-MM").daysInMonth();
        }

        if (!futureDates && selectYear && selectYear === moment().year().toString()) {
            maxMonths = moment().month();

            if (selectMonth && selectMonth === moment().month().toString())
                maxDays = moment().date();
        }

        for (let i = 1; i <= maxDays; i++) {
            day.push(i);
        }

        for (let i = 0; i <= maxMonths; i++) {
            month.push({
                text: props.useMonthNames ? moment.monthsShort('-MMM-', i) : (i + 1),
                value: i
            });
        }

        const maxYear = futureDates ? moment().year() + 10 : moment().year();
        for (let i = maxYear; i >= props.minYear; i--) {
            year.push(i);
        }

        setDay(day); setMonth(month); setYear(year);
        if (value && value.split("/").length > 2) {

            let selectedDay = value.split("/")[0];
            let selectedMonth = value.split("/")[1];
            let selectedYear = value.split("/")[2];

            setSelectDay(selectedDay ? selectedDay : "UNK");
            setSelectMonth(selectedMonth ? selectedMonth : "UNK");
            setSelectYear(selectedYear ? selectedYear : "UNK");
        }
    }, [value]);

    const handleBlur = () => {
        if (props.onBlur)
            onBlur(props.name, true);
    };

    const handleChange = (e, type) => {
        const selectedValue = e.target.value ? e.target.value : "UNK";
        switch (type) {
            case "selectDay":
                setSelectDay(selectedValue);
                onChange(props.name, `${selectedValue}/${selectMonth}/${selectYear}`);
                break;
            case "selectMonth":
                setSelectMonth(selectedValue);
                onChange(props.name, `${selectDay}/${selectedValue}/${selectYear}`);
                break;
            case "selectYear":
                setSelectYear(selectedValue);
                onChange(props.name, `${selectDay}/${selectMonth}/${selectedValue}`);
                break;
            default:
                break;
        }
    };

    if (!day || !month || !year)
        return null;

    const dayElement = day.map((day, id) => {
        return <option value={day} key={id}>{day}</option>;
    });
    const monthElement = month.map((month, id) => {
        return <option value={month.value} key={id}>{month.text}</option>;
    });
    const yearElement = year.map((year, id) => {
        return <option value={year} key={id}>{year}</option>;
    });

    return (
        <Row>
            <Col md={3} style={{ paddingRight: '0' }}>
                <select className="form-control" value={selectMonth} disabled={props.disabled} onChange={(e) => handleChange(e, 'selectMonth')} onBlur={handleBlur} style={{ padding: '0', zIndex: 0 }}>
                    <option value="">{monthLabel}</option>
                    {monthElement}
                </select>
            </Col>
            <Col md={4} style={{ padding: '0' }}>
                <select className="form-control" value={selectDay} disabled={props.disabled} onChange={(e) => handleChange(e, 'selectDay')} onBlur={handleBlur} style={{ padding: '0', zIndex: 0 }}>
                    <option value="">{dayLabel}</option>
                    {dayElement}
                </select>
            </Col>
            <Col md={5} style={{ paddingLeft: '0' }}>
                <select className="form-control" value={selectYear} disabled={props.disabled} onChange={(e) => handleChange(e, 'selectYear')} onBlur={handleBlur} style={{ padding: '0', zIndex: 0 }}>
                    <option value="">{yearLabel}</option>
                    {yearElement}
                </select>
            </Col>
        </Row>
    );
}

NullableDatePicker.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    dayLabel: PropTypes.string,
    monthLabel: PropTypes.string,
    yearLabel: PropTypes.string,
    minYear: PropTypes.number,
    value: PropTypes.string,
    useMonthNames: PropTypes.bool,
    futureDates: PropTypes.bool,
};

NullableDatePicker.defaultProps = {
    dayLabel: 'dia',
    monthLabel: 'mes',
    yearLabel: 'año',
    minYear: 1916,
    value: "UNK/UNK/UNK",
    useMonthNames: true,
    futureDates: false,
};