import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { Panel } from "react-bootstrap";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Alert from './Alert';
import Dot from './Dot';
import '../telemetry.css';

export default function FreezerTogglePanel({ header, daysResume, protocolConfig }) {
    const { t } = useTranslation()
    const [isOpen, setIsOpen] = React.useState(false);
    const columns = [
        {
            dataField: 'Day',
            text: t('commons.day'),
            formatter: (_, row) => {
                const date = moment(row.Day);
                const formattedDate = date.format('DD/MM/YYYY');
                return formattedDate;
            }
        },
        {
            dataField: 'MinTemperature',
            text: t('telemetry.minTemp'),
            formatter: (_, row) => formatTemperature(row.MinTemperature)
        },
        {
            dataField: 'MaxTemperature',
            text: t('telemetry.maxTemp'),
            formatter: (_, row) => formatTemperature(row.MaxTemperature)
        }
    ];

    if (protocolConfig) {
        columns.push({
            dataField: 'state',
            text: t('commons.state'),
            formatter: (_, row) => {
                return <Dot color={row.HasInvalidTemperature(protocolConfig) ? "#f18e41" : "#219aa8"} />;
            }
        });
    }



    function formatTemperature(value) {
        const formattedValue = value.toFixed(2);
        return `${formattedValue}°`;
    }

    return (
        <Panel expanded={isOpen} onToggle={() => setIsOpen(!isOpen)}>
            <Panel.Heading>
                <div className='panel-header'>
                    {header}
                    <div className='text-right'>
                        <Panel.Toggle>{t('commons.show')}</Panel.Toggle>
                    </div>
                </div>
            </Panel.Heading>
            <Panel.Collapse>
                <Panel.Body>
                    {daysResume?.length > 0 && <BootstrapTable
                        bordered={false}
                        hover
                        remote
                        keyField='Day'
                        classes="table-spaced table-spaced-small"
                        wrapperClasses="table-responsive"
                        data={daysResume}
                        columns={columns}
                    /> || <Alert message={t("commons.noData")} />}
                </Panel.Body>
            </Panel.Collapse>
        </Panel>
    )
}