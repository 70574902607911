import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { FormControl, FormGroup, InputGroup } from "react-bootstrap";
import { IconSVG } from '../Icons/Icons';

export const CustomSearchBox = (props) => {

    const [value, setValue] = useState("");
    const debouncedSearch = useCallback(
        debounce((value) => {
            props.handleSearch(value);
        }, 1000),
        [],
    );

    const handleInputChange = (ev) => {

        if (!ev) {
            setValue("");
            debouncedSearch("");
            return;
        }

        var q = ev.currentTarget.value;
        setValue(q);
        if (q && q.length >= 2) {
            debouncedSearch(q);
        }

        // Limpio búsqueda. 
        if (q.length === 0) {
            debouncedSearch("");
        }
    }

    return (
        <FormGroup>
            <InputGroup>
                <FormControl type="text" value={value} placeholder={props.placeholder} onChange={handleInputChange} />
                <InputGroup.Addon>
                    <IconSVG name="search" fill="#fff" stroke="#fff" />
                </InputGroup.Addon>
            </InputGroup>
        </FormGroup>
    )
}