import axios from './index';
export const getPagingAndOrderParams = (newActiveFilters, searchParams) => {

    if (!newActiveFilters)
        return;

    searchParams.delete('pageIndex'); // se resetea el paginado
    if (newActiveFilters.pagination?.page) {
        searchParams.append('pageIndex', newActiveFilters.pagination.page);
    }
    searchParams.delete('pageSize');
    if (newActiveFilters.pagination?.sizePerPage) {
        searchParams.append('pageSize', newActiveFilters.pagination.sizePerPage);
    }
    searchParams.delete('sortField');
    if (newActiveFilters.sort?.sortField) {
        searchParams.append('sortField', newActiveFilters.sort.sortField);
    }
    searchParams.delete('sortOrder');
    if (newActiveFilters.sort?.sortOrder) {
        searchParams.append('sortOrder', newActiveFilters.sort.sortOrder);
    }

    return searchParams;
}
export const getQueryString = (query, search) => {

    var searchParams = new URLSearchParams(search || '');

    if (!query)
        return searchParams.toString();

    searchParams = getPagingAndOrderParams(query, searchParams);

    if (query.filters) {
        for (const property in query.filters) {

            searchParams.delete(property);
            if (query.filters[property] !== null && query.filters[property] !== undefined) {
                searchParams.append(property, query.filters[property]);
            }
        }
    }

    return searchParams.toString();
}
/*******************/

export const getConfiguration = () => {
    return axios.get(`api/settings`);
};

export const uploadHeader = async (formData, type) => {
    return axios.post(`api/settings/prescriptionorders/${type}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const getSettings = () => {
    return axios.get(`api/settings/clientsettings`);
};

export const setSettings = (key, value) => {
    return axios.put(`api/settings/clientsettings/key/${key}/value/${value}`, value);
};

export const getAdminNotificationsAPI = async (filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(queryString ? `api/v1/adminNotifications/?${queryString}` : `api/v1/adminNotifications`);
};
export const getLastActiveAdminNotificationAPI = async () => {
    return axios.get("api/v1/adminNotifications/lastActive");
};
export const getAdminNotificationAPI = async (notificationId) => {
    return axios.get(`api/v1/adminNotifications/${notificationId}`);
};
export const createAdminNotificationAPI = async (notification) => {
    return axios.post(`api/v1/adminNotifications`, notification);
};
export const updateAdminNotificationAPI = async (notification) => {
    return axios.put(`api/v1/adminNotifications`, notification);
};
export const removeAdminNotificationAPI = async (notificationId) => {
    return axios.delete(`api/v1/adminNotifications/${notificationId}`);
};
export const setIsReadOnlyAPI = async (readonly) => {
    return axios.put(`api/settings/systemsettings/isReadOnly/${readonly}`, null);
};
