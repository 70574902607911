import React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLastVitals } from '../../containers/MedicalRecords/Hooks/useVitals';
import { Card } from '../Cards/Card';
import { IconSVG } from '../Icons/Icons';
import { Spinner } from '../Utils/Loaders';

export const LastVitals = (props) => {
    const { t } = useTranslation();

    const [data, isLoading] = useLastVitals(props.medicalRecordNumber);

    if (!data)
        return null;
    if (isLoading)
        return <Card noHeader content={<Spinner />} />;

    var bmiColor = "#20ad9b"; //Normopeso (18.5-24.9 kg/m2 ) 
    var diagnosis = t("medicalRecords.anthropometrics.normal");
    if (data.bmi < 18.5) {
        diagnosis = t("medicalRecords.anthropometrics.underweight");
        bmiColor = "#ca6e6e";  //Bajo peso (<18.5 kg/m2)
    }
    else if (data.bmi >= 25 && data.bmi < 30) {
        diagnosis = t("medicalRecords.anthropometrics.overweight");
        bmiColor = "#ffa534"; //Sobrepeso (25-29.9 kg/m2 ) 
    }
    else if (data.bmi >= 30) {
        diagnosis = t("medicalRecords.anthropometrics.obese");
        bmiColor = "#ca6e6e"; //Obesidad
    }

    return (
        <Card
            className="card-personal-habits"
            noHeader
            content={
                <Grid fluid className="personal-habits">
                    <Row>
                        {
                            props.bloodGroup && props.bloodFactor &&
                            <Col sm={4}>
                                <div className="">
                                    <div className="icon"><IconSVG name="bloodtype" fill="#20ad9b" /></div>
                                    <div>
                                        <div className="group">{t("person.bloodType")}</div>
                                        <div className="title">{`${props.bloodGroup} ${props.bloodFactor}`}</div>
                                    </div>
                                </div>
                            </Col>
                        }
                        <Col sm={4}>
                            <div className="">
                                <div className="icon"><IconSVG name="bloodpressure" fill="#20ad9b" /></div>
                                <div>
                                    <div className="group">{t("medicalRecords.vitals.bloodPressure")}</div>
                                    <div className="title">{data.bloodPressureHigh}/{data.bloodPressureLow} mm</div>
                                    <div className="description"></div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className="">
                                <div className="icon"><IconSVG name="heartrate" fill="#20ad9b" /></div>
                                <div>
                                    <div className="group">{t("medicalRecords.vitals.heartRate")}</div>
                                    <div className="title">{data.heartRate}</div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className="">
                                <div className="icon"><IconSVG name="height" /></div>
                                <div>
                                    <div className="group">{t('medicalRecords.anthropometrics.height')}</div>
                                    <div className="title">{data.height} cm</div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className="">
                                <div className="icon"><IconSVG name="weight" fill="#20ad9b" /></div>
                                <div>
                                    <div className="group">{t("medicalRecords.anthropometrics.weight")}</div>
                                    <div className="title">{data.weight} kg</div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className="">
                                <div className="icon"><IconSVG name="bmi" fill={bmiColor} /></div>
                                <div>
                                    <div className="group">{t("medicalRecords.anthropometrics.bmi")}</div>
                                    <div className="title">{data.bmi}</div>
                                    <div className="description">{diagnosis}</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Grid >
            }
        />
    );
}
