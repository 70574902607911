import { ErrorMessage } from 'formik';
import React from 'react';
import { Col, ControlLabel, FormControl, Grid, Row } from 'react-bootstrap';
import Card from '../Cards/Card';
import Autocomplete from "react-google-autocomplete";
import { FormErrorMessage } from '../Validations/FormValidationErrors';
import { googleAPIKey } from '../../variables/Constants';
import { ExpandableContent } from '../CustomControls/ExpandableContent';


export const AddressDataForm = props => {

    const {
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        t
    } = props;

    const getPostalCode = (addressArray) => {
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && "postal_code" === addressArray[i].types[0]) {
                    return addressArray[i].long_name;
                }
            }
        }
    };

    const onPlaceSelected = (place) => {
        //const addressArray = place.address_components,
        const latValue = place.geometry?.location?.lat(), lngValue = place.geometry?.location?.lng();

        // Set these values in the state.
        setFieldValue("address.fullAddress", place.formatted_address, false);
        setFieldValue("address.postalCode", getPostalCode(place.address_components), false);
        setFieldValue("address.lat", latValue, false);
        setFieldValue("address.long", lngValue, false);
    };

    if (!values.address) values.address = { fullAddress: '', streetName: '', streetNumber: '' };

    return (
        <ExpandableContent title={t("person.address.address")} expandedAll={true} content="">
            <Card
                className="card-form"
                content={
                    <Grid fluid>
                        <Row>
                            <Col md={8}>
                                <ControlLabel>{t("person.address.address")}</ControlLabel>
                                <Autocomplete
                                    apiKey={googleAPIKey}
                                    className="form-control"
                                    id="address.fullAddress"
                                    name="address.fullAddress"
                                    onPlaceSelected={onPlaceSelected}
                                    options={{
                                        types: ['address'],
                                        fields: ['address_components', 'geometry.location', 'place_id', 'formatted_address']
                                    }}
                                    defaultValue={values.address?.fullAddress ?? ""}
                                    onBlur={handleBlur}
                                />
                                <ErrorMessage name="address.fullAddress" component={FormErrorMessage} />
                            </Col>
                            <Col sm={4}>
                                <ControlLabel>{t("person.address.postalCode")}</ControlLabel>
                                <FormControl type="text" name="address.postalCode" value={values.address?.postalCode ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                <ErrorMessage name="address.postalCode" component={FormErrorMessage} />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col sm={2} md={2} lg={1}>
                                <ControlLabel>{t("person.address.floor")}</ControlLabel>
                                <FormControl type="text" name="address.floor" value={values.address?.floor ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            </Col>
                            <Col sm={2} md={2} lg={1}>
                                <ControlLabel>{t("person.address.department")}</ControlLabel>
                                <FormControl type="text" name="address.department" value={values.address?.department ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <ControlLabel>{t("person.address.body")}</ControlLabel>
                                <FormControl type="text" name="address.body" value={values.address?.body ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            </Col>
                            <Col sm={4} md={4} lg={4}>
                                <ControlLabel>{t("person.address.between")}</ControlLabel>
                                <FormControl type="text" name="address.between" value={values.address?.between ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                <ErrorMessage name="address.between" component={FormErrorMessage} />
                            </Col>
                        </Row>
                    </Grid>
                }
            />
        </ExpandableContent>
    );
}