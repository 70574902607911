import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ProtocolContext from '../ProtocolContext/ProtocolContext';
import BootstrapTable from 'react-bootstrap-table-next';
import Card from '../../../components/Cards/Card';
import { Modal, Button } from 'react-bootstrap'
import { useModalContext } from '../../../components/Modals/useModalContext';
import { ConfigurationFreezerFormik } from './ConfigureProtocolFreezerContainer';
import Loader from '../../../components/Common/Loader';
import useProtocolFreezer from './useProtocolFreezer';
import { ProtocolFreezerConfiguration } from '../../../api/Freezer/Entities/ProtocolFreezerConfiguration';
import { Field } from '../../../components/Common/Field/Field';
import { WithAuth } from '../../../components/Authorization/WithAuthProtocol';

export default function FreezerTabContainer() {
    const { t } = useTranslation();
    const protocolContext = useContext(ProtocolContext);
    const [protocolConfiguration, isLoading, reload] = useProtocolFreezer(protocolContext.protocolId, null);
    const [modalContext, openConfigModal] = useModalContext({
        handleEvent: (e) => { reload() }
    })
    const onHandleConfig = () => {
        openConfigModal({ protocolId: protocolContext.protocolId })
    }

    const columns = [
        { dataField: 'Description', text: t("commons.description"), headerStyle: { width: '15.625rem' } },
        { dataField: 'Location', text: t("commons.location"), headerStyle: { width: '12.5rem' } },
    ]

    return (
        <>
            <Card
                header={<div>
                    <WithAuth protocolId={protocolContext.protocolId} requiredPermission={["Freezers.Edit"]} >
                        <Button onClick={onHandleConfig} disabled={isLoading}>{t('protocols.freezer.configure')}</Button>
                    </WithAuth>
                </div>}
                content={
                    <>
                        <Loader isLoading={isLoading}>
                            {protocolConfiguration && <Row>
                                <Col sm={12} md={6}>
                                    <div className=''>
                                        <div className=''>
                                            <div className='text-center'>
                                                <label className='info-title'>{t('protocols.freezer.temperatureConfigTitle')}</label>
                                            </div>
                                            <Field title={t('protocols.freezer.minTemperature')} value={`${protocolConfiguration.MinTemperature} °`} />
                                            <Field title={t('protocols.freezer.maxTemperature')} value={`${protocolConfiguration.MaxTemperature} °`} />
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div>
                                        {protocolConfiguration.AssignedFreezers?.length > 0
                                            &&
                                            <div>
                                                <div className='text-center'> <strong className='info-title'>{t('protocols.freezer.assignedFreezers')}</strong> </div>
                                                <div style={{ overflowY: 'auto', maxHeight: '350px' }}>
                                                    <BootstrapTable
                                                        bordered={false}
                                                        hover
                                                        remote
                                                        keyField='Id'
                                                        classes="table-spaced table-spaced-small"
                                                        wrapperClasses="table-responsive"
                                                        columns={columns}
                                                        data={protocolConfiguration.AssignedFreezers}
                                                        noDataIndication={() => <>{t("commons.noData")}</>}
                                                    />
                                                </div>
                                            </div>
                                            || <div>{t('protocols.freezer.emptyAssignations')}</div>}
                                    </div>
                                </Col>
                            </Row>
                                || <div className='text-center'>
                                    <strong style={{ fontSize: '1.4rem' }}>
                                        {t('protocols.freezer.notConfigured')}
                                    </strong>
                                </div>}
                        </Loader>
                    </>}
            />
            {!isNaN(protocolContext.protocolId) && <ProtocolConfigModal state={modalContext} />}
        </>)
}




function ProtocolConfigModal({ state: { show, value, onClose, onEvent } }) {
    const { t } = useTranslation();
    const { protocolId } = value ?? {};
    const [protocolConfig, loading, reload] = useProtocolFreezer(protocolId, new ProtocolFreezerConfiguration({ id: protocolId }))

    useEffect(() => {
        reload()
    }, [protocolId])

    if (!show && isNaN(protocolId)) return null
    return (
        <>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('protocols.freezer.formTitle')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Loader isLoading={loading}>
                        <ConfigurationFreezerFormik
                            protocolId={protocolId}
                            configuration={protocolConfig}
                            onCancel={onClose}
                            onSubmit={() => {
                                onClose()
                                onEvent("reload")
                            }} />
                    </Loader>
                </Modal.Body>
            </Modal>
        </>)
}

