import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from 'react-i18next';
import { putLaboratoryResult, removeLaboratoryAPI, removeLaboratoryResultAPI } from '../../../api/medicalRecord';
import { AuditModal, CreateAudit, CreateAuditModal } from "../../../components/AuditTrail/AuditModal";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { LaboratoriesForm } from "../../../components/MedicalRecords/Forms/LaboratoriesForm";
import { Laboratory } from "../../../components/MedicalRecords/Laboratory";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import { getMedicalRecordsSchema } from '../../../components/Validations/FormValidationSchema';
import { AuditTrail } from "../../Admin/AuditTrail";
import { useLaboratories, useLaboratory } from "../Hooks/useLaboratories";
import { ModalLaboHistory } from '../Modals/ModalLaboHistory';
import { ModalSpinner } from '../../../components/Utils/Loaders';
import { ModalConfirm } from '../../../components/Modals/ModalConfirm';
import { removeLaboratory } from '../../../api/protocolAdmin';
import { Success } from '../../../components/Notifications/Notifications';

export const LaboratoriesContainer = (props) => {
    const { entryId, entryStatus, expandedAll } = props;
    const [content, setContent] = useState(null);
    const [laboratories, query, totalSize, isLoading, handleTableChange, setReload] = useLaboratories(null, entryId);
    const { t } = useTranslation();

    useEffect(() => {
        if (props.contentToReload?.contentName === "laboratory")
            setReload(true);

    }, [props.contentToReload]);

    const handleEdit = (id) => {
        setContent(<LaboratoryEditContainer
            entryId={entryId}
            id={id}
            onSubmit={handleSubmit}
            onCancel={handleClose}
        />);
    }

    const handleSubmit = (data) => {
        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (data && data.laboratories && data.laboratories.length > 0) {
                let laboratory = data.laboratories[0];
                laboratory.laboratoryDate = data.laboratoryDate;
                if (reason) {
                    laboratory.auditReason = reason;
                    laboratory.auditComments = comments;
                }
                await putLaboratoryResult(entryId, laboratory.id, laboratory);
                setContent(null);
                setReload(true);
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    const handleClose = () => {
        setContent(null);
    }

    const handleAuditTrail = (entityId) => {
        setContent(
            <AuditModal
                onClose={() => { setContent(null); }}
                title={t("auditTrail.audit")}
            >
                <AuditTrail entity="LaboratoryResult" entityId={entityId} />
            </AuditModal>
        )
    }

    const handleLaboratoryResultHistory = (laboratoryId) => {
        setContent(
            <ModalLaboHistory
                onClose={() => { setContent(null); }}
                title={t("medicalRecords.history.history")}
                laboratoryId={laboratoryId}
            >
            </ModalLaboHistory>
        )
    }

    //#region Delete item with audit
    const handleRemove = (id, type) => {
        setContent(
            <ModalConfirm
                onConfirm={() => confirmRemoveItem(id, type)}
                onCancel={() => setContent(null)}
                title={t("medicalRecordsNotifications.item_delete_title")}
                description={t("medicalRecordsNotifications.item_delete_description")}
                confirmStyle="danger"
            />);
    }
    const confirmRemoveItem = (id, type) => {
        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => _removeItemAudited(id, type, reason, comments)}
            />)
        }
        else {
            _removeItemAudited(id, type, null, null);
        }
    }
    const _removeItemAudited = async (id, type, reason, comments) => {
        try {
            setContent(<ModalSpinner isShowing={true} hide={null} />);
            if (type === "laboratoryResult")
                await removeLaboratoryResultAPI(entryId, id, reason?.code, comments);
            else
                await removeLaboratoryAPI(entryId, id, reason?.code, comments);
            setReload(true);
            setContent(null);
            Success("medicalRecordsNotifications.item_DeletedSuccessfully");
        }
        catch (error) {
            setContent(null);
            console.log(error);
            HandleApiError(error);
        }
    }
    //#endregion

    if (totalSize === 0)
        return null;

    return (
        <ExpandableContent title={t("medicalRecords.laboratory.title")} expandedAll={expandedAll} content="laboratory">
            {content}
            <Laboratory
                data={laboratories}
                entryId={entryId}
                withCard={false}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onEdit={handleEdit}
                onAuditTrail={handleAuditTrail}
                onLaboratoryResultHistory={handleLaboratoryResultHistory}
                onRemove={handleRemove}
                onRemoveResult={handleRemove}
            />
        </ExpandableContent>
    );
}

export const LaboratoryCreateContainer = (props) => {
    const { entryId, onSubmit, entryStatus, hide } = props;
    const [, , create,] = useLaboratory(entryId);
    const [content, setContent] = useState(null);

    const handleSubmit = async (data) => {

        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAudit
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (reason) {
                data.auditReason = reason;
                data.auditComments = comments;
            }
            await create(entryId, data);
            setContent(null);
            onSubmit("laboratory")
        }
        catch (error) {
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleCancel = () => {
        hide()
    }

    return content ?? <LaboratoryContainerFormik
        onSubmit={handleSubmit}
        onCancel={handleCancel}
    />
}

const LaboratoryEditContainer = (props) => {
    const { entryId, id, onSubmit, onCancel } = props;
    const [data, isLoading, , update] = useLaboratory(entryId, id);

    if (!data || isLoading)
        return null;

    return (
        <LaboratoryContainerFormik
            onSubmit={onSubmit}
            onCancel={onCancel}
            laboratory={data}
            edit={true}
        />
    );
}

const LaboratoryContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => (props.laboratory && { laboratories: [props.laboratory] }),

    validationSchema: getMedicalRecordsSchema().laboratories,

    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values);
    },

    displayName: 'LaboratoriesForm',

})(LaboratoriesForm));

LaboratoryContainerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    laboratory: PropTypes.object,
    edit: PropTypes.bool,
};