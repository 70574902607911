import PropTypes from 'prop-types';
import React from "react";
import { Button as BootstrapButton } from "react-bootstrap";

export const Button = (props) => {

    return (
        <>
            <BootstrapButton {...props}>
                {props.children}
            </BootstrapButton>
        </>
    );
}
Button.propTypes = {
    children: PropTypes.object.isRequired,
};
Button.defaultProps = {
};