import React from 'react';
import { useTranslation } from 'react-i18next';
import { dateFormatter } from '../Utils/Formatter';
import { EntryItemsTable } from './EntryItemsTable';

export const Vitals = (props) => {
    const { t } = useTranslation();

    const columns = [
        { dataField: 'created', text: t('commons.date'), formatter: function (cell) { return dateFormatter(cell, "DD/MM/YY") }, headerClasses: 'header-date' },
        { dataField: 'bloodPressure', text: t('medicalRecords.vitals.bloodPressure') },
        { dataField: 'heartRate', text: t('medicalRecords.vitals.heartRate') },
        { dataField: 'respiratoryRate', text: t('medicalRecords.vitals.respiratoryRate') }
    ];

    const expandedContent = (row) => (
        <div className="info">
            {row.temperatureC && <div><span className="patient-label">{t('medicalRecords.vitals.temperatureC')}:</span> <span className="patient-text">{row.temperatureC}</span></div>}
            {row.oxygenSaturation && <div><span className="patient-label">{t('medicalRecords.vitals.oxygenSaturation')}:</span> <span className="patient-text">{row.oxygenSaturation}</span></div>}
            {row.others && <div><span className="patient-label">{t('medicalRecords.vitals.others')}:</span> <span className="patient-text">{row.others}</span></div>}
            {row.notes && <div><span className="patient-label">{t('medicalRecords.vitals.notes')}:</span> <span className="patient-text">{row.notes}</span></div>}
        </div>
    )

    return (<EntryItemsTable columns={columns} {...props} expandContent={expandedContent} />);
}