import React, { useContext, useState } from 'react';
import { Alert } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import { WithAuth } from '../../../components/Authorization/WithAuthProtocol';
import Card from '../../../components/Cards/Card';
import { Add, Remove, View } from '../../../components/Icons/Icons';
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import { Success } from '../../../components/Notifications/Notifications';
import { Tooltip } from "../../../components/Utils/Tooltips";
import ProtocolContext from '../ProtocolContext/ProtocolContext';

import { removeExtraForm } from '../../../api/protocolsExtraForms';
import { RemoveModal } from '../Visits/RemoveVisit';
import NewExtraForm from './NewExtraForm';
import { useExtraForms } from './useExtraForms';

export const ExtraFormsContainer = ({ protocolId }) => {
    const { t } = useTranslation();
    const context = useContext(ProtocolContext);
    const [setData, data, , , isLoading, handleTableChange,] = useExtraForms(protocolId);

    const columns = [
        {
            dataField: 'extraFormType', text: t("protocols.extraForms.formType"),
            formatter: function (_cell, row) { return t(`protocols.extraForms.extraFormType.${row.extraFormType}`) }
        },
        { dataField: 'title', text: t("protocols.extraForms.name") },
        { dataField: 'type', text: t("protocols.extraForms.syncTo") },
        {
            dataField: 'view',
            text: t("commons.actions"),
            isDummyField: true,
            headerClasses: 'header-extraform-actions',
            classes: 'row-extraform-actions',
            formatter: function (_cell, row, rowIndex) {
                return (
                    <div key={rowIndex}>
                        <WithAuth protocolId={protocolId} requiredPermission={["Visits.Add"]}>
                            <Tooltip tooltip={t("protocols.extraForms.viewEdit")} id={"tooltipedit-" + row.id}>
                                <NavLink to={`/admin/protocols/${row.protocolId}/extraforms/${row.id}`} className="btn-table-actions" activeClassName="active">
                                    <View />
                                </NavLink>
                            </Tooltip>
                        </WithAuth>
                        {
                            !context.isDisabled &&
                            <WithAuth protocolId={protocolId} requiredPermission={["Visits.Add"]}>
                                <Tooltip tooltip={t("protocols.extraForms.delete")} id={"tooltipremove-" + row.id}>
                                    <button className="btn-remove btn-table-actions" onClick={() => handleRemove(row.id)}>
                                        <Remove />
                                    </button>
                                </Tooltip>
                            </WithAuth>
                        }
                    </div>
                )
            }
        }
    ];

    const [modal, setModal] = useState(null);
    const handleRemove = (formId) => {
        if (!formId) return;
        setModal(<RemoveModal
            id={formId}
            title="¿Está seguro?"
            onClose={() => { setModal(null); }}
            onConfirm={handleConfirmRemove} />)
    }
    const handleConfirmRemove = (formId) => {
        if (formId) {
            removeExtraForm(protocolId, formId)
                .then(() => {
                    let copiedData = JSON.parse(JSON.stringify(data));
                    setData(copiedData.filter(obj => obj.id !== formId));
                    Success("protocolNotifications.visit_Removed");
                    setModal(null);
                }).catch(error => {
                    console.error(error);
                    HandleApiError(error);
                    setModal(null);
                });
        }
        setModal(null);
    }

    const handleAddExtraForm = () => {
        setModal(<NewExtraForm
            protocolId={protocolId}
            hide={() => setModal(null)}
            isShowing={true} />);
    }

    return (
        <Card
            header={
                <div className="row">
                    <div className="col-md-2">
                        <WithAuth protocolId={protocolId} requiredPermission={["Visits.Add"]}>
                            <button onClick={handleAddExtraForm} className="btn btn-primary" disabled={context.isDisabled}>
                                <Add /> {t('protocols.visit.addVisitForm')}
                            </button>
                        </WithAuth>
                    </div>
                </div>
            }
            content={
                <div>
                    {modal}
                    <BootstrapTable
                        classes="table-spaced table-spaced-small"
                        wrapperClasses="table-responsive"
                        bordered={false}
                        hover
                        remote
                        keyField='id'
                        data={data}
                        columns={columns}
                        noDataIndication={() => <>{t("commons.noData")}</>}
                        loading={isLoading}
                        overlay={overlayFactory({ spinner: true })}
                        //pagination={paginationFactory({
                        //    page: query.pagination.page,
                        //    sizePerPage: query.pagination.sizePerPage,
                        //    totalSize: totalSize,
                        //    hideSizePerPage: true,
                        //})}

                        onTableChange={handleTableChange}
                    />
                </div>
            }
        />
    );
}