import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Card from '../Cards/Card';
import { IconSVG } from '../Icons/Icons';

const PatientWidget = () => {
    const { t } = useTranslation();
    return (
        <Card
            shadow={false}
            className="new-patient-widget"
            content={
                <>
                    <div className="center">
                        <IconSVG name="add-patient" />
                    </div>
                    <div className="center">
                        <NavLink to="/admin/database/create" className="btn btn-primary" activeClassName="active">
                            {t("dashboard.addPatient")}
                        </NavLink>
                    </div>
                </>
            }
        />

    )
}


export default PatientWidget;
