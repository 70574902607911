import { useEffect, useState } from 'react';
import { deleteCondition, getConditions, postCondition, putAdverseEvent } from '../../../api/medicalRecord';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { conditions } from '../../../variables/Enums';

export const useDiagnoses = (entryId) => {

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalSize, setTotalSize] = useState(0);
    const [query, setQuery] = useState({
        pagination: {
            page: 1,
            sizePerPage: 10,
            totalSize: 0
        }
    });
    const [reload, setReload] = useState(false);

    useEffect(() => {
        let isSubscribed = true
        const fetchData = async () => {
            try {
                setReload(false);
                setIsLoading(true);
                const paginatedItems = await getConditions(entryId, conditions.diagnosis, query);
                if (isSubscribed) {
                    setTotalSize(paginatedItems.data.count);
                    setData(paginatedItems.data.data);
                    setIsLoading(false);
                }

            } catch (error) {
                HandleApiError(error);
                setIsLoading(false);
                setTotalSize(0);
                setData([]);
            }
        }
        fetchData();

        return () => isSubscribed = false;
    }, [query, reload]);

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        var newQuery = JSON.parse(JSON.stringify(query));

        switch (type) {
            case 'pagination': {
                newQuery.pagination.page = page;
                newQuery.pagination.sizePerPage = sizePerPage;
                setQuery(newQuery);
                break;
            }
            case 'sort': {
                newQuery.sort.sortField = sortField;
                newQuery.sort.sortOrder = sortOrder;
                setQuery(newQuery);
                break;
            }
            default: break;
        }
    }

    const create = (entryId, data) => {
        return postCondition(entryId, data, conditions.diagnosis);
    }

    const remove = (entryId, id, reason, comments) => {
        return deleteCondition(entryId, id, reason, comments);
    }

    const update = (entryId, id, data) => {
        return putAdverseEvent(entryId, id, data);
    }

    return [data, query, totalSize, isLoading, handleTableChange, setReload, create, remove, update];
}