import React from "react";
import { ErrorMessage } from 'formik';
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import { FreezerStates, FreezerTypes, Freezer } from "../../../api/Freezer/Entities/Freezer";
import { CustomSelect } from "../../../components/CustomControls/CustomSelect";
import Card from "../../../components/Cards/Card";
import { FormErrorMessage } from "../../../components/Validations/FormValidationErrors";

export function FreezerForm({ values, handleSubmit, handleChange, handleBlur, t, onCancel, isSubmitting, setFieldValue, setFieldTouched }) {
    if (!values.freezer) {
        values.freezer = new Freezer()
    }
    const { freezer } = values;

    const isEdition = (freezer.Id !== null &&  freezer.Id !== "")
    return (
        <Card
            title={isEdition ? t("settings.freezer.form.edit") : t("settings.freezer.form.create")}
            ctFullWidth
            content={
                <form onSubmit={handleSubmit} autoComplete="off">
                    <FormGroup>
                        <ControlLabel>{t("commons.description")}</ControlLabel>
                        <FormControl type="text" name={'freezer.Description'} value={freezer.Description ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                        <ErrorMessage name={"freezer.Description"} component={FormErrorMessage} />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>{t("commons.location")}</ControlLabel>
                        <FormControl type="text" name={'freezer.Location'} value={freezer.Location ?? ""} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                        <ErrorMessage name={"freezer.Location"} component={FormErrorMessage} />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>{t("commons.type")}</ControlLabel>
                        <CustomSelect
                            value={freezer.Type}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            name={"freezer.Type"}
                            options={FreezerTypes.ToArray()}
                            getOptionLabel={(option) => t(`settings.freezer.types.${option.Name}`)}
                            getOptionValue={(option) => option.Value}
                        />
                    </FormGroup>

                    {isEdition && <FormGroup>
                        <ControlLabel>{t("commons.status")}</ControlLabel>
                        <CustomSelect
                            value={freezer.State}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            name={"freezer.State"}
                            options={FreezerStates.ToArray()}
                            getOptionLabel={(option) => t(`settings.freezer.states.${option.Name}`)}
                            getOptionValue={(option) => option.Value}
                        />
                    </FormGroup>}

                    <Grid fluid>
                        <Row>
                            <Col sm={1} smOffset={10}>
                                <button type="button" className="btn btn-info btn-right" onClick={onCancel} >{t("buttons.back")}</button>
                            </Col>
                            <Col sm={1}>
                                <button type="submit" className="btn btn-success btn-right" disabled={isSubmitting} >{t("buttons.save")}</button>
                            </Col>
                        </Row>
                    </Grid>
                </form>
            }
        />
    )
}