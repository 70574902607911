import { ErrorMessage, FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, ControlLabel, FormControl, FormGroup, Grid, Row, Panel, PanelGroup } from 'react-bootstrap';
import { getFamilyHistoryData } from '../../../api/admin';
import { conceptType, snomedExpressions } from '../../../variables/Enums';
import { CustomCheckbox } from "../../CustomControls/CustomCheckbox";
import { CustomSelect } from "../../CustomControls/CustomSelect";
import { NullableDatePickerCombo } from "../../CustomControls/DatePicker";
import { SnomedPicker } from '../../CustomControls/SnomedPicker';
import { Remove } from '../../Icons/Icons';
import { FormErrorMessage } from '../../Validations/FormValidationErrors';

export const FamilyHistoryForm = props => {

    const {
        values,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleSubmit,
        isSubmitting,
        t
    } = props;

    const [data, setData] = useState({
        relatives: []
    });
    useEffect(() => {
        const fetchData = () => {
            getFamilyHistoryData()
                .then(response => {
                    //console.log(response.data);
                    setData(response.data);
                }).catch(error => {
                    setData({});
                });
        }

        fetchData();
    }, []);

    const emptyResult = {
        name: '',
        relative: '',
        diagnosisYear: ''
    };

    if (!values.familyHistories) {
        values.familyHistories = [emptyResult];
    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <PanelGroup accordion id="multi-items" defaultActiveKey="multi-item-0">
                <FieldArray
                    name="familyHistories"
                    render={arrayHelper => (
                        <div>
                            {values.familyHistories && values.familyHistories.length > 0 && (
                                values.familyHistories.map((familyHistory, index) => (
                                    <Panel key={`multi-item-${index}`} eventKey={`multi-item-${index}`}>
                                        <Panel.Heading>
                                            <Panel.Title toggle>{familyHistory.name ? familyHistory.name.description : t("medicalRecords.familyHistory.title")}</Panel.Title>
                                        </Panel.Heading>
                                        <Panel.Body collapsible>
                                            <FormGroup key={index}>
                                                <Grid fluid>
                                                    <Row>
                                                        <Col sm={11}>
                                                            <ControlLabel>{t("medicalRecords.familyHistory.adverseEvent")}</ControlLabel>
                                                            <SnomedPicker
                                                                conceptType={conceptType.events}
                                                                autoFocus
                                                                defaultInputValue={familyHistory.name ? familyHistory.name.description : ''}
                                                                id={`familyHistories.${index}.name`}
                                                                value={familyHistory.name}
                                                                name={`familyHistories.${index}.name`}
                                                                placeholder={t("commons.search")}
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                conceptId={snomedExpressions.Hallazgos} />
                                                            <ErrorMessage name={`familyHistories.${index}.name`} component={FormErrorMessage} />
                                                        </Col>
                                                        <Col md={1}>
                                                            <button
                                                                className="btn-table-actions btn-remove"
                                                                style={{ verticalAlign: 'middle' }}
                                                                type="button"
                                                                onClick={() => arrayHelper.remove(index)}>
                                                                <Remove />
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={4} md={4}>
                                                            <ControlLabel>{t("medicalRecords.familyHistory.relative")}</ControlLabel>
                                                            <CustomSelect
                                                                value={familyHistory.relative}
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                name={`familyHistories.${index}.relative`}
                                                                placeholder={t("commons.select")}
                                                                options={data.relatives} />
                                                            <ErrorMessage name={`familyHistories.${index}.relative`} component={FormErrorMessage} />
                                                        </Col>
                                                        <Col sm={4}>
                                                            <ControlLabel>{t("medicalRecords.familyHistory.diagnosisDate")}</ControlLabel>
                                                            <NullableDatePickerCombo
                                                                nameDay={`familyHistories.${index}.diagnosisDay`}
                                                                nameMonth={`familyHistories.${index}.diagnosisMonth`}
                                                                nameYear={`familyHistories.${index}.diagnosisYear`}
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                valueDay={familyHistory.diagnosisDay}
                                                                valueMonth={familyHistory.diagnosisMonth}
                                                                valueYear={familyHistory.diagnosisYear}
                                                            />
                                                        </Col>
                                                        <Col sm={4} md={4}>
                                                            <ControlLabel>{t("medicalRecords.familyHistory.causeOfDeath")}</ControlLabel>
                                                            <CustomCheckbox
                                                                name={`familyHistories.${index}.causeOfDeath`}
                                                                isChecked={familyHistory.causeOfDeath || false}
                                                                onChange={handleChange} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <ControlLabel>{t("medicalRecords.familyHistory.notes")}</ControlLabel>
                                                            <FormControl componentClass="textarea" value={familyHistory.notes || ''} name={`familyHistories.${index}.notes`} onChange={handleChange} />
                                                        </Col>
                                                    </Row>
                                                </Grid>
                                            </FormGroup>
                                        </Panel.Body>
                                    </Panel>
                                ))
                            )}
                            {
                                (values.familyHistories.length === 0 || (values.familyHistories.length > 0 && !values.familyHistories[0].id)) &&
                                <Row style={{ marginTop: "20px" }}>
                                    <Col md={4}><button type="button" className="btn btn-default" onClick={() => arrayHelper.push(emptyResult)}>{t("buttons.add")}</button></Col>
                                    <Col md={8}></Col>
                                </Row>
                            }
                        </div>
                    )}
                />
            </PanelGroup>
            <button type="button" className="btn btn-default" onClick={props.onCancel} disabled={isSubmitting}>{t("buttons.cancel")}</button>
            {
                values.familyHistories && values.familyHistories.length > 0 &&
                <button type="submit" className="btn btn-success btn-right" disabled={isSubmitting}>{t("buttons.save")}</button>
            }

            <div className="clearfix"></div>
        </form>
    );
}
