import React from 'react'
import PropTypes from 'prop-types';
import './Slider.css'

export const Slider = (props) => {
    const { isOpen, children } = props;

    if (!isOpen)
        return (
            <div className={'side-drawer'}>
            </div>);

    return (
        <div className={'side-drawer open'}>
            {children}
        </div>
    );
}

Slider.propTypes = {
    isOpen: PropTypes.bool,
    children: PropTypes.object
};