import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types'
import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';

import { getRole, editRole } from "../../../api/security";
import { useRoleMasterData } from "./useRoles";
import { getRoleSchema } from "../../../components/Validations/FormValidationSchema";
import { RoleForm } from "../../../components/Authorization/Roles";
import { Success } from '../../../components/Notifications/Notifications';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';

export const EditRoleContainer = (props) => {
    let history = useHistory();
    const { match: { params } } = props;

    const [isRoleLoading, masterData] = useRoleMasterData();
    const [isLoading, setIsLoading] = useState(false);
    const [role, setRole] = useState(null);
    useEffect(() => {
        const fetchData = () => {
            setIsLoading(true);
            getRole(params.roleId)
                .then(response => {
                    var role = response.data;
                    //console.log("GET Role: ", role);
                    //role.claims = role.claims.map(x => ({ id: x, title: x }));
                    setRole(role);
                    setIsLoading(false);
                }).catch(error => {
                    HandleApiError(error);
                    setIsLoading(false);
                });
        }
        fetchData();
    }, [params.roleId]);

    const handleSubmit = (brief) => {
        history.push(`/admin/settings/roles`);
    }
    const handleCancel = () => {
        history.push(`/admin/settings/roles`);
    }

    if (isRoleLoading || isLoading)
        return null;

    return (
        <EditRoleContainerFormik
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            masterData={masterData}
            role={role}
        />
    );
}

const EditRoleContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => ({ role: props.role }),

    validationSchema: getRoleSchema(),

    handleSubmit: (values, formikBag) => {
        //console.log("Puting Role:", values.role);
        const role = {
            id: values.role.id,
            name: values.role.name,
            claims: values.role.claims
        }

        editRole(role.id, role)
            .then(res => {
                var role = res.data;
                formikBag.setSubmitting(false);
                Success("securityNotifications.role_Updated");
                formikBag.props.onSubmit(role);
            }).catch(error => {
                console.log(error);
                formikBag.setSubmitting(false);
                HandleApiError(error);
            });
    },

    displayName: 'RoleForm',

})(RoleForm));

EditRoleContainerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    masterData: PropTypes.object.isRequired,
};