import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, ControlLabel, FormControl, FormGroup, Grid, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { useTranslation } from 'react-i18next';
import Card from '../Cards/Card';
import { CustomSelect } from '../CustomControls/CustomSelect';
import { CustomSwitch } from '../CustomControls/CustomSwitch';
import { Edit, Remove } from '../Icons/Icons';
import { Tooltip } from "../Utils/Tooltips";
import { FormErrorMessage } from '../Validations/FormValidationErrors';
import { ConfirmRemoveRole } from './Modals';
import { Permissions } from './Permissions';
import { useAuthorization } from './useAuthorization';
import { WithAuth } from './WithAuth';

export const Roles = props => {
    const { t } = useTranslation();
    const { roles, onEdit, onRemove, isLoading } = props;
    const [modal, setModal] = useState(null);
    const [, , isAuthorized] = useAuthorization();

    const columns = [
        { dataField: 'name', text: t("security.roles.name"), headerStyle: { width: '250px' }, },
        { dataField: 'tenant', text: t("security.roles.tenant"), headerStyle: { width: '250px' }, },
        {
            dataField: 'view',
            text: t("protocols.practitioner.actions"),
            isDummyField: true,
            headerClasses: 'header-actions',
            classes: 'row-actions',
            formatter: function (_cell, row, rowIndex) {
                return (
                    <div key={rowIndex}>
                        <WithAuth requiredPermission={["Security.Edit"]}>
                            <button className="btn-table-actions" onClick={() => onEdit(row.id)}>
                                <Tooltip tooltip={t("security.edit")} id={"tooltipedit-" + row.id}>
                                    <span aria-hidden="true"><Edit /></span>
                                </Tooltip>
                            </button>
                        </WithAuth>
                        <WithAuth requiredPermission={["Security.Delete"]}>
                            <button className="btn-table-actions" onClick={() => showModal(row.id)}>
                                <Tooltip tooltip={t("security.remove")} id={"tooltipremove-" + row.id}>
                                    <span aria-hidden="true"><Remove /></span>
                                </Tooltip>
                            </button>
                        </WithAuth>
                    </div>
                )
            }
        }
    ];

    const showModal = (roleId) => {
        setModal(
            <ConfirmRemoveRole
                onClose={() => setModal(null)}
                onConfirm={(confirmRemove)}
                roleId={roleId}
            />
        );
    }
    const confirmRemove = (roleId) => {
        setModal(null);
        onRemove(roleId);
    }

    const expandedContent = (row) => {
        const modules = [
            { id: 0, code: "Tenant", title: t("security.permissions.modules.Tenant") },
            { id: 1, code: "Agenda", title: t("security.permissions.modules.Agenda") },
            { id: 2, code: "Database", title: t("security.permissions.modules.Database") },
            { id: 3, code: "MedicalRecords", title: t("security.permissions.modules.MedicalRecords") },
            { id: 4, code: "Protocols", title: t("security.permissions.modules.Protocols") },
            { id: 5, code: "Security", title: t("security.permissions.modules.Security") },
            { id: 6, code: "Users", title: t("security.permissions.modules.Users") },
            { id: 7, code: "Settings", title: t("security.permissions.modules.Settings") },
        ];

        const claims = row.claims;
        const yes = <CustomSwitch value={true} disabled={true} />;//<Enabled className="status-active" />;
        const no = <CustomSwitch value={false} disabled={true} />; //<Disabled className="status-inactive" />;
        return (
            <table className="table table-hover table-spaced-small">
                <thead>
                    <tr>
                        <th>{t('security.permissions.module')}</th>
                        <th>{t('security.permissions.view')}</th>
                        <th>{t('security.permissions.list')}</th>
                        <th>{t('security.permissions.create')}</th>
                        <th>{t('security.permissions.edit')}</th>
                        <th>{t('security.permissions.delete')}</th>
                    </tr>
                </thead>
                <tbody>
                    {modules.map((x, idx) => {
                        if (!isAuthorized(["Tenant.View"]) && x.code === "Tenant")
                            return "";

                        return (<tr key={`roleClaims-${idx}`}>
                            <td>{x.title}</td>
                            <td>{claims.filter(c => c == `${x.code}.View`).length > 0 ? yes : no}</td>
                            <td>{claims.filter(c => c == `${x.code}.List`).length > 0 ? yes : no}</td>
                            <td>{claims.filter(c => c == `${x.code}.Create`).length > 0 ? yes : no}</td>
                            <td>{claims.filter(c => c == `${x.code}.Edit`).length > 0 ? yes : no}</td>
                            <td>{claims.filter(c => c == `${x.code}.Delete`).length > 0 ? yes : no}</td>
                        </tr>
                        )
                    })}
                </tbody>
            </table>
        );
    }

    const expandRow = {
        renderer: expandedContent,
        onlyOneExpanding: true,
    };

    return (
        <>
            {modal}
            <BootstrapTable
                bordered={false}
                hover
                remote
                keyField='id'
                classes="table-spaced table-spaced-small"
                wrapperClasses="table-responsive"

                data={roles}
                columns={columns}
                noDataIndication={() => <>{t("commons.noData")}</>}
                loading={isLoading}  //only loading is true, react-bootstrap-table will render overlay
                overlay={overlayFactory({ spinner: true })}
                expandRow={expandRow}
            />
        </>
    );
}

Roles.propTypes = {
    roles: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
};

export const RoleForm = props => {

    const {
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        masterData,
        tenants,
    } = props;

    const { t } = useTranslation();

    if (!values.role) {
        values.role = {
            name: '',
            claims: [],
            tenant: {}
        };
    }

    return (
        <Card
            title={t('security.roles.roleData')}
            content={
                <form onSubmit={handleSubmit} autoComplete="off">
                    <FormGroup>
                        <Grid fluid>
                            {
                                tenants && tenants.length > 0 &&
                                <Row>
                                    <Col sm={4}>
                                        <ControlLabel>{t('security.users.tenant')}</ControlLabel>
                                        <CustomSelect
                                            value={values.role.tenant}
                                            name={`role.tenant`}
                                            placeholder={t("commons.select")}
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            options={tenants}
                                            getOptionLabel={x => x.tenantName}
                                            getOptionValue={x => x.tenantId}
                                        />
                                        <ErrorMessage name={`tenant`} component={FormErrorMessage} />
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col sm={4}>
                                    <ControlLabel>{t("security.roles.name")}</ControlLabel>
                                    <FormControl type="text" name={`role.name`} value={values.role.name} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                    <ErrorMessage name={`role.name`} component={FormErrorMessage} />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <ControlLabel>{t("security.roles.permissions")}</ControlLabel>
                                    <Permissions
                                        claims={values.role.claims || []}
                                        name={`role.claims`}
                                        onChange={setFieldValue}
                                        modules={masterData.modules || []}
                                    />
                                    <ErrorMessage name={`role.claims`} component={FormErrorMessage} />
                                </Col>
                            </Row>
                        </Grid>
                    </FormGroup>
                    <Grid fluid>
                        <Row>
                            <Col sm={1} smOffset={10}>
                                <button type="button" className="btn btn-info btn-right" onClick={props.onCancel} >{t("buttons.cancel")}</button>
                            </Col>
                            <Col sm={1} >
                                <button type="submit" className="btn btn-success btn-right" disabled={props.isSubmitting} >{t("buttons.save")}</button>
                            </Col>
                        </Row>
                    </Grid>
                </form >
            }
        />
    );
}
