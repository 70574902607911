import React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Card from '../Cards/Card';
import { Back, Save } from '../Icons/Icons';
import { AddressData } from '../Person/AddressData';
import { AddressDataForm } from '../Person/AddressDataForm';
import { ContactData } from '../Person/ContactData';
import { ContactDataForm } from '../Person/ContactDataForm';
import { PersonalData } from '../Person/PersonalData';
import { PersonalDataForm } from '../Person/PersonalDataForm';
import { ProfessionalData } from '../Person/ProfessionalData';
import { ProfessionalDataForm } from '../Person/ProfessionalDataForm';
import { UserSignForm } from '../Person/UserSign';
import { Actions } from './Actions';
import { DigitalSignDataForm } from '../Person/DigitalSignDataForm';

export const UserProfile = props => {
    const { user } = props;
    let history = useHistory();

    return (
        <Grid fluid>
            <Row className="display-flex">
                <Col md={6}>
                    <PersonalData data={user} />
                </Col>
                <Col md={6}>
                    <ProfessionalData data={user} />
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={2}>
                    <Actions
                        userId={user?.userId}
                        onEdit={() => history.push(`/admin/users/${user?.userId}/edit`)}
                        onConfig={() => history.push(`/admin/users/${user?.userId}/config`)}
                        onRedirectTo={() => history.goBack()}
                    />
                </Col>
                <Col md={12} lg={10}>
                    <AddressData data={user} />
                    <ContactData data={user} />
                </Col>
            </Row>
        </Grid>
    );
}

export const UserProfileForm = props => {
    const {
        handleSubmit,
        isSubmitting,
        hideCancel,
        t,
    } = props;

    let navigation = useHistory();
    const settingsStore = useSelector(state => state.settings);

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <PersonalDataForm {...props} />
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={12}>
                        <AddressDataForm {...props} />
                        <ContactDataForm {...props} />
                        <ProfessionalDataForm {...props} />
                        {settingsStore.settings.digitalSignEnabled && <DigitalSignDataForm {...props} />}
                        <UserSignForm {...props} />
                    </Col>
                    <Col md={12} lg={12}>
                        <button className="btn btn-success btn-fill" type="submit" disabled={isSubmitting} style={{ float: "right", marginRight: '15px' }}>
                            <Save /> {t("buttons.save")}
                        </button>
                        <button className="btn btn-default btn-fill" type="button" disabled={isSubmitting || hideCancel} onClick={() => navigation.goBack()} style={{ float: "right", marginRight: '15px' }}>
                            <Back /> {t('buttons.back')}
                        </button>
                    </Col>
                </Row>
            </Grid>
        </form>
    );
};