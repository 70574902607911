import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { AppointmentForm } from '../../components/Agenda/AppointmentForm';
import { getAgendaAppointmentSchema } from '../../components/Validations/FormValidationSchema';

export const AddAppointmentContainer = (props) => {
    return (
        <AddAppointmentFormik
            onSubmit={props.onSubmit}
            onClose={props.onClose}
            masterdata={props.masterdata}
            formType="new"
        />
    );
}
AddAppointmentContainer.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

const AddAppointmentFormik = withFormik({
    validationSchema: getAgendaAppointmentSchema(),
    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values);
    },
    displayName: 'AddAppointment',
})(AppointmentForm);

AddAppointmentFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};