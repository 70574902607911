import React from 'react';
import { useTranslation } from 'react-i18next';
import { Chart } from '../Charts/Chart';
import { Warning } from '../Icons/Icons';
import { dateFormatter } from '../Utils/Dates';
import { EntryItemsTable } from './EntryItemsTable';

export const AnthropometricsHistory = (props) => {
    const { t } = useTranslation();

    const columns = [
        { dataField: 'created', text: t('commons.date'), formatter: function (cell) { return dateFormatter(cell, "DD/MM/YY") }, headerClasses: 'header-date' },
        { dataField: 'weight', text: t('medicalRecords.anthropometrics.weight') },
        { dataField: 'height', text: t('medicalRecords.anthropometrics.height') },
        {
            dataField: 'bmi', text: t('medicalRecords.anthropometrics.bmi'), formatter: function (_cell, row) {
                var bmiColor = "#20ad9b"; //Normopeso (18.5-24.9 kg/m2 ) 
                var showWarning = false;
                if (row.bmi < 18.5) {
                    showWarning = true;
                    bmiColor = "#ca6e6e";  //Bajo peso (<18.5 kg/m2)
                } else if (row.bmi >= 25 && row.bmi < 30) {
                    showWarning = true;
                    bmiColor = "#ffa534"; //Sobrepeso (25-29.9 kg/m2 ) 
                } else if (row.bmi >= 30) {
                    bmiColor = "#ca6e6e"; //Obesidad
                    showWarning = true;
                }

                return <div style={{ color: bmiColor }}>{row.bmi} {showWarning && <Warning />}</div>;
            }
        },
        { dataField: 'waist', text: t('medicalRecords.anthropometrics.waist') },
        { dataField: 'sagittal', text: t('medicalRecords.anthropometrics.sagittal') },
        { dataField: 'neck', text: t('medicalRecords.anthropometrics.neck') }
    ];

    return (<EntryItemsTable
        {...props}
        withCard={false}
        columns={columns}
    />);
}

export const AnthropometricsHistoryChart = ({ data, type }) => {
    const { t } = useTranslation();
    if (!data || data.length === 0)
        return null;

    const options = {
        responsive: true,
        plugins: {
            datalabels: {
                color: '#247dbd',
                align: 'top',
            },
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: t("medicalRecords.anthropometrics.history"),
            },
        },
    };

    const chartData = {
        labels: data.sort(function (a, b) {
            return new Date(a.created) - new Date(b.created);
        }).map(x => dateFormatter(x.created)),
        datasets: [
            {
                label: t("medicalRecords.anthropometrics.weight"),
                data: data.map(x => x.weight),
                borderColor: 'rgb(218, 220, 222)',
                backgroundColor: 'rgba(218, 220, 222, 0.39)',
            },
            //{
            //    label: t("medicalRecords.anthropometrics.height"),
            //    data: data.map(x => x.height),
            //    borderColor: 'rgb(36, 125, 189)',
            //    backgroundColor: 'rgba(36, 125, 189, 0.29)',
            //},
            {
                label: t("medicalRecords.anthropometrics.bmi"),
                data: data.map(x => x.bmi),
                borderColor: 'rgb(28, 151, 136)',
                backgroundColor: 'rgba(28, 151, 136, 0.29)',
            },
            {
                label: t("medicalRecords.anthropometrics.waist"),
                data: data.map(x => x.waist),
                borderColor: 'rgb(187, 44, 13)',
                backgroundColor: 'rgba(187, 44, 13, 0.29)',
            },
            {
                label: t("medicalRecords.anthropometrics.sagittal"),
                data: data.map(x => x.sagittal),
                borderColor: 'rgb(156, 187, 13)',
                backgroundColor: 'rgba(156, 187, 13, 0.29)',
            },
            {
                label: t("medicalRecords.anthropometrics.neck"),
                data: data.map(x => x.neck),
                borderColor: 'rgb(160, 13, 187)',
                backgroundColor: 'rgba(160, 13, 187, 0.29)',
            },
        ],
    };

    return (<Chart options={options} data={chartData} type={type} />);
}