import React from "react";
import PropTypes from "prop-types";
import NumericInput from 'react-numeric-input';

export const CustomInput = (props) => {

    const { name, mobile, value } = props;

    const handleChange = (value) => {
        //console.log(value)
        props.onChange(name, value);
    };

    const handleBlur = () => {
        if (props.onBlur)
            props.onBlur(props.name, true);
    };

    return (<NumericInput
        name={name}
        mobile={mobile}
        className="form-control"
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        min={props.min}
        max={props.max}
    />);
}

CustomInput.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
};
CustomInput.defaultProps = {
    mobile: true,
    min: 0,
    max: 100
};
