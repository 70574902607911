import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card } from '../../components/Cards/Card';
import { IconSVG } from '../Icons/Icons';
import { WithAuth } from '../Authorization/WithAuth';
import { useAuthorization } from '../Authorization/useAuthorization';

export const Actions = (props) => {
    const { t } = useTranslation();
    const { userId, onConfig, onRedirectTo, onEdit, onExportToPDF } = props;
    const [, user, hasPermission] = useAuthorization();

    var redirectTo = null;
    if (onRedirectTo) {
        redirectTo = (
            <button className={`btn btn-default btn-fill btn-full`} onClick={onRedirectTo}>
                <IconSVG name="back" /> {t('buttons.back')}
            </button>
        );
    }

    var configure = null;
    if (onConfig) {
        configure = (
            <WithAuth requiredPermission={["Users.Edit"]}>
                <button className={`btn btn-primary btn-fill btn-full`} onClick={onConfig}>
                    <IconSVG name="settings" fill="#fff" /> {t('buttons.config')}
                </button>
            </WithAuth>
        );
    }
    var edit = null;
    if (onEdit) {
        if (user?.sub === userId || hasPermission(["Users.Edit"]))
            edit = (
                <button className={`btn btn-primary btn-fill btn-full`} onClick={onEdit}>
                    <IconSVG name="edit" /> {t('buttons.edit')}
                </button>
            );
    }

    var exportToPDF = null;
    if (onExportToPDF) {
        exportToPDF = (
            <button className={`btn btn-success btn-fill btn-full`} onClick={onExportToPDF}>
                <IconSVG name="export" /> {t('buttons.export')}
            </button>
        );
    }

    return (
        <Card
            title={t('medicalRecords.actions')}
            className="card-actions"
            content={
                <>
                    {edit}
                    {configure}
                    {exportToPDF}
                    {redirectTo}
                </>
            }
        />
    );
}

Actions.propTypes = {
    onConfig: PropTypes.func,
    onRedirectTo: PropTypes.func,
    onEdit: PropTypes.func,
    onExportToPDF: PropTypes.func,
};
