import PropTypes from 'prop-types'
import React from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { useTranslation } from 'react-i18next'

export const ConfirmRemove = (props) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <SweetAlert
                warning
                title={t("commons.confirmRemove")}
                onConfirm={() => props.onConfirm(props.id)}
                onCancel={() => { props.onClose() }}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="info"
                confirmBtnText={t("buttons.yes")}
                cancelBtnText={t("buttons.no")}
                showCancel
            >
            </SweetAlert>
        </React.Fragment>
    );
}

ConfirmRemove.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired
};