import React from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { withFormik } from 'formik';
import { getDeviceSchema } from "../../../components/Validations/FormValidationSchema";
import { ErrorMessage, Success } from "../../../components/Notifications/Notifications";
import DeviceService from "../../../api/Freezer/DeviceService";
import { DeviceForm } from "./DeviceForm";

export default function CreateIotDeviceContainer() {
    let history = useHistory();

    const handleSubmit = () => {
        history.push(`/admin/settings/devices`);
    }
    const handleCancel = () => {
        history.push(`/admin/settings/devices`);
    }

    return (
        <CreateFreezerContainerFormik
            onSubmit={handleSubmit}
            onCancel={handleCancel} />
    )
}

const CreateFreezerContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => ({}),
    validationSchema: getDeviceSchema(),
    handleSubmit: async (values, formikBag) => {
        try {
            const result = await DeviceService.Create(values.device)
            if (result.Succeeded) {
                Success("commons.successSave")
                formikBag.props.onSubmit();
            } else {
                formikBag.props.onSubmit(false);
                ErrorMessage("errors.serverError")
            }
        } catch (err) {
            ErrorMessage("errors.serverError")
        } finally {
            formikBag.setSubmitting(false);

        }
    },
    displayName: 'DeviceForm',
})(DeviceForm))