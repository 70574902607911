import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { withTranslation } from 'react-i18next';
import { useHistory, useLocation } from "react-router-dom";
import { createPatient } from "../../api/database";
import { PatientForm } from "../../components/Database/Patient";
import { HandleApiError } from '../../components/Notifications/APIErrorHandler';
import { Success } from '../../components/Notifications/Notifications';
import { getQueryParam } from '../../components/Utils/Commons';
import { getPatientSchema } from "../../components/Validations/FormValidationSchema";
import { useUsers } from "../Users/Hooks";
import { useMasterData } from "./Hooks";


export const CreatePatientContainer = () => {
    let history = useHistory();
    let location = useLocation();
    const [practitioners, isLoadingPractitioners] = useUsers();
    const [masterData, isLoadingMasterdata] = useMasterData();

    if (isLoadingPractitioners || isLoadingMasterdata) return null;

    const handlePostSubmit = (mrn) => {

        const redirectTo = getQueryParam(location.search, "redirectTo");
        if (mrn && redirectTo === "agenda")
            history.push(`/admin/agenda?medicalRecordNumber=${mrn}&appointment=true`)
        else
            history.push(`/admin/database`)
    }

    return (
        <CreatePatientFormik
            onSubmit={handlePostSubmit}
            onCancel={() => history.push(`/admin/database`)}
            form="new"
            masterData={masterData}
            practitioners={practitioners}
        />
    );
}

const CreatePatientFormik = withTranslation()(withFormik({

    enableReinitialize: true,
    mapPropsToValues: () => ({
        given: '',
        familyName: '',
        identificationType: '',
        identificationNumber: '',
        birthDate: '',
        gender: '',
        address: {
            fullAddress: '',
        },
        telecom: {
            cellPhone: '',
            email: ''
        },
        relatedContacts: [],
        medicalInsuranceInfo: {
            medicalInsuranceNumber: '',
            medicalInsurance: '',
        },
    }),

    validationSchema: getPatientSchema(),

    handleSubmit: async (patient, formikBag) => {

        var patientCopy = {
            ...patient,
            bloodGroup: patient.bloodGroup != null ? patient.bloodGroup.title : "",
            bloodFactor: patient.bloodFactor != null ? patient.bloodFactor.title : ""
        }

        try {
            var createdPatient = await createPatient(patientCopy);
            formikBag.resetForm();
            formikBag.setSubmitting(false);
            Success("databaseNotifications.Database_PatientCreated")
            formikBag.props.onSubmit && formikBag.props.onSubmit(createdPatient.data.medicalRecordNumber);
        }
        catch (error) {
            console.log(error);
            formikBag.setSubmitting(false);
            HandleApiError(error);
        }
    },

    displayName: 'PatientForm',

})(PatientForm));

CreatePatientFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    masterData: PropTypes.object.isRequired,
    practitioners: PropTypes.array.isRequired
};