import { ErrorMessage, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, ControlLabel, FormControl, Grid, Label, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useTranslation } from 'react-i18next';
import { AuditTrail } from '../../containers/Admin/AuditTrail';
import { patientInProtocolStatus } from "../../variables/Enums";
import { AuditModal } from '../AuditTrail/AuditModal';
import { PatientPicker } from "../CustomControls/PatientPicker";
import { Audit } from '../Icons/Icons';
import { Tooltip } from "../Utils/Tooltips";
import { FormErrorMessage } from '../Validations/FormValidationErrors';

export const Patients = props => {
    const { t } = useTranslation();
    const { patients, query, totalSize, onTableChange } = props;

    const columns = [
        { dataField: 'numberInProtocol', text: t("protocols.patient.nro"), sort: true },
        { dataField: 'fullName', text: t("protocols.patient.fullName"), sort: true },
        {
            dataField: 'status', text: t("protocols.brief.status"), formatter: function (cell, row, rowIndex, formatExtraData) {
                return (<PatientStatusInProtocol key={`statusrow-${rowIndex}`} status={cell} />);
            },
        },
        {
            dataField: 'view',
            text: t("protocols.practitioner.actions"),
            isDummyField: true,
            headerClasses: 'header-protocol-actions',
            classes: 'row-protocol-actions',
            formatter: function (_cell, row, rowIndex) {
                return (
                    <div key={rowIndex}>
                        <button className="btn-table-actions" onClick={() => handleAuditTrail(row.medicalRecordNumber)}>
                            <Tooltip tooltip={t("protocols.practitioner.audit")} id={"tooltipaudit-" + row.id}>
                                <span aria-hidden="true"><Audit /></span>
                            </Tooltip>
                        </button>
                    </div>
                )
            }
        }
    ];

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        onTableChange(type, { page, sizePerPage, sortField, sortOrder });
    }

    const [modal, setModal] = useState(null);
    const handleAuditTrail = (medicalRecordNumber) => {
        setModal(
            <AuditModal
                onClose={() => { setModal(null); }}
                title={t("auditTrail.audit")}
            >
                <AuditTrail entity="ProtocolPatient" entityId={medicalRecordNumber} />
            </AuditModal>
        )
    }

    return (
        <>
            {modal}
            <BootstrapTable
                classes="table-spaced table-spaced-small"
                wrapperClasses="table-responsive"
                bordered={false}
                hover
                remote
                keyField='medicalRecordNumber'
                data={patients}
                columns={columns}
                pagination={paginationFactory({
                    page: query.pagination.page,
                    sizePerPage: query.pagination.sizePerPage,
                    totalSize: totalSize,
                    hideSizePerPage: true
                })}
                onTableChange={handleTableChange}
                noDataIndication={() => <>{t("commons.noData")}</>}
            />
        </>
    );
}

Patients.propTypes = {
    patients: PropTypes.array.isRequired,
    query: PropTypes.object.isRequired,
    totalSize: PropTypes.number.isRequired,
    onTableChange: PropTypes.func.isRequired,
};

export const PatientsForm = props => {

    const {
        values,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
        handleChange,
        handleBlur,
        t
    } = props;

    const empty = { patient: null, numberInProtocol: '' };
    if (!values.patients) {
        values.patients = [];
        values.patients.push(empty);
    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <FieldArray
                name="patients"
                render={arrayHelper => (
                    <Grid fluid>
                        <Row>
                            <Col md={2}><ControlLabel>{t("protocols.patient.nro")}</ControlLabel></Col>
                            <Col md={8}><ControlLabel>{t("protocols.patient.patient")}</ControlLabel></Col>
                            {/*<Col md={1}><ControlLabel>{t("protocols.patient.actions")}</ControlLabel></Col>*/}
                        </Row>
                        {values.patients && values.patients.length > 0 ? (
                            values.patients.map((patient, index) => (
                                <Row key={"patients" + index}>
                                    <Col md={2}>
                                        <FormControl type="text" name={`patients.${index}.numberInProtocol`} value={patient.numberInProtocol} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                        <ErrorMessage name={`patients.${index}.numberInProtocol`} component={FormErrorMessage} />
                                    </Col>
                                    <Col md={8}>
                                        <PatientPicker
                                            id={`patients.${index}.patient`}
                                            value={patient.patient}
                                            name={`patients.${index}.patient`}
                                            multiple={false}
                                            placeholder={t("filters.patient")}
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            t={t} />
                                        <ErrorMessage name={`patients.${index}.patient`} component={FormErrorMessage} />
                                    </Col>
                                    {/*<Col md={1}>
                                                <button
                                                    type="button"
                                                    className="btn-remove"
                                                    onClick={() => arrayHelper.remove(index)} >
                                                    <Remove /> 
                                                </button>
                                            </Col>*/}
                                </Row>
                            ))
                        ) : ""}

                        {/*<button type="button" className="btn btn-default" onClick={() => arrayHelper.push(empty)}>{t("buttons.add")}</button>*/}
                    </Grid>
                )}
            />
            <Grid fluid>
                <Row>
                    <Col sm={12}>
                        <button type="submit" className="btn btn-success btn-right" disabled={props.isSubmitting}>{t("buttons.save")}</button>
                        <button type="button" className="btn btn-default btn-right" onClick={props.onCancel} style={{ marginRight: '20px' }}>{t("buttons.cancel")}</button>
                    </Col>
                </Row>
            </Grid>
        </form>
    );
}

export const PatientStatusInProtocol = ({ status }) => {
    const { t } = useTranslation();
    let content = '';
    switch (status) {
        case patientInProtocolStatus.Screening:
            content = (<Label bsStyle="info">{t('protocols.patient.statuses.screening')}</Label>); break;
        case patientInProtocolStatus.Random:
            content = (<Label bsStyle="success">{t('protocols.patient.statuses.random')}</Label>); break;
        case patientInProtocolStatus.Treatment:
            content = (<Label bsStyle="success">{t('protocols.patient.statuses.treatment')}</Label>); break;
        case patientInProtocolStatus.FollowUp:
            content = (<Label bsStyle="success">{t('protocols.patient.statuses.followUp')}</Label>); break;
        case patientInProtocolStatus.Failed:
            content = (<Label bsStyle="danger">{t('protocols.patient.statuses.failed')}</Label>); break;
        case patientInProtocolStatus.LostToFollowUp:
            content = (<Label bsStyle="danger">{t('protocols.patient.statuses.lostToFollowUp')}</Label>); break;
        case patientInProtocolStatus.WithoutDrugs:
            content = (<Label bsStyle="info">{t('protocols.patient.statuses.withoutDrugs')}</Label>); break;
        case patientInProtocolStatus.Discontinuation:
            content = (<Label bsStyle="danger">{t('protocols.patient.statuses.discontinuation')}</Label>); break;
        case patientInProtocolStatus.EndParticipationInProtocol:
            content = (<Label bsStyle="info">{t('protocols.patient.statuses.endParticipationInProtocol')}</Label>); break;
        default:
            content = "";
    }

    return <span className="patient-status">{content}</span>
}

PatientStatusInProtocol.propTypes = {
    status: PropTypes.number.isRequired,
};

export const PatientNumberForm = props => {

    const {
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        t
    } = props;

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <Grid fluid>
                <Row>
                    <Col md={4}><ControlLabel>{t("protocols.patient.nro")}</ControlLabel></Col>
                    <Col md={8}><ControlLabel>{t("protocols.patient.patient")}</ControlLabel></Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormControl type="text" name={`updatedNumberInProtocol`} value={values.updatedNumberInProtocol ?? values.numberInProtocol} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                        <ErrorMessage name={`updatedNumberInProtocol`} component={FormErrorMessage} />
                    </Col>
                    <Col md={8}>
                        {values.fullName}
                    </Col>
                </Row>
            </Grid>
            <Grid fluid>
                <Row>
                    <Col sm={12}>
                        <button type="submit" className="btn btn-success btn-right" disabled={props.isSubmitting}>{t("buttons.save")}</button>
                        <button type="button" className="btn btn-default btn-right" onClick={props.onCancel} style={{ marginRight: '20px' }}>{t("buttons.cancel")}</button>
                    </Col>
                </Row>
            </Grid>
        </form>
    );
}