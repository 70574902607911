import React from "react";
import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types';
import * as FileSaver from 'file-saver';

import { Success } from "../Notifications/Notifications"
import { HandleApiError } from "../Notifications/APIErrorHandler"

import { withTranslation } from 'react-i18next';
import { IconSVG } from "../Icons/Icons";
import { Tooltip } from "../Utils/Tooltips";

class ExportToExcel extends React.Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    async onClick() {

        // Obtengo datos a exportar
        this.props.exportToExcel(this.props.filters).then(response => {

            const data = new Blob([response.data], { type: response.headers['content-type'] });

            var disposition = response.headers["content-disposition"];
            var filename = "Turnos.xslx";
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }

            FileSaver.saveAs(data, filename);

            Success("commons.exported", this.props.t)
        }).catch(error => {
            HandleApiError(error, this.props.t);
        });
    }

    render() {
        const { t } = this.props;

        return (
            <Tooltip id="agenda-export" tooltip={t('buttons.export')}>
                <Button bsStyle="success" onClick={() => this.onClick()} style={{ float: 'right' }}><IconSVG name="export" /></Button>
            </Tooltip>
        );
    }
}
export default withTranslation()(ExportToExcel);

ExportToExcel.propTypes = {
    filters: PropTypes.object.isRequired,
    exportToExcel: PropTypes.func.isRequired
};
