import moment from 'moment';

export const getOffset = (prevDate, prevOffset, offset, offsetType) => {
    switch (offsetType) {
        case 0: //"days":
            prevDate.setDate(prevDate.getDate() + (Math.abs(prevOffset ?? 0) - Math.abs(offset ?? 0)));
            break;
        case 1: //"weeks":
            prevDate.setDate(prevDate.getDate() + (Math.abs(prevOffset ?? 0) - Math.abs(offset ?? 0)) * 7);
            break;
        case 2: // "months":
            prevDate.setMonth(prevDate.getMonth() + Math.abs(offset ?? 0));
            break;
        default:
            break;
    }

    return prevDate;
}

export const getOffsetFromRandom = (date, offset, offsetType) => {
    switch (offsetType) {
        case 0: //"days":
            date.setDate(date.getDate() + Math.abs(offset ?? 0));
            break;
        case 1: //"weeks":
            date.setDate(date.getDate() + (Math.abs(offset ?? 0) * 7));
            break;
        case 2: // "months":
            date.setMonth(date.getMonth() + Math.abs(offset ?? 0));
            break;
        default:
            break;
    }

    return date;
}

export const isLowerThanToday = (date) => {
    return moment(date).isBefore(moment());
}
export const isGreatherThanToday = (date) => {
    return moment(date).isAfter(moment());
}

export const isBetween = (date, dateFrom, dateTo) => {
    return moment(date).isBetween(dateFrom, dateTo, "minutes", '[]');
}

export const addDays = (date, daysToAdd) => {
    return moment(date).add(daysToAdd, 'days');
}

export const dateFormatter = (dateTime, format) => {
    if (!format)
        format = 'DD/MM/YYYY';

    if (dateTime)
        return moment(dateTime).format(format);

    return null;
}