import React from "react";
import { Spinner } from "../Utils/Loaders";

export default function Loader({ children, isLoading, loadingText }) {
    if (!isLoading)
        return children;
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Spinner />
            {loadingText && <strong style={{ fontSize: '1.5rem' }}>{loadingText}</strong>}
        </div>)
}