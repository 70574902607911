import React from "react";

export const CustomRadio = (props) => {
  const { number, label, option, name, ...rest } = props;


  const handleClick = (event) => {
    if (props.onChange) {
      if (props.checked)
        props.onChange(props.name, null);
      else
        props.onChange(props.name, event.target.value);
    }
  }

  return (
    <div className="radio">
      <input
        id={number}
        name={name}
        type="radio"
        value={option}
        onClick={handleClick}
        {...rest}
      />
      <label htmlFor={number}>{label}</label>
    </div>
  );
}
