import moment from 'moment';
//import 'moment/locale/es';
//import 'moment/locale/pt-br';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const DateTimeWidget = () => {

    const [today, setDate] = useState(new Date());
    const auth = useSelector(state => state.authentication);

    useEffect(() => {
        const timer = setInterval(() => { // Creates an interval which will update the current data every minute
            // This will trigger a rerender every component that uses the useDate hook.
            setDate(new Date());
        }, 60 * 1000);
        return () => {
            clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
        }
    }, []);

    function getLocaleDate() {
        if (auth && auth.user && auth.user.language)
            return moment(today).locale(auth.user.language).format("dddd, D MMMM, YYYY");
        return moment(today).locale("es").format("dddd, D MMMM, YYYY");
    }

    return (
        <div className="datetime-widget">
            <div className="date">{getLocaleDate()}</div>
            <div className="time">{moment(today).format("HH:mm")}</div>
        </div>
    );
}


export default DateTimeWidget;
