import React from 'react';
import { Col, FormControl, FormGroup, Grid, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Card from '../Cards/Card';
import { CustomSelect } from '../CustomControls/CustomSelect';
import { Actions } from './Actions';
import { PersonalData } from '../Person/PersonalData';
import { WithAuth } from '../Authorization/WithAuth';

export const UserSettingsForm = props => {
    const {
        isSubmitting,
        onChangeRole,
        onEnableUser,
        onDisableUser,
        onResendEmail,
        onNewPassword,
        roles,
        values,
        setFieldValue,
        setFieldTouched,
        handleChange,
        t,
    } = props;

    let history = useHistory();

    return (
        <Grid fluid>
            <Row className="display-flex">
                <Col md={6}>
                    <PersonalData data={values} />
                </Col>
                <Col md={6}>
                    {
                        values.active ?
                            <Card
                                title= {t("security.users.disableUser")}
                                content={
                                    <button className="btn btn-danger" type="button" disabled={isSubmitting} onClick={onDisableUser}>
                                        {t("buttons.disableUser")}
                                    </button>
                                }
                            />
                            :
                            <Card
                                title={t("security.users.enableUser")}
                                content={
                                    <button className="btn btn-success" type="button" disabled={isSubmitting} onClick={onEnableUser}>
                                        {t("buttons.enableUser")}
                                    </button>
                                }
                            />
                    }
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={2}>
                    <Actions
                        onRedirectTo={() => history.goBack()}
                    />
                </Col>
                <Col md={10}>
                    <Card
                        title=""
                        content={
                            <FormGroup>
                                <Grid fluid>
                                    <Row>
                                        <Col sm={6}>
                                            <div className="card-label">{t("security.users.changeRole")}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <CustomSelect
                                                defaultValue={roles && roles.filter(x => x && x.name === values.role)}
                                                name={`role`}
                                                placeholder={t("commons.select")}
                                                onChange={setFieldValue}
                                                onBlur={setFieldTouched}
                                                options={roles}
                                                getOptionLabel={x => x.name}
                                            />
                                        </Col>
                                        <Col sm={6}>
                                            <button className="btn btn-success" type="button" disabled={isSubmitting} onClick={() => onChangeRole(values.role?.id)}>
                                                {t("buttons.save")}
                                            </button>
                                        </Col>
                                    </Row>
                                </Grid>
                                <WithAuth requiredPermission={["Users.Create"]}>
                                    <Grid fluid style={{ marginTop: '40px' }}>
                                        <Row>
                                            <Col sm={6}>
                                                <div className="card-label">{t("security.users.resendEmail")}</div>
                                            </Col>
                                            <Col sm={6}>
                                                <button className="btn btn-success" type="button" disabled={isSubmitting} onClick={() => onResendEmail(values.role?.id)}>
                                                {t("security.users.resendEmail_button")}
                                                </button>
                                            </Col>
                                        </Row>
                                    </Grid>

                                    <Grid fluid>
                                        <Row>
                                            <Col sm={6}>
                                                <div className="card-label">{t("security.users.changePassword")}</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <FormControl autoFocus type="text" name={`newpassword`} value={values.newpassword || ''} onChange={handleChange} autoComplete="off" />
                                            </Col>
                                            <Col sm={6}>
                                                <button className="btn btn-success" type="button" disabled={isSubmitting} onClick={() => onNewPassword(values.newpassword)}>
                                                {t("security.users.changePassword_button")}
                                                </button>
                                            </Col>
                                        </Row>
                                    </Grid>
                                </WithAuth>
                            </FormGroup>
                        }
                    />
                </Col>
            </Row>
        </Grid>
    );
};