import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Card from '../Cards/Card';
import { IconSVG } from '../Icons/Icons';

const AppointmentWidget = () => {

    const { t } = useTranslation();


    return (
        <Card
            shadow={false}
            className="new-appointment-widget"
            content={
                <div>
                    <div className="center">
                        <IconSVG name="add-appointment" />
                    </div>
                    <div className="center">
                        <NavLink to="/admin/agenda" className="btn btn-success" activeClassName="active">
                            {t("dashboard.addAppointment")}
                        </NavLink>
                    </div>
                </div>
            }
        />

    )
}


export default AppointmentWidget;
