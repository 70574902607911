import React from "react";
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Card from "../Cards/Card";

export const ProfessionalData = props => {
    const { t } = useTranslation();

    const data = props.data;

    return (
        <Card
            className="card-medical-data"
            content={
                <Row className="display-flex">
                    <Col sm={6}>
                        <div className="info">
                            <div><span className="patient-label">{t("person.registrationNumber")}:</span> <span className="patient-text">{data.registrationNumber}</span></div>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="info">
                            <div><span className="patient-label">{t("person.speciality")}:</span> <span className="patient-text">{data.speciality}</span></div>
                        </div>
                    </Col>
                </Row>
            }
        />
    );
}