import React from 'react';
import { NavLink } from 'react-router-dom';
import '../../assets/sass/lbd/_error-pages.scss';
import image from "../../assets/img/forbidden.png";

const ForbiddenPage = () => {

    return (
        <div className="error-container">
            <img src={image} alt="Acceso Denegado" />

            <h1>403</h1>
            <h2>Acceso Denegado</h2>
            <p>No tiene los permisos suficientes para acceder al recurso. En caso de que lo necesite, contactese con el administrador del sistema.</p>
            <NavLink to="/admin/dashboard">&larr; Volver</NavLink>
        </div>
    );
}

export default ForbiddenPage;
