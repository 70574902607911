import React from "react";
import { useTranslation } from 'react-i18next'
import useIotDevices from "../useIotDevices";
import IotDevices from "./IotDevices";
import Card from "../../../components/Cards/Card";
import Loader from "../../../components/Common/Loader";
import { DeleteDeviceModal } from "./DeleteDeviceModal";
import { useModalContext } from "../../../components/Modals/useModalContext";
import DeviceService from "../../../api/Freezer/DeviceService";
import { NavLink, useHistory } from "react-router-dom"
import { WithAuth } from "../../../components/Authorization/WithAuth";
import { Add } from "../../../components/Icons/Icons";

export default function IotDevicesContainers({ }) {
    const { t } = useTranslation();
    let history = useHistory();
    const [pagedDevices, isLoading, navigation] = useIotDevices()
    const [modalContext, openDeleteModal] = useModalContext({
        handleEvent: (e) => navigation.Reload()
    })

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        navigation.ToPage({ page })
    }

    const handleRemove = async (deviceId) => {
        if (deviceId) {
            const device = await DeviceService.GetById(deviceId)
            openDeleteModal(device)
        }
    }

    const handleDetail = async (deviceId) => {
        history.push(`/admin/settings/devices/detail/${deviceId}`);
    }

    return (
        <>
            <Card
                header={
                    <>
                        <WithAuth requiredPermission={["Devices.Create"]}>
                            <NavLink to={`/admin/settings/devices/create`} className="btn btn-primary" activeClassName="active">
                                <Add /> {t("settings.device.add")}
                            </NavLink>
                        </WithAuth>
                    </>
                }
                content={
                    <Loader isLoading={isLoading}>
                        <IotDevices
                            pagedDevices={pagedDevices}
                            onRemove={handleRemove}
                            isLoading={isLoading}
                            onTableChange={handleTableChange}
                            onDetail={handleDetail} />
                    </Loader>}
            />
            <DeleteDeviceModal state={modalContext} />
        </>
    )
}