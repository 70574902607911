export const getIconClassName = (type) => {

    if (!type)
        return;

    if (type.includes(".pdf"))
        return "far fa-file-pdf";

    if (type.includes(".doc") || type.includes(".docx"))
        return "far fa-file-word";

    if (type.includes(".xslx"))
        return "far fa-file-excel";

    if (type.includes(".txt"))
        return "far fa-file-alt";

    if (type.includes(".jpg") || type.includes(".jpeg") || type.includes(".jpg") || type.includes(".png") || type.includes(".gif"))
        return "far fa-file-image";
};

export const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

export const activeStyle = {
    borderColor: '#2196f3'
};

export const uploadStatusEnum = {
    Uploading: 0,
    Uploaded: 1
}