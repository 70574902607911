import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { Button, Col, ControlLabel, FormControl, FormGroup, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getPatient } from '../../api/agenda';
import { useMedicalServices } from '../../containers/Agenda/Hooks';
import { WithAuth } from '../Authorization/WithAuth';
import Card from '../Cards/Card';
import { Check, Edit, PersonCheck, Remove, VideoCall } from "../Icons/Icons";
import { Tooltip } from '../Utils/Tooltips';
import { MedicalServiceInfo } from './MedicalServiceInfo';
import { PatientInfo } from './PatientInfo';

export const ViewEvent = (props) => {
    const { handleModalClose,
        handleViewEdit,
        handleAttended,
        handleNotAttendance,
        handleEventStatus,
        handleCancelEvent,
        handleSetPresent,
        event } = props;

    const [patient, setPatient] = useState({});
    const settingsStore = useSelector(state => state.settings);
    const { t } = useTranslation();

    const [isLoading, setIsLoading,
        , , ,
        , , ,
        medicalServicePrice, getMedicalServicePrice, setMedicalServicePrice] = useMedicalServices();

    useEffect(() => {
        const getPatientData = async (patientId) => {

            // Obtengo datos del paciente
            try {
                const response = await getPatient(patientId);
                setPatient(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        const getServicesData = async () => {
            if (!event.medicalServiceId)
                return;
            // Obtengo Servicios
            try {
                setIsLoading(true);
                var res2 = await getMedicalServicePrice(event.medicalServiceId, event.medicalInsuranceId, event.medicalInsurancePlanId);
                setMedicalServicePrice(res2.data.data);
                setIsLoading(false);
            }
            catch (ex) {
                console.log(ex)
                setIsLoading(false);
            }
        }

        if (event.visitType?.code !== "SCHEDULELOCK") {
            if (event.medicalServiceId) getServicesData();
            getPatientData(event.patient.medicalRecordNumber);
        }
    }, []);

    var start = event.start.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });
    var end = event.end.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });

    var actionButtons = null;
    var editButton = null;
    var cancelButton = null;
    var presentButton = null;
    var telemedicineButton = null;

    if (event.telemedicineToken)
        telemedicineButton = (
            <a href={settingsStore.settings.telemedicineUrl.replace("{callid}", event.telemedicineToken).replace("{medicoid}", event.practitioner.userId)} target="_blank"
                style={{ fontSize: '27px', marginRight: '10px', verticalAlign: 'middle' }}>
                <Tooltip tooltip={t("calendar.videoCall")} id={"tooltipTelemedicine-" + event.telemedicineToken}>
                    <VideoCall />
                </Tooltip>
            </a>
        );

    if (!event.present && !event.attended) {
        presentButton = (<Button bsStyle="success" onClick={() => handleSetPresent(event.id)}><PersonCheck /> {t('calendar.setPresent')}</Button>);
    }

    if (moment().isSameOrAfter(event.start)) {
        if (event.attended === null)
            actionButtons = (
                <>
                    <Button bsStyle="danger" onClick={() => handleNotAttendance(event.id, "reason")}><Remove /> {t('calendar.notassist')}</Button>
                    <Button bsStyle="success" onClick={() => handleAttended(event.id)}><Check /> {t('calendar.assist')}</Button>
                </>
            );
    }
    else {
        editButton = (<Button bsStyle="primary" onClick={() => handleViewEdit(event)}><Edit /> {t('buttons.edit')}</Button>);
        cancelButton = (<Button bsStyle="danger" style={{ float: 'left' }} onClick={() => handleCancelEvent(event)}><Remove /> {t('calendar.cancelAppointment')}</Button>);

        if (!event.confirmed) {
            actionButtons = (
                <>
                    <Button bsStyle="success" onClick={() => handleEventStatus(event.id, true)}><Check /> {t('calendar.confirm')}</Button>
                </>
            );
        }
    }

    if (event.visitType?.code === "SCHEDULELOCK")
        return (
            <Modal show={true} onHide={handleModalClose} bsSize="large">
                <Modal.Header closeButton>
                    <Modal.Title>{t('calendar.scheduleLock')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card
                        content={
                            <>
                                <Row>
                                    <Col sm={12}>
                                        <Row>
                                            {
                                                event.practitioner ?
                                                    <Col sm={12}>
                                                        <FormGroup>
                                                            <ControlLabel>{t('calendar.practitioner')}: {event.practitioner?.fullName}</ControlLabel>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <ControlLabel>{t('calendar.scheduleLock')}: {start} - {end}</ControlLabel>
                                                        </FormGroup>
                                                    </Col> :
                                                    <Col sm={12}>
                                                        <FormGroup>
                                                            <ControlLabel>{t('calendar.all')}</ControlLabel>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <ControlLabel>{t('calendar.scheduleLock')}: {start} - {end}</ControlLabel>
                                                        </FormGroup>
                                                    </Col>
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                                {
                                    event.desc &&
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <ControlLabel>{t('calendar.notes')}</ControlLabel>
                                                <FormControl type="text" componentClass="textarea" readOnly value={event.desc} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                }
                            </>
                        }
                    />
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        );

    return (
        <Modal show={true} onHide={handleModalClose} bsSize="large">
            <Modal.Header closeButton>
                <Modal.Title>{telemedicineButton} {t('calendar.turno')}: {start} - {end}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PatientInfo patient={patient} />

                <Card
                    className="card-patient"
                    content={
                        <>
                            <Row>
                                <Col sm={6}>
                                    <Row>
                                        <Col sm={12}>
                                            <div className="info">
                                                <div className="name">{t('calendar.visitType')}</div>
                                            </div>
                                            <div className="info">
                                                <div><span className="card-text">{event.visitType?.title}</span></div>
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="info">
                                                <div className="name">{t('calendar.practitioner')}</div>
                                            </div>
                                            <div className="info">
                                                <div><span className="card-text">{event.practitioner?.fullName}</span></div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={6}>
                                    <MedicalServiceInfo medicalService={medicalServicePrice} isLoading={isLoading} />
                                </Col>
                            </Row>
                        </>
                    }
                />

                {
                    event.protocol != null ?
                        <FormGroup>
                            <ControlLabel>{t('calendar.protocol')}</ControlLabel>
                            <FormControl type="text" readOnly value={event.protocol.title} />
                        </FormGroup>
                        : null
                }
                {
                    event.project != null ?
                        <FormGroup>
                            <ControlLabel>{t('calendar.project')}</ControlLabel>
                            <FormControl type="text" readOnly value={event.project.title} />
                        </FormGroup> : null
                }
                {
                    event.desc != null ?
                        <FormGroup>
                            <ControlLabel>{t('calendar.notes')}</ControlLabel>
                            <FormControl type="text" componentClass="textarea" readOnly value={event.desc} />
                        </FormGroup> : null
                }
                {
                    event.location != null ?
                        <FormGroup>
                            <ControlLabel>{t('calendar.location')}</ControlLabel>
                            <FormControl type="text" readOnly value={event.location} />
                        </FormGroup> : null
                }
            </Modal.Body>
            <Modal.Footer>
                <WithAuth requiredPermission={["Agenda.Edit"]}>
                    {cancelButton}
                    {editButton}
                    {actionButtons}
                    {presentButton}
                </WithAuth>
            </Modal.Footer>
        </Modal>
    );

}

ViewEvent.propTypes = {
    event: PropTypes.object.isRequired,
    handleModalClose: PropTypes.func.isRequired,
    handleViewEdit: PropTypes.func.isRequired,
    handleAttended: PropTypes.func.isRequired,
    handleNotAttendance: PropTypes.func.isRequired,
    handleEventStatus: PropTypes.func.isRequired,
    handleCancelEvent: PropTypes.func.isRequired,
    handleSetPresent: PropTypes.func.isRequired,
};

