import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React from "react";
import { ConfirmRemove } from '../../components/Modals/RemoveModal';
import { HandleApiError } from "../../components/Notifications/APIErrorHandler";
import { ResultsTemplateForm } from "../../components/Settings/ResultsTemplates";
import { getSettingsSchema } from '../../components/Validations/FormValidationSchema';
import { useResultsTemplate } from './Hooks/useResultsTemplates';

export const ResultsTemplateCreateContainer = (props) => {
    const { onSubmit } = props;
    const [, , create,] = useResultsTemplate();

    const handleSubmit = async (data) => {
        try {
            const res = await create(data);
            onSubmit(res);
        }
        catch (error) {
            console.log(error);
            HandleApiError(error);
        }
    }

    return (
        <ResultsTemplateContainerFormik
            onSubmit={handleSubmit}
            onCancel={props.onCancel}
        />
    );
}

export const ResultsTemplateEditContainer = (props) => {
    const { id, onSubmit } = props;
    const [data, isLoading, , update] = useResultsTemplate(id);

    const handleSubmit = async (data) => {
        try {
            const res = await update(id, data);
            onSubmit(res);
        }
        catch (error) {
            console.log(error);
            HandleApiError(error);
        }
    }

    if (!data || isLoading)
        return null;

    return (
        <ResultsTemplateContainerFormik
            onSubmit={handleSubmit}
            onCancel={props.onCancel}
            template={data}
        />
    );
}

export const ResultsTemplateRemoveContainer = (props) => {
    const { id, onSubmit, onCancel } = props;
    const [data, isLoading, , , remove] = useResultsTemplate(id);

    const handleSubmit = async () => {
        try {
            const res = await remove(id);
            onSubmit(res);
        }
        catch (error) {
            console.log(error);
            HandleApiError(error);
        }
    }

    if (!data || isLoading)
        return null;

    return (<ConfirmRemove id={id} onClose={onCancel} onConfirm={handleSubmit} />);
}

const ResultsTemplateContainerFormik = withFormik({
    mapPropsToValues: (props) => (props.template),

    validationSchema: getSettingsSchema(),

    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values);
    },

    displayName: 'ResultsTemplateForm',

})(ResultsTemplateForm);

ResultsTemplateContainerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    template: PropTypes.object.isRequired,
};