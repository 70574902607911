import React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import Card from '../Cards/Card';
import CustomImageUpload from '../CustomControls/Images/CustomImage';
import { ExpandableContent } from '../CustomControls/ExpandableContent';

export const UserSignForm = props => {
    const {
        isSubmitting,
        isDisabled,
        onUploadSign,
        values,
        setFieldValue,
        t,
    } = props;

    return (
        <ExpandableContent title={t("person.sign.sign")} expandedAll={true} content="">
            <Card
                className="card-patient-edit card-form"
                content={
                    <Grid fluid>
                        <Row>
                            <Col sm={12}>
                                <CustomImageUpload
                                    disabled={isDisabled}
                                    value={values.sign}
                                    name="sign"
                                    placeholder="Subir firma"
                                    onChange={setFieldValue}
                                //maxWidth={400}
                                //maxHeight={200}
                                //minWidth={400}
                                //minHeight={200}
                                />
                            </Col>
                            <Col sm={12}>
                                <button className="btn btn-success" type="button" disabled={isSubmitting || !values.sign} onClick={() => onUploadSign(values.sign)}>
                                    {t("person.sign.addSign")}
                                </button>
                            </Col>
                        </Row>
                    </Grid>
                }
            />
        </ExpandableContent>
    );
};