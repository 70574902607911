import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card } from '../../components/Cards/Card';
import { IconSVG, ViewMR } from '../Icons/Icons';
import { WithAuth } from '../Authorization/WithAuth';

export const Actions = (props) => {
    const { t } = useTranslation();
    const { onCreateAppointment, onRedirectTo, onEditPatient, onViewMR, onExportToPDF } = props;

    var redirectTo = null;
    if (onRedirectTo) {
        redirectTo = (
            <button className={`btn btn-primary btn-fill btn-full`} onClick={onRedirectTo}>
                <IconSVG name="back" /> {t('buttons.back')}
            </button>
        );
    }

    var createAppointment = null;
    if (onCreateAppointment) {
        createAppointment = (
            <button className={`btn btn-primary btn-fill btn-full`} onClick={onCreateAppointment}>
                <IconSVG name="addappointment" fill="#fff" /> {t('medicalRecords.actionNewAppointment')}
            </button>
        );
    }
    var editPatient = null;
    if (onEditPatient) {
        editPatient = (
            <button className={`btn btn-primary btn-fill btn-full`} onClick={onEditPatient}>
                <IconSVG name="edit" /> {t('buttons.edit')}
            </button>
        );
    }

    var viewMR = null;
    if (onViewMR) {
        viewMR = (
            <button className={`btn btn-primary btn-fill btn-full`} onClick={onViewMR}>
                <ViewMR /> {t('database.viewMR')}
            </button>
        );
    }

    var exportToPDF = null;
    if (onExportToPDF) {
        exportToPDF = (
            <button className={`btn btn-success btn-fill btn-full`} onClick={onExportToPDF}>
                <IconSVG name="export" /> {t('buttons.export')}
            </button>
        );
    }

    return (
        <Card
            title={t('medicalRecords.actions')}
            className="card-actions"
            content={
                <>

                    <WithAuth requiredPermission={["MedicalRecords.View"]}>
                        {viewMR}
                    </WithAuth>
                    <WithAuth requiredPermission={["Agenda.Create"]}>
                        {createAppointment}
                    </WithAuth>
                    <WithAuth requiredPermission={["Database.Edit"]}>
                        {editPatient}
                    </WithAuth>
                    <WithAuth requiredPermission={["Database.View"]}>
                        {exportToPDF}
                    </WithAuth>
                    {redirectTo}
                </>
            }
        />
    );
}

Actions.propTypes = {
    onViewMR: PropTypes.func,
    onCreateAppointment: PropTypes.func,
    onRedirectTo: PropTypes.func,
    onEditPatient: PropTypes.func,
    onExportToPDF: PropTypes.func,
};
