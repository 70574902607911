import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { authUserActions } from '../../store/AuthUser';
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants';
import authService from './AuthorizeService';

export const AuthorizeRoute = props => {
    const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
    const dispatch = useDispatch();

    const [ready, setReady] = useState(false);
    useEffect(() => {

        async function populateAuthenticationState() {
            await dispatch(authUserActions.requestAuthentication());
            setReady(true);
        }

        async function authenticationChanged() {
            setReady(false);
            await dispatch(authUserActions.clearAuthentication(false));
            populateAuthenticationState();
        }

        populateAuthenticationState();

        const _subscription = authService.subscribe(() => authenticationChanged());

        // Especifica cómo sanear este efecto:
        return function cleanup() {
            authService.unsubscribe(_subscription);
        };


    }, []);

    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`
    if (!ready) {
        return <div></div>;
    } else {
        //const { ...rest } = props;
        return <Route
            path={props.path}
            render={innerProps => {
                if (!isAuthenticated) {
                    return <Redirect to={redirectUrl} />
                } else {
                    return <props.component {...innerProps} />
                }
            }
            }
        />
    }
}