import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { usePractitioners } from '../../containers/Users/Hooks';
import Card from '../Cards/Card';
import { CustomSelect } from '../CustomControls/CustomSelect';
import { Calendar, CalendarCheck, CalendarCross, IconSVG, PersonCheckLine, PersonCrossLine } from '../Icons/Icons';
import { dateFormatter, isLowerThanToday } from '../Utils/Dates';
import { Spinner } from '../Utils/Loaders';
import { Tooltip } from '../Utils/Tooltips';
import { visitTypesEnum } from '../../variables/Enums';
import { colourStyles } from './Utils';

export const AppointmentsScrollable = (props) => {
    const { data, totalSize, getMore, onFilterChange } = props;
    const { t } = useTranslation();
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [users, isLoading] = usePractitioners();

    useEffect(() => {
        if (data && data.length > 0) {
            var montotos = [];
            if (props.currentPage > 1) // Si esoty en la primer página, refresco Items. 
                montotos = JSON.parse(JSON.stringify(items));

            setHasMore(totalSize !== montotos.length + data.length);
            setItems(montotos.concat(data));
        }

        if (data.length === 0)
            setItems([]);

    }, [data, totalSize]);

    const handleOnChangeFilter = (name, value) => {
        //console.log(`handleOnChangeFilter ${name} - ${JSON.stringify(value)}`)
        var filterValue = null;
        switch (name) {
            case "practitioner":
                filterValue = value && value.userId;
                break;
            case "speciality":
                filterValue = value && value.speciality;
                break;
            case "group":
                filterValue = value && value.code;
                break;
            case "history":
                if (value)
                    filterValue = value.id === 'history' ? true : false;
                break;
            case "attended":
                if (value)
                    filterValue = value.id === 'attended' ? true : false;
                break;
            case "type":
                filterValue = value && value.code;
            default:
                break;
        }

        onFilterChange(name, filterValue);
    }

    return (
        <Card
            tabs
            noHeader
            content={
                <Grid fluid>
                    <Row style={{ marginBottom: '15px' }}>
                        <Col md={2}>
                            <CustomSelect
                                name="history"
                                isLoading={isLoading}
                                isClearable={true}
                                placeholder={t('filters.date')}
                                options={[{ id: 'future', title: t("appointments.future") }, { id: 'history', title: t("appointments.history") }]}
                                onChange={handleOnChangeFilter}
                            />
                        </Col>
                        <Col md={2}>
                            <CustomSelect
                                name="attended"
                                isLoading={isLoading}
                                isClearable={true}
                                placeholder={t('filters.status')}
                                options={[{ id: 'attended', title: t("appointments.attended") }, { id: 'notAttended', title: t("appointments.notAttended") }]}
                                onChange={handleOnChangeFilter}
                            />
                        </Col>
                        <Col md={3}>
                            <CustomSelect
                                name="type"
                                isClearable={true}
                                isLoading={isLoading}
                                placeholder={t('filters.type')}
                                getOptionValue={(option) => option.code}
                                options={visitTypesEnum}
                                styles={colourStyles}
                                onChange={handleOnChangeFilter}
                            />
                        </Col>
                        <Col md={3}>
                            <CustomSelect
                                name="practitioner"
                                isLoading={isLoading}
                                isClearable={true}
                                placeholder={t('filters.professional')}
                                getOptionLabel={(option) => option.fullName}
                                getOptionValue={(option) => option.userId}
                                options={users}
                                onChange={handleOnChangeFilter}
                            />
                        </Col>
                        <Col md={2}>
                            <CustomSelect
                                name="speciality"
                                isClearable={true}
                                isLoading={isLoading}
                                placeholder={t('filters.speciality')}
                                options={[...new Map(users.map(item => [item.speciality, item])).values()]}
                                getOptionLabel={(option) => option.speciality}
                                getOptionValue={(option) => option.speciality}
                                onChange={handleOnChangeFilter}
                            />
                        </Col>
                    </Row>
                    {
                        data && data.length > 0 ?
                            <Row>
                                <Col md={12}>
                                    <InfiniteScroll
                                        dataLength={items.length}
                                        next={() => getMore()}
                                        hasMore={hasMore}
                                        loader={<Spinner />}
                                        endMessage={
                                            <p style={{ textAlign: "center" }}>
                                                <b>{t("medicalRecords.entries.noentries")}</b>
                                            </p>
                                        }
                                        height={300}
                                    >
                                        {items.map((item, index) => (
                                            <Grid fluid key={`event-item-${index}`} className="event-item">
                                                <Row>
                                                    <Col md={2}>
                                                        {
                                                            item.canceled ? <CalendarCross fill="#ec1657" /> :
                                                                isLowerThanToday(item.start) ?
                                                                    <CalendarCheck fill="#20ad9b" />
                                                                    :
                                                                    <Calendar fill="#247dbd" />
                                                        }
                                                        {dateFormatter(item.start, "DD/MM/YYYY HH:mm")}
                                                    </Col>
                                                    <Col md={2} className="w900">{item.practitioner}</Col>
                                                    <Col md={2}><IconSVG name="speciality" />{item.practitionerSpeciality}</Col>
                                                    <Col md={1}>
                                                        {
                                                            (item.attended === true || item.attended === false) &&
                                                            <Tooltip id={`event-tooltip-${index}`} tooltip={item.attended ? t("calendar.assist") : t("calendar.notassist")}>
                                                                {item.attended === true && <PersonCheckLine stroke="#20ad9b" />}
                                                                {item.attended === false && <PersonCrossLine stroke="#ec1657" />}
                                                            </Tooltip>
                                                        }
                                                    </Col>
                                                    <Col md={3}><IconSVG name="visitType" />{item.visitType}</Col>
                                                    <Col md={2}>{t("commons.createdBy")}: {item.createdBy}</Col>
                                                    {
                                                        item.canceled &&
                                                        <Col md={12}>
                                                            <Grid fluid>
                                                                <Row>
                                                                    <Col md={6} className="event-summary">
                                                                        <div>
                                                                            <span className="w900">
                                                                                {item.attended === false ? t("appointments.notAttendanceReason") : t("appointments.canceledReason")}
                                                                            </span>
                                                                            {item.canceledReason}
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={6} className="event-summary">
                                                                        <div>
                                                                            <span className="w900">
                                                                                {item.attended === false ? t("appointments.notAttendanceBy") : t("appointments.canceledBy")}
                                                                            </span>
                                                                            {item.canceledBy}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Grid>
                                                        </Col>
                                                    }
                                                </Row>
                                            </Grid>
                                        )
                                        )}
                                    </InfiniteScroll>
                                </Col>
                            </Row>
                            :
                            <p style={{ textAlign: "center" }}>
                                <b>{t("medicalRecords.entries.noentries")}</b>
                            </p>
                    }

                </Grid >
            }
        />

    );
}

AppointmentsScrollable.propTypes = {
    data: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalSize: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    getMore: PropTypes.func.isRequired,
    onFilterChange: PropTypes.func.isRequired,
};
