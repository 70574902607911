import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types'
import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';

import { getCodeableConceptSchema } from "../../../components/Validations/FormValidationSchema";
import { Success } from '../../../components/Notifications/Notifications';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { useStudyTask } from "./useStudyTasks";
import { editStudyTask } from "../../../api/protocolAdmin";
import { StudyTaskForm } from "../../../components/Protocols/Admin/StudyTasks";

export const EditStudyTaskContainer = (props) => {
    let history = useHistory();
    const { match: { params } } = props;
    const [studyTask, isLoading] = useStudyTask(params.studyTaskId);

    const handleSubmit = () => {
        history.push(`/admin/settings/studyTasks`);
    }
    const handleCancel = () => {
        history.push(`/admin/settings/studyTasks`);
    }

    if (!studyTask.id)
        return null;

    return (
        <EditStudyTaskContainerFormik
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            studyTask={studyTask}
            loading={isLoading}
        />
    );
}

const EditStudyTaskContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => ({ studyTask: props.studyTask }),

    validationSchema: getCodeableConceptSchema(),

    handleSubmit: (values, formikBag) => {
        editStudyTask(values.studyTask.id, values.studyTask)
            .then(res => {
                formikBag.setSubmitting(false);
                Success("protocolNotifications.protocol_Updated");
                formikBag.props.onSubmit(res.data);
            }).catch(error => {
                console.log(error);
                formikBag.setSubmitting(false);
                HandleApiError(error);
            });
    },

    displayName: 'StudyTaskForm',

})(StudyTaskForm));

EditStudyTaskContainerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};