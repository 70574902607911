import { Formik, ErrorMessage } from 'formik';
import React from 'react';
import { FormGroup, ControlLabel, FormControl, Col, Grid, Row } from 'react-bootstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { useTranslation } from 'react-i18next';
import { Save } from '../Icons/Icons';
import { FormErrorMessage } from '../Validations/FormValidationErrors';
import { CustomDateTime } from '../CustomControls/CustomDateTime';
import { CustomSwitch } from '../CustomControls/CustomSwitch';
import { Button } from '../CustomControls/Button';

export const AdminNotification = props => {

    const {
        values,
        onCancel
    } = props;

    const { t } = useTranslation();

    return (
        <Formik
            initialValues={values ?? { message: 'Mensaje', from: '', to: '', active: false }}
            onSubmit={(values) => {
                if (props.onSubmit)
                    props.onSubmit(values);
            }}
        >
            {props => (
                <form onSubmit={props.handleSubmit}>
                    <FormGroup>
                        <Grid fluid>
                            <Row>
                                <Col sm={12}>
                                    <ControlLabel>{t("settings.adminNotifications.message")}</ControlLabel>
                                    <FormControl componentClass="textarea" name='message' value={props.values.message} onChange={props.handleChange} onBlur={props.handleBlur} autoComplete="off" />
                                    <ErrorMessage name="message" component={FormErrorMessage} />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={5}>
                                    <ControlLabel>{t("settings.adminNotifications.from")}</ControlLabel>
                                    <CustomDateTime
                                        closeOnSelect={true}
                                        value={props.values.from ?? ''}
                                        name="from"
                                        onChange={props.setFieldValue}
                                        onBlur={props.setFieldTouched}
                                        utc={true}
                                    />
                                    <ErrorMessage name="from" component={FormErrorMessage} />
                                </Col>
                                <Col sm={5}>
                                    <ControlLabel>{t("settings.adminNotifications.to")}</ControlLabel>
                                    <CustomDateTime
                                        closeOnSelect={true}
                                        value={props.values.to ?? ''}
                                        name="to"
                                        onChange={props.setFieldValue}
                                        onBlur={props.setFieldTouched}
                                        utc={true}
                                    />
                                    <ErrorMessage name="to" component={FormErrorMessage} />
                                </Col>
                                <Col sm={2}>
                                    <ControlLabel>{t("settings.adminNotifications.active")}</ControlLabel>
                                    <CustomSwitch value={props.values.active} name="active" onChange={props.setFieldValue} />
                                </Col>
                            </Row>
                        </Grid>
                    </FormGroup>
                    <Grid fluid>
                        <Row>
                            <Col sm={12}>
                                <Button type="submit" className="btn btn-success btn-right" disabled={props.isSubmitting}><Save />{t("buttons.save")}</Button>
                                <Button type="button" className="btn btn-default  btn-right" onClick={onCancel} style={{ marginRight: '10px' }}>{t("buttons.cancel")}</Button>
                            </Col>
                        </Row>
                    </Grid>
                </form>
            )}
        </Formik>
    );
}