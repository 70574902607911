import { useEffect, useState } from "react";
import FreezerProtocolServices from "../../../../api/Freezer/FreezerProtocolServices";
import IotMessagesServices from "../../../../api/Freezer/IotMessagesServices";
import { getProtocols } from "../../../../api/protocol";
import { ProtocolFullData } from "../../../../api/Freezer/Entities/FreezerTelemetryResume";

export default function useProtocolResume(from, to) {
    const [state, setState] = useState({
        resumes: [],
        isLoading: false
    });

    useEffect(() => {
        load();
    }, [])

    const findResume = async ({ from, to }) => {
        const protocolConfigurationsList = (await FreezerProtocolServices
            .GetProtocolConfigs())
            .Value;
        const uniqueFreezers = protocolConfigurationsList.reduce((map, value) => {
            value.AssignedFreezers.forEach(f => map.set(f.Id, f))
            return map;
        }, new Map());

        const uniqueFreezerIds = uniqueFreezers.keys();
        const resumeDataByFreezerId = (await IotMessagesServices
            .GetWeatherResume({ from, to, freezerIds: Array.from(uniqueFreezerIds) }))
            .reduce((map, r) => map.set(r.FreezerId, [...(map.get(r.FreezerId) ?? []), ...r.Days]), new Map());

        const protocols = (await getProtocols({
            pagination: { page: 1, sizePerPage: 1000, totalSize: 0 },
            sort: { sortField: "Created", sortOrder: "desc" },
        })).data.data
            .reduce((map, p) => map.set(p.id, p), new Map())

        const data = protocolConfigurationsList
            .flatMap(p => p.AssignedFreezers.map(f => ({ Config: p, Freezer: f })))
            .map(p => new ProtocolFullData({
                protocol: protocols.get(p.Config.Id)
                , configuration: p.Config
                , freezerDaysResume: resumeDataByFreezerId.get(p.Freezer.Id)
                , freezer: p.Freezer
            }))

        return data;
    }

    const load = async () => {
        setState({ resumes: [], isLoading: true })
        const data = await findResume({ from, to })
        setState({ resumes: data, isLoading: false })
    }


    return [state.resumes, state.isLoading, load];
}