import React, { Fragment, useContext, useState } from "react";
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from "react-router-dom";
import { AuditModal } from '../../../components/AuditTrail/AuditModal';
import { WithAuth } from "../../../components/Authorization/WithAuthProtocol";
import { Audit, Close, Edit, Remove } from "../../../components/Icons/Icons";
import { Brief, CloseProtocolModal } from "../../../components/Protocols/Brief";
import { Tooltip } from "../../../components/Utils/Tooltips";
import { AuditTrail } from "../../Admin/AuditTrail";
import ProtocolContext from "../ProtocolContext/ProtocolContext";
import { ModalConfirm } from "../../../components/Modals/ModalConfirm";
import { ModalSpinner } from "../../../components/Utils/Loaders";
import { removeProtocolApi } from "../../../api/protocol";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";

export const ProtocolBriefContainer = () => {
    const { t } = useTranslation();
    const [modal, setModal] = useState(null);
    const history = useHistory();
    const context = useContext(ProtocolContext);

    const handleAuditTrail = () => {
        setModal(
            <AuditModal
                onClose={() => { setModal(null); }}
                title={t("auditTrail.audit")}
            >
                <AuditTrail entity="Protocol" entityId={context.protocolId} />
            </AuditModal>
        )
    }

    const handleCloseProtocol = () => {
        setModal(
            <CloseProtocolModal
                onClose={() => { setModal(null); }}
                onConfirm={() => { context.closeProtocol(context.protocolId); setModal(null); history.push("/admin/protocols/"); }}
                title={t("protocols.brief.closeProtocolTitle")}
            >
                {t("protocols.brief.closeProtocolDescription")}
            </CloseProtocolModal>
        )
    }

    const handleRemoveProtocol = () => {
        setModal(
            <ModalConfirm
                onCancel={() => { setModal(null); }}
                onConfirm={onRemoveProtocol}
                title={t("protocols.brief.removeProtocolTitle")}
                description={t("protocols.brief.removeProtocolDescription")}
            />
        )
    }

    const onRemoveProtocol = async () => {
        setModal(<ModalSpinner isShowing={true} hide={null} />);
        try {
            await removeProtocolApi(context.protocolId);
            setModal(null);
            history.push("/admin/protocols/");
        }
        catch (error) {
            setModal(null);
            HandleApiError(error);
        }
    }

    if (context.isLoading || !context.protocolId)
        return null;

    return (
        <Fragment>
            {modal}
            <Brief />

            <ButtonToolbar>
                <ButtonGroup>
                    {
                        !context.isDisabled &&
                        <WithAuth protocolId={context.protocolId} requiredPermission={["Brief.Edit"]}>
                            <Tooltip tooltip={t("protocols.brief.editProtocol")} id="tooltip-edit-brief">
                                <NavLink to={`/admin/protocols/${context.protocolId}/edit`} disabled={context.isDisabled} className="btn btn-primary btn-right" activeClassName="active" style={{ marginRight: '10px' }}>
                                    <Edit /> {t("buttons.edit")}
                                </NavLink>
                            </Tooltip>
                        </WithAuth>
                    }
                    <Tooltip tooltip={t("protocols.visitTracking.auditTrail")} id="tooltip-audit-brief">
                        <Button className="btn btn-default btn-right" onClick={handleAuditTrail} style={{ marginRight: '10px' }}>
                            <Audit />  {t("protocols.visitTracking.auditTrail")}
                        </Button>
                    </Tooltip>
                </ButtonGroup>
                <ButtonGroup style={{ float: 'right' }}>
                    {
                        !context.isDisabled &&
                        <WithAuth protocolId={context.protocolId} requiredPermission={["Brief.Close"]}>
                            <Tooltip tooltip={t("protocols.brief.closeProtocol")} id="tooltip-audit-brief">
                                <Button className="btn btn-warning btn-right" onClick={handleCloseProtocol} style={{ marginRight: '10px' }}>
                                    <Close /> {t("buttons.close")}
                                </Button>
                            </Tooltip>
                        </WithAuth>
                    }
                    {
                        !context.isDisabled &&
                        <WithAuth protocolId={context.protocolId} requiredPermission={["Brief.Delete"]}>
                            <Tooltip tooltip={t("protocols.brief.removeProtocol")} id="tooltip-audit-brief">
                                <Button className="btn btn-danger btn-right" onClick={handleRemoveProtocol} style={{ marginRight: '10px' }}>
                                    <Remove /> {t("buttons.delete")}
                                </Button>
                            </Tooltip>
                        </WithAuth>
                    }
                </ButtonGroup>
            </ButtonToolbar>
        </Fragment>
    );
}