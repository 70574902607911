import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { Col, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RichTextControl } from '../../CustomControls/RichTextControl';
import { SignWithOutInfo } from '../../DigitalSign/Sign';
import { Add, Edit, RemoveCircle, Save, Signature } from '../../Icons/Icons';
import { toLocaleDateFormatter } from '../../Utils/Formatter';
import { Spinner } from '../../Utils/Loaders';
import { Popover } from '../../Utils/Popover';

export const EvolutionForm = (props) => {
    const {
        evolution,
        visitStatus,
        onSave,
        onUpdate,
        onCancel,
        isDisabled,
        isLoading
    } = props;

    const { t } = useTranslation();
    const [collapse, setCollapse] = useState(visitStatus === 1 ? true : false);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (evolution) {
            setData(evolution.text)
            setCollapse(false)
        }
        else {
            setData(null)
        }

    }, [evolution])


    const handleSave = () => { if (onSave && data) { onSave(data); setData(null); } }
    const handleEdit = () => { if (onUpdate && evolution && data) onUpdate(evolution?.id, data); }
    const handleCancel = () => { setData(null); setCollapse(true); if (onCancel) onCancel(); }

    if (isLoading)
        return <Spinner />

    return (
        <div>
            <div style={{ textAlign: 'end' }}>
                <button className="btn-table-actions" type="button" onClick={() => setCollapse(!collapse)}>
                    {collapse ? <Add /> : <RemoveCircle />}
                </button>
            </div>
            {
                !collapse &&
                <div style={{ marginBottom: '10px' }}>
                    <RichTextControl
                        autoFocus={true}
                        name="evolution"
                        isDisabled={isDisabled}
                        style={props.style}
                        height={props.height}
                        value={data ?? ""}
                        onChange={(_, value) => setData(value)}
                        onBlur={() => { }}
                        toolbar={
                            [
                                ['clean']
                            ]
                        }
                    />

                    <Grid fluid>
                        <Row>
                            <Col sm={12}>
                                {
                                    evolution && evolution.id ?
                                        <button type="button" className="btn btn-success btn-right" disabled={!data || data.length < 5} onClick={handleEdit} ><Edit /> {t("buttons.edit")}</button> :
                                        <button type="button" className="btn btn-success btn-right" disabled={!data || data.length < 5} onClick={handleSave} ><Save /> {t("buttons.save")}</button>
                                }
                                <button type="button" className="btn btn-default btn-right" onClick={handleCancel} style={{ marginRight: '10px' }}>{t("buttons.cancel")}</button>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            }
        </div>
    );
}
EvolutionForm.defaultProps = {
    height: 300
}
EvolutionForm.propTypes = {
    height: PropTypes.number,
    visitStatus: PropTypes.number,
    evolution: PropTypes.object,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export const Evolutions = (props) => {
    const { data, onEdit } = props;
    const { t } = useTranslation();

    return (<Grid fluid key="evolution-items" className="evolution-items draft">
        {
            data && data.length > 0 ?
                data.map((item, idx) => (
                    <Row key={`evolution-entry-${idx}`}>
                        <Col md={12} className="evolution-item">
                            <div>
                                <button type="button" className="btn-table-actions" onClick={() => onEdit(item.id)}>
                                    <Edit />
                                </button>
                                <Popover
                                    id={`sign-${idx}`}
                                    key={`sign-${idx}`}
                                    cssClass="popover-sign"
                                    popover={<SignWithOutInfo signedBy={item.createdById} created={item.created} />}
                                >
                                    <Signature style={{ fontSize: '25px', color: '#50ABB5', cursor: 'hand' }} />
                                </Popover>
                                <span className="w900">{toLocaleDateFormatter(item.created, "DD/MM/YYYY HH:mm")} [{item.createdBy?.fullName} - {item.createdBy?.speciality}]:</span>
                                <span dangerouslySetInnerHTML={{ __html: item.text }} />
                                {
                                    item.modified !== item.created &&
                                    <span style={{ fontStyle: "italic" }}>[Modificado el {toLocaleDateFormatter(item.modified, "DD/MM/YYYY HH:mm")} por {item.modifiedBy?.fullName} - {item.createdBy?.speciality}]</span>
                                }
                            </div>
                        </Col>
                    </Row>

                ))
                :
                <p style={{ textAlign: "center" }}>
                    <b>{t("medicalRecords.entries.noentries")}</b>
                </p>
        }
    </Grid>
    );
}

Evolutions.propTypes = {
    data: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired
};