import { useState } from "react";
import useLoading from "../../../components/Common/useLoading";
import FreezerProtocolServices from "../../../api/Freezer/FreezerProtocolServices";

export default function useProtocolFreezer(protocolId, defaultValue) {
    const [protocol, setProtocol] = useState(defaultValue)
    const [isLoading, useLoad, reload] = useLoading({
        onLoad: async () => {
            if (isNaN(protocolId)) return;
            const result = await FreezerProtocolServices.GetConfigById(protocolId);
            if (result.Succeeded) {
                setProtocol(result.Value)
            }
        }
    })
    return [protocol, isLoading, reload]
}