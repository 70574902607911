import PropTypes from 'prop-types';
import React from 'react';
import { AuditTooltip } from '../CustomControls/AuditTooltip';
import { ExpandableContent } from '../CustomControls/ExpandableContent';
import { customDateTimeFormatter } from '../Table/Formatters';
import { renderEvolutions } from './Entries';

const EntryView = (props) => {
    const { entry, audit } = props;

    const getGroup = () => {
        if (!entry)
            return null;

        let retVal = null;

        switch (entry.groupCode) {
            case "PROJECT": retVal = `${entry.group}: ${entry.project} `; break
            case "PROTOCOL": retVal = `${entry.group} - ${entry.type}: ${entry.protocol}`; break
            default:
                retVal = `Evolución ${entry.group}`;
        }

        return <>{retVal} {customDateTimeFormatter(entry.created)} {audit && <AuditTooltip id="visitText" text={audit} />}</>
    }

    if (!entry)
        return null;

    return (
        <ExpandableContent title={getGroup()} expandedAll={true} content={entry.groupCode.toLowerCase()}>
            <div dangerouslySetInnerHTML={{ __html: renderEvolutions(entry.evolutions) }} />
        </ExpandableContent>
    );
}
EntryView.defaultProps = {
    rows: 5
};

EntryView.propTypes = {
    entry: PropTypes.object.isRequired,
    audit: PropTypes.object,
};

export default EntryView;

