import authService from '../components/api-authorization/AuthorizeService';

const receiveAuthentication = 'AUTHENTICATION_RECEIVE';
const clearAuthentication = 'AUTHENTICATION_RECEIVE';

const initialState = { isAuthenticated: false, user: {} };

export const authUserActions = {

    clearAuthentication: () => async (dispatch) => {
        dispatch({ type: clearAuthentication });
    },
    requestAuthentication: () => async (dispatch) => {
        try {
            const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
            dispatch({ type: receiveAuthentication, isAuthenticated, user });
        }
        catch (error) {
            console.log(error)
        }
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === receiveAuthentication) {
        return {
            ...state,
            isAuthenticated: action.isAuthenticated,
            user: action.user,
        };
    }
    if (action.type === clearAuthentication) {
        return {
            ...state,
            isAuthenticated: false,
            user: null,
        };
    }

    return state;
};
