import { useState, useEffect } from "react";
import { getMasterDataSelect } from '../../api/admin'
import { getAdverseEvents, getPersonalHistory } from '../../api/medicalRecord'

export const useMasterData = (type) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        let isSubscribed = true

        const fetchData = () => {
            getMasterDataSelect(type).then(response => {
                if (isSubscribed) {
                    var data = JSON.parse(response.data);
                    setData(data[type]);
                }
            }).catch(error => {
                if (isSubscribed) {
                    setData([]);
                    console.log(error)
                }
            });
        };

        if (type)
            fetchData();

        return () => isSubscribed = false;
    }, [type]);

    return data;
}

export const useAdverseEvents = (entryId, medicalRecordId) => {
    const [events, setEvents] = useState([]);

    const query = {
        entryId: entryId,
        medicalRecordId: medicalRecordId,
        pagination: {
            page: 1,
            sizePerPage: 100,
            totalSize: 0
        }
    };
    var groups = [
        {
            label: 'Eventos Adversos',
            options: [],
        },
        {
            label: 'Antecedentes Personales',
            options: [],
        },
        {
            label: 'Otros',
            options: [{ id: 0, adverseEffect: "Profilaxis" }],
        }
    ];

    useEffect(() => {
        let isSubscribed = true;

        const fetchData = async () => {
            try {
                const response = await getAdverseEvents(query);
                if (isSubscribed) {
                    var resEvents = response.data;
                    resEvents.data.forEach(event => { event.reasonId = event.id; });
                    groups[0].options = resEvents.data;

                    const res = await getPersonalHistory(query);
                    if (isSubscribed) {
                        let resPersonalHistory = res.data;
                        resPersonalHistory.data.forEach(event => { event.reasonId = event.id; });
                        groups[1].options = resPersonalHistory.data;

                        setEvents(groups);
                    }
                }
            }
            catch (error) {
                if (isSubscribed) {
                    setEvents([]);
                }
            }
        };

        fetchData();

        return () => isSubscribed = false;
    }, []);

    return events;
}

export const useCollapsable = () => {
    const [isCollapse, setIsCollapse] = useState(false);

    function toggle() {
        setIsCollapse(!isCollapse);
    }

    return [
        isCollapse,
        toggle
    ]
};