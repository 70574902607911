import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { ProtocolProvider } from '../../containers/Protocols/ProtocolContext/ProtocolProvider';
import { formTypeFields, formTypes, snomedExpressions } from "../../variables/Enums";
import { RemoveModal } from "../Protocols/Visit/Modals";
import { DynamicFormComponents } from './DynamicFormComponents';
import { DynamicFormControls } from './DynamicFormControls';
import { DynamicFormCreatorViewer } from './DynamicFormCreatorViewer';
import { DynamicFormHeader } from './DynamicFormHeader';
import { DynamicOptionsFormik } from './DynamicFormOptions';

export const DynamicFormCreator = ({ protocolId, visitId, initialForm, formTemplates, isDisabled, isLoading, backUrl, onSave, onRemove, onSaveAsTemplate, onRemoveTemplate }) => {
    const { t } = useTranslation();
    const [form, setForm] = useState(null);
    const [formType, setFormType] = useState(null);
    const [fields, setFields] = useState([]);
    const [fieldConfig, setFieldConfig] = useState(null);
    const [fieldSettings, setFieldSettings] = useState(null);
    const [modal, setModal] = useState(null);

    useEffect(() => {
        setForm(initialForm);
        setFormType(initialForm?.type);
        setFields(initialForm?.fields || []);
    }, [initialForm]);

    function _getSnomed(type) {
        switch (type) {
            case "AdverseEvent": return snomedExpressions.Hallazgos;
            case "Drug": return snomedExpressions.Producto_O_Sustancia;
            case "Procedure": return snomedExpressions.Procedimiento;
            default: return snomedExpressions.Hallazgos;
        }
    }

    function _configField(field) {
        switch (field._t) {
            case 'TextField':
                field.label = t("protocols.visit.formTemplate.label_txtField");
                break;
            case 'SingleLineField':
            case 'MultiLineField':
            case 'YesNoField':
            case 'DateField':
            case 'TimeField':
            case 'DateTimeField':
            case 'CheckboxField':
            case 'AdverseEventsAsyncField':
            case 'FileUploadField':
                field.required = false;
                field.placeholder = "placeholder";
                field.value = null;
                break;
            case 'PayableField':
            case 'BooleanField':
                field.required = false;
                field.placeholder = "placeholder";
                field.value = null;
                field.conditional = [];
                break;
            case 'IntegerField':
            case 'DecimalFIeld':
                field.required = false;
                field.placeholder = "placeholder";
                field.value = null;
                field.text = "";
                field.textposition = 1;
                break;
            case 'RadioField':
            case 'MultiCheckBoxField':
            case 'SelectField':
                field.required = false;
                field.placeholder = "placeholder";
                field.value = null;
                field.options = 'Ejemplo de Opción 1\nEjemplo de Opción 2';
                break;
            case 'SelectAsyncField':
                field.required = false;
                field.placeholder = "placeholder";
                field.value = null;
                field.note = t("protocols.visit.formTemplate.label_selectAsyncField");
                field.masterData = "";
                field.options = [
                    { code: "Actions", title: t("protocols.visit.formTemplate.options_selectAsyncField.actions") },
                    { code: "EntryGroups", title: t("protocols.visit.formTemplate.options_selectAsyncField.entryGroups") },
                    { code: "EntryTypes", title: t("protocols.visit.formTemplate.options_selectAsyncField.entryTypes") },
                    { code: "Intensity", title: t("protocols.visit.formTemplate.options_selectAsyncField.intensity") },
                    { code: "RelationWithDrugs", title: t("protocols.visit.formTemplate.options_selectAsyncField.relationWithDrugs") },
                    { code: "Relatives", title: t("protocols.visit.formTemplate.options_selectAsyncField.relatives") },
                    { code: "RoutesOfAdministration", title: t("protocols.visit.formTemplate.options_selectAsyncField.routesOfAdministration") },
                    { code: "Units", title: t("protocols.visit.formTemplate.options_selectAsyncField.units") },
                    { code: "Frequencies", title: t("protocols.visit.formTemplate.options_selectAsyncField.frequencies") },
                ];
                break;
            case 'SnomedField':
                field.required = false;
                field.placeholder = "placeholder";
                field.value = null;
                field.snomedExpression = _getSnomed(field.syncTo); //"404684003";
                field.note = t("protocols.visit.formTemplate.label_snomedField")
                break;
            case 'ProcedureSnomedField':
                field.required = true;
                field.placeholder = "";
                field.syncTo = "Procedure";
                field.snomedExpression = snomedExpressions.Procedimiento;
                field.note = t("protocols.visit.formTemplate.label_procedureSnomedField")
                break;
            default:
                break;
        }
    }

    const handleAddFormFieldControlClick = (selectedField) => {

        let field = {
            _t: selectedField.fieldType,
            order: fields.length + 1,
            name: selectedField.fieldType,
            syncTo: selectedField.value,
            label: t(`protocols.visit.formTemplate.formTypeFields.${selectedField.value}`),
            note: '',
        }

        _configField(field);

        let fieldsCopy = JSON.parse(JSON.stringify(fields));
        fieldsCopy.push(field);
        setFields(fieldsCopy);
        setFieldSettings({ type: "field", fieldIndex: fieldsCopy.length - 1 });
        field._t !== "DividerField" && setFieldConfig(field);
    }
    const handleAddControlClick = (event) => {
        let code = event.currentTarget.name;
        let fieldsCopy = JSON.parse(JSON.stringify(fields));
        let field = {
            _t: code,
            id: uuidv4(),
            order: fieldsCopy.length + 1,
            name: code,
            label: code,
            note: '',
            syncTo: ''
        }

        _configField(field);

        fieldsCopy.push(field);
        setFields(fieldsCopy);
        setFieldSettings({ type: "field", fieldIndex: fieldsCopy.length - 1 });
        field._t !== "DividerField" && setFieldConfig(field);
    }
    const handleAddComponentsClick = (template) => {

        if (!template || !template.fields)
            return;

        let fieldsCopy = JSON.parse(JSON.stringify(fields));
        const currentFieldsCount = fieldsCopy.length;

        template.fields.forEach((field, index) => {
            field.id = field.id ?? uuidv4();
            field.order = currentFieldsCount + index;
            fieldsCopy.push(field);
        });

        if (formTypes.find(x => x.value === template.type))
            setFormType(template.type)
        setFields(fieldsCopy);
    }
    const handleRemoveClick = (fieldIndex) => {
        let result = fields.filter((_field, index) => index != fieldIndex);
        setFields(result);
        setFieldConfig(null);
    }

    const handleRemoveFromTemplateClick = (fieldIndex, templateFieldIndex) => {
        let fieldsCopy = JSON.parse(JSON.stringify(fields));
        fieldsCopy[fieldIndex].template = fields[fieldIndex].template.filter((_field, index) => index != templateFieldIndex);
        setFields(fieldsCopy);
        setFieldConfig(null);
    }

    const handleConfigClick = (fieldIndex) => {
        let field = JSON.parse(JSON.stringify(fields[fieldIndex]));
        setFieldConfig(field);
        setFieldSettings({ type: "field", fieldIndex })
    }
    const handleOptionsSubmit = (options) => {
        let fieldsCopy = JSON.parse(JSON.stringify(fields));

        if (fieldSettings.type === "field") {
            fieldsCopy.splice(fieldSettings.fieldIndex, 1, options); //Reemplazo field. 
        }
        else if (fieldSettings.type === "arrayField") {
            fieldsCopy[fieldSettings.fieldIndex].template.splice(fieldSettings.templateFieldIndex, 1, options); //Reemplazo field. 
        }

        setFields(fieldsCopy);
        setFieldConfig(null);
        setFieldSettings(null);
    }

    const handleConfigFromTemplateClick = (fieldIndex, templateFieldIndex) => {
        let field = JSON.parse(JSON.stringify(fields[fieldIndex].template[templateFieldIndex]));
        setFieldConfig(field);
        setFieldSettings({ type: "arrayField", fieldIndex, templateFieldIndex });
    }

    //aca llamo a container.
    const handleSaveClick = (formHeader) => {
        let formCopy = JSON.parse(JSON.stringify(form));
        formCopy.title = formHeader.title;
        formCopy.type = formHeader.type ?? formType;
        setForm(formCopy); // Header del form
        if (fields.length == 0 || !onSave) {
            return;
        }

        formCopy.fields = fields;
        formCopy._Fields = "";

        onSave(formCopy);
    }

    const handleSortEnd = (oldIndex, newIndex) => {

        if (oldIndex == newIndex)
            return;

        // Hago sort en cliente. 
        let copiedFields = fields.slice();
        copiedFields.splice(newIndex < 0 ? copiedFields.length + newIndex : newIndex, 0, copiedFields.splice(oldIndex, 1)[0]);

        copiedFields.forEach((field, index) => {
            field.order = index + 1;
        });

        setFieldConfig(null);
        setFields(copiedFields);
    };

    const handleRemoveForm = (formId) => {
        setModal(<RemoveModal
            id={formId}
            title={t("commons.areyousure")}
            onClose={() => { setModal(null); }}
            onConfirm={handleConfirmRemove} />)
    }

    //aca llamo a container.
    const handleConfirmRemove = (id) => {
        if (!onRemove) return;

        onRemove(id);
    }

    const handleTypeChange = (type) => {
        setFormType(type)
    }

    const handleClose = () => {
        setFieldConfig(null);
    }

    //aca llamo a container.
    const handleSaveAsTemplate = async () => {
        let formCopy = JSON.parse(JSON.stringify(form));
        if (fields.length == 0 || !onSaveAsTemplate)
            return;

        formCopy.fields = fields;
        formCopy._Fields = "";
        onSaveAsTemplate(formCopy);
    }
    //aca llamo a container.
    const handleRemoveTemplate = async (templateId) => {
        if (!onRemoveTemplate)
            return;

        onRemoveTemplate(templateId);
    }

    const handleChangeLaboTemplate = (fieldIndex, labos) => {
        let fieldsCopy = JSON.parse(JSON.stringify(fields));

        let fieldArrayLabo = fieldsCopy[fieldIndex];
        if (!fieldArrayLabo) return;

        fieldArrayLabo.components = labos;
        fieldsCopy.splice(fieldIndex, 1, fieldArrayLabo); //Reemplazo field. 
        setFields(fieldsCopy);
    }

    return (
        <ProtocolProvider protocolId={protocolId}>
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        {modal}
                        {
                            form &&
                            <DynamicFormHeader
                                onSubmit={handleSaveClick}
                                onRemove={handleRemoveForm}
                                onSaveAsTemplate={handleSaveAsTemplate}
                                onTypeChange={handleTypeChange}
                                backUrl={backUrl}
                                protocolId={protocolId}
                                visitId={visitId}
                                form={form}
                                formType={formType}
                                isDisabled={isDisabled || isLoading}
                                showSaveAsTemplate={onSaveAsTemplate || false}
                            />
                        }
                    </Col>
                </Row>
                <Row className="display-flex">
                    <Col md={2}>
                        <Row>
                            <Col md={12}>
                                <DynamicFormControls
                                    formFieldTypes={formTypeFields.find(x => x.type === formType)?.fields}
                                    onAddControlClick={handleAddControlClick}
                                    onAddFormFieldControlClick={handleAddFormFieldControlClick}
                                    isDisabled={isDisabled}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <DynamicFormComponents
                                    isLoading={isLoading}
                                    onAddComponentClick={handleAddComponentsClick}
                                    onRemoveTemplate={handleRemoveTemplate}
                                    templates={formTemplates}
                                    isDisabled={isDisabled}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        {
                            fields && <DynamicFormCreatorViewer
                                fields={fields}
                                onConfigClick={handleConfigClick}
                                onRemoveClick={handleRemoveClick}
                                onRemoveFromTemplateClick={handleRemoveFromTemplateClick}
                                onConfigFromTemplateClick={handleConfigFromTemplateClick}
                                onSortEnd={handleSortEnd}
                                onChangeLaboTemplate={handleChangeLaboTemplate}
                                isDisabled={isDisabled}
                            />
                        }
                    </Col>
                    <Col md={4}>
                        {
                            fieldConfig && <DynamicOptionsFormik
                                fields={fields}
                                fieldOptions={fieldConfig}
                                formType={formType}
                                onSubmit={handleOptionsSubmit}
                                onClose={handleClose}
                            />
                        }
                    </Col>
                </Row>
            </Grid>
        </ProtocolProvider>
    );
}

DynamicFormCreator.propTypes = {
    onSave: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onSaveAsTemplate: PropTypes.func,
    onRemoveTemplate: PropTypes.func,
    protocolId: PropTypes.number.isRequired,
    visitId: PropTypes.number.isRequired,
    formId: PropTypes.number.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    formTemplates: PropTypes.array.isRequired
};