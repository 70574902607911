import { useState, useEffect } from "react";
import { HandleApiError } from "../../components/Notifications/APIErrorHandler";
import { getMasterData, getPatients, getPatient } from '../../api/database'

export const usePatients = (query) => {
    const [patients, setPatients] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalSize, setTotalSize] = useState(0);

    useEffect(() => {
        let isSubscribed = true
        const getAllPatients = async () => {
            try {
                const paginatedPatients = await getPatients(query);

                if (isSubscribed) {
                    setTotalSize(paginatedPatients.data.count);
                    setPatients(paginatedPatients.data.data);
                    setIsLoading(false);
                }
            } catch (error) {
                HandleApiError(error);
                setIsLoading(false);
            }
        }

        getAllPatients();

        return () => isSubscribed = false

    }, [query]);

    return [patients, isLoading, totalSize];
}

export const usePatient = (medicalRecordNumber) => {
    const [patient, setPatient] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let isSubscribed = true
        const fetchData = async () => {
            try {
                const patient = await getPatient(medicalRecordNumber, true);

                if (isSubscribed) {
                    setPatient(patient.data);
                    setIsLoading(false);
                }
            } catch (error) {
                HandleApiError(error);
                setIsLoading(false);
            }
        }

        fetchData();

        return () => isSubscribed = false;
    }, [medicalRecordNumber]);

    return [patient, isLoading];
}

export const useMasterData = () => {
    const [masterData, setMasterData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = () => {
            getMasterData().then(res => {
                setMasterData(res.data);
                setIsLoading(false);
            }).catch(error => {
                HandleApiError(error);
                setIsLoading(false);
            });
        }

        fetchData();
    }, []);

    return [masterData, isLoading];
}