import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import profilePic from "../../assets/img/faces/face-0.jpg";
import "../../assets/sass/lbd/_skeleton.scss";
import Card from '../Cards/Card';
import { CustomImage } from "../CustomControls/Image";
import { ReadMoreLines } from '../CustomControls/ReadMore';
import { BirthDate, Identification, MedicalInsurance, MedicalInsuranceNumber, MedicalRecord } from "../Icons/Icons";
import { dateFormatter } from '../Utils/Formatter';

export const PatientInfo = (props) => {
    const { patient, isLoading } = props;
    const { t } = useTranslation();

    if (isLoading || !patient)
        return <PatientInfoSkeleton />;

    const ageComponent = patient.age ? <>({patient.age} {t('medicalRecords.patientInfo.age')})</> : null;
    //console.log(patient)
    return (
        <Card
            className="card-patient"
            content={
                <Row className="display-flex">
                    <Col sm={4} mdHidden lg={4}>
                        <div className="patient-avatar">
                            <CustomImage
                                src={patient.photo ?? profilePic}
                                defaultSrc={profilePic}
                            />
                        </div>
                    </Col>
                    <Col sm={8} md={12} lg={8}>
                        <Row>
                            <div className="info">
                                <div className="name">{patient.fullName}</div>
                            </div>
                        </Row>
                        <Row>
                            <Col sm={6} style={{ paddingLeft: 0 }}>
                                <div className="info">
                                    <div><span className="card-label">{t('medicalRecords.patientInfo.birthDate')}:</span> <span className="card-text">{moment(patient.birthDate).format('DD/MM/YY')} {ageComponent}</span></div>
                                    <div><span className="card-label">{patient.identificationType}:</span> <span className="card-text">{patient.identificationNumber}</span></div>
                                    {patient.medicalInsurance && <div><span className="card-label">{t('medicalRecords.patientInfo.medicalInsurance')}:</span> <span className="card-text">{patient.medicalInsurance} {patient.medicalInsurancePlan ? <>({patient.medicalInsurancePlan})</> : null}</span></div>}
                                    {patient.medicalInsuranceNumber && <div><span className="card-label">{t('medicalRecords.patientInfo.medicalInsuranceNumber')}:</span> <span className="card-text">{patient.medicalInsuranceNumber}</span></div>}
                                    <div><span className="card-label">{t('medicalRecords.patientInfo.medicalRecord')}:</span> <span className="card-text">{patient.medicalRecordNumber}</span></div>
                                </div>
                            </Col>
                            <Col sm={6} style={{ paddingLeft: 0 }}>
                                <div className="info">
                                    <div>
                                        <span className="card-label">{t('person.address.address')}: </span>
                                        <span className="card-text">
                                            <ReadMoreLines maxLine={1} content={patient.address} />
                                        </span>
                                    </div>
                                    <div><span className="card-label">{t('person.contact.cellPhone')}:</span> <span className="card-text">{patient.cellPhone}</span></div>
                                    <div><span className="card-label">{t('person.contact.email')}:</span> <span className="card-text">{patient.email}</span></div>
                                    {patient.protocols?.length > 0 && <div><span className="card-label">{t('medicalRecords.patientInfo.protocol')}:</span> <span className="card-text">{patient.protocols[0].title}</span></div>}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
        />
    );
}

PatientInfo.propTypes = {
    patient: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

const PatientInfoSkeleton = () => {
    return (
        <Card
            className="card-patient"
            content={
                <Row className="display-flex">
                    <Col sm={4} mdHidden lg={4}>
                        <div className="patient-avatar">
                            <CustomImage
                                src={profilePic}
                                defaultSrc={profilePic}
                            />
                        </div>
                    </Col>
                    <Col sm={8} md={12} lg={8}>
                        <Row>
                            <div className="info">
                                <div className="name skeleton-box"></div>
                            </div>
                        </Row>
                        <Row>
                            <Col sm={12} style={{ paddingLeft: 0 }}>
                                <div className="info">
                                    <div>
                                        <span className="card-text skeleton-box"></span>
                                    </div>
                                    <div>
                                        <span className="card-text skeleton-box"></span>
                                    </div>
                                    <div>
                                        <span className="card-text skeleton-box"></span>
                                    </div>
                                    <div>
                                        <span className="card-text skeleton-box"></span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
        />
    );
}


export const PatientInfo2 = ({ patient, isLoading }) => {
    const { t } = useTranslation();

    if (isLoading || !patient)
        return <PatientInfo2Skeleton />;

    return (
        <Card
            className="card-patient-horizontal"
            content={
                <Grid fluid >
                    <Row>
                        <Col md={3}>
                            <Col md={4}>
                                <div className="patient-avatar">
                                    <CustomImage
                                        src={patient.photo ?? profilePic}
                                        defaultSrc={profilePic}
                                    />
                                </div>
                            </Col>
                            <Col md={8}>
                                <div className="">
                                    <div>
                                        <div className="title">{patient.fullName}</div>
                                        <div className="group">{patient.age} años ({dateFormatter(patient.birthDate, "DD/MMM/YYYY")})</div>
                                    </div>
                                </div>
                            </Col>
                        </Col>
                        <Col md={9}>
                            <Col sm={2}>
                                <div className="">
                                    <div className="icon">
                                        <div className="svg-container"><MedicalRecord /></div>
                                    </div>
                                    <div>
                                        <div className="group">{t("medicalRecords.patientInfo.medicalRecord")}</div>
                                        <div className="title">{patient.medicalRecordNumber}</div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className="">
                                    <div className="icon">
                                        <div className="svg-container"><Identification /></div>
                                    </div>
                                    <div>
                                        <div className="group">{patient.identificationType}</div>
                                        <div className="title">{patient.identificationNumber}</div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="">
                                    <div className="icon">
                                        <div className="svg-container"><MedicalInsurance /></div>
                                    </div>
                                    <div>
                                        <div className="group">{t("medicalRecords.patientInfo.medicalInsurance")}</div>
                                        <div className="title">{patient.medicalInsurance}</div>
                                        <div className="title">{patient.medicalInsurancePlan}</div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className="">
                                    <div className="icon">
                                        <div className="svg-container"><MedicalInsuranceNumber /></div>
                                    </div>
                                    <div>
                                        <div className="group">{t("medicalRecords.patientInfo.medicalInsuranceNumber")}</div>
                                        <div className="title">{patient.medicalInsuranceNumber}</div>
                                    </div>
                                </div>
                            </Col>

                        </Col>
                    </Row>
                </Grid >
            }
        />
    );
}

PatientInfo2.propTypes = {
    patient: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

const PatientInfo2Skeleton = () => {
    const { t } = useTranslation();
    return (
        <Card
            className="card-protocol-info"
            content={
                <Grid fluid >
                    <Row>
                        <Col md={3}>
                            <Col md={12}>
                                <div className="">
                                    <div>
                                        <div className="title skeleton-box"></div>
                                        <div className="group skeleton-box"></div>
                                    </div>
                                </div>
                            </Col>
                        </Col>
                        <Col md={9}>
                            <Col sm={2}>
                                <div className="">
                                    <div className="icon">
                                        <div className="svg-container"><BirthDate /></div>
                                    </div>
                                    <div>
                                        <div className="group">{t("medicalRecords.patientInfo.birthDate")}</div>
                                        <div className="title skeleton-box"></div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className="">
                                    <div className="icon">
                                        <div className="svg-container"><Identification /></div>
                                    </div>
                                    <div>
                                        <div className="group skeleton-box"></div>
                                        <div className="title skeleton-box"></div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="">
                                    <div className="icon">
                                        <div className="svg-container"><MedicalInsurance /></div>
                                    </div>
                                    <div>
                                        <div className="group">{t("medicalRecords.patientInfo.medicalInsurance")}</div>
                                        <div className="title skeleton-box"></div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className="">
                                    <div className="icon">
                                        <div className="svg-container"><MedicalRecord /></div>
                                    </div>
                                    <div>
                                        <div className="group">{t("medicalRecords.patientInfo.medicalRecord")}</div>
                                        <div className="title skeleton-box"></div>
                                    </div>
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </Grid >
            }
        />
    );
}

export const PatientInfo3 = ({ patient, isLoading }) => {
    const { t } = useTranslation();

    if (isLoading || !patient)
        return <PatientInfo2Skeleton />;

    return (
        <Card
            className="card-patient-horizontal"
            content={
                <Grid fluid >
                    <Row>
                        <Col md={4} className="margin">
                            <Col md={4}>
                                <div className="patient-avatar">
                                    <CustomImage
                                        src={patient.photo ?? profilePic}
                                        defaultSrc={profilePic}
                                    />
                                </div>
                            </Col>
                            <Col md={8}>
                                <div>
                                    <div className="title">{patient.fullName}</div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div>
                                    <div className="group">{patient.age} años</div>
                                    <div className="group">({dateFormatter(patient.birthDate, "DD/MMM/YYYY")})</div>
                                </div>
                            </Col>
                        </Col>
                        <Col md={8} className="margin">
                            <Col sm={6}>
                                <div className="">
                                    <div className="icon">
                                        <div className="svg-container"><MedicalRecord /></div>
                                    </div>
                                    <div>
                                        <div className="group">{t("medicalRecords.patientInfo.medicalRecord")}</div>
                                        <div className="title">{patient.medicalRecordNumber}</div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="">
                                    <div className="icon">
                                        <div className="svg-container"><Identification /></div>
                                    </div>
                                    <div>
                                        <div className="group">{patient.identificationType}</div>
                                        <div className="title">{patient.identificationNumber}</div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="">
                                    <div className="icon">
                                        <div className="svg-container"><MedicalInsurance /></div>
                                    </div>
                                    <div>
                                        <div className="group">{t("medicalRecords.patientInfo.medicalInsurance")}</div>
                                        <div className="title">{patient.medicalInsurance}</div>
                                        <div className="title">{patient.medicalInsurancePlan}</div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="">
                                    <div className="icon">
                                        <div className="svg-container"><MedicalInsuranceNumber /></div>
                                    </div>
                                    <div>
                                        <div className="group">{t("medicalRecords.patientInfo.medicalInsuranceNumber")}</div>
                                        <div className="title">{patient.medicalInsuranceNumber}</div>
                                    </div>
                                </div>
                            </Col>

                        </Col>
                    </Row>
                </Grid >
            }
        />
    );
}

PatientInfo3.propTypes = {
    patient: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
};