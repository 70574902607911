import React from 'react';
import { useTranslation } from 'react-i18next';
import { Chart } from '../Charts/Chart';
import { dateFormatter } from '../Utils/Dates';
import { EntryItemsTable } from './EntryItemsTable';

export const LaboratoryResultHistory = (props) => {
    const { t } = useTranslation();

    const columns = [
        { dataField: 'laboratoryDate', text: t('commons.date'), formatter: function (cell) { return dateFormatter(cell, "DD/MM/YY") }, headerClasses: 'header-date' },
        { dataField: 'name.description', text: t('medicalRecords.laboratory.laboratory') },
        {
            dataField: 'result', text: t('medicalRecords.laboratory.result'),
            formatter: function (_cell, row) {
                return <div>{`${row.result} ${row.unit?.title}`}</div>
            }
        }
    ];

    return (<EntryItemsTable
        {...props}
        withCard={false}
        columns={columns}
    />);
}

export const LaboratoryResultHistoryChart = ({ data, type }) => {

    if (!data || data.length === 0)
        return null;

    const options = {
        responsive: true,
        plugins: {
            datalabels: {
                color: '#247dbd',
                align: 'top',
            },
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: `${data[0].name.description} (${data[0].unit.title})`,
            },
        },
    };

    const chartData = {
        labels: data.sort(function (a, b) {
            return new Date(a.laboratoryDate) - new Date(b.laboratoryDate);
        }).map(x => dateFormatter(x.laboratoryDate)),
        datasets: [
            {
                label: `${data[0].name.description} (${data[0].unit.title})`,
                data: data.map(x => x.result),
                borderColor: 'rgb(32, 173, 155)',
                backgroundColor: 'rgba(32, 173, 155, 0.19)',
            },
        ],
    };

    return (<Chart options={options} data={chartData} type={type} />);
}