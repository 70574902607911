import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from "react-router-dom";
import { removeRole } from "../../../api/security";
import { Roles } from "../../../components/Authorization/Roles";
import { WithAuth } from "../../../components/Authorization/WithAuth";
import Card from "../../../components/Cards/Card";
import { Add } from "../../../components/Icons/Icons";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import { Success } from "../../../components/Notifications/Notifications";
import AdminContext from "../../Settings/AdminContext/AdminContext";
import { useRoles } from "./useRoles";


export const RolesContainer = () => {
    let history = useHistory();
    const { t } = useTranslation();
    const [query, setQuery] = useState({
        filters: {}
    });

    const [roles, isLoading, reloadRoles] = useRoles(query);
    const context = useContext(AdminContext);

    useEffect(() => {
        var newQuery = JSON.parse(JSON.stringify(query));
        newQuery.filters["TenantId"] = context?.selectedTenant != null ? context.selectedTenant.tenantId : null;
        setQuery(newQuery);

    }, [context]);

    const handleEditRole = (roleId) => {
        history.push(`/admin/settings/roles/${roleId}`);
    }

    const handleRemoveRole = (roleId) => {
        if (roleId) {
            removeRole(roleId)
                .then(res => {
                    reloadRoles(true);
                    Success("security.roles.removed");
                })
                .catch(error => { HandleApiError(error); })
        }
    }

    return (
        <Card
            tabs
            header={
                <WithAuth requiredPermission={["Security.Create"]}>
                    <NavLink to={`/admin/settings/roles/create`} className="btn btn-primary" activeClassName="active">
                        <Add /> {t("security.roles.add")}
                    </NavLink>
                </WithAuth>
            }
            content={
                <Roles
                    roles={roles}
                    onEdit={handleEditRole}
                    onRemove={handleRemoveRole}
                    isLoading={isLoading}
                />
            }
        />
    );
}