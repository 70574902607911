import { useEffect, useState } from 'react';
import { getPersonalHabit, getPersonalHabits, postPersonalHabit, putPersonalHabit, } from '../../../api/medicalRecord';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';

export const usePersonalHabits = (medicalRecordNumber, entryId) => {
    
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        let isSubscribed = true
        const fetchData = async () => {
            try {
                setReload(false);
                setIsLoading(true);
                const res = await getPersonalHabits(medicalRecordNumber, entryId);

                if (isSubscribed) {
                    setData(res.data);
                    setIsLoading(false);
                }

            } catch (error) {
                HandleApiError(error);
                setData([]);
            }
        }

        fetchData();

        return () => isSubscribed = false;
    }, [medicalRecordNumber, reload]);

    return [data, isLoading, setReload];
}

export const usePersonalHabit = (entryId, id) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        let isSubscribed = true
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const res = await getPersonalHabit(entryId, id);

                if (isSubscribed) {
                    setData(res.data);
                    setIsLoading(false);
                }

            } catch (error) {
                HandleApiError(error);
                setIsLoading(false);
                setData(null);
            }
        }

        if (id)
            fetchData();

        return () => isSubscribed = false;
    }, [entryId, id]);

    const create = (entryId, data) => {
        return postPersonalHabit(entryId, data);
    }

    const update = (entryId, id, data) => {
        return putPersonalHabit(entryId, id, data);
    }

    return [data, isLoading, create, update];
}