import React, { useEffect, useState } from "react";
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import FreezerProtocolServices from "../../../api/Freezer/FreezerProtocolServices";
import { withTranslation } from "react-i18next";
import { withFormik } from "formik";
import { getFreezerConfigurationSchema } from "../../../components/Validations/FormValidationSchema";
import { FormErrorMessage } from "../../../components/Validations/FormValidationErrors";
import { ErrorMessage as ErrorNotification, Success } from "../../../components/Notifications/Notifications";
import { ErrorMessage } from 'formik';
import { CustomSelect } from "../../../components/CustomControls/CustomSelect";
import { EnumValue } from "../../../api/Freezer/Entities/Utils";
import Loader from "../../../components/Common/Loader";
import useLoading from "../../../components/Common/useLoading";

export const ConfigurationFreezerFormik = withTranslation()(withFormik({
    mapPropsToStatus: ({ configuration, protocolId }) => ({ configuration, protocolId }),
    validationSchema: getFreezerConfigurationSchema(),
    handleSubmit: async (values, formikBag) => {
        try {
            formikBag.setSubmitting(true);
            const result = await FreezerProtocolServices.Configure({ ...values.configuration, AssignedFreezers: values.configuration.AssignedFreezers })
            if (result.Succeeded) {
                Success("commons.successSave")
                formikBag.props.onSubmit();
            }
            else {
                ErrorNotification("errors.serverError")
                formikBag.props.onSubmit(false);
            }
        } catch (err) {
            ErrorNotification("errors.serverError")
            formikBag.props.onSubmit(false);
        } finally {
            formikBag.setSubmitting(false);
        }
    },
    displayName: 'ConfigureFreezerForm'
})(ConfigureFreezerForm))

function ConfigureFreezerForm({ values, t, handleSubmit, handleChange, handleBlur, onCancel, isSubmitting, setFieldValue, setFieldTouched }) {
    const [selecteableFreezers, setSelecteableFreezers] = useState([]);
    const [currentFreezers, setCurrentFreezers] = useState([]);
    const [selectedFreezers, setSelectedFreezers] = useState([])
    const [isLoading, reload] = useLoading({
        onLoad: async () => {
            const unassignedFreezers = await FreezerProtocolServices.GetUnassignedFreezers(values.protocolId)
            setSelecteableFreezers(unassignedFreezers.Value?.map(f => new EnumValue(f.Id, f.Description)) ?? [])
        }
    })

    useEffect(() => {
        setCurrentFreezers([...(values.configuration?.AssignedFreezers?.map(f => f.Id) ?? [])])
        values.configuration = {
            ...values.configuration
            , AssignedFreezers: [...(values.configuration?.AssignedFreezers?.map(f => f.Id) ?? [])]
            , Id: values.protocolId
        };
    }, [])

    useEffect(() => {
        configuration.AssignedFreezers = [...new Set([...currentFreezers, ...selectedFreezers])];
    }, [JSON.stringify(selectedFreezers)])
    const { configuration } = values;

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <Loader isLoading={isLoading}>
                <Row>
                    <Col sm={12} lg={6}>
                        <FormGroup>
                            <ControlLabel>{t('protocols.freezer.minTemperature')}</ControlLabel>
                            <FormControl type="number" name={'configuration.MinTemperature'} value={configuration.MinTemperature}
                                autoComplete="off"
                                onChange={handleChange}
                                onBlur={handleBlur} />
                            <ErrorMessage name={'configuration.MinTemperature'} component={FormErrorMessage} />
                        </FormGroup>
                    </Col>
                    <Col sm={12} lg={6}>
                        <FormGroup>
                            <ControlLabel>{t('protocols.freezer.maxTemperature')}</ControlLabel>
                            <FormControl type="number" name={'configuration.MaxTemperature'} value={configuration.MaxTemperature}
                                autoComplete="off"
                                onChange={handleChange}
                                onBlur={handleBlur} />
                            <ErrorMessage name={'configuration.MaxTemperature'} component={FormErrorMessage} />
                        </FormGroup>
                    </Col>
                    <Col sm={12}>
                        <FormGroup>
                            <ControlLabel>{t('protocols.freezer.freezers')}</ControlLabel>
                            {selecteableFreezers && selecteableFreezers.length > 0 &&
                                <CustomSelect
                                    isMulti={true}
                                    onChange={(e, v) => {
                                        setSelectedFreezers(v?.map(v => v.Value) ?? [])
                                    }}
                                    onBlur={(e, v) => { }}
                                    name={"configuration.AssignedFreezers"}
                                    options={selecteableFreezers}
                                    getOptionLabel={(option) => option.Name}
                                    getOptionValue={(option) => option.Value}
                                />
                                || <div className="text-center"><strong>{t('protocols.freezer.noAssignibleFreezers')}</strong></div>}
                        </FormGroup>
                    </Col>
                </Row>

                <Grid fluid>
                    <Row>
                        <Col sm={3} smOffset={6}>
                            <button type="button" className="btn btn-info btn-right" onClick={onCancel} >{t("buttons.back")}</button>
                        </Col>
                        <Col sm={3}>
                            <button type="submit" className="btn btn-success btn-right" disabled={isSubmitting} >{t("buttons.save")}</button>
                        </Col>
                    </Row>
                </Grid>
            </Loader>
        </form>
    )
}

